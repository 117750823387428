//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfRolesService } from './wfroles.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WfRolesServiceEntitySetConfig = {
  name: 'WfRoles',
  entityType: 'OptiaApi.DataDomain.EFEntities.Workflow.WfRoleEdm',
  service: WfRolesService
} as EntitySetConfig;
//#endregion