import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyPersonWfRoleEdm } from './DataDomain/EFEntities/Agency/agencypersonwfroleedm.entity';
import { WfRoleEdm } from './DataDomain/EFEntities/Workflow/wfroleedm.entity';
import { WfTaskRoleEdm } from './DataDomain/EFEntities/Workflow/wftaskroleedm.entity';
//#endregion

@Injectable()
export class WfRolesService extends ODataEntitySetService<WfRoleEdm> {
  constructor(client: ODataClient) {
    super(client, 'WfRoles', 'OptiaApi.DataDomain.EFEntities.Workflow.WfRoleEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public agencyPersonWfRoles(key: EntityKey<WfRoleEdm>): ODataNavigationPropertyResource<AgencyPersonWfRoleEdm> { 
    return this.entity(key).navigationProperty<AgencyPersonWfRoleEdm>('agencyPersonWfRoles'); 
  }
  public fetchAgencyPersonWfRoles(key: EntityKey<WfRoleEdm>, options?: ODataQueryArgumentsOptions<AgencyPersonWfRoleEdm>) {
    return this.fetchNavigationProperty<AgencyPersonWfRoleEdm>(
      this.agencyPersonWfRoles(key), 
      'entities', options) as Observable<ODataEntities<AgencyPersonWfRoleEdm>>;
  }
  public addAgencyPersonWfRoleEdmToAgencyPersonWfRoles(key: EntityKey<WfRoleEdm>, target: ODataEntityResource<ODataEntities<AgencyPersonWfRoleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyPersonWfRoles(key).reference()
      .add(target);
  }
  public removeAgencyPersonWfRoleEdmFromAgencyPersonWfRoles(key: EntityKey<WfRoleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyPersonWfRoleEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyPersonWfRoles(key).reference()
      .remove(target);
  }
  public roleGuidWfTaskRoles(key: EntityKey<WfRoleEdm>): ODataNavigationPropertyResource<WfTaskRoleEdm> { 
    return this.entity(key).navigationProperty<WfTaskRoleEdm>('roleGuidWfTaskRoles'); 
  }
  public fetchRoleGuidWfTaskRoles(key: EntityKey<WfRoleEdm>, options?: ODataQueryArgumentsOptions<WfTaskRoleEdm>) {
    return this.fetchNavigationProperty<WfTaskRoleEdm>(
      this.roleGuidWfTaskRoles(key), 
      'entities', options) as Observable<ODataEntities<WfTaskRoleEdm>>;
  }
  public addWfTaskRoleEdmToRoleGuidWfTaskRoles(key: EntityKey<WfRoleEdm>, target: ODataEntityResource<ODataEntities<WfTaskRoleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.roleGuidWfTaskRoles(key).reference()
      .add(target);
  }
  public removeWfTaskRoleEdmFromRoleGuidWfTaskRoles(key: EntityKey<WfRoleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfTaskRoleEdm>>, etag?: string} = {}): Observable<any> {
    return this.roleGuidWfTaskRoles(key).reference()
      .remove(target);
  }
  //#endregion
}
