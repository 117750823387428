import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

	message: string = "Are you sure?"
	confirmButtonText = "Yes"
	cancelButtonText = "Cancel"
	hideCancel: false;


	constructor(
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialogRef: MatDialogRef<ConfirmationDialogComponent>
	) {
		if (data) {
			this.message = data.message || this.message;
			if (data.buttonText) {
				this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
				this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
			}
			if (data.hideCancel) {
				this.hideCancel = data.hideCancel;
			}
		}
	}

	onConfirmClick(): void {
		this.dialogRef.close(true);
	}

}
