import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyEdm } from './DataDomain/EFEntities/Agency/agencyedm.entity';
import { TransferEdm } from './DataDomain/EFEntities/Reporting/transferedm.entity';
import { TransferAgencyEdm } from './DataDomain/EFEntities/Reporting/transferagencyedm.entity';
//#endregion

@Injectable()
export class TransferAgenciesService extends ODataEntitySetService<TransferAgencyEdm> {
  constructor(client: ODataClient) {
    super(client, 'TransferAgencies', 'OptiaApi.DataDomain.EFEntities.Reporting.TransferAgencyEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public agency(key: EntityKey<TransferAgencyEdm>): ODataNavigationPropertyResource<AgencyEdm> { 
    return this.entity(key).navigationProperty<AgencyEdm>('agency'); 
  }
  public fetchAgency(key: EntityKey<TransferAgencyEdm>, options?: ODataQueryArgumentsOptions<AgencyEdm>) {
    return this.fetchNavigationProperty<AgencyEdm>(
      this.agency(key), 
      'entity', options) as Observable<ODataEntity<AgencyEdm>>;
  }
  public setAgencyEdmAsAgency(key: EntityKey<TransferAgencyEdm>, target: ODataEntityResource<ODataEntity<AgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .set(target, {etag});
  }
  public unsetAgencyEdmAsAgency(key: EntityKey<TransferAgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .unset({etag});
  }
  public transfer(key: EntityKey<TransferAgencyEdm>): ODataNavigationPropertyResource<TransferEdm> { 
    return this.entity(key).navigationProperty<TransferEdm>('transfer'); 
  }
  public fetchTransfer(key: EntityKey<TransferAgencyEdm>, options?: ODataQueryArgumentsOptions<TransferEdm>) {
    return this.fetchNavigationProperty<TransferEdm>(
      this.transfer(key), 
      'entity', options) as Observable<ODataEntity<TransferEdm>>;
  }
  public setTransferEdmAsTransfer(key: EntityKey<TransferAgencyEdm>, target: ODataEntityResource<ODataEntity<TransferEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.transfer(key).reference()
      .set(target, {etag});
  }
  public unsetTransferEdmAsTransfer(key: EntityKey<TransferAgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<TransferEdm>>, etag?: string} = {}): Observable<any> {
    return this.transfer(key).reference()
      .unset({etag});
  }
  //#endregion
}
