//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { NotificationPersonEdm } from './notificationpersonedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const NotificationPersonEdmEntityConfig = {
  name: 'NotificationPersonEdm',
  keys: [{name: 'notificationGuid'},{name: 'personGuid'}],
  fields: {
    notificationGuid: {type: 'Edm.Guid'},
    personGuid: {type: 'Edm.Guid'},
    status: {type: 'Edm.Int16', nullable: false},
    dateRead: {type: 'Edm.DateTimeOffset'},
    notification: {type: 'OptiaApi.DataDomain.EFEntities.Notify.NotificationEdm', navigation: true, referentials: [{property: 'notificationGuid', referencedProperty: 'notificationGuid'}]},
    person: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<NotificationPersonEdm>;
//#endregion