//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DepartmentEdm } from './departmentedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const DepartmentEdmEntityConfig = {
  name: 'DepartmentEdm',
  keys: [{name: 'departmentGuid'}],
  fields: {
    departmentGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String'},
    tag: {type: 'Edm.String'},
    personDepartments: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonDepartmentEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<DepartmentEdm>;
//#endregion