import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DocumentEdm } from './DataDomain/EFEntities/documentedm.entity';
import { OptiaNewEdm } from './DataDomain/EFEntities/Notify/optianewedm.entity';
import { OptiaNewsDocumentEdm } from './DataDomain/EFEntities/Notify/optianewsdocumentedm.entity';
//#endregion

@Injectable()
export class OptiaNewsDocumentsService extends ODataEntitySetService<OptiaNewsDocumentEdm> {
  constructor(client: ODataClient) {
    super(client, 'OptiaNewsDocuments', 'OptiaApi.DataDomain.EFEntities.Notify.OptiaNewsDocumentEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public document(key: EntityKey<OptiaNewsDocumentEdm>): ODataNavigationPropertyResource<DocumentEdm> { 
    return this.entity(key).navigationProperty<DocumentEdm>('document'); 
  }
  public fetchDocument(key: EntityKey<OptiaNewsDocumentEdm>, options?: ODataQueryArgumentsOptions<DocumentEdm>) {
    return this.fetchNavigationProperty<DocumentEdm>(
      this.document(key), 
      'entity', options) as Observable<ODataEntity<DocumentEdm>>;
  }
  public setDocumentEdmAsDocument(key: EntityKey<OptiaNewsDocumentEdm>, target: ODataEntityResource<ODataEntity<DocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.document(key).reference()
      .set(target, {etag});
  }
  public unsetDocumentEdmAsDocument(key: EntityKey<OptiaNewsDocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<DocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.document(key).reference()
      .unset({etag});
  }
  public optiaNew(key: EntityKey<OptiaNewsDocumentEdm>): ODataNavigationPropertyResource<OptiaNewEdm> { 
    return this.entity(key).navigationProperty<OptiaNewEdm>('optiaNew'); 
  }
  public fetchOptiaNew(key: EntityKey<OptiaNewsDocumentEdm>, options?: ODataQueryArgumentsOptions<OptiaNewEdm>) {
    return this.fetchNavigationProperty<OptiaNewEdm>(
      this.optiaNew(key), 
      'entity', options) as Observable<ODataEntity<OptiaNewEdm>>;
  }
  public setOptiaNewEdmAsOptiaNew(key: EntityKey<OptiaNewsDocumentEdm>, target: ODataEntityResource<ODataEntity<OptiaNewEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.optiaNew(key).reference()
      .set(target, {etag});
  }
  public unsetOptiaNewEdmAsOptiaNew(key: EntityKey<OptiaNewsDocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<OptiaNewEdm>>, etag?: string} = {}): Observable<any> {
    return this.optiaNew(key).reference()
      .unset({etag});
  }
  //#endregion
}
