//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyDetailEdm } from './agencydetailedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AgencyDetailEdmComplexConfig = {
  name: 'AgencyDetailEdm',
  fields: {
    agencyGuid: {type: 'Edm.Guid', nullable: false},
    knownAsName: {type: 'Edm.String'},
    exactLegalName: {type: 'Edm.String'},
    shortCode: {type: 'Edm.String'},
    readyToTrade: {type: 'Edm.Boolean'},
    deleted: {type: 'Edm.DateTimeOffset'},
    payrollAccountManager: {type: 'Edm.String'},
    creditController: {type: 'Edm.String'},
    status: {type: 'Edm.Int16', nullable: false},
    warningMarker: {type: 'Edm.Boolean'}
  }
} as StructuredTypeConfig<AgencyDetailEdm>;
//#endregion