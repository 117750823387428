//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssignmentEdm } from './assignmentedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AssignmentEdmEntityConfig = {
  name: 'AssignmentEdm',
  keys: [{name: 'assignmentGuid'}],
  fields: {
    assignmentGuid: {type: 'Edm.Guid', nullable: false},
    contractGuid: {type: 'Edm.Guid'},
    workerGuid: {type: 'Edm.Guid'},
    status: {type: 'Edm.Int16', nullable: false},
    description: {type: 'Edm.String'},
    fromDate: {type: 'Edm.DateTimeOffset'},
    toDate: {type: 'Edm.DateTimeOffset'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    contract: {type: 'OptiaApi.DataDomain.EFEntities.ContractEdm', navigation: true, referentials: [{property: 'contractGuid', referencedProperty: 'contractGuid'}]},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    worker: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerEdm', navigation: true, referentials: [{property: 'workerGuid', referencedProperty: 'workerGuid'}]}
  }
} as StructuredTypeConfig<AssignmentEdm>;
//#endregion