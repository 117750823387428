//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerTypeDetailEdmComplexConfig } from './workertypedetailedm.complex.config';
import { WorkerEdmEntityConfig } from './workeredm.entity.config';
import { WorkerAddressEdmEntityConfig } from './workeraddressedm.entity.config';
import { WorkerBankDetailEdmEntityConfig } from './workerbankdetailedm.entity.config';
import { WorkerDocumentEdmEntityConfig } from './workerdocumentedm.entity.config';
import { WorkerPersonEdmEntityConfig } from './workerpersonedm.entity.config';
import { WorkerPhoneNumberEdmEntityConfig } from './workerphonenumberedm.entity.config';
import { WorkerTypeEdmEntityConfig } from './workertypeedm.entity.config';
import { WorkerTypeBankDetailEdmEntityConfig } from './workertypebankdetailedm.entity.config';
import { WorkerTypeDocumentEdmEntityConfig } from './workertypedocumentedm.entity.config';
import { WorkerTypePersonEdmEntityConfig } from './workertypepersonedm.entity.config';
import { WorkerTypeReportingLabelEdmEntityConfig } from './workertypereportinglabeledm.entity.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const WorkerSchema = {
  namespace: 'OptiaApi.DataDomain.EFEntities.Worker',
  enums: [],
  entities: [WorkerTypeDetailEdmComplexConfig,
    WorkerEdmEntityConfig,
    WorkerAddressEdmEntityConfig,
    WorkerBankDetailEdmEntityConfig,
    WorkerDocumentEdmEntityConfig,
    WorkerPersonEdmEntityConfig,
    WorkerPhoneNumberEdmEntityConfig,
    WorkerTypeEdmEntityConfig,
    WorkerTypeBankDetailEdmEntityConfig,
    WorkerTypeDocumentEdmEntityConfig,
    WorkerTypePersonEdmEntityConfig,
    WorkerTypeReportingLabelEdmEntityConfig],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion