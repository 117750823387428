import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyEdm } from './DataDomain/EFEntities/Agency/agencyedm.entity';
import { ChangeSetEdm } from './DataDomain/EFEntities/Admin/changesetedm.entity';
import { ChangeSetGroupEdm } from './DataDomain/EFEntities/Admin/changesetgroupedm.entity';
//#endregion

@Injectable()
export class ChangeSetGroupsService extends ODataEntitySetService<ChangeSetGroupEdm> {
  constructor(client: ODataClient) {
    super(client, 'ChangeSetGroups', 'OptiaApi.DataDomain.EFEntities.Admin.ChangeSetGroupEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public agency(key: EntityKey<ChangeSetGroupEdm>): ODataNavigationPropertyResource<AgencyEdm> { 
    return this.entity(key).navigationProperty<AgencyEdm>('agency'); 
  }
  public fetchAgency(key: EntityKey<ChangeSetGroupEdm>, options?: ODataQueryArgumentsOptions<AgencyEdm>) {
    return this.fetchNavigationProperty<AgencyEdm>(
      this.agency(key), 
      'entity', options) as Observable<ODataEntity<AgencyEdm>>;
  }
  public setAgencyEdmAsAgency(key: EntityKey<ChangeSetGroupEdm>, target: ODataEntityResource<ODataEntity<AgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .set(target, {etag});
  }
  public unsetAgencyEdmAsAgency(key: EntityKey<ChangeSetGroupEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .unset({etag});
  }
  public changeSets(key: EntityKey<ChangeSetGroupEdm>): ODataNavigationPropertyResource<ChangeSetEdm> { 
    return this.entity(key).navigationProperty<ChangeSetEdm>('changeSets'); 
  }
  public fetchChangeSets(key: EntityKey<ChangeSetGroupEdm>, options?: ODataQueryArgumentsOptions<ChangeSetEdm>) {
    return this.fetchNavigationProperty<ChangeSetEdm>(
      this.changeSets(key), 
      'entities', options) as Observable<ODataEntities<ChangeSetEdm>>;
  }
  public addChangeSetEdmToChangeSets(key: EntityKey<ChangeSetGroupEdm>, target: ODataEntityResource<ODataEntities<ChangeSetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.changeSets(key).reference()
      .add(target);
  }
  public removeChangeSetEdmFromChangeSets(key: EntityKey<ChangeSetGroupEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ChangeSetEdm>>, etag?: string} = {}): Observable<any> {
    return this.changeSets(key).reference()
      .remove(target);
  }
  //#endregion
}
