//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { UserGroupPermissionEdm } from './usergrouppermissionedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const UserGroupPermissionEdmEntityConfig = {
  name: 'UserGroupPermissionEdm',
  keys: [{name: 'permissionGuid'},{name: 'userGroupGuid'}],
  fields: {
    userGroupGuid: {type: 'Edm.Guid'},
    permissionGuid: {type: 'Edm.Guid'},
    permission: {type: 'OptiaApi.DataDomain.EFEntities.Admin.PermissionEdm', navigation: true, referentials: [{property: 'permissionGuid', referencedProperty: 'permissionGuid'}]},
    userGroup: {type: 'OptiaApi.DataDomain.EFEntities.Admin.UserGroupEdm', navigation: true, referentials: [{property: 'userGroupGuid', referencedProperty: 'userGroupGuid'}]}
  }
} as StructuredTypeConfig<UserGroupPermissionEdm>;
//#endregion