//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerAddressesService } from './workeraddresses.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WorkerAddressesServiceEntitySetConfig = {
  name: 'WorkerAddresses',
  entityType: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerAddressEdm',
  service: WorkerAddressesService
} as EntitySetConfig;
//#endregion