//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyPermissionDetailEdmComplexConfig } from './agencypermissiondetailedm.complex.config';
import { BackOfficePermissionDetailEdmComplexConfig } from './backofficepermissiondetailedm.complex.config';
import { UserGroupDetailEdmComplexConfig } from './usergroupdetailedm.complex.config';
import { AuditEdmEntityConfig } from './auditedm.entity.config';
import { ChangeSetEdmEntityConfig } from './changesetedm.entity.config';
import { ChangeSetGroupEdmEntityConfig } from './changesetgroupedm.entity.config';
import { ChangeSetLineEdmEntityConfig } from './changesetlineedm.entity.config';
import { DataMismatchEdmEntityConfig } from './datamismatchedm.entity.config';
import { HistoricalReferenceEdmEntityConfig } from './historicalreferenceedm.entity.config';
import { PermissionEdmEntityConfig } from './permissionedm.entity.config';
import { PersonUserGroupEdmEntityConfig } from './personusergroupedm.entity.config';
import { RegExEdmEntityConfig } from './regexedm.entity.config';
import { UserGroupEdmEntityConfig } from './usergroupedm.entity.config';
import { UserGroupPermissionEdmEntityConfig } from './usergrouppermissionedm.entity.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const AdminSchema = {
  namespace: 'OptiaApi.DataDomain.EFEntities.Admin',
  enums: [],
  entities: [AgencyPermissionDetailEdmComplexConfig,
    BackOfficePermissionDetailEdmComplexConfig,
    UserGroupDetailEdmComplexConfig,
    AuditEdmEntityConfig,
    ChangeSetEdmEntityConfig,
    ChangeSetGroupEdmEntityConfig,
    ChangeSetLineEdmEntityConfig,
    DataMismatchEdmEntityConfig,
    HistoricalReferenceEdmEntityConfig,
    PermissionEdmEntityConfig,
    PersonUserGroupEdmEntityConfig,
    RegExEdmEntityConfig,
    UserGroupEdmEntityConfig,
    UserGroupPermissionEdmEntityConfig],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion