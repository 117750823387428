//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TransferLogEdm } from './transferlogedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const TransferLogEdmEntityConfig = {
  name: 'TransferLogEdm',
  keys: [{name: 'agencyGuid'},{name: 'miReportTypeGuid'},{name: 'transferGuid'}],
  fields: {
    transferGuid: {type: 'Edm.Guid'},
    agencyGuid: {type: 'Edm.Guid'},
    miReportTypeGuid: {type: 'Edm.Guid'},
    fileName: {type: 'Edm.String', maxLength: -1},
    pdfFileName: {type: 'Edm.String', maxLength: -1},
    errorMessage: {type: 'Edm.String', maxLength: -1},
    status: {type: 'Edm.Int16'},
    rejectionReason: {type: 'Edm.String', maxLength: -1},
    approvalDate: {type: 'Edm.DateTimeOffset'},
    approvedBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    agency: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm', navigation: true, referentials: [{property: 'agencyGuid', referencedProperty: 'agencyGuid'}]},
    miReportType: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportTypeEdm', navigation: true, referentials: [{property: 'miReportTypeGuid', referencedProperty: 'miReportTypeGuid'}]},
    approvedByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'approvedBy', referencedProperty: 'personGuid'}]},
    transfer: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferEdm', navigation: true, referentials: [{property: 'transferGuid', referencedProperty: 'transferGuid'}]}
  }
} as StructuredTypeConfig<TransferLogEdm>;
//#endregion