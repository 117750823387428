//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerTypeDocumentsService } from './workertypedocuments.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WorkerTypeDocumentsServiceEntitySetConfig = {
  name: 'WorkerTypeDocuments',
  entityType: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeDocumentEdm',
  service: WorkerTypeDocumentsService
} as EntitySetConfig;
//#endregion