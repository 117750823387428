//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyPersonWfRoleEdm } from './agencypersonwfroleedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AgencyPersonWfRoleEdmEntityConfig = {
  name: 'AgencyPersonWfRoleEdm',
  keys: [{name: 'agencyGuid'},{name: 'personGuid'},{name: 'wfRoleGuid'}],
  fields: {
    agencyGuid: {type: 'Edm.Guid'},
    personGuid: {type: 'Edm.Guid'},
    wfRoleGuid: {type: 'Edm.Guid'},
    agency: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm', navigation: true, referentials: [{property: 'agencyGuid', referencedProperty: 'agencyGuid'}]},
    person: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]},
    wfRole: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfRoleEdm', navigation: true, referentials: [{property: 'wfRoleGuid', referencedProperty: 'wfRoleGuid'}]}
  }
} as StructuredTypeConfig<AgencyPersonWfRoleEdm>;
//#endregion