//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CharityEdm } from './charityedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CharityEdmEntityConfig = {
  name: 'CharityEdm',
  keys: [{name: 'charityGuid'}],
  fields: {
    charityGuid: {type: 'Edm.Guid', nullable: false},
    number: {type: 'Edm.String'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    customers: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<CharityEdm>;
//#endregion