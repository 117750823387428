import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { BankDetailEdm } from './DataDomain/EFEntities/bankdetailedm.entity';
import { WorkerEdm } from './DataDomain/EFEntities/Worker/workeredm.entity';
import { WorkerBankDetailEdm } from './DataDomain/EFEntities/Worker/workerbankdetailedm.entity';
//#endregion

@Injectable()
export class WorkerBankDetailsService extends ODataEntitySetService<WorkerBankDetailEdm> {
  constructor(client: ODataClient) {
    super(client, 'WorkerBankDetails', 'OptiaApi.DataDomain.EFEntities.Worker.WorkerBankDetailEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public bankDetail(key: EntityKey<WorkerBankDetailEdm>): ODataNavigationPropertyResource<BankDetailEdm> { 
    return this.entity(key).navigationProperty<BankDetailEdm>('bankDetail'); 
  }
  public fetchBankDetail(key: EntityKey<WorkerBankDetailEdm>, options?: ODataQueryArgumentsOptions<BankDetailEdm>) {
    return this.fetchNavigationProperty<BankDetailEdm>(
      this.bankDetail(key), 
      'entity', options) as Observable<ODataEntity<BankDetailEdm>>;
  }
  public setBankDetailEdmAsBankDetail(key: EntityKey<WorkerBankDetailEdm>, target: ODataEntityResource<ODataEntity<BankDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.bankDetail(key).reference()
      .set(target, {etag});
  }
  public unsetBankDetailEdmAsBankDetail(key: EntityKey<WorkerBankDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<BankDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.bankDetail(key).reference()
      .unset({etag});
  }
  public worker(key: EntityKey<WorkerBankDetailEdm>): ODataNavigationPropertyResource<WorkerEdm> { 
    return this.entity(key).navigationProperty<WorkerEdm>('worker'); 
  }
  public fetchWorker(key: EntityKey<WorkerBankDetailEdm>, options?: ODataQueryArgumentsOptions<WorkerEdm>) {
    return this.fetchNavigationProperty<WorkerEdm>(
      this.worker(key), 
      'entity', options) as Observable<ODataEntity<WorkerEdm>>;
  }
  public setWorkerEdmAsWorker(key: EntityKey<WorkerBankDetailEdm>, target: ODataEntityResource<ODataEntity<WorkerEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.worker(key).reference()
      .set(target, {etag});
  }
  public unsetWorkerEdmAsWorker(key: EntityKey<WorkerBankDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WorkerEdm>>, etag?: string} = {}): Observable<any> {
    return this.worker(key).reference()
      .unset({etag});
  }
  //#endregion
}
