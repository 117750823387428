//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DataMismatchEdm } from './datamismatchedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const DataMismatchEdmEntityConfig = {
  name: 'DataMismatchEdm',
  keys: [{name: 'dataMismatchGuid'}],
  fields: {
    dataMismatchGuid: {type: 'Edm.Guid', nullable: false},
    entityType: {type: 'Edm.Int16', nullable: false},
    entityGuid: {type: 'Edm.Guid', nullable: false},
    entityDescription: {type: 'Edm.String'},
    status: {type: 'Edm.Int16', nullable: false},
    optiaField: {type: 'Edm.String'},
    optiaValue: {type: 'Edm.String'},
    payrollField: {type: 'Edm.String'},
    payrollValue: {type: 'Edm.String'},
    createdOn: {type: 'Edm.DateTimeOffset'}
  }
} as StructuredTypeConfig<DataMismatchEdm>;
//#endregion