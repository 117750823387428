//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyDetailEdmComplexConfig } from './agencydetailedm.complex.config';
import { AgencyEdmEntityConfig } from './agencyedm.entity.config';
import { AgencyAddressEdmEntityConfig } from './agencyaddressedm.entity.config';
import { AgencyBankDetailEdmEntityConfig } from './agencybankdetailedm.entity.config';
import { AgencyDocumentEdmEntityConfig } from './agencydocumentedm.entity.config';
import { AgencyPersonEdmEntityConfig } from './agencypersonedm.entity.config';
import { AgencyPersonWfRoleEdmEntityConfig } from './agencypersonwfroleedm.entity.config';
import { AgencyReportingCategoryEdmEntityConfig } from './agencyreportingcategoryedm.entity.config';
import { AgencyWorkerEdmEntityConfig } from './agencyworkeredm.entity.config';
import { TradingStyleEdmEntityConfig } from './tradingstyleedm.entity.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const AgencySchema = {
  namespace: 'OptiaApi.DataDomain.EFEntities.Agency',
  enums: [],
  entities: [AgencyDetailEdmComplexConfig,
    AgencyEdmEntityConfig,
    AgencyAddressEdmEntityConfig,
    AgencyBankDetailEdmEntityConfig,
    AgencyDocumentEdmEntityConfig,
    AgencyPersonEdmEntityConfig,
    AgencyPersonWfRoleEdmEntityConfig,
    AgencyReportingCategoryEdmEntityConfig,
    AgencyWorkerEdmEntityConfig,
    TradingStyleEdmEntityConfig],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion