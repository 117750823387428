//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OptiaNewsDto } from './optianewsdto.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const OptiaNewsDtoComplexConfig = {
  name: 'OptiaNewsDto',
  fields: {
    optiaNewsGuid: {type: 'Edm.Guid', nullable: false},
    title: {type: 'Edm.String', nullable: false},
    details: {type: 'Edm.String', nullable: false},
    launchDate: {type: 'Edm.DateTimeOffset'},
    lastEditedDate: {type: 'Edm.DateTimeOffset'},
    lastEditedBy: {type: 'Edm.Guid'},
    expiryDate: {type: 'Edm.DateTimeOffset'},
    isUrgent: {type: 'Edm.Boolean', nullable: false},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid', nullable: false},
    createdByPerson: {type: 'Edm.String', nullable: false},
    deleted: {type: 'Edm.DateTimeOffset'},
    expiredBy: {type: 'Edm.Guid'},
    optiaNewsDocuments: {type: 'OptiaApi.OptiaNewsDocumentDto', collection: true},
    status: {type: 'Edm.Int32', nullable: false}
  }
} as StructuredTypeConfig<OptiaNewsDto>;
//#endregion