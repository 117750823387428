//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SupplierAddressEdm } from './supplieraddressedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const SupplierAddressEdmEntityConfig = {
  name: 'SupplierAddressEdm',
  keys: [{name: 'addressGuid'},{name: 'fromDate'},{name: 'supplierGuid'}],
  fields: {
    supplierGuid: {type: 'Edm.Guid'},
    addressGuid: {type: 'Edm.Guid'},
    addressType: {type: 'Edm.Int16', nullable: false},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    address: {type: 'OptiaApi.DataDomain.EFEntities.AddressEdm', navigation: true, referentials: [{property: 'addressGuid', referencedProperty: 'addressGuid'}]},
    supplier: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierEdm', navigation: true, referentials: [{property: 'supplierGuid', referencedProperty: 'supplierGuid'}]}
  }
} as StructuredTypeConfig<SupplierAddressEdm>;
//#endregion