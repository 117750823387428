//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PermissionDTO } from './permissiondto.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PermissionDTOEntityConfig = {
  name: 'PermissionDTO',
  keys: [{name: 'permissionGuid'}],
  fields: {
    permissionGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String'},
    tag: {type: 'Edm.String'},
    active: {type: 'Edm.Boolean'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid', nullable: false},
    deleted: {type: 'Edm.DateTimeOffset'}
  }
} as StructuredTypeConfig<PermissionDTO>;
//#endregion