import { PersonEdm } from "src/app/odata";


export function getAllPermissions(person: PersonEdm): string[] {

    let permissions: string[] = new Array();

    person.personUserGroups?.forEach((personUserGroup) => {
        personUserGroup.userGroup?.userGroupPermissions?.forEach((userGroupPermission) => {
            if (userGroupPermission.permission
                && userGroupPermission.permission.tag) {
                if (userGroupPermission.permission.active) {
                    permissions.push(userGroupPermission.permission?.tag as string);
                }
            }
        });
    });

    return permissions;
}

export function hasPermission(person: PersonEdm, permissionTag: string): boolean {
    return getAllPermissions(person).indexOf(permissionTag) >= 0;
}