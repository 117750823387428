//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AuditsService } from './audits.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AuditsServiceEntitySetConfig = {
  name: 'Audits',
  entityType: 'OptiaApi.DataDomain.EFEntities.Admin.AuditEdm',
  service: AuditsService
} as EntitySetConfig;
//#endregion