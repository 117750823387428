import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notifications = [
    {title: 'Notification 1', description: 'Important notification 1'},
    {title: 'Notification 2', description: 'Important notification 2'},
    {title: 'Notification 3', description: 'Important notification 3'},
    {title: 'Notification 4', description: 'Important notification 4'},
    {title: 'Notification 5', description: 'Important notification 5'}
  ];

  constructor() { }
}
