//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyBankDetailsService } from './agencybankdetails.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AgencyBankDetailsServiceEntitySetConfig = {
  name: 'AgencyBankDetails',
  entityType: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyBankDetailEdm',
  service: AgencyBankDetailsService
} as EntitySetConfig;
//#endregion