import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DocumentEdm } from './DataDomain/EFEntities/documentedm.entity';
import { CustomerEdm } from './DataDomain/EFEntities/Customer/customeredm.entity';
import { CustomerDocumentEdm } from './DataDomain/EFEntities/Customer/customerdocumentedm.entity';
//#endregion

@Injectable()
export class CustomerDocumentsService extends ODataEntitySetService<CustomerDocumentEdm> {
  constructor(client: ODataClient) {
    super(client, 'CustomerDocuments', 'OptiaApi.DataDomain.EFEntities.Customer.CustomerDocumentEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public customer(key: EntityKey<CustomerDocumentEdm>): ODataNavigationPropertyResource<CustomerEdm> { 
    return this.entity(key).navigationProperty<CustomerEdm>('customer'); 
  }
  public fetchCustomer(key: EntityKey<CustomerDocumentEdm>, options?: ODataQueryArgumentsOptions<CustomerEdm>) {
    return this.fetchNavigationProperty<CustomerEdm>(
      this.customer(key), 
      'entity', options) as Observable<ODataEntity<CustomerEdm>>;
  }
  public setCustomerEdmAsCustomer(key: EntityKey<CustomerDocumentEdm>, target: ODataEntityResource<ODataEntity<CustomerEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customer(key).reference()
      .set(target, {etag});
  }
  public unsetCustomerEdmAsCustomer(key: EntityKey<CustomerDocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<CustomerEdm>>, etag?: string} = {}): Observable<any> {
    return this.customer(key).reference()
      .unset({etag});
  }
  public document(key: EntityKey<CustomerDocumentEdm>): ODataNavigationPropertyResource<DocumentEdm> { 
    return this.entity(key).navigationProperty<DocumentEdm>('document'); 
  }
  public fetchDocument(key: EntityKey<CustomerDocumentEdm>, options?: ODataQueryArgumentsOptions<DocumentEdm>) {
    return this.fetchNavigationProperty<DocumentEdm>(
      this.document(key), 
      'entity', options) as Observable<ODataEntity<DocumentEdm>>;
  }
  public setDocumentEdmAsDocument(key: EntityKey<CustomerDocumentEdm>, target: ODataEntityResource<ODataEntity<DocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.document(key).reference()
      .set(target, {etag});
  }
  public unsetDocumentEdmAsDocument(key: EntityKey<CustomerDocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<DocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.document(key).reference()
      .unset({etag});
  }
  //#endregion
}
