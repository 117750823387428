//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { InputValidationTaskEdm } from './inputvalidationtaskedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const InputValidationTaskEdmComplexConfig = {
  name: 'InputValidationTaskEdm',
  fields: {
    transferGuid: {type: 'Edm.Guid', nullable: false},
    miReportTypeGuid: {type: 'Edm.Guid', nullable: false},
    agencyGuid: {type: 'Edm.Guid', nullable: false},
    reportName: {type: 'Edm.String'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    status: {type: 'Edm.Int16'},
    errorMessage: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<InputValidationTaskEdm>;
//#endregion