//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SupplierDocumentsService } from './supplierdocuments.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const SupplierDocumentsServiceEntitySetConfig = {
  name: 'SupplierDocuments',
  entityType: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierDocumentEdm',
  service: SupplierDocumentsService
} as EntitySetConfig;
//#endregion