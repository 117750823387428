//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfActionOutstandingEdm } from './wfactionoutstandingedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfActionOutstandingEdmEntityConfig = {
  name: 'WfActionOutstandingEdm',
  keys: [{name: 'wfActionGuid'}],
  fields: {
    wfActionGuid: {type: 'Edm.Guid', nullable: false},
    wfInstanceGuid: {type: 'Edm.Guid', nullable: false},
    wfTaskGuid: {type: 'Edm.Guid', nullable: false},
    wfOutcomeGuid: {type: 'Edm.Guid'},
    wfRuleGuid: {type: 'Edm.Guid'},
    ownerGuid: {type: 'Edm.Guid'},
    requested: {type: 'Edm.DateTimeOffset'},
    completed: {type: 'Edm.DateTimeOffset'},
    tag: {type: 'Edm.String'},
    reminderDue: {type: 'Edm.DateTimeOffset'},
    completionDue: {type: 'Edm.DateTimeOffset'},
    comments: {type: 'Edm.String'},
    branchIndex: {type: 'Edm.Int32'},
    taskDescription: {type: 'Edm.String'},
    taskType: {type: 'Edm.Int32'},
    positiveOutcomeGuid: {type: 'Edm.Guid'},
    negativeOutcomeGuid: {type: 'Edm.Guid'},
    changeSetGroupGuid: {type: 'Edm.Guid'},
    changeSetGuid: {type: 'Edm.Guid'},
    changeSetEntity: {type: 'Edm.Int16'},
    agencyGuid: {type: 'Edm.Guid'},
    agencyKnownAsName: {type: 'Edm.String'},
    agencyShortCode: {type: 'Edm.String'},
    agencyExactLegalName: {type: 'Edm.String'},
    customerGuid: {type: 'Edm.Guid'},
    customerAgencyGuid: {type: 'Edm.Guid'},
    customerTradingName: {type: 'Edm.String'},
    accountGuid: {type: 'Edm.Guid'},
    accountReference: {type: 'Edm.String'},
    contractGuid: {type: 'Edm.Guid'},
    contractName: {type: 'Edm.String'},
    contractFromDate: {type: 'Edm.DateTimeOffset'},
    contractToDate: {type: 'Edm.DateTimeOffset'},
    workerName: {type: 'Edm.String'},
    workerGuid: {type: 'Edm.Guid'}
  }
} as StructuredTypeConfig<WfActionOutstandingEdm>;
//#endregion