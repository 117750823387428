import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AccountEdm } from './DataDomain/EFEntities/accountedm.entity';
import { AccountPersonEdm } from './DataDomain/EFEntities/accountpersonedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class AccountPersonsService extends ODataEntitySetService<AccountPersonEdm> {
  constructor(client: ODataClient) {
    super(client, 'AccountPersons', 'OptiaApi.DataDomain.EFEntities.AccountPersonEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public account(key: EntityKey<AccountPersonEdm>): ODataNavigationPropertyResource<AccountEdm> { 
    return this.entity(key).navigationProperty<AccountEdm>('account'); 
  }
  public fetchAccount(key: EntityKey<AccountPersonEdm>, options?: ODataQueryArgumentsOptions<AccountEdm>) {
    return this.fetchNavigationProperty<AccountEdm>(
      this.account(key), 
      'entity', options) as Observable<ODataEntity<AccountEdm>>;
  }
  public setAccountEdmAsAccount(key: EntityKey<AccountPersonEdm>, target: ODataEntityResource<ODataEntity<AccountEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.account(key).reference()
      .set(target, {etag});
  }
  public unsetAccountEdmAsAccount(key: EntityKey<AccountPersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AccountEdm>>, etag?: string} = {}): Observable<any> {
    return this.account(key).reference()
      .unset({etag});
  }
  public person(key: EntityKey<AccountPersonEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<AccountPersonEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<AccountPersonEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<AccountPersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  //#endregion
}
