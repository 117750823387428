//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerDetailEdm } from './customerdetailedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CustomerDetailEdmEntityConfig = {
  name: 'CustomerDetailEdm',
  keys: [{name: 'customerAgencyGuid'}],
  fields: {
    customerGuid: {type: 'Edm.Guid', nullable: false},
    customerAgencyGuid: {type: 'Edm.Guid', nullable: false},
    customerDesc: {type: 'Edm.String'},
    agencyDesc: {type: 'Edm.String'},
    customerStatus: {type: 'Edm.Int16', nullable: false},
    verificationOutcome: {type: 'Edm.String'},
    customerAgencyStatus: {type: 'Edm.Int16', nullable: false},
    customerType: {type: 'Edm.Int16'},
    descAndType: {type: 'Edm.String'},
    totalSearchCount: {type: 'Edm.Int32', nullable: false}
  }
} as StructuredTypeConfig<CustomerDetailEdm>;
//#endregion