import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DocumentEdm } from './DataDomain/EFEntities/documentedm.entity';
import { AgencyDocumentEdm } from './DataDomain/EFEntities/Agency/agencydocumentedm.entity';
import { CustomerAgencyDocumentEdm } from './DataDomain/EFEntities/Customer/customeragencydocumentedm.entity';
import { CustomerDocumentEdm } from './DataDomain/EFEntities/Customer/customerdocumentedm.entity';
import { OptiaNewsDocumentEdm } from './DataDomain/EFEntities/Notify/optianewsdocumentedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
import { SupplierDocumentEdm } from './DataDomain/EFEntities/Supplier/supplierdocumentedm.entity';
import { WorkerDocumentEdm } from './DataDomain/EFEntities/Worker/workerdocumentedm.entity';
import { WorkerTypeDocumentEdm } from './DataDomain/EFEntities/Worker/workertypedocumentedm.entity';
//#endregion

@Injectable()
export class DocumentsService extends ODataEntitySetService<DocumentEdm> {
  constructor(client: ODataClient) {
    super(client, 'Documents', 'OptiaApi.DataDomain.EFEntities.DocumentEdm');
  }
  //#region ODataApiGen Actions
  public markAsDeleted(key: EntityKey<DocumentEdm>): ODataActionResource<null, boolean> { 
    return this.entity(key).action<null, boolean>('OptiaApi.MarkAsDeleted');
  }
  public callMarkAsDeleted(key: EntityKey<DocumentEdm>, options?: ODataOptions & {alias?: boolean}) {
    return this.callAction<null, boolean>(
      null, 
      this.markAsDeleted(key), 
      'property', options) as Observable<ODataProperty<boolean>>;
  }
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public agencyDocuments(key: EntityKey<DocumentEdm>): ODataNavigationPropertyResource<AgencyDocumentEdm> { 
    return this.entity(key).navigationProperty<AgencyDocumentEdm>('agencyDocuments'); 
  }
  public fetchAgencyDocuments(key: EntityKey<DocumentEdm>, options?: ODataQueryArgumentsOptions<AgencyDocumentEdm>) {
    return this.fetchNavigationProperty<AgencyDocumentEdm>(
      this.agencyDocuments(key), 
      'entities', options) as Observable<ODataEntities<AgencyDocumentEdm>>;
  }
  public addAgencyDocumentEdmToAgencyDocuments(key: EntityKey<DocumentEdm>, target: ODataEntityResource<ODataEntities<AgencyDocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyDocuments(key).reference()
      .add(target);
  }
  public removeAgencyDocumentEdmFromAgencyDocuments(key: EntityKey<DocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyDocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyDocuments(key).reference()
      .remove(target);
  }
  public customerAgencyDocuments(key: EntityKey<DocumentEdm>): ODataNavigationPropertyResource<CustomerAgencyDocumentEdm> { 
    return this.entity(key).navigationProperty<CustomerAgencyDocumentEdm>('customerAgencyDocuments'); 
  }
  public fetchCustomerAgencyDocuments(key: EntityKey<DocumentEdm>, options?: ODataQueryArgumentsOptions<CustomerAgencyDocumentEdm>) {
    return this.fetchNavigationProperty<CustomerAgencyDocumentEdm>(
      this.customerAgencyDocuments(key), 
      'entities', options) as Observable<ODataEntities<CustomerAgencyDocumentEdm>>;
  }
  public addCustomerAgencyDocumentEdmToCustomerAgencyDocuments(key: EntityKey<DocumentEdm>, target: ODataEntityResource<ODataEntities<CustomerAgencyDocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customerAgencyDocuments(key).reference()
      .add(target);
  }
  public removeCustomerAgencyDocumentEdmFromCustomerAgencyDocuments(key: EntityKey<DocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CustomerAgencyDocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.customerAgencyDocuments(key).reference()
      .remove(target);
  }
  public customerDocuments(key: EntityKey<DocumentEdm>): ODataNavigationPropertyResource<CustomerDocumentEdm> { 
    return this.entity(key).navigationProperty<CustomerDocumentEdm>('customerDocuments'); 
  }
  public fetchCustomerDocuments(key: EntityKey<DocumentEdm>, options?: ODataQueryArgumentsOptions<CustomerDocumentEdm>) {
    return this.fetchNavigationProperty<CustomerDocumentEdm>(
      this.customerDocuments(key), 
      'entities', options) as Observable<ODataEntities<CustomerDocumentEdm>>;
  }
  public addCustomerDocumentEdmToCustomerDocuments(key: EntityKey<DocumentEdm>, target: ODataEntityResource<ODataEntities<CustomerDocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customerDocuments(key).reference()
      .add(target);
  }
  public removeCustomerDocumentEdmFromCustomerDocuments(key: EntityKey<DocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CustomerDocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.customerDocuments(key).reference()
      .remove(target);
  }
  public optiaNewsDocuments(key: EntityKey<DocumentEdm>): ODataNavigationPropertyResource<OptiaNewsDocumentEdm> { 
    return this.entity(key).navigationProperty<OptiaNewsDocumentEdm>('optiaNewsDocuments'); 
  }
  public fetchOptiaNewsDocuments(key: EntityKey<DocumentEdm>, options?: ODataQueryArgumentsOptions<OptiaNewsDocumentEdm>) {
    return this.fetchNavigationProperty<OptiaNewsDocumentEdm>(
      this.optiaNewsDocuments(key), 
      'entities', options) as Observable<ODataEntities<OptiaNewsDocumentEdm>>;
  }
  public addOptiaNewsDocumentEdmToOptiaNewsDocuments(key: EntityKey<DocumentEdm>, target: ODataEntityResource<ODataEntities<OptiaNewsDocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.optiaNewsDocuments(key).reference()
      .add(target);
  }
  public removeOptiaNewsDocumentEdmFromOptiaNewsDocuments(key: EntityKey<DocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<OptiaNewsDocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.optiaNewsDocuments(key).reference()
      .remove(target);
  }
  public supplierDocuments(key: EntityKey<DocumentEdm>): ODataNavigationPropertyResource<SupplierDocumentEdm> { 
    return this.entity(key).navigationProperty<SupplierDocumentEdm>('supplierDocuments'); 
  }
  public fetchSupplierDocuments(key: EntityKey<DocumentEdm>, options?: ODataQueryArgumentsOptions<SupplierDocumentEdm>) {
    return this.fetchNavigationProperty<SupplierDocumentEdm>(
      this.supplierDocuments(key), 
      'entities', options) as Observable<ODataEntities<SupplierDocumentEdm>>;
  }
  public addSupplierDocumentEdmToSupplierDocuments(key: EntityKey<DocumentEdm>, target: ODataEntityResource<ODataEntities<SupplierDocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.supplierDocuments(key).reference()
      .add(target);
  }
  public removeSupplierDocumentEdmFromSupplierDocuments(key: EntityKey<DocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<SupplierDocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.supplierDocuments(key).reference()
      .remove(target);
  }
  public uploadedByPerson(key: EntityKey<DocumentEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('uploadedByPerson'); 
  }
  public fetchUploadedByPerson(key: EntityKey<DocumentEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.uploadedByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsUploadedByPerson(key: EntityKey<DocumentEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.uploadedByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsUploadedByPerson(key: EntityKey<DocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.uploadedByPerson(key).reference()
      .unset({etag});
  }
  public workerDocuments(key: EntityKey<DocumentEdm>): ODataNavigationPropertyResource<WorkerDocumentEdm> { 
    return this.entity(key).navigationProperty<WorkerDocumentEdm>('workerDocuments'); 
  }
  public fetchWorkerDocuments(key: EntityKey<DocumentEdm>, options?: ODataQueryArgumentsOptions<WorkerDocumentEdm>) {
    return this.fetchNavigationProperty<WorkerDocumentEdm>(
      this.workerDocuments(key), 
      'entities', options) as Observable<ODataEntities<WorkerDocumentEdm>>;
  }
  public addWorkerDocumentEdmToWorkerDocuments(key: EntityKey<DocumentEdm>, target: ODataEntityResource<ODataEntities<WorkerDocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerDocuments(key).reference()
      .add(target);
  }
  public removeWorkerDocumentEdmFromWorkerDocuments(key: EntityKey<DocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerDocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerDocuments(key).reference()
      .remove(target);
  }
  public workerTypeDocuments(key: EntityKey<DocumentEdm>): ODataNavigationPropertyResource<WorkerTypeDocumentEdm> { 
    return this.entity(key).navigationProperty<WorkerTypeDocumentEdm>('workerTypeDocuments'); 
  }
  public fetchWorkerTypeDocuments(key: EntityKey<DocumentEdm>, options?: ODataQueryArgumentsOptions<WorkerTypeDocumentEdm>) {
    return this.fetchNavigationProperty<WorkerTypeDocumentEdm>(
      this.workerTypeDocuments(key), 
      'entities', options) as Observable<ODataEntities<WorkerTypeDocumentEdm>>;
  }
  public addWorkerTypeDocumentEdmToWorkerTypeDocuments(key: EntityKey<DocumentEdm>, target: ODataEntityResource<ODataEntities<WorkerTypeDocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerTypeDocuments(key).reference()
      .add(target);
  }
  public removeWorkerTypeDocumentEdmFromWorkerTypeDocuments(key: EntityKey<DocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerTypeDocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerTypeDocuments(key).reference()
      .remove(target);
  }
  //#endregion
}
