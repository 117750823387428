//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfProcessWfScheduleEdm } from './wfprocesswfscheduleedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfProcessWfScheduleEdmEntityConfig = {
  name: 'WfProcessWfScheduleEdm',
  keys: [{name: 'wfProcessGuid'},{name: 'wfScheduleGuid'}],
  fields: {
    wfProcessGuid: {type: 'Edm.Guid'},
    wfScheduleGuid: {type: 'Edm.Guid'},
    wfProcess: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfProcessEdm', navigation: true, referentials: [{property: 'wfProcessGuid', referencedProperty: 'wfProcessGuid'}]},
    wfSchedule: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfScheduleEdm', navigation: true, referentials: [{property: 'wfScheduleGuid', referencedProperty: 'wfScheduleGuid'}]}
  }
} as StructuredTypeConfig<WfProcessWfScheduleEdm>;
//#endregion