import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CreditAssignmentEdm } from './DataDomain/EFEntities/Credit/creditassignmentedm.entity';
import { CreditLimitEdm } from './DataDomain/EFEntities/Credit/creditlimitedm.entity';
import { CustomerAgencyEdm } from './DataDomain/EFEntities/Customer/customeragencyedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class CreditAssignmentsService extends ODataEntitySetService<CreditAssignmentEdm> {
  constructor(client: ODataClient) {
    super(client, 'CreditAssignments', 'OptiaApi.DataDomain.EFEntities.Credit.CreditAssignmentEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public createdByPerson(key: EntityKey<CreditAssignmentEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<CreditAssignmentEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<CreditAssignmentEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<CreditAssignmentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public creditLimit(key: EntityKey<CreditAssignmentEdm>): ODataNavigationPropertyResource<CreditLimitEdm> { 
    return this.entity(key).navigationProperty<CreditLimitEdm>('creditLimit'); 
  }
  public fetchCreditLimit(key: EntityKey<CreditAssignmentEdm>, options?: ODataQueryArgumentsOptions<CreditLimitEdm>) {
    return this.fetchNavigationProperty<CreditLimitEdm>(
      this.creditLimit(key), 
      'entity', options) as Observable<ODataEntity<CreditLimitEdm>>;
  }
  public setCreditLimitEdmAsCreditLimit(key: EntityKey<CreditAssignmentEdm>, target: ODataEntityResource<ODataEntity<CreditLimitEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.creditLimit(key).reference()
      .set(target, {etag});
  }
  public unsetCreditLimitEdmAsCreditLimit(key: EntityKey<CreditAssignmentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<CreditLimitEdm>>, etag?: string} = {}): Observable<any> {
    return this.creditLimit(key).reference()
      .unset({etag});
  }
  public customerAgency(key: EntityKey<CreditAssignmentEdm>): ODataNavigationPropertyResource<CustomerAgencyEdm> { 
    return this.entity(key).navigationProperty<CustomerAgencyEdm>('customerAgency'); 
  }
  public fetchCustomerAgency(key: EntityKey<CreditAssignmentEdm>, options?: ODataQueryArgumentsOptions<CustomerAgencyEdm>) {
    return this.fetchNavigationProperty<CustomerAgencyEdm>(
      this.customerAgency(key), 
      'entity', options) as Observable<ODataEntity<CustomerAgencyEdm>>;
  }
  public setCustomerAgencyEdmAsCustomerAgency(key: EntityKey<CreditAssignmentEdm>, target: ODataEntityResource<ODataEntity<CustomerAgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customerAgency(key).reference()
      .set(target, {etag});
  }
  public unsetCustomerAgencyEdmAsCustomerAgency(key: EntityKey<CreditAssignmentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<CustomerAgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.customerAgency(key).reference()
      .unset({etag});
  }
  //#endregion
}
