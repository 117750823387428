//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfRulesService } from './wfrules.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WfRulesServiceEntitySetConfig = {
  name: 'WfRules',
  entityType: 'OptiaApi.DataDomain.EFEntities.Workflow.WfRuleEdm',
  service: WfRulesService
} as EntitySetConfig;
//#endregion