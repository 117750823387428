//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyPersonsService } from './agencypersons.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AgencyPersonsServiceEntitySetConfig = {
  name: 'AgencyPersons',
  entityType: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyPersonEdm',
  service: AgencyPersonsService
} as EntitySetConfig;
//#endregion