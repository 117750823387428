import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfProcessWfScheduleEdm } from './DataDomain/EFEntities/Workflow/wfprocesswfscheduleedm.entity';
import { WfScheduleEdm } from './DataDomain/EFEntities/Workflow/wfscheduleedm.entity';
import { WfTaskWfScheduleEdm } from './DataDomain/EFEntities/Workflow/wftaskwfscheduleedm.entity';
//#endregion

@Injectable()
export class WfSchedulesService extends ODataEntitySetService<WfScheduleEdm> {
  constructor(client: ODataClient) {
    super(client, 'WfSchedules', 'OptiaApi.DataDomain.EFEntities.Workflow.WfScheduleEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public wfProcessWfSchedules(key: EntityKey<WfScheduleEdm>): ODataNavigationPropertyResource<WfProcessWfScheduleEdm> { 
    return this.entity(key).navigationProperty<WfProcessWfScheduleEdm>('wfProcessWfSchedules'); 
  }
  public fetchWfProcessWfSchedules(key: EntityKey<WfScheduleEdm>, options?: ODataQueryArgumentsOptions<WfProcessWfScheduleEdm>) {
    return this.fetchNavigationProperty<WfProcessWfScheduleEdm>(
      this.wfProcessWfSchedules(key), 
      'entities', options) as Observable<ODataEntities<WfProcessWfScheduleEdm>>;
  }
  public addWfProcessWfScheduleEdmToWfProcessWfSchedules(key: EntityKey<WfScheduleEdm>, target: ODataEntityResource<ODataEntities<WfProcessWfScheduleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfProcessWfSchedules(key).reference()
      .add(target);
  }
  public removeWfProcessWfScheduleEdmFromWfProcessWfSchedules(key: EntityKey<WfScheduleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfProcessWfScheduleEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfProcessWfSchedules(key).reference()
      .remove(target);
  }
  public wfTaskWfSchedules(key: EntityKey<WfScheduleEdm>): ODataNavigationPropertyResource<WfTaskWfScheduleEdm> { 
    return this.entity(key).navigationProperty<WfTaskWfScheduleEdm>('wfTaskWfSchedules'); 
  }
  public fetchWfTaskWfSchedules(key: EntityKey<WfScheduleEdm>, options?: ODataQueryArgumentsOptions<WfTaskWfScheduleEdm>) {
    return this.fetchNavigationProperty<WfTaskWfScheduleEdm>(
      this.wfTaskWfSchedules(key), 
      'entities', options) as Observable<ODataEntities<WfTaskWfScheduleEdm>>;
  }
  public addWfTaskWfScheduleEdmToWfTaskWfSchedules(key: EntityKey<WfScheduleEdm>, target: ODataEntityResource<ODataEntities<WfTaskWfScheduleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfTaskWfSchedules(key).reference()
      .add(target);
  }
  public removeWfTaskWfScheduleEdmFromWfTaskWfSchedules(key: EntityKey<WfScheduleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfTaskWfScheduleEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfTaskWfSchedules(key).reference()
      .remove(target);
  }
  //#endregion
}
