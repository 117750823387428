//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfActionEdm } from './wfactionedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfActionEdmEntityConfig = {
  name: 'WfActionEdm',
  keys: [{name: 'wfActionGuid'}],
  fields: {
    wfActionGuid: {type: 'Edm.Guid', nullable: false},
    wfInstanceGuid: {type: 'Edm.Guid'},
    wfTaskGuid: {type: 'Edm.Guid'},
    wfOutcomeGuid: {type: 'Edm.Guid'},
    wfRuleGuid: {type: 'Edm.Guid'},
    ownerGuid: {type: 'Edm.Guid'},
    requested: {type: 'Edm.DateTimeOffset'},
    completed: {type: 'Edm.DateTimeOffset'},
    tag: {type: 'Edm.String'},
    reminderDue: {type: 'Edm.DateTimeOffset'},
    completionDue: {type: 'Edm.DateTimeOffset'},
    comments: {type: 'Edm.String', maxLength: -1},
    branchIndex: {type: 'Edm.Int32'},
    wfInstance: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfInstanceEdm', navigation: true, referentials: [{property: 'wfInstanceGuid', referencedProperty: 'wfInstanceGuid'}]},
    wfOutcome: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfOutcomeEdm', navigation: true, referentials: [{property: 'wfOutcomeGuid', referencedProperty: 'wfOutcomeGuid'}]},
    wfTask: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskEdm', navigation: true, referentials: [{property: 'wfTaskGuid', referencedProperty: 'wfTaskGuid'}]}
  }
} as StructuredTypeConfig<WfActionEdm>;
//#endregion