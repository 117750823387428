//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SupplierPersonEdm } from './supplierpersonedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const SupplierPersonEdmEntityConfig = {
  name: 'SupplierPersonEdm',
  keys: [{name: 'fromDate'},{name: 'personGuid'},{name: 'supplierGuid'}],
  fields: {
    supplierGuid: {type: 'Edm.Guid'},
    personGuid: {type: 'Edm.Guid'},
    personType: {type: 'Edm.Int16', nullable: false},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    person: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]},
    supplier: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierEdm', navigation: true, referentials: [{property: 'supplierGuid', referencedProperty: 'supplierGuid'}]}
  }
} as StructuredTypeConfig<SupplierPersonEdm>;
//#endregion