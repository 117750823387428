//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerDetailsService } from './customerdetails.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CustomerDetailsServiceEntitySetConfig = {
  name: 'CustomerDetails',
  entityType: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerDetailEdm',
  service: CustomerDetailsService
} as EntitySetConfig;
//#endregion