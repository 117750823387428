import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyEdm } from './DataDomain/EFEntities/Agency/agencyedm.entity';
import { ChangeSetEdm } from './DataDomain/EFEntities/Admin/changesetedm.entity';
import { ChangeSetGroupEdm } from './DataDomain/EFEntities/Admin/changesetgroupedm.entity';
import { ChangeSetLineEdm } from './DataDomain/EFEntities/Admin/changesetlineedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class ChangeSetsService extends ODataEntitySetService<ChangeSetEdm> {
  constructor(client: ODataClient) {
    super(client, 'ChangeSets', 'OptiaApi.DataDomain.EFEntities.Admin.ChangeSetEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public actionedByPerson(key: EntityKey<ChangeSetEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('actionedByPerson'); 
  }
  public fetchActionedByPerson(key: EntityKey<ChangeSetEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.actionedByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsActionedByPerson(key: EntityKey<ChangeSetEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.actionedByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsActionedByPerson(key: EntityKey<ChangeSetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.actionedByPerson(key).reference()
      .unset({etag});
  }
  public agency(key: EntityKey<ChangeSetEdm>): ODataNavigationPropertyResource<AgencyEdm> { 
    return this.entity(key).navigationProperty<AgencyEdm>('agency'); 
  }
  public fetchAgency(key: EntityKey<ChangeSetEdm>, options?: ODataQueryArgumentsOptions<AgencyEdm>) {
    return this.fetchNavigationProperty<AgencyEdm>(
      this.agency(key), 
      'entity', options) as Observable<ODataEntity<AgencyEdm>>;
  }
  public setAgencyEdmAsAgency(key: EntityKey<ChangeSetEdm>, target: ODataEntityResource<ODataEntity<AgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .set(target, {etag});
  }
  public unsetAgencyEdmAsAgency(key: EntityKey<ChangeSetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .unset({etag});
  }
  public changeSetGroup(key: EntityKey<ChangeSetEdm>): ODataNavigationPropertyResource<ChangeSetGroupEdm> { 
    return this.entity(key).navigationProperty<ChangeSetGroupEdm>('changeSetGroup'); 
  }
  public fetchChangeSetGroup(key: EntityKey<ChangeSetEdm>, options?: ODataQueryArgumentsOptions<ChangeSetGroupEdm>) {
    return this.fetchNavigationProperty<ChangeSetGroupEdm>(
      this.changeSetGroup(key), 
      'entity', options) as Observable<ODataEntity<ChangeSetGroupEdm>>;
  }
  public setChangeSetGroupEdmAsChangeSetGroup(key: EntityKey<ChangeSetEdm>, target: ODataEntityResource<ODataEntity<ChangeSetGroupEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.changeSetGroup(key).reference()
      .set(target, {etag});
  }
  public unsetChangeSetGroupEdmAsChangeSetGroup(key: EntityKey<ChangeSetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<ChangeSetGroupEdm>>, etag?: string} = {}): Observable<any> {
    return this.changeSetGroup(key).reference()
      .unset({etag});
  }
  public changeSetLines(key: EntityKey<ChangeSetEdm>): ODataNavigationPropertyResource<ChangeSetLineEdm> { 
    return this.entity(key).navigationProperty<ChangeSetLineEdm>('changeSetLines'); 
  }
  public fetchChangeSetLines(key: EntityKey<ChangeSetEdm>, options?: ODataQueryArgumentsOptions<ChangeSetLineEdm>) {
    return this.fetchNavigationProperty<ChangeSetLineEdm>(
      this.changeSetLines(key), 
      'entities', options) as Observable<ODataEntities<ChangeSetLineEdm>>;
  }
  public addChangeSetLineEdmToChangeSetLines(key: EntityKey<ChangeSetEdm>, target: ODataEntityResource<ODataEntities<ChangeSetLineEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.changeSetLines(key).reference()
      .add(target);
  }
  public removeChangeSetLineEdmFromChangeSetLines(key: EntityKey<ChangeSetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ChangeSetLineEdm>>, etag?: string} = {}): Observable<any> {
    return this.changeSetLines(key).reference()
      .remove(target);
  }
  public person(key: EntityKey<ChangeSetEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<ChangeSetEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<ChangeSetEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<ChangeSetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  //#endregion
}
