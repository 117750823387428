//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerTypeBankDetailEdm } from './workertypebankdetailedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WorkerTypeBankDetailEdmEntityConfig = {
  name: 'WorkerTypeBankDetailEdm',
  keys: [{name: 'bankDetailsGuid'},{name: 'fromDate'},{name: 'workerTypeGuid'}],
  fields: {
    workerTypeGuid: {type: 'Edm.Guid'},
    bankDetailsGuid: {type: 'Edm.Guid'},
    bankDetailsType: {type: 'Edm.Int16', nullable: false},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    workerTypeBankDetailsGuid: {type: 'Edm.Guid'},
    bankDetail: {type: 'OptiaApi.DataDomain.EFEntities.BankDetailEdm', navigation: true, referentials: [{property: 'bankDetailsGuid', referencedProperty: 'bankDetailsGuid'}]},
    workerType: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeEdm', navigation: true, referentials: [{property: 'workerTypeGuid', referencedProperty: 'workerTypeGuid'}]}
  }
} as StructuredTypeConfig<WorkerTypeBankDetailEdm>;
//#endregion