import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
import { WorkerTypeEdm } from './DataDomain/EFEntities/Worker/workertypeedm.entity';
import { WorkerTypePersonEdm } from './DataDomain/EFEntities/Worker/workertypepersonedm.entity';
//#endregion

@Injectable()
export class WorkerTypePersonsService extends ODataEntitySetService<WorkerTypePersonEdm> {
  constructor(client: ODataClient) {
    super(client, 'WorkerTypePersons', 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypePersonEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public person(key: EntityKey<WorkerTypePersonEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<WorkerTypePersonEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<WorkerTypePersonEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<WorkerTypePersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  public workerType(key: EntityKey<WorkerTypePersonEdm>): ODataNavigationPropertyResource<WorkerTypeEdm> { 
    return this.entity(key).navigationProperty<WorkerTypeEdm>('workerType'); 
  }
  public fetchWorkerType(key: EntityKey<WorkerTypePersonEdm>, options?: ODataQueryArgumentsOptions<WorkerTypeEdm>) {
    return this.fetchNavigationProperty<WorkerTypeEdm>(
      this.workerType(key), 
      'entity', options) as Observable<ODataEntity<WorkerTypeEdm>>;
  }
  public setWorkerTypeEdmAsWorkerType(key: EntityKey<WorkerTypePersonEdm>, target: ODataEntityResource<ODataEntity<WorkerTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerType(key).reference()
      .set(target, {etag});
  }
  public unsetWorkerTypeEdmAsWorkerType(key: EntityKey<WorkerTypePersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WorkerTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerType(key).reference()
      .unset({etag});
  }
  //#endregion
}
