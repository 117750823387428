//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SetPasswordDTOService } from './setpassworddto.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const SetPasswordDTOServiceEntitySetConfig = {
  name: 'SetPasswordDTO',
  entityType: 'OptiaApi.ServiceLayer.DTOs.SetPasswordDTO',
  service: SetPasswordDTOService
} as EntitySetConfig;
//#endregion