//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SupplierAgencyEdm } from './supplieragencyedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const SupplierAgencyEdmEntityConfig = {
  name: 'SupplierAgencyEdm',
  keys: [{name: 'agencyGuid'},{name: 'fromDate'},{name: 'supplierGuid'}],
  fields: {
    supplierGuid: {type: 'Edm.Guid'},
    agencyGuid: {type: 'Edm.Guid'},
    compliancePack: {type: 'Edm.Boolean'},
    complianceChecks: {type: 'Edm.String'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    agency: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm', navigation: true, referentials: [{property: 'agencyGuid', referencedProperty: 'agencyGuid'}]},
    supplier: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierEdm', navigation: true, referentials: [{property: 'supplierGuid', referencedProperty: 'supplierGuid'}]}
  }
} as StructuredTypeConfig<SupplierAgencyEdm>;
//#endregion