//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MiReportTypePersonEdm } from './mireporttypepersonedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const MiReportTypePersonEdmEntityConfig = {
  name: 'MiReportTypePersonEdm',
  keys: [{name: 'miReportTypeGuid'},{name: 'personGuid'}],
  fields: {
    miReportTypeGuid: {type: 'Edm.Guid'},
    personGuid: {type: 'Edm.Guid'},
    miReportType: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportTypeEdm', navigation: true, referentials: [{property: 'miReportTypeGuid', referencedProperty: 'miReportTypeGuid'}]},
    person: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<MiReportTypePersonEdm>;
//#endregion