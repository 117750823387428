//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { InputValidationTaskEdmComplexConfig } from './inputvalidationtaskedm.complex.config';
import { MiReportCombinationEdmComplexConfig } from './mireportcombinationedm.complex.config';
import { ReportDetailEdmComplexConfig } from './reportdetailedm.complex.config';
import { ReportGroupsWithTypeEdmComplexConfig } from './reportgroupswithtypeedm.complex.config';
import { TransferDetailEdmComplexConfig } from './transferdetailedm.complex.config';
import { ContractReportingLabelEdmEntityConfig } from './contractreportinglabeledm.entity.config';
import { MiReportGroupEdmEntityConfig } from './mireportgroupedm.entity.config';
import { MiReportGroupPersonEdmEntityConfig } from './mireportgrouppersonedm.entity.config';
import { MiReportTypeEdmEntityConfig } from './mireporttypeedm.entity.config';
import { MiReportTypePersonEdmEntityConfig } from './mireporttypepersonedm.entity.config';
import { MiReportTypeValidationRuleEdmEntityConfig } from './mireporttypevalidationruleedm.entity.config';
import { ReportingCategoryEdmEntityConfig } from './reportingcategoryedm.entity.config';
import { ReportingLabelEdmEntityConfig } from './reportinglabeledm.entity.config';
import { TransferEdmEntityConfig } from './transferedm.entity.config';
import { TransferAgencyEdmEntityConfig } from './transferagencyedm.entity.config';
import { TransferMiReportTypeEdmEntityConfig } from './transfermireporttypeedm.entity.config';
import { TransferLogEdmEntityConfig } from './transferlogedm.entity.config';
import { TransferRevisionEdmEntityConfig } from './transferrevisionedm.entity.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const ReportingSchema = {
  namespace: 'OptiaApi.DataDomain.EFEntities.Reporting',
  enums: [],
  entities: [InputValidationTaskEdmComplexConfig,
    MiReportCombinationEdmComplexConfig,
    ReportDetailEdmComplexConfig,
    ReportGroupsWithTypeEdmComplexConfig,
    TransferDetailEdmComplexConfig,
    ContractReportingLabelEdmEntityConfig,
    MiReportGroupEdmEntityConfig,
    MiReportGroupPersonEdmEntityConfig,
    MiReportTypeEdmEntityConfig,
    MiReportTypePersonEdmEntityConfig,
    MiReportTypeValidationRuleEdmEntityConfig,
    ReportingCategoryEdmEntityConfig,
    ReportingLabelEdmEntityConfig,
    TransferEdmEntityConfig,
    TransferAgencyEdmEntityConfig,
    TransferMiReportTypeEdmEntityConfig,
    TransferLogEdmEntityConfig,
    TransferRevisionEdmEntityConfig],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion