//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TransferMiReportTypeEdm } from './transfermireporttypeedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const TransferMiReportTypeEdmEntityConfig = {
  name: 'TransferMiReportTypeEdm',
  keys: [{name: 'miReportTypeGuid'},{name: 'transferGuid'}],
  fields: {
    transferGuid: {type: 'Edm.Guid'},
    miReportTypeGuid: {type: 'Edm.Guid'},
    miReportType: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportTypeEdm', navigation: true, referentials: [{property: 'miReportTypeGuid', referencedProperty: 'miReportTypeGuid'}]},
    transfer: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferEdm', navigation: true, referentials: [{property: 'transferGuid', referencedProperty: 'transferGuid'}]}
  }
} as StructuredTypeConfig<TransferMiReportTypeEdm>;
//#endregion