import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { PageStatesEnum } from '../../shared/grids/grid';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})

export class LogoutComponent implements OnInit {

  showLogoutInformation: boolean = false;
  private readonly _destroying$ = new Subject<void>();

  public userMessage:string = 'Your session has expired due to inactivity. Please login to proceed';

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,    
    private route: ActivatedRoute,
    private router: Router) {

      this.route.queryParams.subscribe(params => {
        const useraction = params['useraction'];
        if (useraction) {
          this.userMessage = 'You have been logged out. Please login to proceed';
        }
      });
  }

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$
    .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
    )
    .subscribe(() => {
        this.checkIfLoggedIn();
    });
  }

  checkIfLoggedIn() {
    this.showLogoutInformation = false;
    let activeAccount = this.authService.instance.getActiveAccount();
    if(this.authService.instance.getAllAccounts().length > 0) {
      this.router.navigate(["/dashboard"]);
    } else {
      this.clearPageState();
      this.showLogoutInformation = true;
    }
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
    } else {
        this.authService.loginRedirect(userFlowRequest);
    }
  }

  clearPageState() {
    const values = Object.values(PageStatesEnum)
    values.forEach((value) => {
      localStorage.removeItem(value);
    })
  }

}
