//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskTabsService } from './wftasktabs.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WfTaskTabsServiceEntitySetConfig = {
  name: 'WfTaskTabs',
  entityType: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskTabEdm',
  service: WfTaskTabsService
} as EntitySetConfig;
//#endregion