//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ReportingCategoryEdm } from './reportingcategoryedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ReportingCategoryEdmEntityConfig = {
  name: 'ReportingCategoryEdm',
  keys: [{name: 'reportingCategoryGuid'}],
  fields: {
    reportingCategoryGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String'},
    agencyReportingCategories: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyReportingCategoryEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<ReportingCategoryEdm>;
//#endregion