//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DocumentDetailEdm } from './documentdetailedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const DocumentDetailEdmComplexConfig = {
  name: 'DocumentDetailEdm',
  fields: {
    documentGuid: {type: 'Edm.Guid', nullable: false},
    uploadedOn: {type: 'Edm.DateTimeOffset'},
    uploadedBy: {type: 'Edm.Guid', nullable: false},
    filename: {type: 'Edm.String', nullable: false},
    mimeType: {type: 'Edm.String', nullable: false},
    documentType: {type: 'Edm.Int16', nullable: false},
    deleted: {type: 'Edm.DateTimeOffset'},
    approved: {type: 'Edm.Boolean', nullable: false},
    uploadedByName: {type: 'Edm.String'},
    uploadedByFirstName: {type: 'Edm.String'},
    uploadedBySurname: {type: 'Edm.String'},
    agencyGuid: {type: 'Edm.Guid'}
  }
} as StructuredTypeConfig<DocumentDetailEdm>;
//#endregion