//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { NotificationPersonsService } from './notificationpersons.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const NotificationPersonsServiceEntitySetConfig = {
  name: 'NotificationPersons',
  entityType: 'OptiaApi.DataDomain.EFEntities.Notify.NotificationPersonEdm',
  service: NotificationPersonsService
} as EntitySetConfig;
//#endregion