import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { NotificationService } from './../../shared/services/notification.service';
import { InactivityLogoutService } from './../../shared/services/inactivity-logout.service';

import { PromptValue } from '@azure/msal-common';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SsoSilentRequest } from '@azure/msal-browser';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';

import { b2cPolicies } from '../../aadb2cauth/auth-config';

import { compareStrings } from '../../shared/utils/str';

import { UserInformationClaims } from '../../shared/types/user-information-claims';
import { IdTokenClaimsWithPolicyId } from '../../shared/types/id-token-claims-with-policy';
import { OptiaUserAccountService } from '../../../app/shared/services/optia-user-account.service';
import { UserTypes } from '../../../app/manage/models/user-types';
import { hasPermission } from '../../../app/shared/utils/user-permissions.extensions';
import { allowAnonymousPages } from '../../../app/shared/lookups/arrays';
import { ConfirmationDialogComponent } from '@app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { StringDict } from 'msal/lib-commonjs/MsalTypes';
import { AuditEdm } from '@app/odata';




@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  public showUserMenu = false;
  public showSystemMenu = false;
  showMIMenu = false;
  canSeeMIMenu = false;
  showDocumentsMenu = false;
  canSeeDocumentsMenu = false;
  showSuppliers = false;
  showProspects = false;
  showAngencyProspect = true;

  notifications: any[] = [];
  isIframe = false;
  userIsAllowedToSee = false;
  userIsLoggedIn = false;
  userFullName = '';
  userOptiaRole = '';
  private readonly _destroying$ = new Subject<void>();

  public agencyLinkText: string = "My Agency";
  public agencyLinkURL: string = "";
  public customerLinkText: string = "My Customers";
  public customerLinkURL: string = "";
  public canImpersonate: boolean = false;
  public canReadAgencies: boolean = false;
  public canReadCustomers: boolean = false;
  public canReadSuppliers: boolean = false;
  public canReadProspects: boolean = false;
  public canManageNews: boolean = false;
  public canManageUsers: boolean = false;
  public canManageUserGroups: boolean = false;
  public canManageLists: boolean = false;
  public canViewWorkers: boolean = false;
  public canManageReportGroups: boolean = false;
  public canManageReportTypes: boolean = false;
  public canUploadReports: boolean = false;
  public canViewWeeklyReports: boolean = false; //TODO Sem: integrate the permission validation here
  public canViewMonthlyReports: boolean = false; //TODO Sem: integrate the permission validation here
  public canViewInputValidationReports: boolean = false; //TODO Sem: integrate the permission validation here
  public canSeeReportBatches: boolean = false;
  public canManageDocuments: boolean = false;
  public canManageFormsTemplates: boolean = false;
  public canViewDataMismatch: boolean = false;




  quickActionisExpanded = false;
  userMenuExpanded = false;

  @ViewChild('sidenav') sidenavElementRef!: ElementRef;

  constructor(
    private dialog: MatDialog,
    private inactivityLogoutService: InactivityLogoutService,
    private notificationService: NotificationService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private optiaUserAccountService: OptiaUserAccountService
  ) {
    this.notifications = notificationService.notifications;
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();
    this.setUserInfo();
    this.loadSignedInOptiaUser(null);

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
      )
      .subscribe({
        next: (rslt: EventMessage) => {

          // this is done to return the user back to the login screen, when he cancel the reset password flow
          // this can be done in other similar scenarios. When users cancels the previous operation.
          if (rslt.error) {
            let error = (rslt.error as any);
            if (error.errorMessage) {
              // Check for forgot password error
              // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
              if (error.errorMessage.indexOf('AADB2C90118') == -1) {
                let signInFlowRequest: RedirectRequest | PopupRequest = {
                  authority: b2cPolicies.authorities.customSignUpSignIn.authority,
                  prompt: PromptValue.LOGIN,
                  scopes: []
                };

                this.login(signInFlowRequest);
              }
            }
          }
        },
        error: (err) => {
          console.log('msalSubject error');
          console.log(err);
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED
          || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
          this.checkAndSetActiveAccount(null);
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount(null);
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => compareStrings(msg.eventType, EventType.LOGIN_SUCCESS)
          || compareStrings(msg.eventType, EventType.ACQUIRE_TOKEN_SUCCESS)
          || compareStrings(msg.eventType, EventType.SSO_SILENT_SUCCESS)),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {

        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (compareStrings(idtoken.acr, b2cPolicies.names.customSignUpSignIn)
          || compareStrings(idtoken.tfp, b2cPolicies.names.customSignUpSignIn)
          || compareStrings(idtoken.acr, b2cPolicies.names.impersonateUser)
          || compareStrings(idtoken.tfp, b2cPolicies.names.impersonateUser)) {
          this.checkAndSetActiveAccount(payload.account);
        }

        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
         * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (compareStrings(idtoken.acr, b2cPolicies.names.editProfile)
          || compareStrings(idtoken.tfp, b2cPolicies.names.editProfile)) {

          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = this.authService.instance.getAllAccounts()
            .find((account: AccountInfo) =>
              compareStrings(account.idTokenClaims?.oid, idtoken?.oid)
              && compareStrings(account.idTokenClaims?.sub, idtoken?.sub)
              && (compareStrings((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr, b2cPolicies.names.customSignUpSignIn)
                || compareStrings((account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp, b2cPolicies.names.customSignUpSignIn))
            );

          if (originalSignInAccount) {
            this.trySsoSilentLogin(originalSignInAccount);
          }
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow (see above ln. 74-92).
         */
        if (compareStrings(idtoken.acr, b2cPolicies.names.customResetPassword)
          || compareStrings(idtoken.tfp, b2cPolicies.names.customResetPassword)) {
          let signInFlowRequest: RedirectRequest | PopupRequest = {
            authority: b2cPolicies.authorities.customSignUpSignIn.authority,
            prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
            scopes: []
          };

          this.login(signInFlowRequest);
        }

        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => compareStrings(msg.eventType, EventType.LOGIN_FAILURE) || compareStrings(msg.eventType, EventType.ACQUIRE_TOKEN_FAILURE)),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Checking for the forgot password error. Learn more about B2C error codes at
        // https://learn.microsoft.com/azure/active-directory-b2c/error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority: b2cPolicies.authorities.customResetPassword.authority,
            scopes: [],
          };

          this.login(resetPasswordFlowRequest);
        } else if (result.error && result.error.message.indexOf('AADB2C90091') > -1) {

          let activeAccount = this.authService.instance.getActiveAccount();
          if (activeAccount == null) {
            this.checkAndSetActiveAccount(activeAccount);
          }

          activeAccount = this.authService.instance.getActiveAccount();
          if (activeAccount == null) {
            let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
              authority: b2cPolicies.authorities.customResetPassword.authority,
              scopes: [],
            };

            this.login(resetPasswordFlowRequest);
          } else {
            this.trySsoSilentLogin(activeAccount as AccountInfo);
          }
        };
      });
  }

  trySsoSilentLogin(originalSignInAccount: AccountInfo) {

    let signInFlowRequest: SsoSilentRequest = {
      authority: b2cPolicies.authorities.customSignUpSignIn.authority,
      account: originalSignInAccount as AccountInfo
    };

    // silently login again with the customSignUpSignIn policy
    this.authService.ssoSilent(signInFlowRequest);
  }

  showNotifications() {

  }

  toggleSidenav() {
    (this.sidenavElementRef as any).toggle();
  }

  setLoginDisplay() {

    this.userIsAllowedToSee = false;
    this.userIsLoggedIn = false;

    if (this.authService.instance.getAllAccounts().length > 0) {
      this.userIsLoggedIn = true;

      // //Add an Audit log to record this user logging in
      // let audit: AuditEdm = {

      // }

    } else if (allowAnonymousPages.includes(location.pathname)) {
      this.userIsAllowedToSee = true;
    }
  }

  checkAndSetActiveAccount(account: AccountInfo | null) {

    if (account) {
      this.authService.instance.setActiveAccount(account);
      this.setUserInfo();
      this.loadSignedInOptiaUser(account);
    } else {

      /**
       * If no active account set but there are accounts signed in, sets first account to active account
       * To use active account set here, subscribe to inProgress$ first in your component
       * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
       */
      let activeAccount = this.authService.instance.getActiveAccount();

      if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
        let accounts = this.authService.instance.getAllAccounts();
        // add your code for handling multiple accounts here
        // check if there is an impersonated account
        let impersonatedAccount = accounts.find(elem => compareStrings((elem.idTokenClaims as IdTokenClaimsWithPolicyId).acr, b2cPolicies.names.impersonateUser));

        // here set priority for the impersonated account
        if (impersonatedAccount) {

          this.authService.instance.setActiveAccount(impersonatedAccount);
          this.setUserInfo();
          this.loadSignedInOptiaUser(impersonatedAccount);

        } else {

          this.authService.instance.setActiveAccount(accounts[0]);
          this.setUserInfo();
          this.loadSignedInOptiaUser(accounts[0]);
        }
      }
    }
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.checkAndSetActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.checkAndSetActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  impersonateUser() {
    let impersonateUserRequest: RedirectRequest | PopupRequest = {
      authority: b2cPolicies.authorities.impersonateUser.authority,
      scopes: [],
    };

    this.login(impersonateUserRequest);
  }

  resetPassword() {
    let resetPasswordRequest: RedirectRequest | PopupRequest = {
      authority: b2cPolicies.authorities.customResetPassword.authority,
      scopes: [],
    };

    this.login(resetPasswordRequest);
  }

  changePassword() {
    let changePasswordRequest: RedirectRequest | PopupRequest = {
      authority: b2cPolicies.authorities.customChangePassword.authority,
      scopes: [],
    };

    this.login(changePasswordRequest);
  }

  logout() {
    let logoutUseractionParam: StringDict = {
      'useraction': 'true'
    };
    this.authService.logout({
      postLogoutRedirectUri: '/logout?useraction=true',
      extraQueryParameters: logoutUseractionParam
    });
  }

  editProfile() {
    let editProfileFlowRequest: RedirectRequest | PopupRequest = {
      authority: b2cPolicies.authorities.editProfile.authority,
      scopes: [],
    };

    this.login(editProfileFlowRequest);
  }

  setUserInfo() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (activeAccount !== null) {
      let userInfo = (activeAccount?.idTokenClaims as UserInformationClaims);
      this.userFullName = userInfo.given_name + ' ' + userInfo.family_name;
      // this.userOptiaRole = userInfo.extension_optia_role ?? '';
    }
  }

  loadSignedInOptiaUser(activeAccount: AccountInfo | null) {
    if (!activeAccount) {
      activeAccount = this.authService.instance.getActiveAccount();
    }

    if (!activeAccount) {
      return; //TODO Sem: investigate what to do it the current account is null
    }
    this.optiaUserAccountService.retrieveOptiaUser(activeAccount?.localAccountId as string)
      .subscribe((loggedInUser) => {

        if (!loggedInUser.personUserGroups || loggedInUser.personUserGroups.length == 0) {
          this.throwOutUnauthorisedUser(loggedInUser.userType);
        }

        //Agency Link
        if (hasPermission(loggedInUser, '_agenciesread')) {
          this.canReadAgencies = true;

          if (loggedInUser.userType == UserTypes.BackOffice) {
            this.agencyLinkText = 'Agencies';
            this.agencyLinkURL = '/agencies';
            this.customerLinkText = 'Customers';
            this.customerLinkURL = '/customers';
          }
          else {
            //User is Agency User so agency link takes them to their agency details
            if (loggedInUser.agencyPersons && loggedInUser.agencyPersons.length > 0) {
              this.agencyLinkURL = '/agencies/' + loggedInUser.agencyPersons[0].agencyGuid;
              this.customerLinkURL = 'customers/agencies';
            }
          }
        }

        if (hasPermission(loggedInUser, '_customersread') || hasPermission(loggedInUser, '_agencycustomersread')) {
          this.canReadCustomers = true;
        }


        //Suppliers Link
        if (loggedInUser.userType == UserTypes.BackOffice && hasPermission(loggedInUser, '_suppliersread')) {
          this.canReadSuppliers = true;
        }

        //Prospects Link
        if (loggedInUser.userType == UserTypes.BackOffice && hasPermission(loggedInUser, '_agenciesread')) {
          this.canReadProspects = true;
        }


        //Workers Link
        if (hasPermission(loggedInUser, '_workersread')) {
          this.canViewWorkers = true;
        }
        //Optia News Link
        if (loggedInUser.userType == UserTypes.BackOffice && hasPermission(loggedInUser, '_newsread')) {
          this.canManageNews = true;
        }
        //Users Link
        if (hasPermission(loggedInUser, '_userreadbackofficeusers') || hasPermission(loggedInUser, '_userreadagencyusers')) {
          this.canManageUsers = true;
        }
        //User Groups Link
        if (hasPermission(loggedInUser, '_usergroupsread') || hasPermission(loggedInUser, '_agencystandardusergroupsread') || hasPermission(loggedInUser, '_agencyspecificusergroupsread')) {
          this.canManageUserGroups = true;
        }
        //Lists Link
        if (loggedInUser.userType == UserTypes.BackOffice && hasPermission(loggedInUser, '_listsread')) {
          this.canManageLists = true;
        }
        //Impersonate Link
        // if (hasPermission(loggedInUser, "_impersonate")) {
        //   this.canImpersonate = true;
        // }
        // Report Group Link
        if (hasPermission(loggedInUser, '_reportgroupsread')) {
          this.canManageReportGroups = true;
        }
        // Report Type Link
        if (hasPermission(loggedInUser, '_reporttypesread')) {
          this.canManageReportTypes = true;
        }
        // Upload Reports         
        if (hasPermission(loggedInUser, '_reportweeklyupload') || hasPermission(loggedInUser, '_reportmonthlyupload')) {
          this.canUploadReports = true;
        }
        //Now hide the spinner
        this.userIsAllowedToSee = true;

        // Uploaded Documents Link
        if (hasPermission(loggedInUser, '_documentsread')) {
          this.canManageDocuments = true;
        }

        // Forms Templates Link
        if (hasPermission(loggedInUser, '_formread')) {
          this.canManageFormsTemplates = true;
        }

        if (loggedInUser.userType == UserTypes.BackOffice && hasPermission(loggedInUser, '_reportbatchuploadread')) {
          this.canSeeReportBatches = true;
        }

        if (hasPermission(loggedInUser, '_reportweeklyread')) {
          this.canViewWeeklyReports = true;
        }

        if (hasPermission(loggedInUser, '_reportmonthlyread')) {
          this.canViewMonthlyReports = true;
        }

        if (hasPermission(loggedInUser, '_reportinputvalread')) {
          this.canViewInputValidationReports = true;
        }

        // check if we can show the MI Menu
        this.canSeeMIMenu = this.canManageReportGroups || this.canManageReportTypes || this.canViewWeeklyReports ||
          this.canViewMonthlyReports || this.canViewInputValidationReports || this.canSeeReportBatches ||
          this.canUploadReports;

        // check if can show the Documents Menu
        this.canSeeDocumentsMenu = this.canManageDocuments || this.canManageFormsTemplates;

        //DataMismatch Menu
        if (hasPermission(loggedInUser, "_workerdatamismatchread") || hasPermission(loggedInUser, "_customerdatamismatchread")) {
          this.canViewDataMismatch = true;
        }

      });
  }

  throwOutUnauthorisedUser(userType: number | undefined) {
    let contactStr = '';

    if (userType !== undefined) {
      switch (userType) {
        case UserTypes.BackOffice:
          contactStr = 'If you believe this is an error, please contact the Back Office IT team.';
          break;
        case UserTypes.Agency:
          contactStr = 'If you believe this is an error, please contact your payroll account manager.';
          break;
        case UserTypes.Customer:
        case UserTypes.Worker:
          contactStr = 'If you believe this is an error, please contact your agency master user.';
          break;
      }
    }


    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'You have no permission to access Optia. ' + contactStr,
        buttonText: {
          ok: 'Ok'
        },
        hideCancel: true
      },
        width: '30rem'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      this.logout();
    });
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this.inactivityLogoutService.cleanUp();
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
