//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerPersonEdm } from './customerpersonedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CustomerPersonEdmEntityConfig = {
  name: 'CustomerPersonEdm',
  keys: [{name: 'customerGuid'},{name: 'fromDate'},{name: 'personGuid'}],
  fields: {
    customerGuid: {type: 'Edm.Guid'},
    personGuid: {type: 'Edm.Guid'},
    jobDescription: {type: 'Edm.String'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    customer: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerEdm', navigation: true, referentials: [{property: 'customerGuid', referencedProperty: 'customerGuid'}]},
    person: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<CustomerPersonEdm>;
//#endregion