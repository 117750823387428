//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TransferRevisionsService } from './transferrevisions.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const TransferRevisionsServiceEntitySetConfig = {
  name: 'TransferRevisions',
  entityType: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferRevisionEdm',
  service: TransferRevisionsService
} as EntitySetConfig;
//#endregion