//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { HistoricalReferencesService } from './historicalreferences.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const HistoricalReferencesServiceEntitySetConfig = {
  name: 'HistoricalReferences',
  entityType: 'OptiaApi.DataDomain.EFEntities.Admin.HistoricalReferenceEdm',
  service: HistoricalReferencesService
} as EntitySetConfig;
//#endregion