import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';



@Component({
  selector: 'app-page-notfound',
  templateUrl: './page-notfound.component.html',
  styleUrls: ['./page-notfound.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  statusPath: number = 400;

  constructor(private router: Router) {

    if (this.router.getCurrentNavigation()?.extras.state) {
      this.statusPath = <number><unknown>this.router.getCurrentNavigation()?.extras.state;
    } else {
      this.statusPath = 404;
    }
  }

  ngOnInit(): void {
   
  }


}
