import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyEdm } from './DataDomain/EFEntities/Agency/agencyedm.entity';
import { MiReportTypeEdm } from './DataDomain/EFEntities/Reporting/mireporttypeedm.entity';
import { TransferEdm } from './DataDomain/EFEntities/Reporting/transferedm.entity';
import { TransferLogEdm } from './DataDomain/EFEntities/Reporting/transferlogedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class TransferLogsService extends ODataEntitySetService<TransferLogEdm> {
  constructor(client: ODataClient) {
    super(client, 'TransferLogs', 'OptiaApi.DataDomain.EFEntities.Reporting.TransferLogEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public agency(key: EntityKey<TransferLogEdm>): ODataNavigationPropertyResource<AgencyEdm> { 
    return this.entity(key).navigationProperty<AgencyEdm>('agency'); 
  }
  public fetchAgency(key: EntityKey<TransferLogEdm>, options?: ODataQueryArgumentsOptions<AgencyEdm>) {
    return this.fetchNavigationProperty<AgencyEdm>(
      this.agency(key), 
      'entity', options) as Observable<ODataEntity<AgencyEdm>>;
  }
  public setAgencyEdmAsAgency(key: EntityKey<TransferLogEdm>, target: ODataEntityResource<ODataEntity<AgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .set(target, {etag});
  }
  public unsetAgencyEdmAsAgency(key: EntityKey<TransferLogEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .unset({etag});
  }
  public approvedByPerson(key: EntityKey<TransferLogEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('approvedByPerson'); 
  }
  public fetchApprovedByPerson(key: EntityKey<TransferLogEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.approvedByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsApprovedByPerson(key: EntityKey<TransferLogEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.approvedByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsApprovedByPerson(key: EntityKey<TransferLogEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.approvedByPerson(key).reference()
      .unset({etag});
  }
  public miReportType(key: EntityKey<TransferLogEdm>): ODataNavigationPropertyResource<MiReportTypeEdm> { 
    return this.entity(key).navigationProperty<MiReportTypeEdm>('miReportType'); 
  }
  public fetchMiReportType(key: EntityKey<TransferLogEdm>, options?: ODataQueryArgumentsOptions<MiReportTypeEdm>) {
    return this.fetchNavigationProperty<MiReportTypeEdm>(
      this.miReportType(key), 
      'entity', options) as Observable<ODataEntity<MiReportTypeEdm>>;
  }
  public setMiReportTypeEdmAsMiReportType(key: EntityKey<TransferLogEdm>, target: ODataEntityResource<ODataEntity<MiReportTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.miReportType(key).reference()
      .set(target, {etag});
  }
  public unsetMiReportTypeEdmAsMiReportType(key: EntityKey<TransferLogEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<MiReportTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.miReportType(key).reference()
      .unset({etag});
  }
  public transfer(key: EntityKey<TransferLogEdm>): ODataNavigationPropertyResource<TransferEdm> { 
    return this.entity(key).navigationProperty<TransferEdm>('transfer'); 
  }
  public fetchTransfer(key: EntityKey<TransferLogEdm>, options?: ODataQueryArgumentsOptions<TransferEdm>) {
    return this.fetchNavigationProperty<TransferEdm>(
      this.transfer(key), 
      'entity', options) as Observable<ODataEntity<TransferEdm>>;
  }
  public setTransferEdmAsTransfer(key: EntityKey<TransferLogEdm>, target: ODataEntityResource<ODataEntity<TransferEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.transfer(key).reference()
      .set(target, {etag});
  }
  public unsetTransferEdmAsTransfer(key: EntityKey<TransferLogEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<TransferEdm>>, etag?: string} = {}): Observable<any> {
    return this.transfer(key).reference()
      .unset({etag});
  }
  //#endregion
}
