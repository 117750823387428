import { InjectionToken } from "@angular/core";

export const INACTIVITY_CONFIG = new InjectionToken<string>("INACTIVITY_LOGOUT_CONFIG");

export interface IInactivityLogoutConfig {
    minutesAutoLogout: number;
    checkInterval: number;
    localStorageKey: string
}

export const inactivityConfig: IInactivityLogoutConfig = {
    minutesAutoLogout: 20,
    checkInterval: 5000, // in milliseconds
    localStorageKey: '_inactivityExpiredTime'
}