import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, of, ReplaySubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ODataClient, ODataProperty, ODataServiceFactory } from "angular-odata";
import { AgencyPersonEdm, AuditEdm, PersonDepartmentEdm, PersonEdm } from "./../../odata";
import { OrderByField } from "angular-odata/lib/resources/query/expressions";
import { PersonDetailEdm } from "./../../odata";
import { SetPasswordDTO } from "./../../odata";
import { PeopleService } from "./../../odata/OptiaApi/people.service";
import { AuditService } from "./audit-service.service";

@Injectable({
    providedIn: 'root'
})
export class OptiaUserAccountService {
    constructor(private http: HttpClient,
        private factory: ODataServiceFactory,
        private auditService: AuditService,
        private peopleService: PeopleService) {

    }

    private optiaUserSubject = new ReplaySubject<PersonEdm>();
    private optiaCurrentUser: PersonEdm;
    private userDetailsAreLoading: boolean = false;

    retrieveOptiaUser(identityGuid: string): Observable<PersonEdm> {

        if (this.optiaCurrentUser) {
            return of(this.optiaCurrentUser);
        }

        if (!this.userDetailsAreLoading) {
            this.getCurrentUserByIdentityGuid(identityGuid).subscribe((res) => {
                this.optiaCurrentUser = res;
                this.callIPAddressAPI().then(ipAddress => {
                    this.createLoginAudit(res, ipAddress);
                });
                this.optiaUserSubject.next(res);
                this.userDetailsAreLoading = false;
            });
            this.userDetailsAreLoading = true;
        }

        return this.optiaUserSubject.asObservable();
    }

    updateUserPassword(person: PersonEdm, newPassword: string): Observable<boolean> {

        return this.peopleService.callSetPassword(person, {
            identityGuid: person.identityGuid,
            password: newPassword
        } as SetPasswordDTO).pipe(
            map(res => (res as any).property as boolean)
        );
    }

    private getCurrentUserByIdentityGuid(identityGuid: string): Observable<PersonEdm> {

        let peopleService = this.factory.entitySet<PersonEdm>(
            "People",
            "OptiaApi.DataDomain.EFEntities.PersonEdm"
        );
        let person = peopleService.entities();

        person.query(q => q.expand({
            agencyPersons: { expand: { agency: { select: ["description", "agencyGuid"] } } },
            personDepartments: { expand: { department: { select: ["description", "departmentGuid"] } } },
            personUserGroups: {
                expand: {
                    userGroup: {
                        select: ["name"],
                        expand: {
                            userGroupPermissions: {
                                expand: {
                                    permission: {
                                        select: ["permissionGuid", "description", "tag", "active"],
                                        filter: "active eq true"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }));

        person.query(q => q.filter('identityGuid eq ' + identityGuid));
        return person.fetchOne().pipe(
            tap(usr => this.optiaCurrentUser = usr.entity as PersonEdm),
            map(res =>
                res.entity as PersonEdm
            )
        );
    }

    private createLoginAudit(loggedInUser: PersonEdm, ipAddress: string) {

        let audit: AuditEdm = {
            personGuid: loggedInUser.personGuid!,
            type: 5,
            description: 'Logged In to Optia Site',
            entityType: 45,
            auditDate: new Date(),
            ipAddress: ipAddress
        };

        this.auditService.createAudit(audit).subscribe();
    }

    callIPAddressAPI(): Promise<string> {
        return this.getIpAddress()
            .then((data: any) => data.ip)
            .catch((error: any) => {
                console.error('Error fetching IP address', error);
                return '';
            });
    }

    getIpAddress(): Promise<any> {
        return this.http.get('https://api.ipify.org?format=json').toPromise();
    }
}