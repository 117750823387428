//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TransferAgenciesService } from './transferagencies.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const TransferAgenciesServiceEntitySetConfig = {
  name: 'TransferAgencies',
  entityType: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferAgencyEdm',
  service: TransferAgenciesService
} as EntitySetConfig;
//#endregion