//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskGroupEdm } from './wftaskgroupedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfTaskGroupEdmEntityConfig = {
  name: 'WfTaskGroupEdm',
  keys: [{name: 'wfTaskGroupGuid'}],
  fields: {
    wfTaskGroupGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String', nullable: false},
    tag: {type: 'Edm.String'},
    processType: {type: 'Edm.Int32'},
    sequence: {type: 'Edm.Int32'},
    ownerGuid: {type: 'Edm.Guid'},
    wfTasks: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<WfTaskGroupEdm>;
//#endregion