import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskTemplateEdm } from './DataDomain/EFEntities/Workflow/wftasktemplateedm.entity';
import { WfTemplateEdm } from './DataDomain/EFEntities/Workflow/wftemplateedm.entity';
//#endregion

@Injectable()
export class WfTemplatesService extends ODataEntitySetService<WfTemplateEdm> {
  constructor(client: ODataClient) {
    super(client, 'WfTemplates', 'OptiaApi.DataDomain.EFEntities.Workflow.WfTemplateEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public wfTaskTemplates(key: EntityKey<WfTemplateEdm>): ODataNavigationPropertyResource<WfTaskTemplateEdm> { 
    return this.entity(key).navigationProperty<WfTaskTemplateEdm>('wfTaskTemplates'); 
  }
  public fetchWfTaskTemplates(key: EntityKey<WfTemplateEdm>, options?: ODataQueryArgumentsOptions<WfTaskTemplateEdm>) {
    return this.fetchNavigationProperty<WfTaskTemplateEdm>(
      this.wfTaskTemplates(key), 
      'entities', options) as Observable<ODataEntities<WfTaskTemplateEdm>>;
  }
  public addWfTaskTemplateEdmToWfTaskTemplates(key: EntityKey<WfTemplateEdm>, target: ODataEntityResource<ODataEntities<WfTaskTemplateEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfTaskTemplates(key).reference()
      .add(target);
  }
  public removeWfTaskTemplateEdmFromWfTaskTemplates(key: EntityKey<WfTemplateEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfTaskTemplateEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfTaskTemplates(key).reference()
      .remove(target);
  }
  //#endregion
}
