//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { NotificationTemplateEdm } from './notificationtemplateedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const NotificationTemplateEdmEntityConfig = {
  name: 'NotificationTemplateEdm',
  keys: [{name: 'notificationTemplateGuid'}],
  fields: {
    notificationTemplateGuid: {type: 'Edm.Guid', nullable: false},
    template: {type: 'Edm.String', maxLength: -1},
    subject: {type: 'Edm.String'},
    type: {type: 'Edm.Int16'},
    tag: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<NotificationTemplateEdm>;
//#endregion