//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { RegExesService } from './regexes.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const RegExesServiceEntitySetConfig = {
  name: 'RegExes',
  entityType: 'OptiaApi.DataDomain.EFEntities.Admin.RegExEdm',
  service: RegExesService
} as EntitySetConfig;
//#endregion