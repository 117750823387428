//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfRoleEdm } from './wfroleedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfRoleEdmEntityConfig = {
  name: 'WfRoleEdm',
  keys: [{name: 'wfRoleGuid'}],
  fields: {
    wfRoleGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String', nullable: false},
    tag: {type: 'Edm.String', nullable: false},
    roleType: {type: 'Edm.Int32'},
    agencyPersonWfRoles: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyPersonWfRoleEdm', collection: true, navigation: true},
    roleGuidWfTaskRoles: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskRoleEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<WfRoleEdm>;
//#endregion