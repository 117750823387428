//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyAddressEdm } from './agencyaddressedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AgencyAddressEdmEntityConfig = {
  name: 'AgencyAddressEdm',
  keys: [{name: 'addressGuid'},{name: 'agencyGuid'},{name: 'fromDate'}],
  fields: {
    agencyGuid: {type: 'Edm.Guid'},
    addressGuid: {type: 'Edm.Guid'},
    addressType: {type: 'Edm.Int16'},
    manuallyEntered: {type: 'Edm.Boolean'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    address: {type: 'OptiaApi.DataDomain.EFEntities.AddressEdm', navigation: true, referentials: [{property: 'addressGuid', referencedProperty: 'addressGuid'}]},
    agency: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm', navigation: true, referentials: [{property: 'agencyGuid', referencedProperty: 'agencyGuid'}]}
  }
} as StructuredTypeConfig<AgencyAddressEdm>;
//#endregion