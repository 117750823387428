import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SupplierEdm } from './DataDomain/EFEntities/Supplier/supplieredm.entity';
import { SupplierWorkerTypeEdm } from './DataDomain/EFEntities/Supplier/supplierworkertypeedm.entity';
import { WorkerTypeEdm } from './DataDomain/EFEntities/Worker/workertypeedm.entity';
//#endregion

@Injectable()
export class SupplierWorkerTypesService extends ODataEntitySetService<SupplierWorkerTypeEdm> {
  constructor(client: ODataClient) {
    super(client, 'SupplierWorkerTypes', 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierWorkerTypeEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public supplier(key: EntityKey<SupplierWorkerTypeEdm>): ODataNavigationPropertyResource<SupplierEdm> { 
    return this.entity(key).navigationProperty<SupplierEdm>('supplier'); 
  }
  public fetchSupplier(key: EntityKey<SupplierWorkerTypeEdm>, options?: ODataQueryArgumentsOptions<SupplierEdm>) {
    return this.fetchNavigationProperty<SupplierEdm>(
      this.supplier(key), 
      'entity', options) as Observable<ODataEntity<SupplierEdm>>;
  }
  public setSupplierEdmAsSupplier(key: EntityKey<SupplierWorkerTypeEdm>, target: ODataEntityResource<ODataEntity<SupplierEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.supplier(key).reference()
      .set(target, {etag});
  }
  public unsetSupplierEdmAsSupplier(key: EntityKey<SupplierWorkerTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<SupplierEdm>>, etag?: string} = {}): Observable<any> {
    return this.supplier(key).reference()
      .unset({etag});
  }
  public workerType(key: EntityKey<SupplierWorkerTypeEdm>): ODataNavigationPropertyResource<WorkerTypeEdm> { 
    return this.entity(key).navigationProperty<WorkerTypeEdm>('workerType'); 
  }
  public fetchWorkerType(key: EntityKey<SupplierWorkerTypeEdm>, options?: ODataQueryArgumentsOptions<WorkerTypeEdm>) {
    return this.fetchNavigationProperty<WorkerTypeEdm>(
      this.workerType(key), 
      'entity', options) as Observable<ODataEntity<WorkerTypeEdm>>;
  }
  public setWorkerTypeEdmAsWorkerType(key: EntityKey<SupplierWorkerTypeEdm>, target: ODataEntityResource<ODataEntity<WorkerTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerType(key).reference()
      .set(target, {etag});
  }
  public unsetWorkerTypeEdmAsWorkerType(key: EntityKey<SupplierWorkerTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WorkerTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerType(key).reference()
      .unset({etag});
  }
  //#endregion
}
