//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskGroupsService } from './wftaskgroups.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WfTaskGroupsServiceEntitySetConfig = {
  name: 'WfTaskGroups',
  entityType: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskGroupEdm',
  service: WfTaskGroupsService
} as EntitySetConfig;
//#endregion