//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerBankDetailEdm } from './workerbankdetailedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WorkerBankDetailEdmEntityConfig = {
  name: 'WorkerBankDetailEdm',
  keys: [{name: 'bankDetailsGuid'},{name: 'fromDate'},{name: 'workerGuid'}],
  fields: {
    workerGuid: {type: 'Edm.Guid'},
    bankDetailsGuid: {type: 'Edm.Guid'},
    bankDetailsType: {type: 'Edm.Int16', nullable: false},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    workerBankDetailsGuid: {type: 'Edm.Guid'},
    bankDetail: {type: 'OptiaApi.DataDomain.EFEntities.BankDetailEdm', navigation: true, referentials: [{property: 'bankDetailsGuid', referencedProperty: 'bankDetailsGuid'}]},
    worker: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerEdm', navigation: true, referentials: [{property: 'workerGuid', referencedProperty: 'workerGuid'}]}
  }
} as StructuredTypeConfig<WorkerBankDetailEdm>;
//#endregion