import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { BankDetailEdm } from './DataDomain/EFEntities/bankdetailedm.entity';
import { AgencyEdm } from './DataDomain/EFEntities/Agency/agencyedm.entity';
import { AgencyBankDetailEdm } from './DataDomain/EFEntities/Agency/agencybankdetailedm.entity';
//#endregion

@Injectable()
export class AgencyBankDetailsService extends ODataEntitySetService<AgencyBankDetailEdm> {
  constructor(client: ODataClient) {
    super(client, 'AgencyBankDetails', 'OptiaApi.DataDomain.EFEntities.Agency.AgencyBankDetailEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public agency(key: EntityKey<AgencyBankDetailEdm>): ODataNavigationPropertyResource<AgencyEdm> { 
    return this.entity(key).navigationProperty<AgencyEdm>('agency'); 
  }
  public fetchAgency(key: EntityKey<AgencyBankDetailEdm>, options?: ODataQueryArgumentsOptions<AgencyEdm>) {
    return this.fetchNavigationProperty<AgencyEdm>(
      this.agency(key), 
      'entity', options) as Observable<ODataEntity<AgencyEdm>>;
  }
  public setAgencyEdmAsAgency(key: EntityKey<AgencyBankDetailEdm>, target: ODataEntityResource<ODataEntity<AgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .set(target, {etag});
  }
  public unsetAgencyEdmAsAgency(key: EntityKey<AgencyBankDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .unset({etag});
  }
  public bankDetail(key: EntityKey<AgencyBankDetailEdm>): ODataNavigationPropertyResource<BankDetailEdm> { 
    return this.entity(key).navigationProperty<BankDetailEdm>('bankDetail'); 
  }
  public fetchBankDetail(key: EntityKey<AgencyBankDetailEdm>, options?: ODataQueryArgumentsOptions<BankDetailEdm>) {
    return this.fetchNavigationProperty<BankDetailEdm>(
      this.bankDetail(key), 
      'entity', options) as Observable<ODataEntity<BankDetailEdm>>;
  }
  public setBankDetailEdmAsBankDetail(key: EntityKey<AgencyBankDetailEdm>, target: ODataEntityResource<ODataEntity<BankDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.bankDetail(key).reference()
      .set(target, {etag});
  }
  public unsetBankDetailEdmAsBankDetail(key: EntityKey<AgencyBankDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<BankDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.bankDetail(key).reference()
      .unset({etag});
  }
  //#endregion
}
