//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTemplateEdm } from './wftemplateedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfTemplateEdmEntityConfig = {
  name: 'WfTemplateEdm',
  keys: [{name: 'wfTemplateGuid'}],
  fields: {
    wfTemplateGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String', nullable: false},
    subject: {type: 'Edm.String', maxLength: -1},
    body: {type: 'Edm.String', nullable: false, maxLength: -1},
    mediaType: {type: 'Edm.Int32'},
    tag: {type: 'Edm.String'},
    footer: {type: 'Edm.String', maxLength: -1},
    wfTaskTemplates: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskTemplateEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<WfTemplateEdm>;
//#endregion