
<div id="error-page">
  <div class="content">
    <h2 *ngIf="statusPath==404" class="header" data-text="404">
      404
    </h2>
    <h5 *ngIf="statusPath==404" data-text="Oops! Page not found">
      Oops! Page not found
    </h5>

    <h2 *ngIf="statusPath==500" class="header" data-text="500">
      500
    </h2>
    <h5 *ngIf="statusPath==500" data-text="Something went wrong">
       Something went wrong
    </h5>
    <p>
      Sorry, the page you're looking for doesn't exist.
      <!--If you think something is broken, report a problem.-->
    </p>
    <div class="btns">
      <a href="/">return home</a>

    </div>
  </div>
</div>
