//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TransferDetailEdm } from './transferdetailedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const TransferDetailEdmComplexConfig = {
  name: 'TransferDetailEdm',
  fields: {
    transferGuid: {type: 'Edm.Guid', nullable: false},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid', nullable: false},
    notifiedOn: {type: 'Edm.DateTimeOffset'},
    completedOn: {type: 'Edm.DateTimeOffset'},
    whichAgenciesForType: {type: 'Edm.Int16', nullable: false},
    periodType: {type: 'Edm.Int16'},
    weekNo: {type: 'Edm.Byte'},
    weekYear: {type: 'Edm.Int16'},
    toWeekNo: {type: 'Edm.Int16'},
    toWeekYear: {type: 'Edm.Int16'},
    monthNo: {type: 'Edm.Int16'},
    monthYear: {type: 'Edm.Int16'},
    toMonthNo: {type: 'Edm.Int16'},
    toMonthYear: {type: 'Edm.Int16'},
    uploadStatus: {type: 'Edm.Int16', nullable: false},
    firstName: {type: 'Edm.String'},
    surname: {type: 'Edm.String'},
    emailAddress: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<TransferDetailEdm>;
//#endregion