//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerDetailsFilterDto } from './customerdetailsfilterdto.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CustomerDetailsFilterDtoEntityConfig = {
  name: 'CustomerDetailsFilterDto',
  keys: [{name: 'customerAgencyGuid'}],
  fields: {
    customerAgencyGuid: {type: 'Edm.Guid', nullable: false},
    filter: {type: 'Edm.String'},
    verificationOutcomeFilter: {type: 'Edm.String'},
    statusFilter: {type: 'Edm.Int32'},
    statusAgencyFilter: {type: 'Edm.Int32'},
    typeFilter: {type: 'Edm.Int32'},
    skip: {type: 'Edm.Int32', nullable: false},
    take: {type: 'Edm.Int32', nullable: false}
  }
} as StructuredTypeConfig<CustomerDetailsFilterDto>;
//#endregion