//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { NotificationTemplatesService } from './notificationtemplates.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const NotificationTemplatesServiceEntitySetConfig = {
  name: 'NotificationTemplates',
  entityType: 'OptiaApi.DataDomain.EFEntities.Notify.NotificationTemplateEdm',
  service: NotificationTemplatesService
} as EntitySetConfig;
//#endregion