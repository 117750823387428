import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfActionEdm } from './DataDomain/EFEntities/Workflow/wfactionedm.entity';
import { WfInstanceEdm } from './DataDomain/EFEntities/Workflow/wfinstanceedm.entity';
import { WfOutcomeEdm } from './DataDomain/EFEntities/Workflow/wfoutcomeedm.entity';
import { WfTaskEdm } from './DataDomain/EFEntities/Workflow/wftaskedm.entity';
import { WfActionOutstandingEdm } from './DataDomain/EFEntities/Workflow/wfactionoutstandingedm.entity';
//#endregion

@Injectable()
export class WfActionsService extends ODataEntitySetService<WfActionEdm> {
  constructor(client: ODataClient) {
    super(client, 'WfActions', 'OptiaApi.DataDomain.EFEntities.Workflow.WfActionEdm');
  }
  //#region ODataApiGen Actions
  public myActions(): ODataActionResource<{personGuid: string}, WfActionOutstandingEdm> { 
    return this.entities().action<{personGuid: string}, WfActionOutstandingEdm>('OptiaApi.myActions');
  }
  public callMyActions(personGuid: string, options?: ODataActionOptions<WfActionOutstandingEdm>) {
    return this.callAction<{personGuid: string}, WfActionOutstandingEdm>(
      {personGuid}, 
      this.myActions(), 
      'entities', options) as Observable<ODataEntities<WfActionOutstandingEdm>>;
  }
  public complete(key: EntityKey<WfActionEdm>): ODataActionResource<{personGuid: string, wfOutcomeGuid: string}, WfActionOutstandingEdm> { 
    return this.entity(key).action<{personGuid: string, wfOutcomeGuid: string}, WfActionOutstandingEdm>('OptiaApi.Complete');
  }
  public callComplete(key: EntityKey<WfActionEdm>, personGuid: string, wfOutcomeGuid: string, options?: ODataActionOptions<WfActionOutstandingEdm>) {
    return this.callAction<{personGuid: string, wfOutcomeGuid: string}, WfActionOutstandingEdm>(
      {personGuid, wfOutcomeGuid}, 
      this.complete(key), 
      'entity', options) as Observable<ODataEntity<WfActionOutstandingEdm>>;
  }
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public wfInstance(key: EntityKey<WfActionEdm>): ODataNavigationPropertyResource<WfInstanceEdm> { 
    return this.entity(key).navigationProperty<WfInstanceEdm>('wfInstance'); 
  }
  public fetchWfInstance(key: EntityKey<WfActionEdm>, options?: ODataQueryArgumentsOptions<WfInstanceEdm>) {
    return this.fetchNavigationProperty<WfInstanceEdm>(
      this.wfInstance(key), 
      'entity', options) as Observable<ODataEntity<WfInstanceEdm>>;
  }
  public setWfInstanceEdmAsWfInstance(key: EntityKey<WfActionEdm>, target: ODataEntityResource<ODataEntity<WfInstanceEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfInstance(key).reference()
      .set(target, {etag});
  }
  public unsetWfInstanceEdmAsWfInstance(key: EntityKey<WfActionEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WfInstanceEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfInstance(key).reference()
      .unset({etag});
  }
  public wfOutcome(key: EntityKey<WfActionEdm>): ODataNavigationPropertyResource<WfOutcomeEdm> { 
    return this.entity(key).navigationProperty<WfOutcomeEdm>('wfOutcome'); 
  }
  public fetchWfOutcome(key: EntityKey<WfActionEdm>, options?: ODataQueryArgumentsOptions<WfOutcomeEdm>) {
    return this.fetchNavigationProperty<WfOutcomeEdm>(
      this.wfOutcome(key), 
      'entity', options) as Observable<ODataEntity<WfOutcomeEdm>>;
  }
  public setWfOutcomeEdmAsWfOutcome(key: EntityKey<WfActionEdm>, target: ODataEntityResource<ODataEntity<WfOutcomeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfOutcome(key).reference()
      .set(target, {etag});
  }
  public unsetWfOutcomeEdmAsWfOutcome(key: EntityKey<WfActionEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WfOutcomeEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfOutcome(key).reference()
      .unset({etag});
  }
  public wfTask(key: EntityKey<WfActionEdm>): ODataNavigationPropertyResource<WfTaskEdm> { 
    return this.entity(key).navigationProperty<WfTaskEdm>('wfTask'); 
  }
  public fetchWfTask(key: EntityKey<WfActionEdm>, options?: ODataQueryArgumentsOptions<WfTaskEdm>) {
    return this.fetchNavigationProperty<WfTaskEdm>(
      this.wfTask(key), 
      'entity', options) as Observable<ODataEntity<WfTaskEdm>>;
  }
  public setWfTaskEdmAsWfTask(key: EntityKey<WfActionEdm>, target: ODataEntityResource<ODataEntity<WfTaskEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfTask(key).reference()
      .set(target, {etag});
  }
  public unsetWfTaskEdmAsWfTask(key: EntityKey<WfActionEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WfTaskEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfTask(key).reference()
      .unset({etag});
  }
  //#endregion
}
