//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfOutcomeEdm } from './wfoutcomeedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfOutcomeEdmEntityConfig = {
  name: 'WfOutcomeEdm',
  keys: [{name: 'wfOutcomeGuid'}],
  fields: {
    wfOutcomeGuid: {type: 'Edm.Guid', nullable: false},
    wfTaskGuid: {type: 'Edm.Guid'},
    description: {type: 'Edm.String'},
    tag: {type: 'Edm.String'},
    isDefault: {type: 'Edm.Int32'},
    requiresValidation: {type: 'Edm.Boolean'},
    hidden: {type: 'Edm.Boolean'},
    toolTip: {type: 'Edm.String', maxLength: -1},
    requiresConfirmation: {type: 'Edm.Boolean'},
    wfTask: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskEdm', navigation: true, referentials: [{property: 'wfTaskGuid', referencedProperty: 'wfTaskGuid'}]},
    wfActions: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfActionEdm', collection: true, navigation: true},
    wfPreRequisites: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfPreRequisiteEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<WfOutcomeEdm>;
//#endregion