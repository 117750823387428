//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MiReportGroupEdm } from './mireportgroupedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const MiReportGroupEdmEntityConfig = {
  name: 'MiReportGroupEdm',
  keys: [{name: 'miReportGroupGuid'}],
  fields: {
    miReportGroupGuid: {type: 'Edm.Guid', nullable: false},
    name: {type: 'Edm.String'},
    status: {type: 'Edm.Int16', nullable: false},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    miReportGroupPersons: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportGroupPersonEdm', collection: true, navigation: true},
    miReportTypes: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportTypeEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<MiReportGroupEdm>;
//#endregion