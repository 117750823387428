//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DocumentDetailEdmComplexConfig } from './documentdetailedm.complex.config';
import { WorkerDetailEdmComplexConfig } from './workerdetailedm.complex.config';
import { AccountEdmEntityConfig } from './accountedm.entity.config';
import { AccountPersonEdmEntityConfig } from './accountpersonedm.entity.config';
import { AddressEdmEntityConfig } from './addressedm.entity.config';
import { AgencyMtdCalendarEdmEntityConfig } from './agencymtdcalendaredm.entity.config';
import { AgencyReportingCombinationEdmEntityConfig } from './agencyreportingcombinationedm.entity.config';
import { AssignmentEdmEntityConfig } from './assignmentedm.entity.config';
import { BankDetailEdmEntityConfig } from './bankdetailedm.entity.config';
import { CharityEdmEntityConfig } from './charityedm.entity.config';
import { CompaniesHouseDetailEdmEntityConfig } from './companieshousedetailedm.entity.config';
import { CompaniesHouseDetailsAddressEdmEntityConfig } from './companieshousedetailsaddressedm.entity.config';
import { CompaniesHouseDetailsPersonEdmEntityConfig } from './companieshousedetailspersonedm.entity.config';
import { ContractEdmEntityConfig } from './contractedm.entity.config';
import { ContractPersonEdmEntityConfig } from './contractpersonedm.entity.config';
import { DepartmentEdmEntityConfig } from './departmentedm.entity.config';
import { DocumentEdmEntityConfig } from './documentedm.entity.config';
import { PhoneNumberEdmEntityConfig } from './phonenumberedm.entity.config';
import { PurchaseOrderEdmEntityConfig } from './purchaseorderedm.entity.config';
import { WfTaskContactTypeEdmEntityConfig } from './wftaskcontacttypeedm.entity.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const EFEntitiesSchema = {
  namespace: 'OptiaApi.DataDomain.EFEntities',
  enums: [],
  entities: [DocumentDetailEdmComplexConfig,
    WorkerDetailEdmComplexConfig,
    AccountEdmEntityConfig,
    AccountPersonEdmEntityConfig,
    AddressEdmEntityConfig,
    AgencyMtdCalendarEdmEntityConfig,
    AgencyReportingCombinationEdmEntityConfig,
    AssignmentEdmEntityConfig,
    BankDetailEdmEntityConfig,
    CharityEdmEntityConfig,
    CompaniesHouseDetailEdmEntityConfig,
    CompaniesHouseDetailsAddressEdmEntityConfig,
    CompaniesHouseDetailsPersonEdmEntityConfig,
    ContractEdmEntityConfig,
    ContractPersonEdmEntityConfig,
    DepartmentEdmEntityConfig,
    DocumentEdmEntityConfig,
    PhoneNumberEdmEntityConfig,
    PurchaseOrderEdmEntityConfig,
    WfTaskContactTypeEdmEntityConfig],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion