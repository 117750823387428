//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfProcessWfSchedulesService } from './wfprocesswfschedules.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WfProcessWfSchedulesServiceEntitySetConfig = {
  name: 'WfProcessWfSchedules',
  entityType: 'OptiaApi.DataDomain.EFEntities.Workflow.WfProcessWfScheduleEdm',
  service: WfProcessWfSchedulesService
} as EntitySetConfig;
//#endregion