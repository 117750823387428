//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AuditEdm } from './auditedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AuditEdmEntityConfig = {
  name: 'AuditEdm',
  keys: [{name: 'auditGuid'}],
  fields: {
    auditGuid: {type: 'Edm.Guid', nullable: false},
    personGuid: {type: 'Edm.Guid'},
    type: {type: 'Edm.Int16'},
    description: {type: 'Edm.String'},
    entityType: {type: 'Edm.Int16'},
    auditDate: {type: 'Edm.DateTimeOffset'},
    ipAddress: {type: 'Edm.String'},
    deleted: {type: 'Edm.DateTimeOffset'},
    person: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<AuditEdm>;
//#endregion