//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ChangeSetGroupsService } from './changesetgroups.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ChangeSetGroupsServiceEntitySetConfig = {
  name: 'ChangeSetGroups',
  entityType: 'OptiaApi.DataDomain.EFEntities.Admin.ChangeSetGroupEdm',
  service: ChangeSetGroupsService
} as EntitySetConfig;
//#endregion