//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TradingStylesService } from './tradingstyles.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const TradingStylesServiceEntitySetConfig = {
  name: 'TradingStyles',
  entityType: 'OptiaApi.DataDomain.EFEntities.Agency.TradingStyleEdm',
  service: TradingStylesService
} as EntitySetConfig;
//#endregion