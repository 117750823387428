//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyMtdCalendarEdm } from './agencymtdcalendaredm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AgencyMtdCalendarEdmEntityConfig = {
  name: 'AgencyMtdCalendarEdm',
  keys: [{name: 'agencyGuid'},{name: 'startPeriod'}],
  fields: {
    agencyGuid: {type: 'Edm.Guid'},
    startPeriod: {type: 'Edm.Int32', nullable: false},
    startPeriodDate: {type: 'Edm.DateTimeOffset', nullable: false},
    endPeriod: {type: 'Edm.Int32', nullable: false},
    endPeriodDate: {type: 'Edm.DateTimeOffset', nullable: false},
    outputReportCreatedOn: {type: 'Edm.DateTimeOffset'},
    outputReportCreatedBy: {type: 'Edm.Guid'},
    outputReportPath: {type: 'Edm.String'},
    inputReportCreatedOn: {type: 'Edm.DateTimeOffset'},
    inputReportCreatedBy: {type: 'Edm.Guid'},
    inputReportPath: {type: 'Edm.String'},
    reportingFrequency: {type: 'Edm.Int32'},
    agency: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm', navigation: true, referentials: [{property: 'agencyGuid', referencedProperty: 'agencyGuid'}]}
  }
} as StructuredTypeConfig<AgencyMtdCalendarEdm>;
//#endregion