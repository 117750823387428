//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { RefDataTypesService } from './refdatatypes.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const RefDataTypesServiceEntitySetConfig = {
  name: 'RefDataTypes',
  entityType: 'OptiaApi.DataDomain.EFEntities.RefData.RefDataTypeEdm',
  service: RefDataTypesService
} as EntitySetConfig;
//#endregion