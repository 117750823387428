//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TransferLogsService } from './transferlogs.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const TransferLogsServiceEntitySetConfig = {
  name: 'TransferLogs',
  entityType: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferLogEdm',
  service: TransferLogsService
} as EntitySetConfig;
//#endregion