//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CreditLimitEdm } from './creditlimitedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CreditLimitEdmEntityConfig = {
  name: 'CreditLimitEdm',
  keys: [{name: 'creditLimitGuid'}],
  fields: {
    creditLimitGuid: {type: 'Edm.Guid', nullable: false},
    creditCheckGuid: {type: 'Edm.Guid'},
    amount: {type: 'Edm.Decimal', scale: 'variable'},
    fromDate: {type: 'Edm.DateTimeOffset'},
    toDate: {type: 'Edm.DateTimeOffset'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    creditCheck: {type: 'OptiaApi.DataDomain.EFEntities.Credit.CreditCheckEdm', navigation: true, referentials: [{property: 'creditCheckGuid', referencedProperty: 'creditCheckGuid'}]},
    creditAssignments: {type: 'OptiaApi.DataDomain.EFEntities.Credit.CreditAssignmentEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<CreditLimitEdm>;
//#endregion