//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerAgencyDetailEdm } from './customeragencydetailedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CustomerAgencyDetailEdmComplexConfig = {
  name: 'CustomerAgencyDetailEdm',
  fields: {
    customerAgencyGuid: {type: 'Edm.Guid', nullable: false},
    name: {type: 'Edm.String'},
    legalName: {type: 'Edm.String'},
    contracts: {type: 'Edm.Int32'},
    customerType: {type: 'Edm.Int16'},
    status: {type: 'Edm.Int16', nullable: false},
    customerGuid: {type: 'Edm.Guid', nullable: false},
    agencyGuid: {type: 'Edm.Guid', nullable: false}
  }
} as StructuredTypeConfig<CustomerAgencyDetailEdm>;
//#endregion