//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OptiaNewEdm } from './optianewedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const OptiaNewEdmEntityConfig = {
  name: 'OptiaNewEdm',
  keys: [{name: 'optiaNewsGuid'}],
  fields: {
    optiaNewsGuid: {type: 'Edm.Guid', nullable: false},
    title: {type: 'Edm.String', nullable: false},
    details: {type: 'Edm.String', maxLength: -1},
    launchDate: {type: 'Edm.DateTimeOffset'},
    lastEditedDate: {type: 'Edm.DateTimeOffset'},
    lastEditedBy: {type: 'Edm.Guid'},
    expiryDate: {type: 'Edm.DateTimeOffset'},
    isUrgent: {type: 'Edm.Boolean', nullable: false},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    expiredBy: {type: 'Edm.Guid'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    lastEditedByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'lastEditedBy', referencedProperty: 'personGuid'}]},
    expiredByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'expiredBy', referencedProperty: 'personGuid'}]},
    optiaNewsDocuments: {type: 'OptiaApi.DataDomain.EFEntities.Notify.OptiaNewsDocumentEdm', collection: true, navigation: true},
    optiaNewsPersons: {type: 'OptiaApi.DataDomain.EFEntities.Notify.OptiaNewsPersonEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<OptiaNewEdm>;
//#endregion