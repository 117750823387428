//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskContactTypesService } from './wftaskcontacttypes.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WfTaskContactTypesServiceEntitySetConfig = {
  name: 'WfTaskContactTypes',
  entityType: 'OptiaApi.DataDomain.EFEntities.WfTaskContactTypeEdm',
  service: WfTaskContactTypesService
} as EntitySetConfig;
//#endregion