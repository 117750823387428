//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { RefDataTypeEdm } from './refdatatypeedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const RefDataTypeEdmEntityConfig = {
  name: 'RefDataTypeEdm',
  keys: [{name: 'refDataTypeGuid'}],
  fields: {
    refDataTypeGuid: {type: 'Edm.Guid', nullable: false},
    parentRefDataTypeGuid: {type: 'Edm.Guid'},
    tag: {type: 'Edm.String'},
    description: {type: 'Edm.String'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    readOnly: {type: 'Edm.Boolean'},
    parentRefDataType: {type: 'OptiaApi.DataDomain.EFEntities.RefData.RefDataTypeEdm', navigation: true, referentials: [{property: 'parentRefDataTypeGuid', referencedProperty: 'refDataTypeGuid'}]},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    parentRefDataTypes: {type: 'OptiaApi.DataDomain.EFEntities.RefData.RefDataTypeEdm', collection: true, navigation: true},
    refDataItems: {type: 'OptiaApi.DataDomain.EFEntities.RefData.RefDataItemEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<RefDataTypeEdm>;
//#endregion