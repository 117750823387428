//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PurchaseOrderEdm } from './purchaseorderedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PurchaseOrderEdmEntityConfig = {
  name: 'PurchaseOrderEdm',
  keys: [{name: 'purchaseOrderGuid'}],
  fields: {
    purchaseOrderGuid: {type: 'Edm.Guid', nullable: false},
    contractGuid: {type: 'Edm.Guid'},
    number: {type: 'Edm.String'},
    value: {type: 'Edm.Decimal', scale: 'variable'},
    isDefault: {type: 'Edm.Boolean'},
    createdBy: {type: 'Edm.Guid'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    deleted: {type: 'Edm.DateTimeOffset'},
    contract: {type: 'OptiaApi.DataDomain.EFEntities.ContractEdm', navigation: true, referentials: [{property: 'contractGuid', referencedProperty: 'contractGuid'}]},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<PurchaseOrderEdm>;
//#endregion