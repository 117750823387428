//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerAddressEdm } from './workeraddressedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WorkerAddressEdmEntityConfig = {
  name: 'WorkerAddressEdm',
  keys: [{name: 'addressGuid'},{name: 'fromDate'},{name: 'workerGuid'}],
  fields: {
    workerGuid: {type: 'Edm.Guid'},
    addressGuid: {type: 'Edm.Guid'},
    addressType: {type: 'Edm.Int16', nullable: false},
    manuallyEntered: {type: 'Edm.Boolean'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    workerAddressGuid: {type: 'Edm.Guid'},
    address: {type: 'OptiaApi.DataDomain.EFEntities.AddressEdm', navigation: true, referentials: [{property: 'addressGuid', referencedProperty: 'addressGuid'}]},
    worker: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerEdm', navigation: true, referentials: [{property: 'workerGuid', referencedProperty: 'workerGuid'}]}
  }
} as StructuredTypeConfig<WorkerAddressEdm>;
//#endregion