//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfInstancesService } from './wfinstances.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WfInstancesServiceEntitySetConfig = {
  name: 'WfInstances',
  entityType: 'OptiaApi.DataDomain.EFEntities.Workflow.WfInstanceEdm',
  service: WfInstancesService
} as EntitySetConfig;
//#endregion