//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskTemplatesService } from './wftasktemplates.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WfTaskTemplatesServiceEntitySetConfig = {
  name: 'WfTaskTemplates',
  entityType: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskTemplateEdm',
  service: WfTaskTemplatesService
} as EntitySetConfig;
//#endregion