import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskContactTypeEdm } from './DataDomain/EFEntities/wftaskcontacttypeedm.entity';
import { WfTaskEdm } from './DataDomain/EFEntities/Workflow/wftaskedm.entity';
//#endregion

@Injectable()
export class WfTaskContactTypesService extends ODataEntitySetService<WfTaskContactTypeEdm> {
  constructor(client: ODataClient) {
    super(client, 'WfTaskContactTypes', 'OptiaApi.DataDomain.EFEntities.WfTaskContactTypeEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public wfTask(key: EntityKey<WfTaskContactTypeEdm>): ODataNavigationPropertyResource<WfTaskEdm> { 
    return this.entity(key).navigationProperty<WfTaskEdm>('wfTask'); 
  }
  public fetchWfTask(key: EntityKey<WfTaskContactTypeEdm>, options?: ODataQueryArgumentsOptions<WfTaskEdm>) {
    return this.fetchNavigationProperty<WfTaskEdm>(
      this.wfTask(key), 
      'entity', options) as Observable<ODataEntity<WfTaskEdm>>;
  }
  public setWfTaskEdmAsWfTask(key: EntityKey<WfTaskContactTypeEdm>, target: ODataEntityResource<ODataEntity<WfTaskEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfTask(key).reference()
      .set(target, {etag});
  }
  public unsetWfTaskEdmAsWfTask(key: EntityKey<WfTaskContactTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WfTaskEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfTask(key).reference()
      .unset({etag});
  }
  //#endregion
}
