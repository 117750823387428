//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfActionOutstandingEdmService } from './wfactionoutstandingedm.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WfActionOutstandingEdmServiceEntitySetConfig = {
  name: 'WfActionOutstandingEdm',
  entityType: 'OptiaApi.DataDomain.EFEntities.Workflow.WfActionOutstandingEdm',
  service: WfActionOutstandingEdmService
} as EntitySetConfig;
//#endregion