//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfInstanceEdm } from './wfinstanceedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfInstanceEdmEntityConfig = {
  name: 'WfInstanceEdm',
  keys: [{name: 'wfInstanceGuid'}],
  fields: {
    wfInstanceGuid: {type: 'Edm.Guid', nullable: false},
    wfProcessGuid: {type: 'Edm.Guid'},
    ownerGuid: {type: 'Edm.Guid'},
    requested: {type: 'Edm.DateTimeOffset'},
    tag: {type: 'Edm.String'},
    wfProcess: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfProcessEdm', navigation: true, referentials: [{property: 'wfProcessGuid', referencedProperty: 'wfProcessGuid'}]},
    wfActions: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfActionEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<WfInstanceEdm>;
//#endregion