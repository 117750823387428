import { AgencyContactType } from "../lookups/enums";


export interface Tile {
  background: string;
  color: string;
  cols: number;
  rows: number;
  text: string;
}

export enum PageStatesEnum {
  Customers = 'Customers',
  CustomersAgency = 'CustomersAgency',
  Agencies = 'Agencies',
  Suppliers = 'Suppliers',
  Workers = 'Workers',
  OptiaNews = 'Optia-news',
  UserGroups = 'User-groups',
  UserList = 'User-list',
  Prospect = 'Prospect',
  ReportGroups = "Report-groups",
  ReportTypes = "Report-types",
  ViewWeeklyReports = "View-weekly-reports",
  ViewMonthlyReports = "View-monthly-reports",
  ViewInputValidationReports = "View-input-validation-reports",
  ReportBatches = "Report-batches",
  UploadedDocuments = "UploadedDocuments",
  FormsTemplates = "FormsTemplates",
  SystemList = "SystemList",
  DataMismatch = "DataMismach",
}
