//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContractPersonsService } from './contractpersons.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ContractPersonsServiceEntitySetConfig = {
  name: 'ContractPersons',
  entityType: 'OptiaApi.DataDomain.EFEntities.ContractPersonEdm',
  service: ContractPersonsService
} as EntitySetConfig;
//#endregion