//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { HistoricalReferenceEdm } from './historicalreferenceedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const HistoricalReferenceEdmEntityConfig = {
  name: 'HistoricalReferenceEdm',
  keys: [{name: 'historicalReferenceGuid'}],
  fields: {
    historicalReferenceGuid: {type: 'Edm.Guid', nullable: false},
    historicalReference: {type: 'Edm.String', nullable: false},
    referenceType: {type: 'Edm.Int16'}
  }
} as StructuredTypeConfig<HistoricalReferenceEdm>;
//#endregion