//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AddressEdm } from './addressedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AddressEdmEntityConfig = {
  name: 'AddressEdm',
  keys: [{name: 'addressGuid'}],
  fields: {
    addressGuid: {type: 'Edm.Guid', nullable: false},
    line1: {type: 'Edm.String'},
    line2: {type: 'Edm.String'},
    city: {type: 'Edm.String'},
    county: {type: 'Edm.String'},
    countryCode: {type: 'Edm.String'},
    country: {type: 'Edm.String'},
    postcode: {type: 'Edm.String'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    agencyAddresses: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyAddressEdm', collection: true, navigation: true},
    companiesHouseDetailsAddresses: {type: 'OptiaApi.DataDomain.EFEntities.CompaniesHouseDetailsAddressEdm', collection: true, navigation: true},
    customerAddresses: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAddressEdm', collection: true, navigation: true},
    personAddresses: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonAddressEdm', collection: true, navigation: true},
    supplierAddresses: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierAddressEdm', collection: true, navigation: true},
    workerAddresses: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerAddressEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<AddressEdm>;
//#endregion