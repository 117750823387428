//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { LoggedInUserDTO } from './loggedinuserdto.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const LoggedInUserDTOEntityConfig = {
  name: 'LoggedInUserDTO',
  keys: [{name: 'personGuid'}],
  fields: {
    personGuid: {type: 'Edm.Guid', nullable: false},
    identityGuid: {type: 'Edm.Guid'},
    firstName: {type: 'Edm.String'},
    surname: {type: 'Edm.String'},
    emailAddress: {type: 'Edm.String'},
    active: {type: 'Edm.Boolean'},
    userType: {type: 'Edm.Int16', nullable: false},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid', nullable: false},
    deleted: {type: 'Edm.DateTimeOffset'},
    userPermissions: {type: 'OptiaApi.ServiceLayer.DTOs.PermissionDTO', collection: true, navigation: true}
  }
} as StructuredTypeConfig<LoggedInUserDTO>;
//#endregion