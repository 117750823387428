//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DataMismatchesService } from './datamismatches.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const DataMismatchesServiceEntitySetConfig = {
  name: 'DataMismatches',
  entityType: 'OptiaApi.DataDomain.EFEntities.Admin.DataMismatchEdm',
  service: DataMismatchesService
} as EntitySetConfig;
//#endregion