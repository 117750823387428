//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonAddressesService } from './personaddresses.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PersonAddressesServiceEntitySetConfig = {
  name: 'PersonAddresses',
  entityType: 'OptiaApi.DataDomain.EFEntities.Person.PersonAddressEdm',
  service: PersonAddressesService
} as EntitySetConfig;
//#endregion