import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyReportingCategoryEdm } from './DataDomain/EFEntities/Agency/agencyreportingcategoryedm.entity';
import { ReportingCategoryEdm } from './DataDomain/EFEntities/Reporting/reportingcategoryedm.entity';
//#endregion

@Injectable()
export class ReportingCategoriesService extends ODataEntitySetService<ReportingCategoryEdm> {
  constructor(client: ODataClient) {
    super(client, 'ReportingCategories', 'OptiaApi.DataDomain.EFEntities.Reporting.ReportingCategoryEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public agencyReportingCategories(key: EntityKey<ReportingCategoryEdm>): ODataNavigationPropertyResource<AgencyReportingCategoryEdm> { 
    return this.entity(key).navigationProperty<AgencyReportingCategoryEdm>('agencyReportingCategories'); 
  }
  public fetchAgencyReportingCategories(key: EntityKey<ReportingCategoryEdm>, options?: ODataQueryArgumentsOptions<AgencyReportingCategoryEdm>) {
    return this.fetchNavigationProperty<AgencyReportingCategoryEdm>(
      this.agencyReportingCategories(key), 
      'entities', options) as Observable<ODataEntities<AgencyReportingCategoryEdm>>;
  }
  public addAgencyReportingCategoryEdmToAgencyReportingCategories(key: EntityKey<ReportingCategoryEdm>, target: ODataEntityResource<ODataEntities<AgencyReportingCategoryEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyReportingCategories(key).reference()
      .add(target);
  }
  public removeAgencyReportingCategoryEdmFromAgencyReportingCategories(key: EntityKey<ReportingCategoryEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyReportingCategoryEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyReportingCategories(key).reference()
      .remove(target);
  }
  //#endregion
}
