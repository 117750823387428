//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerTypePersonsService } from './workertypepersons.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WorkerTypePersonsServiceEntitySetConfig = {
  name: 'WorkerTypePersons',
  entityType: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypePersonEdm',
  service: WorkerTypePersonsService
} as EntitySetConfig;
//#endregion