//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskTemplateEdm } from './wftasktemplateedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfTaskTemplateEdmEntityConfig = {
  name: 'WfTaskTemplateEdm',
  keys: [{name: 'wfTaskGuid'},{name: 'wfTemplateGuid'}],
  fields: {
    wfTaskGuid: {type: 'Edm.Guid', nullable: false},
    wfTemplateGuid: {type: 'Edm.Guid', nullable: false},
    templateType: {type: 'Edm.Int32'},
    wfTask: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskEdm', navigation: true},
    wfTemplate: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTemplateEdm', navigation: true}
  }
} as StructuredTypeConfig<WfTaskTemplateEdm>;
//#endregion