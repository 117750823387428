import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssignmentEdm } from './DataDomain/EFEntities/assignmentedm.entity';
import { AgencyWorkerEdm } from './DataDomain/EFEntities/Agency/agencyworkeredm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
import { WorkerEdm } from './DataDomain/EFEntities/Worker/workeredm.entity';
import { WorkerAddressEdm } from './DataDomain/EFEntities/Worker/workeraddressedm.entity';
import { WorkerBankDetailEdm } from './DataDomain/EFEntities/Worker/workerbankdetailedm.entity';
import { WorkerDocumentEdm } from './DataDomain/EFEntities/Worker/workerdocumentedm.entity';
import { WorkerPersonEdm } from './DataDomain/EFEntities/Worker/workerpersonedm.entity';
import { WorkerPhoneNumberEdm } from './DataDomain/EFEntities/Worker/workerphonenumberedm.entity';
import { WorkerTypeEdm } from './DataDomain/EFEntities/Worker/workertypeedm.entity';
//#endregion

@Injectable()
export class WorkersService extends ODataEntitySetService<WorkerEdm> {
  constructor(client: ODataClient) {
    super(client, 'Workers', 'OptiaApi.DataDomain.EFEntities.Worker.WorkerEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public agencyWorkers(key: EntityKey<WorkerEdm>): ODataNavigationPropertyResource<AgencyWorkerEdm> { 
    return this.entity(key).navigationProperty<AgencyWorkerEdm>('agencyWorkers'); 
  }
  public fetchAgencyWorkers(key: EntityKey<WorkerEdm>, options?: ODataQueryArgumentsOptions<AgencyWorkerEdm>) {
    return this.fetchNavigationProperty<AgencyWorkerEdm>(
      this.agencyWorkers(key), 
      'entities', options) as Observable<ODataEntities<AgencyWorkerEdm>>;
  }
  public addAgencyWorkerEdmToAgencyWorkers(key: EntityKey<WorkerEdm>, target: ODataEntityResource<ODataEntities<AgencyWorkerEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyWorkers(key).reference()
      .add(target);
  }
  public removeAgencyWorkerEdmFromAgencyWorkers(key: EntityKey<WorkerEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyWorkerEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyWorkers(key).reference()
      .remove(target);
  }
  public assignments(key: EntityKey<WorkerEdm>): ODataNavigationPropertyResource<AssignmentEdm> { 
    return this.entity(key).navigationProperty<AssignmentEdm>('assignments'); 
  }
  public fetchAssignments(key: EntityKey<WorkerEdm>, options?: ODataQueryArgumentsOptions<AssignmentEdm>) {
    return this.fetchNavigationProperty<AssignmentEdm>(
      this.assignments(key), 
      'entities', options) as Observable<ODataEntities<AssignmentEdm>>;
  }
  public addAssignmentEdmToAssignments(key: EntityKey<WorkerEdm>, target: ODataEntityResource<ODataEntities<AssignmentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.assignments(key).reference()
      .add(target);
  }
  public removeAssignmentEdmFromAssignments(key: EntityKey<WorkerEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AssignmentEdm>>, etag?: string} = {}): Observable<any> {
    return this.assignments(key).reference()
      .remove(target);
  }
  public createdByPerson(key: EntityKey<WorkerEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<WorkerEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<WorkerEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<WorkerEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public workerAddresses(key: EntityKey<WorkerEdm>): ODataNavigationPropertyResource<WorkerAddressEdm> { 
    return this.entity(key).navigationProperty<WorkerAddressEdm>('workerAddresses'); 
  }
  public fetchWorkerAddresses(key: EntityKey<WorkerEdm>, options?: ODataQueryArgumentsOptions<WorkerAddressEdm>) {
    return this.fetchNavigationProperty<WorkerAddressEdm>(
      this.workerAddresses(key), 
      'entities', options) as Observable<ODataEntities<WorkerAddressEdm>>;
  }
  public addWorkerAddressEdmToWorkerAddresses(key: EntityKey<WorkerEdm>, target: ODataEntityResource<ODataEntities<WorkerAddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerAddresses(key).reference()
      .add(target);
  }
  public removeWorkerAddressEdmFromWorkerAddresses(key: EntityKey<WorkerEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerAddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerAddresses(key).reference()
      .remove(target);
  }
  public workerBankDetails(key: EntityKey<WorkerEdm>): ODataNavigationPropertyResource<WorkerBankDetailEdm> { 
    return this.entity(key).navigationProperty<WorkerBankDetailEdm>('workerBankDetails'); 
  }
  public fetchWorkerBankDetails(key: EntityKey<WorkerEdm>, options?: ODataQueryArgumentsOptions<WorkerBankDetailEdm>) {
    return this.fetchNavigationProperty<WorkerBankDetailEdm>(
      this.workerBankDetails(key), 
      'entities', options) as Observable<ODataEntities<WorkerBankDetailEdm>>;
  }
  public addWorkerBankDetailEdmToWorkerBankDetails(key: EntityKey<WorkerEdm>, target: ODataEntityResource<ODataEntities<WorkerBankDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerBankDetails(key).reference()
      .add(target);
  }
  public removeWorkerBankDetailEdmFromWorkerBankDetails(key: EntityKey<WorkerEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerBankDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerBankDetails(key).reference()
      .remove(target);
  }
  public workerDocuments(key: EntityKey<WorkerEdm>): ODataNavigationPropertyResource<WorkerDocumentEdm> { 
    return this.entity(key).navigationProperty<WorkerDocumentEdm>('workerDocuments'); 
  }
  public fetchWorkerDocuments(key: EntityKey<WorkerEdm>, options?: ODataQueryArgumentsOptions<WorkerDocumentEdm>) {
    return this.fetchNavigationProperty<WorkerDocumentEdm>(
      this.workerDocuments(key), 
      'entities', options) as Observable<ODataEntities<WorkerDocumentEdm>>;
  }
  public addWorkerDocumentEdmToWorkerDocuments(key: EntityKey<WorkerEdm>, target: ODataEntityResource<ODataEntities<WorkerDocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerDocuments(key).reference()
      .add(target);
  }
  public removeWorkerDocumentEdmFromWorkerDocuments(key: EntityKey<WorkerEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerDocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerDocuments(key).reference()
      .remove(target);
  }
  public workerPersons(key: EntityKey<WorkerEdm>): ODataNavigationPropertyResource<WorkerPersonEdm> { 
    return this.entity(key).navigationProperty<WorkerPersonEdm>('workerPersons'); 
  }
  public fetchWorkerPersons(key: EntityKey<WorkerEdm>, options?: ODataQueryArgumentsOptions<WorkerPersonEdm>) {
    return this.fetchNavigationProperty<WorkerPersonEdm>(
      this.workerPersons(key), 
      'entities', options) as Observable<ODataEntities<WorkerPersonEdm>>;
  }
  public addWorkerPersonEdmToWorkerPersons(key: EntityKey<WorkerEdm>, target: ODataEntityResource<ODataEntities<WorkerPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerPersons(key).reference()
      .add(target);
  }
  public removeWorkerPersonEdmFromWorkerPersons(key: EntityKey<WorkerEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerPersons(key).reference()
      .remove(target);
  }
  public workerPhoneNumbers(key: EntityKey<WorkerEdm>): ODataNavigationPropertyResource<WorkerPhoneNumberEdm> { 
    return this.entity(key).navigationProperty<WorkerPhoneNumberEdm>('workerPhoneNumbers'); 
  }
  public fetchWorkerPhoneNumbers(key: EntityKey<WorkerEdm>, options?: ODataQueryArgumentsOptions<WorkerPhoneNumberEdm>) {
    return this.fetchNavigationProperty<WorkerPhoneNumberEdm>(
      this.workerPhoneNumbers(key), 
      'entities', options) as Observable<ODataEntities<WorkerPhoneNumberEdm>>;
  }
  public addWorkerPhoneNumberEdmToWorkerPhoneNumbers(key: EntityKey<WorkerEdm>, target: ODataEntityResource<ODataEntities<WorkerPhoneNumberEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerPhoneNumbers(key).reference()
      .add(target);
  }
  public removeWorkerPhoneNumberEdmFromWorkerPhoneNumbers(key: EntityKey<WorkerEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerPhoneNumberEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerPhoneNumbers(key).reference()
      .remove(target);
  }
  public workerTypes(key: EntityKey<WorkerEdm>): ODataNavigationPropertyResource<WorkerTypeEdm> { 
    return this.entity(key).navigationProperty<WorkerTypeEdm>('workerTypes'); 
  }
  public fetchWorkerTypes(key: EntityKey<WorkerEdm>, options?: ODataQueryArgumentsOptions<WorkerTypeEdm>) {
    return this.fetchNavigationProperty<WorkerTypeEdm>(
      this.workerTypes(key), 
      'entities', options) as Observable<ODataEntities<WorkerTypeEdm>>;
  }
  public addWorkerTypeEdmToWorkerTypes(key: EntityKey<WorkerEdm>, target: ODataEntityResource<ODataEntities<WorkerTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerTypes(key).reference()
      .add(target);
  }
  public removeWorkerTypeEdmFromWorkerTypes(key: EntityKey<WorkerEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerTypes(key).reference()
      .remove(target);
  }
  //#endregion
}
