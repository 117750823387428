//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MiReportGroupPersonsService } from './mireportgrouppersons.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const MiReportGroupPersonsServiceEntitySetConfig = {
  name: 'MiReportGroupPersons',
  entityType: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportGroupPersonEdm',
  service: MiReportGroupPersonsService
} as EntitySetConfig;
//#endregion