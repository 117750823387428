import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfActionEdm } from './DataDomain/EFEntities/Workflow/wfactionedm.entity';
import { WfInstanceEdm } from './DataDomain/EFEntities/Workflow/wfinstanceedm.entity';
import { WfProcessEdm } from './DataDomain/EFEntities/Workflow/wfprocessedm.entity';
//#endregion

@Injectable()
export class WfInstancesService extends ODataEntitySetService<WfInstanceEdm> {
  constructor(client: ODataClient) {
    super(client, 'WfInstances', 'OptiaApi.DataDomain.EFEntities.Workflow.WfInstanceEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public wfActions(key: EntityKey<WfInstanceEdm>): ODataNavigationPropertyResource<WfActionEdm> { 
    return this.entity(key).navigationProperty<WfActionEdm>('wfActions'); 
  }
  public fetchWfActions(key: EntityKey<WfInstanceEdm>, options?: ODataQueryArgumentsOptions<WfActionEdm>) {
    return this.fetchNavigationProperty<WfActionEdm>(
      this.wfActions(key), 
      'entities', options) as Observable<ODataEntities<WfActionEdm>>;
  }
  public addWfActionEdmToWfActions(key: EntityKey<WfInstanceEdm>, target: ODataEntityResource<ODataEntities<WfActionEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfActions(key).reference()
      .add(target);
  }
  public removeWfActionEdmFromWfActions(key: EntityKey<WfInstanceEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfActionEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfActions(key).reference()
      .remove(target);
  }
  public wfProcess(key: EntityKey<WfInstanceEdm>): ODataNavigationPropertyResource<WfProcessEdm> { 
    return this.entity(key).navigationProperty<WfProcessEdm>('wfProcess'); 
  }
  public fetchWfProcess(key: EntityKey<WfInstanceEdm>, options?: ODataQueryArgumentsOptions<WfProcessEdm>) {
    return this.fetchNavigationProperty<WfProcessEdm>(
      this.wfProcess(key), 
      'entity', options) as Observable<ODataEntity<WfProcessEdm>>;
  }
  public setWfProcessEdmAsWfProcess(key: EntityKey<WfInstanceEdm>, target: ODataEntityResource<ODataEntity<WfProcessEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfProcess(key).reference()
      .set(target, {etag});
  }
  public unsetWfProcessEdmAsWfProcess(key: EntityKey<WfInstanceEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WfProcessEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfProcess(key).reference()
      .unset({etag});
  }
  //#endregion
}
