//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ChangeSetEdm } from './changesetedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ChangeSetEdmEntityConfig = {
  name: 'ChangeSetEdm',
  keys: [{name: 'changeSetGuid'}],
  fields: {
    changeSetGuid: {type: 'Edm.Guid', nullable: false},
    changeSetGroupGuid: {type: 'Edm.Guid'},
    status: {type: 'Edm.Int16', nullable: false},
    personGuid: {type: 'Edm.Guid'},
    requestDate: {type: 'Edm.DateTimeOffset'},
    changeType: {type: 'Edm.Int16'},
    actionDate: {type: 'Edm.DateTimeOffset'},
    actionedBy: {type: 'Edm.Guid'},
    entity: {type: 'Edm.Int16', nullable: false},
    entityKey: {type: 'Edm.Guid'},
    entityLink: {type: 'Edm.String'},
    agencyGuid: {type: 'Edm.Guid'},
    actionedByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'actionedBy', referencedProperty: 'personGuid'}]},
    person: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]},
    agency: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm', navigation: true, referentials: [{property: 'agencyGuid', referencedProperty: 'agencyGuid'}]},
    changeSetGroup: {type: 'OptiaApi.DataDomain.EFEntities.Admin.ChangeSetGroupEdm', navigation: true, referentials: [{property: 'changeSetGroupGuid', referencedProperty: 'changeSetGroupGuid'}]},
    changeSetLines: {type: 'OptiaApi.DataDomain.EFEntities.Admin.ChangeSetLineEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<ChangeSetEdm>;
//#endregion