//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CreditAssignmentEdmEntityConfig } from './creditassignmentedm.entity.config';
import { CreditCheckEdmEntityConfig } from './creditcheckedm.entity.config';
import { CreditLimitEdmEntityConfig } from './creditlimitedm.entity.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const CreditSchema = {
  namespace: 'OptiaApi.DataDomain.EFEntities.Credit',
  enums: [],
  entities: [CreditAssignmentEdmEntityConfig,
    CreditCheckEdmEntityConfig,
    CreditLimitEdmEntityConfig],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion