//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfSchedulesService } from './wfschedules.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WfSchedulesServiceEntitySetConfig = {
  name: 'WfSchedules',
  entityType: 'OptiaApi.DataDomain.EFEntities.Workflow.WfScheduleEdm',
  service: WfSchedulesService
} as EntitySetConfig;
//#endregion