//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerAgencyPersonsService } from './customeragencypersons.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CustomerAgencyPersonsServiceEntitySetConfig = {
  name: 'CustomerAgencyPersons',
  entityType: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyPersonEdm',
  service: CustomerAgencyPersonsService
} as EntitySetConfig;
//#endregion