//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskWfSchedulesService } from './wftaskwfschedules.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WfTaskWfSchedulesServiceEntitySetConfig = {
  name: 'WfTaskWfSchedules',
  entityType: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskWfScheduleEdm',
  service: WfTaskWfSchedulesService
} as EntitySetConfig;
//#endregion