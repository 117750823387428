export enum UserTypes {
  BackOffice,
  Agency,
  Customer,
  Worker,
  Supplier
}

export enum VerifyNotFound {
  NotFound="Not Found"
}
