//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OptiaNewsPersonsService } from './optianewspersons.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const OptiaNewsPersonsServiceEntitySetConfig = {
  name: 'OptiaNewsPersons',
  entityType: 'OptiaApi.DataDomain.EFEntities.Notify.OptiaNewsPersonEdm',
  service: OptiaNewsPersonsService
} as EntitySetConfig;
//#endregion