import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PhoneNumberEdm } from './DataDomain/EFEntities/phonenumberedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
import { PersonPhoneNumberEdm } from './DataDomain/EFEntities/Person/personphonenumberedm.entity';
//#endregion

@Injectable()
export class PersonPhoneNumbersService extends ODataEntitySetService<PersonPhoneNumberEdm> {
  constructor(client: ODataClient) {
    super(client, 'PersonPhoneNumbers', 'OptiaApi.DataDomain.EFEntities.Person.PersonPhoneNumberEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public person(key: EntityKey<PersonPhoneNumberEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<PersonPhoneNumberEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<PersonPhoneNumberEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<PersonPhoneNumberEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  public phoneNumber(key: EntityKey<PersonPhoneNumberEdm>): ODataNavigationPropertyResource<PhoneNumberEdm> { 
    return this.entity(key).navigationProperty<PhoneNumberEdm>('phoneNumber'); 
  }
  public fetchPhoneNumber(key: EntityKey<PersonPhoneNumberEdm>, options?: ODataQueryArgumentsOptions<PhoneNumberEdm>) {
    return this.fetchNavigationProperty<PhoneNumberEdm>(
      this.phoneNumber(key), 
      'entity', options) as Observable<ODataEntity<PhoneNumberEdm>>;
  }
  public setPhoneNumberEdmAsPhoneNumber(key: EntityKey<PersonPhoneNumberEdm>, target: ODataEntityResource<ODataEntity<PhoneNumberEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.phoneNumber(key).reference()
      .set(target, {etag});
  }
  public unsetPhoneNumberEdmAsPhoneNumber(key: EntityKey<PersonPhoneNumberEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PhoneNumberEdm>>, etag?: string} = {}): Observable<any> {
    return this.phoneNumber(key).reference()
      .unset({etag});
  }
  //#endregion
}
