//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyReportingCombinationEdm } from './agencyreportingcombinationedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AgencyReportingCombinationEdmEntityConfig = {
  name: 'AgencyReportingCombinationEdm',
  keys: [{name: 'reportingCombinationGuid'}],
  fields: {
    reportingCombinationGuid: {type: 'Edm.Guid', nullable: false},
    agencyGuid: {type: 'Edm.Guid'},
    tradingStylesGuid: {type: 'Edm.Guid'},
    parentGuid: {type: 'Edm.Guid'},
    childGuid: {type: 'Edm.Guid'},
    childGuidReportingLabel: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.ReportingLabelEdm', navigation: true, referentials: [{property: 'childGuid', referencedProperty: 'reportingLabelGuid'}]},
    parentGuidReportingLabel: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.ReportingLabelEdm', navigation: true, referentials: [{property: 'parentGuid', referencedProperty: 'reportingLabelGuid'}]},
    agency: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm', navigation: true, referentials: [{property: 'agencyGuid', referencedProperty: 'agencyGuid'}]},
    tradingStyle: {type: 'OptiaApi.DataDomain.EFEntities.Agency.TradingStyleEdm', navigation: true, referentials: [{property: 'tradingStylesGuid', referencedProperty: 'tradingStylesGuid'}]}
  }
} as StructuredTypeConfig<AgencyReportingCombinationEdm>;
//#endregion