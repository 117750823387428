//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OptiaNewsService } from './optianews.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const OptiaNewsServiceEntitySetConfig = {
  name: 'OptiaNews',
  entityType: 'OptiaApi.DataDomain.EFEntities.Notify.OptiaNewEdm',
  service: OptiaNewsService
} as EntitySetConfig;
//#endregion