<mat-toolbar class="root-header-toolbar" *ngIf="userIsAllowedToSee">
  <button type="button" mat-icon-button (click)="toggleSidenav()" *ngIf="userIsLoggedIn">
    <mat-icon class="sidenav_toggle">menu</mat-icon>
  </button>
  <div class="header-split-line" *ngIf="userIsLoggedIn"></div>
  <mat-toolbar>
    <a class="navbar-brand" [routerLink]="['/']" matTooltip="Dashboard">
      <img src="assets/optia-logo-red.svg" width="98" height="40" alt="Optia Logo" title="Optia" />
    </a>

    <div class="header-breadcrump-section">
      <xng-breadcrumb [separator]="iconTemplate">
        <ng-container *xngBreadcrumbItem="let breadcrumb; let count = count;">
          <ng-container *ngIf="count > 1">{{ breadcrumb }}</ng-container>
        </ng-container>
      </xng-breadcrumb>
      <ng-template #iconTemplate>
        <mat-icon>chevron_right</mat-icon>
      </ng-template>
    </div>
  </mat-toolbar>

  <div class="header-split-line"></div>
  <!-- <div class="header-notifications-profile-actions-section" *ngIf="userIsLoggedIn">
    <button mat-button (click)="showNotifications()">
      <mat-icon matBadge="{{ notifications.length }}" matBadgeColor="warn" matBadgeSize="small"
        class="header-navigation-icon">notifications</mat-icon>
    </button>
  </div> -->
  <div class="header-username-profile-section">
    <button id="userMenuButton" mat-button [matMenuTriggerFor]="userMenu"
      class="optia-reverse-icon-button account-container" (click)="userMenuExpanded = true" iconPositionEnd="true">
      <p *ngIf="userIsLoggedIn" id="userFullNameConatiner" class="username-container">
        {{ userFullName }}
      </p>
      <mat-icon *ngIf="!userMenuExpanded">expand_more</mat-icon>
      <mat-icon *ngIf="userMenuExpanded">expand_less</mat-icon>
    </button>

    <mat-menu #userMenu="matMenu" (close)="userMenuExpanded = false" class="header-username-menu">
      <button id="userMenuImpersonateUser" mat-menu-item *ngIf="canImpersonate" (click)="impersonateUser()">
        Impersonate User
      </button>
      <button id="userMenuChangePassword" mat-menu-item *ngIf="userIsLoggedIn" (click)="changePassword()">
        Change password
      </button>
      <button id="userMenuLogin" mat-menu-item *ngIf="!userIsLoggedIn" (click)="login()">
        Login
      </button>
      <button id="userMenuLogout" mat-menu-item color="accent" *ngIf="userIsLoggedIn" (click)="logout()">
        Logout
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
<mat-sidenav-container class="sidenav-container" *ngIf="userIsAllowedToSee">
  <!-- NAV CONTENT -->
  <mat-sidenav #leftsidenav class="sidenav" #sidenav mode="side" opened name="sidenav" id="sidenav"
    *ngIf="userIsLoggedIn">

    <!-- GLOBAL NAV ITEMS -->
    <mat-nav-list class="nav-items-container">
      <a id="navMenuDashboard" routerLink="/dashboard" routerLinkActive="is-active" class="nav-item sidebar-space-top">
        <!-- <mat-icon>dashboard</mat-icon> -->
        <mat-icon matTooltip="Dashboard">home</mat-icon>
        <mat-label *ngIf="leftsidenav.opened">Dashboard</mat-label>
      </a>

      <hr class="sidenav-separator" />
      <a *ngIf="canReadAgencies" id="navMenuAgencies" [routerLink]="agencyLinkURL" routerLinkActive="is-active"
         class="nav-item" (click)="showProspects = !showProspects" disabled>

        <mat-icon matTooltip="Expand Agency" *ngIf="!showProspects && canReadProspects && leftsidenav.opened">chevron_right</mat-icon>
        <mat-icon matTooltip="Collapse Agency" *ngIf="showProspects && canReadProspects && leftsidenav.opened">expand_more</mat-icon>

        <mat-icon [matTooltip]="agencyLinkText">contacts</mat-icon>
        <mat-label *ngIf="leftsidenav.opened">{{ agencyLinkText }}</mat-label>
      </a>

      <div class="sub-menu-items-container" *ngIf="showProspects">
        <a *ngIf="canReadProspects" routerLink="agencies/prospects" routerLinkActive="is-active"
           class="nav-item sub-menu-item" disabled>
          <mat-icon matTooltip="Prospects">folder_shared</mat-icon>
          <mat-label *ngIf="leftsidenav.opened">Prospects</mat-label>
        </a>
      </div>

      <a *ngIf="canReadCustomers" id="navMenuCustomers" [routerLink]="customerLinkURL" routerLinkActive="is-active" class="nav-item" disabled>
        <mat-icon matTooltip="Customers">people</mat-icon>
        <mat-label *ngIf="leftsidenav.opened">{{ customerLinkText }}</mat-label>
      </a>



      <a *ngIf="canViewWorkers" id="navMenuWorkers" routerLink="/workers" routerLinkActive="is-active" class="nav-item"
         (click)="showSuppliers = !showSuppliers" disabled>

        <mat-icon matTooltip="Expand Workers" *ngIf="!showSuppliers && canReadSuppliers && leftsidenav.opened">chevron_right</mat-icon>
        <mat-icon matTooltip="Collapse Workers" *ngIf="showSuppliers && canReadSuppliers && leftsidenav.opened">expand_more</mat-icon>
        <mat-icon matTooltip="Workers">business_center</mat-icon>
        <mat-label *ngIf="leftsidenav.opened">Workers</mat-label>
      </a>

      <div class="sub-menu-items-container" *ngIf="showSuppliers">
        <a *ngIf="canReadSuppliers" id="navMenuSuppliers" routerLink="/suppliers" routerLinkActive="is-active"
           class="nav-item sub-menu-item" disabled>
          <mat-icon matTooltip="Suppliers">business</mat-icon>
          <mat-label *ngIf="leftsidenav.opened">Suppliers</mat-label>
        </a>
      </div>

      <hr class="sidenav-separator" *ngIf="canManageReportGroups || canManageReportTypes" />
      <a *ngIf="leftsidenav.opened && (canSeeMIMenu)" (click)="showMIMenu = !showMIMenu" class="nav-item" disabled>

        <mat-icon matTooltip="Expand MI" *ngIf="!showMIMenu && canManageReportGroups">chevron_right</mat-icon>
        <mat-icon matTooltip="Collapse MI" *ngIf="showMIMenu && canManageReportGroups">expand_more</mat-icon>

        <mat-icon matTooltip="Management Information">insert_chart</mat-icon>
        <mat-label *ngIf="leftsidenav.opened">MI</mat-label>
      </a>

      <div class="sub-menu-items-container" *ngIf="showMIMenu">
        <a *ngIf="canManageReportGroups && leftsidenav.opened" routerLink="/management-information/report-groups"
           routerLinkActive="is-active" class="nav-item sub-menu-item">
          <mat-icon matTooltip="Report Groups">folder_shared</mat-icon>
          <mat-label *ngIf="leftsidenav.opened">Report Groups</mat-label>
        </a>

        <a *ngIf="canManageReportTypes && leftsidenav.opened" routerLink="/management-information/report-types"
           routerLinkActive="is-active" class="nav-item sub-menu-item">
          <mat-icon matTooltip="Report Types">library_books</mat-icon>
          <mat-label *ngIf="leftsidenav.opened">Report Types</mat-label>
        </a>

        <a *ngIf="canUploadReports && leftsidenav.opened" routerLink="/management-information/upload-reports"
           routerLinkActive="is-active" class="nav-item sub-menu-item">
          <mat-icon matTooltip="Upload Reports">note_add</mat-icon>
          <mat-label *ngIf="leftsidenav.opened">Upload Reports</mat-label>
        </a>

        <a *ngIf="canViewWeeklyReports && leftsidenav.opened" routerLink="/management-information/view-weekly-reports"
           routerLinkActive="is-active" class="nav-item sub-menu-item">
          <mat-icon matTooltip="Weekly Reports">notes</mat-icon>
          <mat-label *ngIf="leftsidenav.opened">Weekly Reports</mat-label>
        </a>

        <a *ngIf="canViewMonthlyReports && leftsidenav.opened" routerLink="/management-information/view-monthly-reports"
           routerLinkActive="is-active" class="nav-item sub-menu-item">
          <mat-icon matTooltip="Monthly Reports">subject</mat-icon>
          <mat-label *ngIf="leftsidenav.opened">Monthly Reports</mat-label>
        </a>

        <a *ngIf="canViewInputValidationReports && leftsidenav.opened"
           routerLink="/management-information/view-input-validation-reports" routerLinkActive="is-active"
           class="nav-item sub-menu-item">
          <mat-icon matTooltip="Input Validation Reports">text_snippet</mat-icon>
          <mat-label matTooltip="Input Validation Reports" *ngIf="leftsidenav.opened">Validation Reports</mat-label>
        </a>

        <a *ngIf="canSeeReportBatches && leftsidenav.opened" routerLink="/management-information/report-batches"
           routerLinkActive="is-active" class="nav-item sub-menu-item">
          <mat-icon matTooltip="Report Batches">history</mat-icon>
          <mat-label *ngIf="leftsidenav.opened">Report Batches</mat-label>
        </a>

      </div>

      <!-- MENU MANAGEMENT INFORMATION LINKS WHEN SIDEBAR COLLAPSED start-->
      <a *ngIf="canManageReportGroups && !leftsidenav.opened" routerLink="/management-information/report-groups"
         routerLinkActive="is-active" class="nav-item sub-menu-item">
        <mat-icon matTooltip="Report Groups">folder_shared</mat-icon>
      </a>

      <a *ngIf="canManageReportTypes && !leftsidenav.opened" routerLink="/management-information/report-types"
         routerLinkActive="is-active" class="nav-item sub-menu-item">
        <mat-icon matTooltip="Report Types">library_books</mat-icon>
      </a>

      <a *ngIf="canUploadReports && !leftsidenav.opened" routerLink="/management-information/upload-reports"
         routerLinkActive="is-active" class="nav-item sub-menu-item">
        <mat-icon matTooltip="Upload Reports">note_add</mat-icon>
      </a>

      <a *ngIf="canViewWeeklyReports && !leftsidenav.opened" routerLink="/management-information/view-weekly-reports"
         routerLinkActive="is-active" class="nav-item sub-menu-item">
        <mat-icon matTooltip="Weekly Reports">notes</mat-icon>
      </a>

      <a *ngIf="canViewMonthlyReports && !leftsidenav.opened" routerLink="/management-information/view-monthly-reports"
         routerLinkActive="is-active" class="nav-item sub-menu-item">
        <mat-icon matTooltip="Monthly Reports">subject</mat-icon>
      </a>

      <a *ngIf="canViewInputValidationReports && !leftsidenav.opened"
         routerLink="/management-information/view-input-validation-reports" routerLinkActive="is-active"
         class="nav-item sub-menu-item">
        <mat-icon matTooltip="Input Validation Reports">text_snippet</mat-icon>
      </a>

      <a *ngIf="canSeeReportBatches && !leftsidenav.opened" routerLink="/management-information/report-batches"
         routerLinkActive="is-active" class="nav-item sub-menu-item">
        <mat-icon matTooltip="Report Batches">history</mat-icon>
      </a>
      <!-- MENU MANAGEMENT INFORMATION LINKS WHEN SIDEBAR COLLAPSED end-->


      <a *ngIf="leftsidenav.opened && (canSeeDocumentsMenu)" (click)="showDocumentsMenu = !showDocumentsMenu"
         class="nav-item" disabled>
        <mat-icon matTooltip="Expand Documents" *ngIf="!showDocumentsMenu && canManageDocuments">chevron_right</mat-icon>
        <mat-icon matTooltip="Collapse Documents" *ngIf="showDocumentsMenu && canManageDocuments">expand_more</mat-icon>

        <mat-icon matTooltip="Documents">file_copy</mat-icon>
        <mat-label *ngIf="leftsidenav.opened">Documents</mat-label>
      </a>

      <div class="sub-menu-items-container" *ngIf="showDocumentsMenu">
        <a *ngIf="canManageDocuments && leftsidenav.opened" routerLink="/documents/uploaded-documents"
           routerLinkActive="is-active" class="nav-item sub-menu-item">
          <mat-icon matTooltip="Uploaded Documents">description</mat-icon>
          <mat-label *ngIf="leftsidenav.opened">Uploaded Documents</mat-label>
        </a>

        <a *ngIf="canManageFormsTemplates && leftsidenav.opened" routerLink="/documents/forms-templates"
           routerLinkActive="is-active" class="nav-item sub-menu-item">
          <mat-icon matTooltip="Forms Templates">assignment</mat-icon>
          <mat-label *ngIf="leftsidenav.opened">Forms Templates</mat-label>
        </a>
      </div>

      <!-- DOCUMENTS LINKS WHEN SIDEBAR COLLAPSED start-->
      <a *ngIf="canManageDocuments && !leftsidenav.opened" routerLink="/documents/uploaded-documents"
         routerLinkActive="is-active" class="nav-item sub-menu-item">
        <mat-icon matTooltip="Uploaded Documents">description</mat-icon>
      </a>

      <a *ngIf="canManageFormsTemplates && !leftsidenav.opened" routerLink="/documents/forms-templates"
         routerLinkActive="is-active" class="nav-item sub-menu-item">
        <mat-icon matTooltip="Forms Templates">assignment</mat-icon>
      </a>
      <!-- DOCUMENTS LINKS WHEN SIDEBAR COLLAPSED end-->

      <a *ngIf="canManageNews" id="navMenuOptiaNews" routerLink="/optia-news" routerLinkActive="is-active"
         class="nav-item">
        <mat-icon matTooltip="News Management">event_note</mat-icon>
        <mat-label *ngIf="leftsidenav.opened">Optia News </mat-label>
      </a>

      <hr class="sidenav-separator" />

      <!-- MENU LINKS WHEN SIDEBAR OPENED -->
      <a *ngIf="leftsidenav.opened && (canManageUsers || canManageUserGroups)" (click)="showUserMenu = !showUserMenu"
         class="nav-item">
        <mat-icon matTooltip="Expand User Management" *ngIf="!showUserMenu && canManageUsers">chevron_right</mat-icon>
        <mat-icon matTooltip="Collapse User Management" *ngIf="showUserMenu && canManageUsers">expand_more</mat-icon>

        <mat-icon matTooltip="user Management">supervisor_account</mat-icon>
        <mat-label *ngIf="leftsidenav.opened">User Management</mat-label>
      </a>
      <div class="sub-menu-items-container" *ngIf="showUserMenu">
        <a *ngIf="canManageUsers && leftsidenav.opened" id="navMenuUsers" routerLink="/manage/users"
           routerLinkActive="is-active" class="nav-item sub-menu-item">
          <mat-icon matTooltip="User Management">person</mat-icon>
          <mat-label *ngIf="leftsidenav.opened">Users</mat-label>
        </a>

        <a id="navMenuGroups" *ngIf="canManageUserGroups && leftsidenav.opened" routerLink="/manage/user-groups"
           routerLinkActive="is-active" class="nav-item sub-menu-item">
          <mat-icon matTooltip="Group Management">groups</mat-icon>
          <mat-label *ngIf="leftsidenav.opened">User Groups</mat-label>
        </a>
      </div>

      <a *ngIf="leftsidenav.opened && (canManageLists || canViewDataMismatch)" (click)="showSystemMenu = !showSystemMenu" class="nav-item">

        <mat-icon matTooltip="Expand System" *ngIf="!showSystemMenu">chevron_right</mat-icon>
        <mat-icon matTooltip="Collapse System" *ngIf="showSystemMenu">expand_more</mat-icon>

        <mat-icon matTooltip="System">settings</mat-icon>
        <mat-label *ngIf="leftsidenav.opened">System</mat-label>
      </a>
      <div class="sub-menu-items-container" *ngIf="showSystemMenu">
        <a *ngIf="canManageLists && leftsidenav.opened" id="navMenuLists" routerLink="/system/lists"
           routerLinkActive="is-active" class="nav-item sub-menu-item">
          <mat-icon matTooltip="List Management">format_list_bulleted</mat-icon>
          <mat-label *ngIf="leftsidenav.opened">Lists</mat-label>
        </a>

        <a *ngIf="leftsidenav.opened && canViewDataMismatch" id="navMenuDataIntegrity" routerLink="/system/data-mismatches"
           routerLinkActive="is-active" class="nav-item sub-menu-item" disabled>
          <mat-icon matTooltip="Data Integrity">cloud_done</mat-icon>
          <mat-label *ngIf="leftsidenav.opened">Data Integrity</mat-label>
        </a>
      </div>

      <!-- MENU LINKS WHEN SIDEBAR COLLAPSED -->
      <a *ngIf="canManageUsers && !leftsidenav.opened" id="navMenuUsers" routerLink="/manage/users"
         routerLinkActive="is-active" class="nav-item">
        <mat-icon matTooltip="User Management">person</mat-icon>
        <mat-label *ngIf="leftsidenav.opened">Users</mat-label>
      </a>

      <a *ngIf="canManageUserGroups && !leftsidenav.opened" id="navMenuGroups" routerLink="/manage/user-groups"
         routerLinkActive="is-active" class="nav-item">
        <mat-icon matTooltip="Group Management">groups</mat-icon>
        <mat-label *ngIf="leftsidenav.opened">User Groups</mat-label>
      </a>

      <a *ngIf="canManageLists && !leftsidenav.opened" id="navMenuLists" routerLink="/system/lists"
         routerLinkActive="is-active" class="nav-item">
        <mat-icon matTooltip="List Management">format_list_bulleted</mat-icon>
        <mat-label *ngIf="leftsidenav.opened">Lists</mat-label>
      </a>

      <a *ngIf="canViewDataMismatch && !leftsidenav.opened" id="navMenuDataIntegrity" routerLink="/system/data-mismatches"
         routerLinkActive="is-active" class="nav-item" disabled>
        <mat-icon matTooltip="Data Integrity">cloud_done</mat-icon>
        <mat-label *ngIf="leftsidenav.opened">Data Integrity</mat-label>
      </a>

    </mat-nav-list>
  </mat-sidenav>
  <!-- MAIN CONTENT -->

  <mat-sidenav-content>
    <div class="sidenav-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<div *ngIf="!userIsAllowedToSee" class="loading-indicator-middle-screen">
  <div class="spinner-container">
    <mat-spinner class="spinner-loading-indicator"></mat-spinner>
  </div>
</div>
