//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfPreRequisiteEdm } from './wfprerequisiteedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfPreRequisiteEdmEntityConfig = {
  name: 'WfPreRequisiteEdm',
  keys: [{name: 'wfOutcomeGuid'},{name: 'wfRuleGuid'}],
  fields: {
    wfRuleGuid: {type: 'Edm.Guid'},
    wfOutcomeGuid: {type: 'Edm.Guid'},
    wfOutcome: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfOutcomeEdm', navigation: true, referentials: [{property: 'wfOutcomeGuid', referencedProperty: 'wfOutcomeGuid'}]},
    wfRule: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfRuleEdm', navigation: true, referentials: [{property: 'wfRuleGuid', referencedProperty: 'wfRuleGuid'}]}
  }
} as StructuredTypeConfig<WfPreRequisiteEdm>;
//#endregion