import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { NotificationEdm } from './DataDomain/EFEntities/Notify/notificationedm.entity';
import { NotificationPersonEdm } from './DataDomain/EFEntities/Notify/notificationpersonedm.entity';
//#endregion

@Injectable()
export class NotificationsService extends ODataEntitySetService<NotificationEdm> {
  constructor(client: ODataClient) {
    super(client, 'Notifications', 'OptiaApi.DataDomain.EFEntities.Notify.NotificationEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public notificationPersons(key: EntityKey<NotificationEdm>): ODataNavigationPropertyResource<NotificationPersonEdm> { 
    return this.entity(key).navigationProperty<NotificationPersonEdm>('notificationPersons'); 
  }
  public fetchNotificationPersons(key: EntityKey<NotificationEdm>, options?: ODataQueryArgumentsOptions<NotificationPersonEdm>) {
    return this.fetchNavigationProperty<NotificationPersonEdm>(
      this.notificationPersons(key), 
      'entities', options) as Observable<ODataEntities<NotificationPersonEdm>>;
  }
  public addNotificationPersonEdmToNotificationPersons(key: EntityKey<NotificationEdm>, target: ODataEntityResource<ODataEntities<NotificationPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.notificationPersons(key).reference()
      .add(target);
  }
  public removeNotificationPersonEdmFromNotificationPersons(key: EntityKey<NotificationEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<NotificationPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.notificationPersons(key).reference()
      .remove(target);
  }
  //#endregion
}
