//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MiReportTypeEdm } from './mireporttypeedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const MiReportTypeEdmEntityConfig = {
  name: 'MiReportTypeEdm',
  keys: [{name: 'miReportTypeGuid'}],
  fields: {
    miReportTypeGuid: {type: 'Edm.Guid', nullable: false},
    miReportGroupGuid: {type: 'Edm.Guid'},
    name: {type: 'Edm.String'},
    fileType: {type: 'Edm.Int16'},
    miReportTab: {type: 'Edm.String'},
    miReportFilePath: {type: 'Edm.String'},
    isMonthEnding: {type: 'Edm.Boolean'},
    validationRequired: {type: 'Edm.Boolean'},
    status: {type: 'Edm.Int16', nullable: false},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    requiresApproval: {type: 'Edm.Boolean'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    miReportGroup: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportGroupEdm', navigation: true, referentials: [{property: 'miReportGroupGuid', referencedProperty: 'miReportGroupGuid'}]},
    miReportTypePersons: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportTypePersonEdm', collection: true, navigation: true},
    miReportTypeValidationRules: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportTypeValidationRuleEdm', collection: true, navigation: true},
    transferMiReportTypes: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferMiReportTypeEdm', collection: true, navigation: true},
    transferLogs: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferLogEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<MiReportTypeEdm>;
//#endregion