//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { BackOfficePermissionDetailEdm } from './backofficepermissiondetailedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const BackOfficePermissionDetailEdmComplexConfig = {
  name: 'BackOfficePermissionDetailEdm',
  fields: {
    createGuid: {type: 'Edm.Guid'},
    readGuid: {type: 'Edm.Guid'},
    updateGuid: {type: 'Edm.Guid'},
    deleteGuid: {type: 'Edm.Guid'},
    grouping: {type: 'Edm.Int32'},
    groupSort: {type: 'Edm.Int32', nullable: false},
    gridDescription: {type: 'Edm.String'},
    read: {type: 'Edm.Int32'},
    create: {type: 'Edm.Int32'},
    update: {type: 'Edm.Int32'},
    delete: {type: 'Edm.Int32'}
  }
} as StructuredTypeConfig<BackOfficePermissionDetailEdm>;
//#endregion