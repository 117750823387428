//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TransferEdm } from './transferedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const TransferEdmEntityConfig = {
  name: 'TransferEdm',
  keys: [{name: 'transferGuid'}],
  fields: {
    transferGuid: {type: 'Edm.Guid', nullable: false},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid', nullable: false},
    notifiedOn: {type: 'Edm.DateTimeOffset'},
    completedOn: {type: 'Edm.DateTimeOffset'},
    whichAgenciesForType: {type: 'Edm.Int16'},
    periodType: {type: 'Edm.Int16'},
    weekNo: {type: 'Edm.Byte'},
    weekYear: {type: 'Edm.Int16'},
    toWeekNo: {type: 'Edm.Int16'},
    toWeekYear: {type: 'Edm.Int16'},
    monthNo: {type: 'Edm.Int16'},
    monthYear: {type: 'Edm.Int16'},
    toMonthNo: {type: 'Edm.Int16'},
    toMonthYear: {type: 'Edm.Int16'},
    uploadStatus: {type: 'Edm.Int16'},
    deleted: {type: 'Edm.DateTimeOffset'},
    transferAgencies: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferAgencyEdm', collection: true, navigation: true},
    transferMiReportTypes: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferMiReportTypeEdm', collection: true, navigation: true},
    transferLogs: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferLogEdm', collection: true, navigation: true},
    transferRevisions: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferRevisionEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<TransferEdm>;
//#endregion