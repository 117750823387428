//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyReportingCategoriesService } from './agencyreportingcategories.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AgencyReportingCategoriesServiceEntitySetConfig = {
  name: 'AgencyReportingCategories',
  entityType: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyReportingCategoryEdm',
  service: AgencyReportingCategoriesService
} as EntitySetConfig;
//#endregion