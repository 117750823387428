//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskRolesService } from './wftaskroles.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WfTaskRolesServiceEntitySetConfig = {
  name: 'WfTaskRoles',
  entityType: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskRoleEdm',
  service: WfTaskRolesService
} as EntitySetConfig;
//#endregion