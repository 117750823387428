//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AccountPersonsService } from './accountpersons.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AccountPersonsServiceEntitySetConfig = {
  name: 'AccountPersons',
  entityType: 'OptiaApi.DataDomain.EFEntities.AccountPersonEdm',
  service: AccountPersonsService
} as EntitySetConfig;
//#endregion