import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AddressEdm } from './DataDomain/EFEntities/addressedm.entity';
import { CompaniesHouseDetailsAddressEdm } from './DataDomain/EFEntities/companieshousedetailsaddressedm.entity';
import { AgencyAddressEdm } from './DataDomain/EFEntities/Agency/agencyaddressedm.entity';
import { CustomerAddressEdm } from './DataDomain/EFEntities/Customer/customeraddressedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
import { PersonAddressEdm } from './DataDomain/EFEntities/Person/personaddressedm.entity';
import { SupplierAddressEdm } from './DataDomain/EFEntities/Supplier/supplieraddressedm.entity';
import { WorkerAddressEdm } from './DataDomain/EFEntities/Worker/workeraddressedm.entity';
//#endregion

@Injectable()
export class AddressesService extends ODataEntitySetService<AddressEdm> {
  constructor(client: ODataClient) {
    super(client, 'Addresses', 'OptiaApi.DataDomain.EFEntities.AddressEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public agencyAddresses(key: EntityKey<AddressEdm>): ODataNavigationPropertyResource<AgencyAddressEdm> { 
    return this.entity(key).navigationProperty<AgencyAddressEdm>('agencyAddresses'); 
  }
  public fetchAgencyAddresses(key: EntityKey<AddressEdm>, options?: ODataQueryArgumentsOptions<AgencyAddressEdm>) {
    return this.fetchNavigationProperty<AgencyAddressEdm>(
      this.agencyAddresses(key), 
      'entities', options) as Observable<ODataEntities<AgencyAddressEdm>>;
  }
  public addAgencyAddressEdmToAgencyAddresses(key: EntityKey<AddressEdm>, target: ODataEntityResource<ODataEntities<AgencyAddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyAddresses(key).reference()
      .add(target);
  }
  public removeAgencyAddressEdmFromAgencyAddresses(key: EntityKey<AddressEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyAddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyAddresses(key).reference()
      .remove(target);
  }
  public companiesHouseDetailsAddresses(key: EntityKey<AddressEdm>): ODataNavigationPropertyResource<CompaniesHouseDetailsAddressEdm> { 
    return this.entity(key).navigationProperty<CompaniesHouseDetailsAddressEdm>('companiesHouseDetailsAddresses'); 
  }
  public fetchCompaniesHouseDetailsAddresses(key: EntityKey<AddressEdm>, options?: ODataQueryArgumentsOptions<CompaniesHouseDetailsAddressEdm>) {
    return this.fetchNavigationProperty<CompaniesHouseDetailsAddressEdm>(
      this.companiesHouseDetailsAddresses(key), 
      'entities', options) as Observable<ODataEntities<CompaniesHouseDetailsAddressEdm>>;
  }
  public addCompaniesHouseDetailsAddressEdmToCompaniesHouseDetailsAddresses(key: EntityKey<AddressEdm>, target: ODataEntityResource<ODataEntities<CompaniesHouseDetailsAddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.companiesHouseDetailsAddresses(key).reference()
      .add(target);
  }
  public removeCompaniesHouseDetailsAddressEdmFromCompaniesHouseDetailsAddresses(key: EntityKey<AddressEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CompaniesHouseDetailsAddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.companiesHouseDetailsAddresses(key).reference()
      .remove(target);
  }
  public createdByPerson(key: EntityKey<AddressEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<AddressEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<AddressEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<AddressEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public customerAddresses(key: EntityKey<AddressEdm>): ODataNavigationPropertyResource<CustomerAddressEdm> { 
    return this.entity(key).navigationProperty<CustomerAddressEdm>('customerAddresses'); 
  }
  public fetchCustomerAddresses(key: EntityKey<AddressEdm>, options?: ODataQueryArgumentsOptions<CustomerAddressEdm>) {
    return this.fetchNavigationProperty<CustomerAddressEdm>(
      this.customerAddresses(key), 
      'entities', options) as Observable<ODataEntities<CustomerAddressEdm>>;
  }
  public addCustomerAddressEdmToCustomerAddresses(key: EntityKey<AddressEdm>, target: ODataEntityResource<ODataEntities<CustomerAddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customerAddresses(key).reference()
      .add(target);
  }
  public removeCustomerAddressEdmFromCustomerAddresses(key: EntityKey<AddressEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CustomerAddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.customerAddresses(key).reference()
      .remove(target);
  }
  public personAddresses(key: EntityKey<AddressEdm>): ODataNavigationPropertyResource<PersonAddressEdm> { 
    return this.entity(key).navigationProperty<PersonAddressEdm>('personAddresses'); 
  }
  public fetchPersonAddresses(key: EntityKey<AddressEdm>, options?: ODataQueryArgumentsOptions<PersonAddressEdm>) {
    return this.fetchNavigationProperty<PersonAddressEdm>(
      this.personAddresses(key), 
      'entities', options) as Observable<ODataEntities<PersonAddressEdm>>;
  }
  public addPersonAddressEdmToPersonAddresses(key: EntityKey<AddressEdm>, target: ODataEntityResource<ODataEntities<PersonAddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personAddresses(key).reference()
      .add(target);
  }
  public removePersonAddressEdmFromPersonAddresses(key: EntityKey<AddressEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonAddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.personAddresses(key).reference()
      .remove(target);
  }
  public supplierAddresses(key: EntityKey<AddressEdm>): ODataNavigationPropertyResource<SupplierAddressEdm> { 
    return this.entity(key).navigationProperty<SupplierAddressEdm>('supplierAddresses'); 
  }
  public fetchSupplierAddresses(key: EntityKey<AddressEdm>, options?: ODataQueryArgumentsOptions<SupplierAddressEdm>) {
    return this.fetchNavigationProperty<SupplierAddressEdm>(
      this.supplierAddresses(key), 
      'entities', options) as Observable<ODataEntities<SupplierAddressEdm>>;
  }
  public addSupplierAddressEdmToSupplierAddresses(key: EntityKey<AddressEdm>, target: ODataEntityResource<ODataEntities<SupplierAddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.supplierAddresses(key).reference()
      .add(target);
  }
  public removeSupplierAddressEdmFromSupplierAddresses(key: EntityKey<AddressEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<SupplierAddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.supplierAddresses(key).reference()
      .remove(target);
  }
  public workerAddresses(key: EntityKey<AddressEdm>): ODataNavigationPropertyResource<WorkerAddressEdm> { 
    return this.entity(key).navigationProperty<WorkerAddressEdm>('workerAddresses'); 
  }
  public fetchWorkerAddresses(key: EntityKey<AddressEdm>, options?: ODataQueryArgumentsOptions<WorkerAddressEdm>) {
    return this.fetchNavigationProperty<WorkerAddressEdm>(
      this.workerAddresses(key), 
      'entities', options) as Observable<ODataEntities<WorkerAddressEdm>>;
  }
  public addWorkerAddressEdmToWorkerAddresses(key: EntityKey<AddressEdm>, target: ODataEntityResource<ODataEntities<WorkerAddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerAddresses(key).reference()
      .add(target);
  }
  public removeWorkerAddressEdmFromWorkerAddresses(key: EntityKey<AddressEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerAddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerAddresses(key).reference()
      .remove(target);
  }
  //#endregion
}
