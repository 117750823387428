//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MiReportTypeValidationRulesService } from './mireporttypevalidationrules.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const MiReportTypeValidationRulesServiceEntitySetConfig = {
  name: 'MiReportTypeValidationRules',
  entityType: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportTypeValidationRuleEdm',
  service: MiReportTypeValidationRulesService
} as EntitySetConfig;
//#endregion