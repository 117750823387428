//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ChangeSetsService } from './changesets.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ChangeSetsServiceEntitySetConfig = {
  name: 'ChangeSets',
  entityType: 'OptiaApi.DataDomain.EFEntities.Admin.ChangeSetEdm',
  service: ChangeSetsService
} as EntitySetConfig;
//#endregion