//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TransfersService } from './transfers.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const TransfersServiceEntitySetConfig = {
  name: 'Transfers',
  entityType: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferEdm',
  service: TransfersService
} as EntitySetConfig;
//#endregion