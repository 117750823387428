//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { UserGroupEdm } from './usergroupedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const UserGroupEdmEntityConfig = {
  name: 'UserGroupEdm',
  keys: [{name: 'userGroupGuid'}],
  fields: {
    userGroupGuid: {type: 'Edm.Guid', nullable: false},
    agencyGuid: {type: 'Edm.Guid'},
    name: {type: 'Edm.String', nullable: false},
    description: {type: 'Edm.String', maxLength: -1},
    tag: {type: 'Edm.String'},
    userGroupType: {type: 'Edm.Int16'},
    userGroupCategory: {type: 'Edm.Int16'},
    active: {type: 'Edm.Boolean'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    readOnly: {type: 'Edm.Boolean'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    personUserGroups: {type: 'OptiaApi.DataDomain.EFEntities.Admin.PersonUserGroupEdm', collection: true, navigation: true},
    userGroupPermissions: {type: 'OptiaApi.DataDomain.EFEntities.Admin.UserGroupPermissionEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<UserGroupEdm>;
//#endregion