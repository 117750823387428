//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { UserGroupDetailEdm } from './usergroupdetailedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const UserGroupDetailEdmComplexConfig = {
  name: 'UserGroupDetailEdm',
  fields: {
    userGroupGuid: {type: 'Edm.Guid', nullable: false},
    agencyGuid: {type: 'Edm.Guid'},
    agencyDesc: {type: 'Edm.String'},
    name: {type: 'Edm.String', nullable: false},
    description: {type: 'Edm.String', maxLength: -1},
    userGroupCategory: {type: 'Edm.Int16'},
    userCount: {type: 'Edm.Int32'},
    deleted: {type: 'Edm.DateTimeOffset'},
    archived: {type: 'Edm.Boolean'}
  }
} as StructuredTypeConfig<UserGroupDetailEdm>;
//#endregion