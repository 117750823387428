//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DocumentsService } from './documents.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const DocumentsServiceEntitySetConfig = {
  name: 'Documents',
  entityType: 'OptiaApi.DataDomain.EFEntities.DocumentEdm',
  service: DocumentsService
} as EntitySetConfig;
//#endregion