//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerEdm } from './workeredm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WorkerEdmEntityConfig = {
  name: 'WorkerEdm',
  keys: [{name: 'workerGuid'}],
  fields: {
    workerGuid: {type: 'Edm.Guid', nullable: false},
    firstName: {type: 'Edm.String', nullable: false},
    surname: {type: 'Edm.String', nullable: false},
    isLeaver: {type: 'Edm.Boolean'},
    knownAsName: {type: 'Edm.String'},
    nationalInsuranceNumber: {type: 'Edm.String'},
    emailAddress: {type: 'Edm.String', nullable: false},
    title: {type: 'Edm.Int16'},
    middlename: {type: 'Edm.String'},
    status: {type: 'Edm.Int16', nullable: false},
    rightToWorkInTheUk: {type: 'Edm.Boolean'},
    dob: {type: 'Edm.DateTimeOffset'},
    armedForcesVeteran: {type: 'Edm.Boolean'},
    armedForcesDischargeDate: {type: 'Edm.DateTimeOffset'},
    type: {type: 'Edm.Int16', nullable: false},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    agencyWorkers: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyWorkerEdm', collection: true, navigation: true},
    assignments: {type: 'OptiaApi.DataDomain.EFEntities.AssignmentEdm', collection: true, navigation: true},
    workerAddresses: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerAddressEdm', collection: true, navigation: true},
    workerBankDetails: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerBankDetailEdm', collection: true, navigation: true},
    workerDocuments: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerDocumentEdm', collection: true, navigation: true},
    workerPersons: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerPersonEdm', collection: true, navigation: true},
    workerPhoneNumbers: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerPhoneNumberEdm', collection: true, navigation: true},
    workerTypes: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<WorkerEdm>;
//#endregion