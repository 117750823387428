import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
import { RefDataItemEdm } from './DataDomain/EFEntities/RefData/refdataitemedm.entity';
import { RefDataTypeEdm } from './DataDomain/EFEntities/RefData/refdatatypeedm.entity';
//#endregion

@Injectable()
export class RefDataItemsService extends ODataEntitySetService<RefDataItemEdm> {
  constructor(client: ODataClient) {
    super(client, 'RefDataItems', 'OptiaApi.DataDomain.EFEntities.RefData.RefDataItemEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public createdByPerson(key: EntityKey<RefDataItemEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<RefDataItemEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<RefDataItemEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<RefDataItemEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public refDataType(key: EntityKey<RefDataItemEdm>): ODataNavigationPropertyResource<RefDataTypeEdm> { 
    return this.entity(key).navigationProperty<RefDataTypeEdm>('refDataType'); 
  }
  public fetchRefDataType(key: EntityKey<RefDataItemEdm>, options?: ODataQueryArgumentsOptions<RefDataTypeEdm>) {
    return this.fetchNavigationProperty<RefDataTypeEdm>(
      this.refDataType(key), 
      'entity', options) as Observable<ODataEntity<RefDataTypeEdm>>;
  }
  public setRefDataTypeEdmAsRefDataType(key: EntityKey<RefDataItemEdm>, target: ODataEntityResource<ODataEntity<RefDataTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.refDataType(key).reference()
      .set(target, {etag});
  }
  public unsetRefDataTypeEdmAsRefDataType(key: EntityKey<RefDataItemEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<RefDataTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.refDataType(key).reference()
      .unset({etag});
  }
  //#endregion
}
