//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerAgencyDocumentEdm } from './customeragencydocumentedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CustomerAgencyDocumentEdmEntityConfig = {
  name: 'CustomerAgencyDocumentEdm',
  keys: [{name: 'customerAgencyDocumentGuid'}],
  fields: {
    documentGuid: {type: 'Edm.Guid'},
    customerAgencyGuid: {type: 'Edm.Guid'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    customerAgencyDocumentGuid: {type: 'Edm.Guid', nullable: false},
    document: {type: 'OptiaApi.DataDomain.EFEntities.DocumentEdm', navigation: true, referentials: [{property: 'documentGuid', referencedProperty: 'documentGuid'}]},
    customerAgency: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyEdm', navigation: true, referentials: [{property: 'customerAgencyGuid', referencedProperty: 'customerAgencyGuid'}]}
  }
} as StructuredTypeConfig<CustomerAgencyDocumentEdm>;
//#endregion