//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerTypeBankDetailsService } from './workertypebankdetails.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WorkerTypeBankDetailsServiceEntitySetConfig = {
  name: 'WorkerTypeBankDetails',
  entityType: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeBankDetailEdm',
  service: WorkerTypeBankDetailsService
} as EntitySetConfig;
//#endregion