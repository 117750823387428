//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SupplierDocumentEdm } from './supplierdocumentedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const SupplierDocumentEdmEntityConfig = {
  name: 'SupplierDocumentEdm',
  keys: [{name: 'agencyGuid'},{name: 'documentGuid'},{name: 'fromDate'},{name: 'supplierGuid'}],
  fields: {
    supplierGuid: {type: 'Edm.Guid'},
    documentGuid: {type: 'Edm.Guid'},
    agencyGuid: {type: 'Edm.Guid'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    document: {type: 'OptiaApi.DataDomain.EFEntities.DocumentEdm', navigation: true, referentials: [{property: 'documentGuid', referencedProperty: 'documentGuid'}]},
    supplier: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierEdm', navigation: true, referentials: [{property: 'supplierGuid', referencedProperty: 'supplierGuid'}]},
    agency: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm', navigation: true, referentials: [{property: 'agencyGuid', referencedProperty: 'agencyGuid'}]}
  }
} as StructuredTypeConfig<SupplierDocumentEdm>;
//#endregion