//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ReportingLabelEdm } from './reportinglabeledm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ReportingLabelEdmEntityConfig = {
  name: 'ReportingLabelEdm',
  keys: [{name: 'reportingLabelGuid'}],
  fields: {
    reportingLabelGuid: {type: 'Edm.Guid', nullable: false},
    agencyReportingCategoryGuid: {type: 'Edm.Guid'},
    parentReportingLabelGuid: {type: 'Edm.Guid'},
    description: {type: 'Edm.String'},
    code: {type: 'Edm.String'},
    agencyReportingCategory: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyReportingCategoryEdm', navigation: true, referentials: [{property: 'agencyReportingCategoryGuid', referencedProperty: 'agencyReportingCategoryGuid'}]},
    parentReportingLabel: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.ReportingLabelEdm', navigation: true, referentials: [{property: 'parentReportingLabelGuid', referencedProperty: 'reportingLabelGuid'}]},
    childGuidAgencyReportingCombinations: {type: 'OptiaApi.DataDomain.EFEntities.AgencyReportingCombinationEdm', collection: true, navigation: true},
    contractReportingLabels: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.ContractReportingLabelEdm', collection: true, navigation: true},
    parentGuidAgencyReportingCombinations: {type: 'OptiaApi.DataDomain.EFEntities.AgencyReportingCombinationEdm', collection: true, navigation: true},
    parentReportingLabels: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.ReportingLabelEdm', collection: true, navigation: true},
    workerTypeReportingLabels: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeReportingLabelEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<ReportingLabelEdm>;
//#endregion