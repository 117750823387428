//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerDocumentsService } from './customerdocuments.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CustomerDocumentsServiceEntitySetConfig = {
  name: 'CustomerDocuments',
  entityType: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerDocumentEdm',
  service: CustomerDocumentsService
} as EntitySetConfig;
//#endregion