//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ChangeSetLinesService } from './changesetlines.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ChangeSetLinesServiceEntitySetConfig = {
  name: 'ChangeSetLines',
  entityType: 'OptiaApi.DataDomain.EFEntities.Admin.ChangeSetLineEdm',
  service: ChangeSetLinesService
} as EntitySetConfig;
//#endregion