import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfInstanceEdm } from './DataDomain/EFEntities/Workflow/wfinstanceedm.entity';
import { WfProcessEdm } from './DataDomain/EFEntities/Workflow/wfprocessedm.entity';
import { WfProcessWfScheduleEdm } from './DataDomain/EFEntities/Workflow/wfprocesswfscheduleedm.entity';
import { WfRuleEdm } from './DataDomain/EFEntities/Workflow/wfruleedm.entity';
//#endregion

@Injectable()
export class WfProcessesService extends ODataEntitySetService<WfProcessEdm> {
  constructor(client: ODataClient) {
    super(client, 'WfProcesses', 'OptiaApi.DataDomain.EFEntities.Workflow.WfProcessEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public wfInstances(key: EntityKey<WfProcessEdm>): ODataNavigationPropertyResource<WfInstanceEdm> { 
    return this.entity(key).navigationProperty<WfInstanceEdm>('wfInstances'); 
  }
  public fetchWfInstances(key: EntityKey<WfProcessEdm>, options?: ODataQueryArgumentsOptions<WfInstanceEdm>) {
    return this.fetchNavigationProperty<WfInstanceEdm>(
      this.wfInstances(key), 
      'entities', options) as Observable<ODataEntities<WfInstanceEdm>>;
  }
  public addWfInstanceEdmToWfInstances(key: EntityKey<WfProcessEdm>, target: ODataEntityResource<ODataEntities<WfInstanceEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfInstances(key).reference()
      .add(target);
  }
  public removeWfInstanceEdmFromWfInstances(key: EntityKey<WfProcessEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfInstanceEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfInstances(key).reference()
      .remove(target);
  }
  public wfProcessWfSchedules(key: EntityKey<WfProcessEdm>): ODataNavigationPropertyResource<WfProcessWfScheduleEdm> { 
    return this.entity(key).navigationProperty<WfProcessWfScheduleEdm>('wfProcessWfSchedules'); 
  }
  public fetchWfProcessWfSchedules(key: EntityKey<WfProcessEdm>, options?: ODataQueryArgumentsOptions<WfProcessWfScheduleEdm>) {
    return this.fetchNavigationProperty<WfProcessWfScheduleEdm>(
      this.wfProcessWfSchedules(key), 
      'entities', options) as Observable<ODataEntities<WfProcessWfScheduleEdm>>;
  }
  public addWfProcessWfScheduleEdmToWfProcessWfSchedules(key: EntityKey<WfProcessEdm>, target: ODataEntityResource<ODataEntities<WfProcessWfScheduleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfProcessWfSchedules(key).reference()
      .add(target);
  }
  public removeWfProcessWfScheduleEdmFromWfProcessWfSchedules(key: EntityKey<WfProcessEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfProcessWfScheduleEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfProcessWfSchedules(key).reference()
      .remove(target);
  }
  public wfRules(key: EntityKey<WfProcessEdm>): ODataNavigationPropertyResource<WfRuleEdm> { 
    return this.entity(key).navigationProperty<WfRuleEdm>('wfRules'); 
  }
  public fetchWfRules(key: EntityKey<WfProcessEdm>, options?: ODataQueryArgumentsOptions<WfRuleEdm>) {
    return this.fetchNavigationProperty<WfRuleEdm>(
      this.wfRules(key), 
      'entities', options) as Observable<ODataEntities<WfRuleEdm>>;
  }
  public addWfRuleEdmToWfRules(key: EntityKey<WfProcessEdm>, target: ODataEntityResource<ODataEntities<WfRuleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfRules(key).reference()
      .add(target);
  }
  public removeWfRuleEdmFromWfRules(key: EntityKey<WfProcessEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfRuleEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfRules(key).reference()
      .remove(target);
  }
  //#endregion
}
