import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PermissionEdm } from './DataDomain/EFEntities/Admin/permissionedm.entity';
import { UserGroupEdm } from './DataDomain/EFEntities/Admin/usergroupedm.entity';
import { UserGroupPermissionEdm } from './DataDomain/EFEntities/Admin/usergrouppermissionedm.entity';
//#endregion

@Injectable()
export class UserGroupPermissionsService extends ODataEntitySetService<UserGroupPermissionEdm> {
  constructor(client: ODataClient) {
    super(client, 'UserGroupPermissions', 'OptiaApi.DataDomain.EFEntities.Admin.UserGroupPermissionEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public permission(key: EntityKey<UserGroupPermissionEdm>): ODataNavigationPropertyResource<PermissionEdm> { 
    return this.entity(key).navigationProperty<PermissionEdm>('permission'); 
  }
  public fetchPermission(key: EntityKey<UserGroupPermissionEdm>, options?: ODataQueryArgumentsOptions<PermissionEdm>) {
    return this.fetchNavigationProperty<PermissionEdm>(
      this.permission(key), 
      'entity', options) as Observable<ODataEntity<PermissionEdm>>;
  }
  public setPermissionEdmAsPermission(key: EntityKey<UserGroupPermissionEdm>, target: ODataEntityResource<ODataEntity<PermissionEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.permission(key).reference()
      .set(target, {etag});
  }
  public unsetPermissionEdmAsPermission(key: EntityKey<UserGroupPermissionEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PermissionEdm>>, etag?: string} = {}): Observable<any> {
    return this.permission(key).reference()
      .unset({etag});
  }
  public userGroup(key: EntityKey<UserGroupPermissionEdm>): ODataNavigationPropertyResource<UserGroupEdm> { 
    return this.entity(key).navigationProperty<UserGroupEdm>('userGroup'); 
  }
  public fetchUserGroup(key: EntityKey<UserGroupPermissionEdm>, options?: ODataQueryArgumentsOptions<UserGroupEdm>) {
    return this.fetchNavigationProperty<UserGroupEdm>(
      this.userGroup(key), 
      'entity', options) as Observable<ODataEntity<UserGroupEdm>>;
  }
  public setUserGroupEdmAsUserGroup(key: EntityKey<UserGroupPermissionEdm>, target: ODataEntityResource<ODataEntity<UserGroupEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.userGroup(key).reference()
      .set(target, {etag});
  }
  public unsetUserGroupEdmAsUserGroup(key: EntityKey<UserGroupPermissionEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<UserGroupEdm>>, etag?: string} = {}): Observable<any> {
    return this.userGroup(key).reference()
      .unset({etag});
  }
  //#endregion
}
