import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AccountEdm } from './DataDomain/EFEntities/accountedm.entity';
import { AssignmentEdm } from './DataDomain/EFEntities/assignmentedm.entity';
import { ContractEdm } from './DataDomain/EFEntities/contractedm.entity';
import { ContractPersonEdm } from './DataDomain/EFEntities/contractpersonedm.entity';
import { PurchaseOrderEdm } from './DataDomain/EFEntities/purchaseorderedm.entity';
import { TradingStyleEdm } from './DataDomain/EFEntities/Agency/tradingstyleedm.entity';
import { ContractReportingLabelEdm } from './DataDomain/EFEntities/Reporting/contractreportinglabeledm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class ContractsService extends ODataEntitySetService<ContractEdm> {
  constructor(client: ODataClient) {
    super(client, 'Contracts', 'OptiaApi.DataDomain.EFEntities.ContractEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public account(key: EntityKey<ContractEdm>): ODataNavigationPropertyResource<AccountEdm> { 
    return this.entity(key).navigationProperty<AccountEdm>('account'); 
  }
  public fetchAccount(key: EntityKey<ContractEdm>, options?: ODataQueryArgumentsOptions<AccountEdm>) {
    return this.fetchNavigationProperty<AccountEdm>(
      this.account(key), 
      'entity', options) as Observable<ODataEntity<AccountEdm>>;
  }
  public setAccountEdmAsAccount(key: EntityKey<ContractEdm>, target: ODataEntityResource<ODataEntity<AccountEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.account(key).reference()
      .set(target, {etag});
  }
  public unsetAccountEdmAsAccount(key: EntityKey<ContractEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AccountEdm>>, etag?: string} = {}): Observable<any> {
    return this.account(key).reference()
      .unset({etag});
  }
  public assignments(key: EntityKey<ContractEdm>): ODataNavigationPropertyResource<AssignmentEdm> { 
    return this.entity(key).navigationProperty<AssignmentEdm>('assignments'); 
  }
  public fetchAssignments(key: EntityKey<ContractEdm>, options?: ODataQueryArgumentsOptions<AssignmentEdm>) {
    return this.fetchNavigationProperty<AssignmentEdm>(
      this.assignments(key), 
      'entities', options) as Observable<ODataEntities<AssignmentEdm>>;
  }
  public addAssignmentEdmToAssignments(key: EntityKey<ContractEdm>, target: ODataEntityResource<ODataEntities<AssignmentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.assignments(key).reference()
      .add(target);
  }
  public removeAssignmentEdmFromAssignments(key: EntityKey<ContractEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AssignmentEdm>>, etag?: string} = {}): Observable<any> {
    return this.assignments(key).reference()
      .remove(target);
  }
  public contractPersons(key: EntityKey<ContractEdm>): ODataNavigationPropertyResource<ContractPersonEdm> { 
    return this.entity(key).navigationProperty<ContractPersonEdm>('contractPersons'); 
  }
  public fetchContractPersons(key: EntityKey<ContractEdm>, options?: ODataQueryArgumentsOptions<ContractPersonEdm>) {
    return this.fetchNavigationProperty<ContractPersonEdm>(
      this.contractPersons(key), 
      'entities', options) as Observable<ODataEntities<ContractPersonEdm>>;
  }
  public addContractPersonEdmToContractPersons(key: EntityKey<ContractEdm>, target: ODataEntityResource<ODataEntities<ContractPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.contractPersons(key).reference()
      .add(target);
  }
  public removeContractPersonEdmFromContractPersons(key: EntityKey<ContractEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ContractPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.contractPersons(key).reference()
      .remove(target);
  }
  public contractReportingLabels(key: EntityKey<ContractEdm>): ODataNavigationPropertyResource<ContractReportingLabelEdm> { 
    return this.entity(key).navigationProperty<ContractReportingLabelEdm>('contractReportingLabels'); 
  }
  public fetchContractReportingLabels(key: EntityKey<ContractEdm>, options?: ODataQueryArgumentsOptions<ContractReportingLabelEdm>) {
    return this.fetchNavigationProperty<ContractReportingLabelEdm>(
      this.contractReportingLabels(key), 
      'entities', options) as Observable<ODataEntities<ContractReportingLabelEdm>>;
  }
  public addContractReportingLabelEdmToContractReportingLabels(key: EntityKey<ContractEdm>, target: ODataEntityResource<ODataEntities<ContractReportingLabelEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.contractReportingLabels(key).reference()
      .add(target);
  }
  public removeContractReportingLabelEdmFromContractReportingLabels(key: EntityKey<ContractEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ContractReportingLabelEdm>>, etag?: string} = {}): Observable<any> {
    return this.contractReportingLabels(key).reference()
      .remove(target);
  }
  public createdByPerson(key: EntityKey<ContractEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<ContractEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<ContractEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<ContractEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public purchaseOrders(key: EntityKey<ContractEdm>): ODataNavigationPropertyResource<PurchaseOrderEdm> { 
    return this.entity(key).navigationProperty<PurchaseOrderEdm>('purchaseOrders'); 
  }
  public fetchPurchaseOrders(key: EntityKey<ContractEdm>, options?: ODataQueryArgumentsOptions<PurchaseOrderEdm>) {
    return this.fetchNavigationProperty<PurchaseOrderEdm>(
      this.purchaseOrders(key), 
      'entities', options) as Observable<ODataEntities<PurchaseOrderEdm>>;
  }
  public addPurchaseOrderEdmToPurchaseOrders(key: EntityKey<ContractEdm>, target: ODataEntityResource<ODataEntities<PurchaseOrderEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.purchaseOrders(key).reference()
      .add(target);
  }
  public removePurchaseOrderEdmFromPurchaseOrders(key: EntityKey<ContractEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PurchaseOrderEdm>>, etag?: string} = {}): Observable<any> {
    return this.purchaseOrders(key).reference()
      .remove(target);
  }
  public tradingStyle(key: EntityKey<ContractEdm>): ODataNavigationPropertyResource<TradingStyleEdm> { 
    return this.entity(key).navigationProperty<TradingStyleEdm>('tradingStyle'); 
  }
  public fetchTradingStyle(key: EntityKey<ContractEdm>, options?: ODataQueryArgumentsOptions<TradingStyleEdm>) {
    return this.fetchNavigationProperty<TradingStyleEdm>(
      this.tradingStyle(key), 
      'entity', options) as Observable<ODataEntity<TradingStyleEdm>>;
  }
  public setTradingStyleEdmAsTradingStyle(key: EntityKey<ContractEdm>, target: ODataEntityResource<ODataEntity<TradingStyleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.tradingStyle(key).reference()
      .set(target, {etag});
  }
  public unsetTradingStyleEdmAsTradingStyle(key: EntityKey<ContractEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<TradingStyleEdm>>, etag?: string} = {}): Observable<any> {
    return this.tradingStyle(key).reference()
      .unset({etag});
  }
  //#endregion
}
