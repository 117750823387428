import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DocumentEdm } from './DataDomain/EFEntities/documentedm.entity';
import { AgencyEdm } from './DataDomain/EFEntities/Agency/agencyedm.entity';
import { AgencyDocumentEdm } from './DataDomain/EFEntities/Agency/agencydocumentedm.entity';
//#endregion

@Injectable()
export class AgencyDocumentsService extends ODataEntitySetService<AgencyDocumentEdm> {
  constructor(client: ODataClient) {
    super(client, 'AgencyDocuments', 'OptiaApi.DataDomain.EFEntities.Agency.AgencyDocumentEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public agency(key: EntityKey<AgencyDocumentEdm>): ODataNavigationPropertyResource<AgencyEdm> { 
    return this.entity(key).navigationProperty<AgencyEdm>('agency'); 
  }
  public fetchAgency(key: EntityKey<AgencyDocumentEdm>, options?: ODataQueryArgumentsOptions<AgencyEdm>) {
    return this.fetchNavigationProperty<AgencyEdm>(
      this.agency(key), 
      'entity', options) as Observable<ODataEntity<AgencyEdm>>;
  }
  public setAgencyEdmAsAgency(key: EntityKey<AgencyDocumentEdm>, target: ODataEntityResource<ODataEntity<AgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .set(target, {etag});
  }
  public unsetAgencyEdmAsAgency(key: EntityKey<AgencyDocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .unset({etag});
  }
  public document(key: EntityKey<AgencyDocumentEdm>): ODataNavigationPropertyResource<DocumentEdm> { 
    return this.entity(key).navigationProperty<DocumentEdm>('document'); 
  }
  public fetchDocument(key: EntityKey<AgencyDocumentEdm>, options?: ODataQueryArgumentsOptions<DocumentEdm>) {
    return this.fetchNavigationProperty<DocumentEdm>(
      this.document(key), 
      'entity', options) as Observable<ODataEntity<DocumentEdm>>;
  }
  public setDocumentEdmAsDocument(key: EntityKey<AgencyDocumentEdm>, target: ODataEntityResource<ODataEntity<DocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.document(key).reference()
      .set(target, {etag});
  }
  public unsetDocumentEdmAsDocument(key: EntityKey<AgencyDocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<DocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.document(key).reference()
      .unset({etag});
  }
  //#endregion
}
