import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyEdm } from './DataDomain/EFEntities/Agency/agencyedm.entity';
import { AgencyReportingCategoryEdm } from './DataDomain/EFEntities/Agency/agencyreportingcategoryedm.entity';
import { ReportingCategoryEdm } from './DataDomain/EFEntities/Reporting/reportingcategoryedm.entity';
import { ReportingLabelEdm } from './DataDomain/EFEntities/Reporting/reportinglabeledm.entity';
//#endregion

@Injectable()
export class AgencyReportingCategoriesService extends ODataEntitySetService<AgencyReportingCategoryEdm> {
  constructor(client: ODataClient) {
    super(client, 'AgencyReportingCategories', 'OptiaApi.DataDomain.EFEntities.Agency.AgencyReportingCategoryEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public agency(key: EntityKey<AgencyReportingCategoryEdm>): ODataNavigationPropertyResource<AgencyEdm> { 
    return this.entity(key).navigationProperty<AgencyEdm>('agency'); 
  }
  public fetchAgency(key: EntityKey<AgencyReportingCategoryEdm>, options?: ODataQueryArgumentsOptions<AgencyEdm>) {
    return this.fetchNavigationProperty<AgencyEdm>(
      this.agency(key), 
      'entity', options) as Observable<ODataEntity<AgencyEdm>>;
  }
  public setAgencyEdmAsAgency(key: EntityKey<AgencyReportingCategoryEdm>, target: ODataEntityResource<ODataEntity<AgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .set(target, {etag});
  }
  public unsetAgencyEdmAsAgency(key: EntityKey<AgencyReportingCategoryEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .unset({etag});
  }
  public reportingCategory(key: EntityKey<AgencyReportingCategoryEdm>): ODataNavigationPropertyResource<ReportingCategoryEdm> { 
    return this.entity(key).navigationProperty<ReportingCategoryEdm>('reportingCategory'); 
  }
  public fetchReportingCategory(key: EntityKey<AgencyReportingCategoryEdm>, options?: ODataQueryArgumentsOptions<ReportingCategoryEdm>) {
    return this.fetchNavigationProperty<ReportingCategoryEdm>(
      this.reportingCategory(key), 
      'entity', options) as Observable<ODataEntity<ReportingCategoryEdm>>;
  }
  public setReportingCategoryEdmAsReportingCategory(key: EntityKey<AgencyReportingCategoryEdm>, target: ODataEntityResource<ODataEntity<ReportingCategoryEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.reportingCategory(key).reference()
      .set(target, {etag});
  }
  public unsetReportingCategoryEdmAsReportingCategory(key: EntityKey<AgencyReportingCategoryEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<ReportingCategoryEdm>>, etag?: string} = {}): Observable<any> {
    return this.reportingCategory(key).reference()
      .unset({etag});
  }
  public reportingLabels(key: EntityKey<AgencyReportingCategoryEdm>): ODataNavigationPropertyResource<ReportingLabelEdm> { 
    return this.entity(key).navigationProperty<ReportingLabelEdm>('reportingLabels'); 
  }
  public fetchReportingLabels(key: EntityKey<AgencyReportingCategoryEdm>, options?: ODataQueryArgumentsOptions<ReportingLabelEdm>) {
    return this.fetchNavigationProperty<ReportingLabelEdm>(
      this.reportingLabels(key), 
      'entities', options) as Observable<ODataEntities<ReportingLabelEdm>>;
  }
  public addReportingLabelEdmToReportingLabels(key: EntityKey<AgencyReportingCategoryEdm>, target: ODataEntityResource<ODataEntities<ReportingLabelEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.reportingLabels(key).reference()
      .add(target);
  }
  public removeReportingLabelEdmFromReportingLabels(key: EntityKey<AgencyReportingCategoryEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ReportingLabelEdm>>, etag?: string} = {}): Observable<any> {
    return this.reportingLabels(key).reference()
      .remove(target);
  }
  //#endregion
}
