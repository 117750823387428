//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonPhoneNumbersService } from './personphonenumbers.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PersonPhoneNumbersServiceEntitySetConfig = {
  name: 'PersonPhoneNumbers',
  entityType: 'OptiaApi.DataDomain.EFEntities.Person.PersonPhoneNumberEdm',
  service: PersonPhoneNumbersService
} as EntitySetConfig;
//#endregion