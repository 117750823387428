import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DocumentEdm } from './DataDomain/EFEntities/documentedm.entity';
import { WorkerEdm } from './DataDomain/EFEntities/Worker/workeredm.entity';
import { WorkerDocumentEdm } from './DataDomain/EFEntities/Worker/workerdocumentedm.entity';
//#endregion

@Injectable()
export class WorkerDocumentsService extends ODataEntitySetService<WorkerDocumentEdm> {
  constructor(client: ODataClient) {
    super(client, 'WorkerDocuments', 'OptiaApi.DataDomain.EFEntities.Worker.WorkerDocumentEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public document(key: EntityKey<WorkerDocumentEdm>): ODataNavigationPropertyResource<DocumentEdm> { 
    return this.entity(key).navigationProperty<DocumentEdm>('document'); 
  }
  public fetchDocument(key: EntityKey<WorkerDocumentEdm>, options?: ODataQueryArgumentsOptions<DocumentEdm>) {
    return this.fetchNavigationProperty<DocumentEdm>(
      this.document(key), 
      'entity', options) as Observable<ODataEntity<DocumentEdm>>;
  }
  public setDocumentEdmAsDocument(key: EntityKey<WorkerDocumentEdm>, target: ODataEntityResource<ODataEntity<DocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.document(key).reference()
      .set(target, {etag});
  }
  public unsetDocumentEdmAsDocument(key: EntityKey<WorkerDocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<DocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.document(key).reference()
      .unset({etag});
  }
  public worker(key: EntityKey<WorkerDocumentEdm>): ODataNavigationPropertyResource<WorkerEdm> { 
    return this.entity(key).navigationProperty<WorkerEdm>('worker'); 
  }
  public fetchWorker(key: EntityKey<WorkerDocumentEdm>, options?: ODataQueryArgumentsOptions<WorkerEdm>) {
    return this.fetchNavigationProperty<WorkerEdm>(
      this.worker(key), 
      'entity', options) as Observable<ODataEntity<WorkerEdm>>;
  }
  public setWorkerEdmAsWorker(key: EntityKey<WorkerDocumentEdm>, target: ODataEntityResource<ODataEntity<WorkerEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.worker(key).reference()
      .set(target, {etag});
  }
  public unsetWorkerEdmAsWorker(key: EntityKey<WorkerDocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WorkerEdm>>, etag?: string} = {}): Observable<any> {
    return this.worker(key).reference()
      .unset({etag});
  }
  //#endregion
}
