import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CreditAssignmentEdm } from './DataDomain/EFEntities/Credit/creditassignmentedm.entity';
import { CreditCheckEdm } from './DataDomain/EFEntities/Credit/creditcheckedm.entity';
import { CreditLimitEdm } from './DataDomain/EFEntities/Credit/creditlimitedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class CreditLimitsService extends ODataEntitySetService<CreditLimitEdm> {
  constructor(client: ODataClient) {
    super(client, 'CreditLimits', 'OptiaApi.DataDomain.EFEntities.Credit.CreditLimitEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public createdByPerson(key: EntityKey<CreditLimitEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<CreditLimitEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<CreditLimitEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<CreditLimitEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public creditAssignments(key: EntityKey<CreditLimitEdm>): ODataNavigationPropertyResource<CreditAssignmentEdm> { 
    return this.entity(key).navigationProperty<CreditAssignmentEdm>('creditAssignments'); 
  }
  public fetchCreditAssignments(key: EntityKey<CreditLimitEdm>, options?: ODataQueryArgumentsOptions<CreditAssignmentEdm>) {
    return this.fetchNavigationProperty<CreditAssignmentEdm>(
      this.creditAssignments(key), 
      'entities', options) as Observable<ODataEntities<CreditAssignmentEdm>>;
  }
  public addCreditAssignmentEdmToCreditAssignments(key: EntityKey<CreditLimitEdm>, target: ODataEntityResource<ODataEntities<CreditAssignmentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.creditAssignments(key).reference()
      .add(target);
  }
  public removeCreditAssignmentEdmFromCreditAssignments(key: EntityKey<CreditLimitEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CreditAssignmentEdm>>, etag?: string} = {}): Observable<any> {
    return this.creditAssignments(key).reference()
      .remove(target);
  }
  public creditCheck(key: EntityKey<CreditLimitEdm>): ODataNavigationPropertyResource<CreditCheckEdm> { 
    return this.entity(key).navigationProperty<CreditCheckEdm>('creditCheck'); 
  }
  public fetchCreditCheck(key: EntityKey<CreditLimitEdm>, options?: ODataQueryArgumentsOptions<CreditCheckEdm>) {
    return this.fetchNavigationProperty<CreditCheckEdm>(
      this.creditCheck(key), 
      'entity', options) as Observable<ODataEntity<CreditCheckEdm>>;
  }
  public setCreditCheckEdmAsCreditCheck(key: EntityKey<CreditLimitEdm>, target: ODataEntityResource<ODataEntity<CreditCheckEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.creditCheck(key).reference()
      .set(target, {etag});
  }
  public unsetCreditCheckEdmAsCreditCheck(key: EntityKey<CreditLimitEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<CreditCheckEdm>>, etag?: string} = {}): Observable<any> {
    return this.creditCheck(key).reference()
      .unset({etag});
  }
  //#endregion
}
