//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ChangeSetLineEdm } from './changesetlineedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ChangeSetLineEdmEntityConfig = {
  name: 'ChangeSetLineEdm',
  keys: [{name: 'changeSetLineGuid'}],
  fields: {
    changeSetLineGuid: {type: 'Edm.Guid', nullable: false},
    changeSetGuid: {type: 'Edm.Guid'},
    columnName: {type: 'Edm.String'},
    charNvalue: {type: 'Edm.String'},
    intValue: {type: 'Edm.Int32'},
    doubleValue: {type: 'Edm.Double'},
    dateValue: {type: 'Edm.DateTimeOffset'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    changeSet: {type: 'OptiaApi.DataDomain.EFEntities.Admin.ChangeSetEdm', navigation: true, referentials: [{property: 'changeSetGuid', referencedProperty: 'changeSetGuid'}]}
  }
} as StructuredTypeConfig<ChangeSetLineEdm>;
//#endregion