//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CompaniesHouseDetailsService } from './companieshousedetails.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CompaniesHouseDetailsServiceEntitySetConfig = {
  name: 'CompaniesHouseDetails',
  entityType: 'OptiaApi.DataDomain.EFEntities.CompaniesHouseDetailEdm',
  service: CompaniesHouseDetailsService
} as EntitySetConfig;
//#endregion