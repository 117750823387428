//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DepartmentsService } from './departments.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const DepartmentsServiceEntitySetConfig = {
  name: 'Departments',
  entityType: 'OptiaApi.DataDomain.EFEntities.DepartmentEdm',
  service: DepartmentsService
} as EntitySetConfig;
//#endregion