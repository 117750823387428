//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MiReportGroupsService } from './mireportgroups.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const MiReportGroupsServiceEntitySetConfig = {
  name: 'MiReportGroups',
  entityType: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportGroupEdm',
  service: MiReportGroupsService
} as EntitySetConfig;
//#endregion