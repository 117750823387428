import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MiReportTypeEdm } from './DataDomain/EFEntities/Reporting/mireporttypeedm.entity';
import { MiReportTypePersonEdm } from './DataDomain/EFEntities/Reporting/mireporttypepersonedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class MiReportTypePersonsService extends ODataEntitySetService<MiReportTypePersonEdm> {
  constructor(client: ODataClient) {
    super(client, 'MiReportTypePersons', 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportTypePersonEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public miReportType(key: EntityKey<MiReportTypePersonEdm>): ODataNavigationPropertyResource<MiReportTypeEdm> { 
    return this.entity(key).navigationProperty<MiReportTypeEdm>('miReportType'); 
  }
  public fetchMiReportType(key: EntityKey<MiReportTypePersonEdm>, options?: ODataQueryArgumentsOptions<MiReportTypeEdm>) {
    return this.fetchNavigationProperty<MiReportTypeEdm>(
      this.miReportType(key), 
      'entity', options) as Observable<ODataEntity<MiReportTypeEdm>>;
  }
  public setMiReportTypeEdmAsMiReportType(key: EntityKey<MiReportTypePersonEdm>, target: ODataEntityResource<ODataEntity<MiReportTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.miReportType(key).reference()
      .set(target, {etag});
  }
  public unsetMiReportTypeEdmAsMiReportType(key: EntityKey<MiReportTypePersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<MiReportTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.miReportType(key).reference()
      .unset({etag});
  }
  public person(key: EntityKey<MiReportTypePersonEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<MiReportTypePersonEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<MiReportTypePersonEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<MiReportTypePersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  //#endregion
}
