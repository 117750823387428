//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerAgencyDetailEdmComplexConfig } from './customeragencydetailedm.complex.config';
import { CustomerEdmEntityConfig } from './customeredm.entity.config';
import { CustomerAddressEdmEntityConfig } from './customeraddressedm.entity.config';
import { CustomerAgencyEdmEntityConfig } from './customeragencyedm.entity.config';
import { CustomerAgencyDocumentEdmEntityConfig } from './customeragencydocumentedm.entity.config';
import { CustomerAgencyPersonEdmEntityConfig } from './customeragencypersonedm.entity.config';
import { CustomerDocumentEdmEntityConfig } from './customerdocumentedm.entity.config';
import { CustomerPersonEdmEntityConfig } from './customerpersonedm.entity.config';
import { CustomerDetailEdmEntityConfig } from './customerdetailedm.entity.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const CustomerSchema = {
  namespace: 'OptiaApi.DataDomain.EFEntities.Customer',
  enums: [],
  entities: [CustomerAgencyDetailEdmComplexConfig,
    CustomerEdmEntityConfig,
    CustomerAddressEdmEntityConfig,
    CustomerAgencyEdmEntityConfig,
    CustomerAgencyDocumentEdmEntityConfig,
    CustomerAgencyPersonEdmEntityConfig,
    CustomerDocumentEdmEntityConfig,
    CustomerPersonEdmEntityConfig,
    CustomerDetailEdmEntityConfig],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion