import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyMtdCalendarEdm } from './DataDomain/EFEntities/agencymtdcalendaredm.entity';
import { AgencyReportingCombinationEdm } from './DataDomain/EFEntities/agencyreportingcombinationedm.entity';
import { CompaniesHouseDetailEdm } from './DataDomain/EFEntities/companieshousedetailedm.entity';
import { AgencyEdm } from './DataDomain/EFEntities/Agency/agencyedm.entity';
import { AgencyAddressEdm } from './DataDomain/EFEntities/Agency/agencyaddressedm.entity';
import { AgencyBankDetailEdm } from './DataDomain/EFEntities/Agency/agencybankdetailedm.entity';
import { AgencyDocumentEdm } from './DataDomain/EFEntities/Agency/agencydocumentedm.entity';
import { AgencyPersonEdm } from './DataDomain/EFEntities/Agency/agencypersonedm.entity';
import { AgencyPersonWfRoleEdm } from './DataDomain/EFEntities/Agency/agencypersonwfroleedm.entity';
import { AgencyReportingCategoryEdm } from './DataDomain/EFEntities/Agency/agencyreportingcategoryedm.entity';
import { AgencyWorkerEdm } from './DataDomain/EFEntities/Agency/agencyworkeredm.entity';
import { TradingStyleEdm } from './DataDomain/EFEntities/Agency/tradingstyleedm.entity';
import { ChangeSetEdm } from './DataDomain/EFEntities/Admin/changesetedm.entity';
import { TransferAgencyEdm } from './DataDomain/EFEntities/Reporting/transferagencyedm.entity';
import { TransferLogEdm } from './DataDomain/EFEntities/Reporting/transferlogedm.entity';
import { CustomerAgencyEdm } from './DataDomain/EFEntities/Customer/customeragencyedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
import { SupplierAgencyEdm } from './DataDomain/EFEntities/Supplier/supplieragencyedm.entity';
import { SupplierDocumentEdm } from './DataDomain/EFEntities/Supplier/supplierdocumentedm.entity';
//#endregion

@Injectable()
export class AgenciesService extends ODataEntitySetService<AgencyEdm> {
  constructor(client: ODataClient) {
    super(client, 'Agencies', 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public agencyAddresses(key: EntityKey<AgencyEdm>): ODataNavigationPropertyResource<AgencyAddressEdm> { 
    return this.entity(key).navigationProperty<AgencyAddressEdm>('agencyAddresses'); 
  }
  public fetchAgencyAddresses(key: EntityKey<AgencyEdm>, options?: ODataQueryArgumentsOptions<AgencyAddressEdm>) {
    return this.fetchNavigationProperty<AgencyAddressEdm>(
      this.agencyAddresses(key), 
      'entities', options) as Observable<ODataEntities<AgencyAddressEdm>>;
  }
  public addAgencyAddressEdmToAgencyAddresses(key: EntityKey<AgencyEdm>, target: ODataEntityResource<ODataEntities<AgencyAddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyAddresses(key).reference()
      .add(target);
  }
  public removeAgencyAddressEdmFromAgencyAddresses(key: EntityKey<AgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyAddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyAddresses(key).reference()
      .remove(target);
  }
  public agencyBankDetails(key: EntityKey<AgencyEdm>): ODataNavigationPropertyResource<AgencyBankDetailEdm> { 
    return this.entity(key).navigationProperty<AgencyBankDetailEdm>('agencyBankDetails'); 
  }
  public fetchAgencyBankDetails(key: EntityKey<AgencyEdm>, options?: ODataQueryArgumentsOptions<AgencyBankDetailEdm>) {
    return this.fetchNavigationProperty<AgencyBankDetailEdm>(
      this.agencyBankDetails(key), 
      'entities', options) as Observable<ODataEntities<AgencyBankDetailEdm>>;
  }
  public addAgencyBankDetailEdmToAgencyBankDetails(key: EntityKey<AgencyEdm>, target: ODataEntityResource<ODataEntities<AgencyBankDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyBankDetails(key).reference()
      .add(target);
  }
  public removeAgencyBankDetailEdmFromAgencyBankDetails(key: EntityKey<AgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyBankDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyBankDetails(key).reference()
      .remove(target);
  }
  public agencyDocuments(key: EntityKey<AgencyEdm>): ODataNavigationPropertyResource<AgencyDocumentEdm> { 
    return this.entity(key).navigationProperty<AgencyDocumentEdm>('agencyDocuments'); 
  }
  public fetchAgencyDocuments(key: EntityKey<AgencyEdm>, options?: ODataQueryArgumentsOptions<AgencyDocumentEdm>) {
    return this.fetchNavigationProperty<AgencyDocumentEdm>(
      this.agencyDocuments(key), 
      'entities', options) as Observable<ODataEntities<AgencyDocumentEdm>>;
  }
  public addAgencyDocumentEdmToAgencyDocuments(key: EntityKey<AgencyEdm>, target: ODataEntityResource<ODataEntities<AgencyDocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyDocuments(key).reference()
      .add(target);
  }
  public removeAgencyDocumentEdmFromAgencyDocuments(key: EntityKey<AgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyDocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyDocuments(key).reference()
      .remove(target);
  }
  public agencyMtdCalendars(key: EntityKey<AgencyEdm>): ODataNavigationPropertyResource<AgencyMtdCalendarEdm> { 
    return this.entity(key).navigationProperty<AgencyMtdCalendarEdm>('agencyMtdCalendars'); 
  }
  public fetchAgencyMtdCalendars(key: EntityKey<AgencyEdm>, options?: ODataQueryArgumentsOptions<AgencyMtdCalendarEdm>) {
    return this.fetchNavigationProperty<AgencyMtdCalendarEdm>(
      this.agencyMtdCalendars(key), 
      'entities', options) as Observable<ODataEntities<AgencyMtdCalendarEdm>>;
  }
  public addAgencyMtdCalendarEdmToAgencyMtdCalendars(key: EntityKey<AgencyEdm>, target: ODataEntityResource<ODataEntities<AgencyMtdCalendarEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyMtdCalendars(key).reference()
      .add(target);
  }
  public removeAgencyMtdCalendarEdmFromAgencyMtdCalendars(key: EntityKey<AgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyMtdCalendarEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyMtdCalendars(key).reference()
      .remove(target);
  }
  public agencyPersons(key: EntityKey<AgencyEdm>): ODataNavigationPropertyResource<AgencyPersonEdm> { 
    return this.entity(key).navigationProperty<AgencyPersonEdm>('agencyPersons'); 
  }
  public fetchAgencyPersons(key: EntityKey<AgencyEdm>, options?: ODataQueryArgumentsOptions<AgencyPersonEdm>) {
    return this.fetchNavigationProperty<AgencyPersonEdm>(
      this.agencyPersons(key), 
      'entities', options) as Observable<ODataEntities<AgencyPersonEdm>>;
  }
  public addAgencyPersonEdmToAgencyPersons(key: EntityKey<AgencyEdm>, target: ODataEntityResource<ODataEntities<AgencyPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyPersons(key).reference()
      .add(target);
  }
  public removeAgencyPersonEdmFromAgencyPersons(key: EntityKey<AgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyPersons(key).reference()
      .remove(target);
  }
  public agencyPersonWfRoles(key: EntityKey<AgencyEdm>): ODataNavigationPropertyResource<AgencyPersonWfRoleEdm> { 
    return this.entity(key).navigationProperty<AgencyPersonWfRoleEdm>('agencyPersonWfRoles'); 
  }
  public fetchAgencyPersonWfRoles(key: EntityKey<AgencyEdm>, options?: ODataQueryArgumentsOptions<AgencyPersonWfRoleEdm>) {
    return this.fetchNavigationProperty<AgencyPersonWfRoleEdm>(
      this.agencyPersonWfRoles(key), 
      'entities', options) as Observable<ODataEntities<AgencyPersonWfRoleEdm>>;
  }
  public addAgencyPersonWfRoleEdmToAgencyPersonWfRoles(key: EntityKey<AgencyEdm>, target: ODataEntityResource<ODataEntities<AgencyPersonWfRoleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyPersonWfRoles(key).reference()
      .add(target);
  }
  public removeAgencyPersonWfRoleEdmFromAgencyPersonWfRoles(key: EntityKey<AgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyPersonWfRoleEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyPersonWfRoles(key).reference()
      .remove(target);
  }
  public agencyReportingCategories(key: EntityKey<AgencyEdm>): ODataNavigationPropertyResource<AgencyReportingCategoryEdm> { 
    return this.entity(key).navigationProperty<AgencyReportingCategoryEdm>('agencyReportingCategories'); 
  }
  public fetchAgencyReportingCategories(key: EntityKey<AgencyEdm>, options?: ODataQueryArgumentsOptions<AgencyReportingCategoryEdm>) {
    return this.fetchNavigationProperty<AgencyReportingCategoryEdm>(
      this.agencyReportingCategories(key), 
      'entities', options) as Observable<ODataEntities<AgencyReportingCategoryEdm>>;
  }
  public addAgencyReportingCategoryEdmToAgencyReportingCategories(key: EntityKey<AgencyEdm>, target: ODataEntityResource<ODataEntities<AgencyReportingCategoryEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyReportingCategories(key).reference()
      .add(target);
  }
  public removeAgencyReportingCategoryEdmFromAgencyReportingCategories(key: EntityKey<AgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyReportingCategoryEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyReportingCategories(key).reference()
      .remove(target);
  }
  public agencyReportingCombinations(key: EntityKey<AgencyEdm>): ODataNavigationPropertyResource<AgencyReportingCombinationEdm> { 
    return this.entity(key).navigationProperty<AgencyReportingCombinationEdm>('agencyReportingCombinations'); 
  }
  public fetchAgencyReportingCombinations(key: EntityKey<AgencyEdm>, options?: ODataQueryArgumentsOptions<AgencyReportingCombinationEdm>) {
    return this.fetchNavigationProperty<AgencyReportingCombinationEdm>(
      this.agencyReportingCombinations(key), 
      'entities', options) as Observable<ODataEntities<AgencyReportingCombinationEdm>>;
  }
  public addAgencyReportingCombinationEdmToAgencyReportingCombinations(key: EntityKey<AgencyEdm>, target: ODataEntityResource<ODataEntities<AgencyReportingCombinationEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyReportingCombinations(key).reference()
      .add(target);
  }
  public removeAgencyReportingCombinationEdmFromAgencyReportingCombinations(key: EntityKey<AgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyReportingCombinationEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyReportingCombinations(key).reference()
      .remove(target);
  }
  public agencyWorkers(key: EntityKey<AgencyEdm>): ODataNavigationPropertyResource<AgencyWorkerEdm> { 
    return this.entity(key).navigationProperty<AgencyWorkerEdm>('agencyWorkers'); 
  }
  public fetchAgencyWorkers(key: EntityKey<AgencyEdm>, options?: ODataQueryArgumentsOptions<AgencyWorkerEdm>) {
    return this.fetchNavigationProperty<AgencyWorkerEdm>(
      this.agencyWorkers(key), 
      'entities', options) as Observable<ODataEntities<AgencyWorkerEdm>>;
  }
  public addAgencyWorkerEdmToAgencyWorkers(key: EntityKey<AgencyEdm>, target: ODataEntityResource<ODataEntities<AgencyWorkerEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyWorkers(key).reference()
      .add(target);
  }
  public removeAgencyWorkerEdmFromAgencyWorkers(key: EntityKey<AgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyWorkerEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyWorkers(key).reference()
      .remove(target);
  }
  public changeSets(key: EntityKey<AgencyEdm>): ODataNavigationPropertyResource<ChangeSetEdm> { 
    return this.entity(key).navigationProperty<ChangeSetEdm>('changeSets'); 
  }
  public fetchChangeSets(key: EntityKey<AgencyEdm>, options?: ODataQueryArgumentsOptions<ChangeSetEdm>) {
    return this.fetchNavigationProperty<ChangeSetEdm>(
      this.changeSets(key), 
      'entities', options) as Observable<ODataEntities<ChangeSetEdm>>;
  }
  public addChangeSetEdmToChangeSets(key: EntityKey<AgencyEdm>, target: ODataEntityResource<ODataEntities<ChangeSetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.changeSets(key).reference()
      .add(target);
  }
  public removeChangeSetEdmFromChangeSets(key: EntityKey<AgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ChangeSetEdm>>, etag?: string} = {}): Observable<any> {
    return this.changeSets(key).reference()
      .remove(target);
  }
  public companiesHouseDetail(key: EntityKey<AgencyEdm>): ODataNavigationPropertyResource<CompaniesHouseDetailEdm> { 
    return this.entity(key).navigationProperty<CompaniesHouseDetailEdm>('companiesHouseDetail'); 
  }
  public fetchCompaniesHouseDetail(key: EntityKey<AgencyEdm>, options?: ODataQueryArgumentsOptions<CompaniesHouseDetailEdm>) {
    return this.fetchNavigationProperty<CompaniesHouseDetailEdm>(
      this.companiesHouseDetail(key), 
      'entity', options) as Observable<ODataEntity<CompaniesHouseDetailEdm>>;
  }
  public setCompaniesHouseDetailEdmAsCompaniesHouseDetail(key: EntityKey<AgencyEdm>, target: ODataEntityResource<ODataEntity<CompaniesHouseDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.companiesHouseDetail(key).reference()
      .set(target, {etag});
  }
  public unsetCompaniesHouseDetailEdmAsCompaniesHouseDetail(key: EntityKey<AgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<CompaniesHouseDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.companiesHouseDetail(key).reference()
      .unset({etag});
  }
  public createdByPerson(key: EntityKey<AgencyEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<AgencyEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<AgencyEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<AgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public customerAgencies(key: EntityKey<AgencyEdm>): ODataNavigationPropertyResource<CustomerAgencyEdm> { 
    return this.entity(key).navigationProperty<CustomerAgencyEdm>('customerAgencies'); 
  }
  public fetchCustomerAgencies(key: EntityKey<AgencyEdm>, options?: ODataQueryArgumentsOptions<CustomerAgencyEdm>) {
    return this.fetchNavigationProperty<CustomerAgencyEdm>(
      this.customerAgencies(key), 
      'entities', options) as Observable<ODataEntities<CustomerAgencyEdm>>;
  }
  public addCustomerAgencyEdmToCustomerAgencies(key: EntityKey<AgencyEdm>, target: ODataEntityResource<ODataEntities<CustomerAgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customerAgencies(key).reference()
      .add(target);
  }
  public removeCustomerAgencyEdmFromCustomerAgencies(key: EntityKey<AgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CustomerAgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.customerAgencies(key).reference()
      .remove(target);
  }
  public supplierAgencies(key: EntityKey<AgencyEdm>): ODataNavigationPropertyResource<SupplierAgencyEdm> { 
    return this.entity(key).navigationProperty<SupplierAgencyEdm>('supplierAgencies'); 
  }
  public fetchSupplierAgencies(key: EntityKey<AgencyEdm>, options?: ODataQueryArgumentsOptions<SupplierAgencyEdm>) {
    return this.fetchNavigationProperty<SupplierAgencyEdm>(
      this.supplierAgencies(key), 
      'entities', options) as Observable<ODataEntities<SupplierAgencyEdm>>;
  }
  public addSupplierAgencyEdmToSupplierAgencies(key: EntityKey<AgencyEdm>, target: ODataEntityResource<ODataEntities<SupplierAgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.supplierAgencies(key).reference()
      .add(target);
  }
  public removeSupplierAgencyEdmFromSupplierAgencies(key: EntityKey<AgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<SupplierAgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.supplierAgencies(key).reference()
      .remove(target);
  }
  public supplierDocuments(key: EntityKey<AgencyEdm>): ODataNavigationPropertyResource<SupplierDocumentEdm> { 
    return this.entity(key).navigationProperty<SupplierDocumentEdm>('supplierDocuments'); 
  }
  public fetchSupplierDocuments(key: EntityKey<AgencyEdm>, options?: ODataQueryArgumentsOptions<SupplierDocumentEdm>) {
    return this.fetchNavigationProperty<SupplierDocumentEdm>(
      this.supplierDocuments(key), 
      'entities', options) as Observable<ODataEntities<SupplierDocumentEdm>>;
  }
  public addSupplierDocumentEdmToSupplierDocuments(key: EntityKey<AgencyEdm>, target: ODataEntityResource<ODataEntities<SupplierDocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.supplierDocuments(key).reference()
      .add(target);
  }
  public removeSupplierDocumentEdmFromSupplierDocuments(key: EntityKey<AgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<SupplierDocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.supplierDocuments(key).reference()
      .remove(target);
  }
  public tradingStyles(key: EntityKey<AgencyEdm>): ODataNavigationPropertyResource<TradingStyleEdm> { 
    return this.entity(key).navigationProperty<TradingStyleEdm>('tradingStyles'); 
  }
  public fetchTradingStyles(key: EntityKey<AgencyEdm>, options?: ODataQueryArgumentsOptions<TradingStyleEdm>) {
    return this.fetchNavigationProperty<TradingStyleEdm>(
      this.tradingStyles(key), 
      'entities', options) as Observable<ODataEntities<TradingStyleEdm>>;
  }
  public addTradingStyleEdmToTradingStyles(key: EntityKey<AgencyEdm>, target: ODataEntityResource<ODataEntities<TradingStyleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.tradingStyles(key).reference()
      .add(target);
  }
  public removeTradingStyleEdmFromTradingStyles(key: EntityKey<AgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<TradingStyleEdm>>, etag?: string} = {}): Observable<any> {
    return this.tradingStyles(key).reference()
      .remove(target);
  }
  public transferAgencies(key: EntityKey<AgencyEdm>): ODataNavigationPropertyResource<TransferAgencyEdm> { 
    return this.entity(key).navigationProperty<TransferAgencyEdm>('transferAgencies'); 
  }
  public fetchTransferAgencies(key: EntityKey<AgencyEdm>, options?: ODataQueryArgumentsOptions<TransferAgencyEdm>) {
    return this.fetchNavigationProperty<TransferAgencyEdm>(
      this.transferAgencies(key), 
      'entities', options) as Observable<ODataEntities<TransferAgencyEdm>>;
  }
  public addTransferAgencyEdmToTransferAgencies(key: EntityKey<AgencyEdm>, target: ODataEntityResource<ODataEntities<TransferAgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.transferAgencies(key).reference()
      .add(target);
  }
  public removeTransferAgencyEdmFromTransferAgencies(key: EntityKey<AgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<TransferAgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.transferAgencies(key).reference()
      .remove(target);
  }
  public transferLogs(key: EntityKey<AgencyEdm>): ODataNavigationPropertyResource<TransferLogEdm> { 
    return this.entity(key).navigationProperty<TransferLogEdm>('transferLogs'); 
  }
  public fetchTransferLogs(key: EntityKey<AgencyEdm>, options?: ODataQueryArgumentsOptions<TransferLogEdm>) {
    return this.fetchNavigationProperty<TransferLogEdm>(
      this.transferLogs(key), 
      'entities', options) as Observable<ODataEntities<TransferLogEdm>>;
  }
  public addTransferLogEdmToTransferLogs(key: EntityKey<AgencyEdm>, target: ODataEntityResource<ODataEntities<TransferLogEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.transferLogs(key).reference()
      .add(target);
  }
  public removeTransferLogEdmFromTransferLogs(key: EntityKey<AgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<TransferLogEdm>>, etag?: string} = {}): Observable<any> {
    return this.transferLogs(key).reference()
      .remove(target);
  }
  //#endregion
}
