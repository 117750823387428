import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyReportingCombinationEdm } from './DataDomain/EFEntities/agencyreportingcombinationedm.entity';
import { AgencyReportingCategoryEdm } from './DataDomain/EFEntities/Agency/agencyreportingcategoryedm.entity';
import { ContractReportingLabelEdm } from './DataDomain/EFEntities/Reporting/contractreportinglabeledm.entity';
import { ReportingLabelEdm } from './DataDomain/EFEntities/Reporting/reportinglabeledm.entity';
import { WorkerTypeReportingLabelEdm } from './DataDomain/EFEntities/Worker/workertypereportinglabeledm.entity';
//#endregion

@Injectable()
export class ReportingLabelsService extends ODataEntitySetService<ReportingLabelEdm> {
  constructor(client: ODataClient) {
    super(client, 'ReportingLabels', 'OptiaApi.DataDomain.EFEntities.Reporting.ReportingLabelEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public agencyReportingCategory(key: EntityKey<ReportingLabelEdm>): ODataNavigationPropertyResource<AgencyReportingCategoryEdm> { 
    return this.entity(key).navigationProperty<AgencyReportingCategoryEdm>('agencyReportingCategory'); 
  }
  public fetchAgencyReportingCategory(key: EntityKey<ReportingLabelEdm>, options?: ODataQueryArgumentsOptions<AgencyReportingCategoryEdm>) {
    return this.fetchNavigationProperty<AgencyReportingCategoryEdm>(
      this.agencyReportingCategory(key), 
      'entity', options) as Observable<ODataEntity<AgencyReportingCategoryEdm>>;
  }
  public setAgencyReportingCategoryEdmAsAgencyReportingCategory(key: EntityKey<ReportingLabelEdm>, target: ODataEntityResource<ODataEntity<AgencyReportingCategoryEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyReportingCategory(key).reference()
      .set(target, {etag});
  }
  public unsetAgencyReportingCategoryEdmAsAgencyReportingCategory(key: EntityKey<ReportingLabelEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AgencyReportingCategoryEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyReportingCategory(key).reference()
      .unset({etag});
  }
  public childGuidAgencyReportingCombinations(key: EntityKey<ReportingLabelEdm>): ODataNavigationPropertyResource<AgencyReportingCombinationEdm> { 
    return this.entity(key).navigationProperty<AgencyReportingCombinationEdm>('childGuidAgencyReportingCombinations'); 
  }
  public fetchChildGuidAgencyReportingCombinations(key: EntityKey<ReportingLabelEdm>, options?: ODataQueryArgumentsOptions<AgencyReportingCombinationEdm>) {
    return this.fetchNavigationProperty<AgencyReportingCombinationEdm>(
      this.childGuidAgencyReportingCombinations(key), 
      'entities', options) as Observable<ODataEntities<AgencyReportingCombinationEdm>>;
  }
  public addAgencyReportingCombinationEdmToChildGuidAgencyReportingCombinations(key: EntityKey<ReportingLabelEdm>, target: ODataEntityResource<ODataEntities<AgencyReportingCombinationEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.childGuidAgencyReportingCombinations(key).reference()
      .add(target);
  }
  public removeAgencyReportingCombinationEdmFromChildGuidAgencyReportingCombinations(key: EntityKey<ReportingLabelEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyReportingCombinationEdm>>, etag?: string} = {}): Observable<any> {
    return this.childGuidAgencyReportingCombinations(key).reference()
      .remove(target);
  }
  public contractReportingLabels(key: EntityKey<ReportingLabelEdm>): ODataNavigationPropertyResource<ContractReportingLabelEdm> { 
    return this.entity(key).navigationProperty<ContractReportingLabelEdm>('contractReportingLabels'); 
  }
  public fetchContractReportingLabels(key: EntityKey<ReportingLabelEdm>, options?: ODataQueryArgumentsOptions<ContractReportingLabelEdm>) {
    return this.fetchNavigationProperty<ContractReportingLabelEdm>(
      this.contractReportingLabels(key), 
      'entities', options) as Observable<ODataEntities<ContractReportingLabelEdm>>;
  }
  public addContractReportingLabelEdmToContractReportingLabels(key: EntityKey<ReportingLabelEdm>, target: ODataEntityResource<ODataEntities<ContractReportingLabelEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.contractReportingLabels(key).reference()
      .add(target);
  }
  public removeContractReportingLabelEdmFromContractReportingLabels(key: EntityKey<ReportingLabelEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ContractReportingLabelEdm>>, etag?: string} = {}): Observable<any> {
    return this.contractReportingLabels(key).reference()
      .remove(target);
  }
  public parentGuidAgencyReportingCombinations(key: EntityKey<ReportingLabelEdm>): ODataNavigationPropertyResource<AgencyReportingCombinationEdm> { 
    return this.entity(key).navigationProperty<AgencyReportingCombinationEdm>('parentGuidAgencyReportingCombinations'); 
  }
  public fetchParentGuidAgencyReportingCombinations(key: EntityKey<ReportingLabelEdm>, options?: ODataQueryArgumentsOptions<AgencyReportingCombinationEdm>) {
    return this.fetchNavigationProperty<AgencyReportingCombinationEdm>(
      this.parentGuidAgencyReportingCombinations(key), 
      'entities', options) as Observable<ODataEntities<AgencyReportingCombinationEdm>>;
  }
  public addAgencyReportingCombinationEdmToParentGuidAgencyReportingCombinations(key: EntityKey<ReportingLabelEdm>, target: ODataEntityResource<ODataEntities<AgencyReportingCombinationEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.parentGuidAgencyReportingCombinations(key).reference()
      .add(target);
  }
  public removeAgencyReportingCombinationEdmFromParentGuidAgencyReportingCombinations(key: EntityKey<ReportingLabelEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyReportingCombinationEdm>>, etag?: string} = {}): Observable<any> {
    return this.parentGuidAgencyReportingCombinations(key).reference()
      .remove(target);
  }
  public parentReportingLabel(key: EntityKey<ReportingLabelEdm>): ODataNavigationPropertyResource<ReportingLabelEdm> { 
    return this.entity(key).navigationProperty<ReportingLabelEdm>('parentReportingLabel'); 
  }
  public fetchParentReportingLabel(key: EntityKey<ReportingLabelEdm>, options?: ODataQueryArgumentsOptions<ReportingLabelEdm>) {
    return this.fetchNavigationProperty<ReportingLabelEdm>(
      this.parentReportingLabel(key), 
      'entity', options) as Observable<ODataEntity<ReportingLabelEdm>>;
  }
  public setReportingLabelEdmAsParentReportingLabel(key: EntityKey<ReportingLabelEdm>, target: ODataEntityResource<ODataEntity<ReportingLabelEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.parentReportingLabel(key).reference()
      .set(target, {etag});
  }
  public unsetReportingLabelEdmAsParentReportingLabel(key: EntityKey<ReportingLabelEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<ReportingLabelEdm>>, etag?: string} = {}): Observable<any> {
    return this.parentReportingLabel(key).reference()
      .unset({etag});
  }
  public parentReportingLabels(key: EntityKey<ReportingLabelEdm>): ODataNavigationPropertyResource<ReportingLabelEdm> { 
    return this.entity(key).navigationProperty<ReportingLabelEdm>('parentReportingLabels'); 
  }
  public fetchParentReportingLabels(key: EntityKey<ReportingLabelEdm>, options?: ODataQueryArgumentsOptions<ReportingLabelEdm>) {
    return this.fetchNavigationProperty<ReportingLabelEdm>(
      this.parentReportingLabels(key), 
      'entities', options) as Observable<ODataEntities<ReportingLabelEdm>>;
  }
  public addReportingLabelEdmToParentReportingLabels(key: EntityKey<ReportingLabelEdm>, target: ODataEntityResource<ODataEntities<ReportingLabelEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.parentReportingLabels(key).reference()
      .add(target);
  }
  public removeReportingLabelEdmFromParentReportingLabels(key: EntityKey<ReportingLabelEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ReportingLabelEdm>>, etag?: string} = {}): Observable<any> {
    return this.parentReportingLabels(key).reference()
      .remove(target);
  }
  public workerTypeReportingLabels(key: EntityKey<ReportingLabelEdm>): ODataNavigationPropertyResource<WorkerTypeReportingLabelEdm> { 
    return this.entity(key).navigationProperty<WorkerTypeReportingLabelEdm>('workerTypeReportingLabels'); 
  }
  public fetchWorkerTypeReportingLabels(key: EntityKey<ReportingLabelEdm>, options?: ODataQueryArgumentsOptions<WorkerTypeReportingLabelEdm>) {
    return this.fetchNavigationProperty<WorkerTypeReportingLabelEdm>(
      this.workerTypeReportingLabels(key), 
      'entities', options) as Observable<ODataEntities<WorkerTypeReportingLabelEdm>>;
  }
  public addWorkerTypeReportingLabelEdmToWorkerTypeReportingLabels(key: EntityKey<ReportingLabelEdm>, target: ODataEntityResource<ODataEntities<WorkerTypeReportingLabelEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerTypeReportingLabels(key).reference()
      .add(target);
  }
  public removeWorkerTypeReportingLabelEdmFromWorkerTypeReportingLabels(key: EntityKey<ReportingLabelEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerTypeReportingLabelEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerTypeReportingLabels(key).reference()
      .remove(target);
  }
  //#endregion
}
