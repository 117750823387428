//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContractEdm } from './contractedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ContractEdmEntityConfig = {
  name: 'ContractEdm',
  keys: [{name: 'contractGuid'}],
  fields: {
    contractGuid: {type: 'Edm.Guid', nullable: false},
    name: {type: 'Edm.String'},
    description: {type: 'Edm.String'},
    fromDate: {type: 'Edm.DateTimeOffset'},
    toDate: {type: 'Edm.DateTimeOffset'},
    estimatedCreditNeeded: {type: 'Edm.Decimal', scale: 'variable'},
    status: {type: 'Edm.Int16', nullable: false},
    subStatus: {type: 'Edm.Int16'},
    accountGuid: {type: 'Edm.Guid'},
    transferToPayrollDate: {type: 'Edm.DateTimeOffset'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    useContactAsInvoice: {type: 'Edm.Boolean'},
    isPoRequired: {type: 'Edm.Boolean'},
    payrollFrequency: {type: 'Edm.Int32'},
    invoicePreference: {type: 'Edm.Int32'},
    tradingStylesGuid: {type: 'Edm.Guid'},
    account: {type: 'OptiaApi.DataDomain.EFEntities.AccountEdm', navigation: true, referentials: [{property: 'accountGuid', referencedProperty: 'accountGuid'}]},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    tradingStyle: {type: 'OptiaApi.DataDomain.EFEntities.Agency.TradingStyleEdm', navigation: true, referentials: [{property: 'tradingStylesGuid', referencedProperty: 'tradingStylesGuid'}]},
    assignments: {type: 'OptiaApi.DataDomain.EFEntities.AssignmentEdm', collection: true, navigation: true},
    contractPersons: {type: 'OptiaApi.DataDomain.EFEntities.ContractPersonEdm', collection: true, navigation: true},
    contractReportingLabels: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.ContractReportingLabelEdm', collection: true, navigation: true},
    purchaseOrders: {type: 'OptiaApi.DataDomain.EFEntities.PurchaseOrderEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<ContractEdm>;
//#endregion