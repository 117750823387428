//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyEdm } from './agencyedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AgencyEdmEntityConfig = {
  name: 'AgencyEdm',
  keys: [{name: 'agencyGuid'}],
  fields: {
    agencyGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String'},
    exactLegalName: {type: 'Edm.String'},
    shortCode: {type: 'Edm.String'},
    status: {type: 'Edm.Int16', nullable: false},
    sector: {type: 'Edm.Int16'},
    knownAsName: {type: 'Edm.String'},
    tradingAs: {type: 'Edm.String'},
    companiesHouseDetailsGuid: {type: 'Edm.Guid'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    rowVersion: {type: 'Edm.Binary', nullable: false},
    optia: {type: 'Edm.Boolean'},
    payroll: {type: 'Edm.Boolean'},
    alert: {type: 'Edm.Boolean'},
    miFolderPath: {type: 'Edm.String'},
    miGrossMarginName: {type: 'Edm.String'},
    miSalesInvoiceTab: {type: 'Edm.String'},
    miExcludeFromUpload: {type: 'Edm.Boolean'},
    vatRegistrationNumber: {type: 'Edm.String'},
    nextVatPeriodEndDate: {type: 'Edm.DateTimeOffset'},
    payrollFrequency: {type: 'Edm.Int32'},
    readyToTrade: {type: 'Edm.Boolean'},
    warningMarker: {type: 'Edm.Boolean'},
    companiesHouseDetail: {type: 'OptiaApi.DataDomain.EFEntities.CompaniesHouseDetailEdm', navigation: true, referentials: [{property: 'companiesHouseDetailsGuid', referencedProperty: 'companiesHouseDetailsGuid'}]},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    agencyAddresses: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyAddressEdm', collection: true, navigation: true},
    agencyBankDetails: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyBankDetailEdm', collection: true, navigation: true},
    agencyDocuments: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyDocumentEdm', collection: true, navigation: true},
    agencyMtdCalendars: {type: 'OptiaApi.DataDomain.EFEntities.AgencyMtdCalendarEdm', collection: true, navigation: true},
    agencyPersons: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyPersonEdm', collection: true, navigation: true},
    agencyPersonWfRoles: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyPersonWfRoleEdm', collection: true, navigation: true},
    agencyReportingCategories: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyReportingCategoryEdm', collection: true, navigation: true},
    agencyReportingCombinations: {type: 'OptiaApi.DataDomain.EFEntities.AgencyReportingCombinationEdm', collection: true, navigation: true},
    agencyWorkers: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyWorkerEdm', collection: true, navigation: true},
    changeSets: {type: 'OptiaApi.DataDomain.EFEntities.Admin.ChangeSetEdm', collection: true, navigation: true},
    customerAgencies: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyEdm', collection: true, navigation: true},
    supplierAgencies: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierAgencyEdm', collection: true, navigation: true},
    supplierDocuments: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierDocumentEdm', collection: true, navigation: true},
    tradingStyles: {type: 'OptiaApi.DataDomain.EFEntities.Agency.TradingStyleEdm', collection: true, navigation: true},
    transferAgencies: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferAgencyEdm', collection: true, navigation: true},
    transferLogs: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferLogEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<AgencyEdm>;
//#endregion