import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OptiaNewEdm } from './DataDomain/EFEntities/Notify/optianewedm.entity';
import { OptiaNewsDocumentEdm } from './DataDomain/EFEntities/Notify/optianewsdocumentedm.entity';
import { OptiaNewsPersonEdm } from './DataDomain/EFEntities/Notify/optianewspersonedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class OptiaNewsService extends ODataEntitySetService<OptiaNewEdm> {
  constructor(client: ODataClient) {
    super(client, 'OptiaNews', 'OptiaApi.DataDomain.EFEntities.Notify.OptiaNewEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public createdByPerson(key: EntityKey<OptiaNewEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<OptiaNewEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<OptiaNewEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<OptiaNewEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public expiredByPerson(key: EntityKey<OptiaNewEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('expiredByPerson'); 
  }
  public fetchExpiredByPerson(key: EntityKey<OptiaNewEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.expiredByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsExpiredByPerson(key: EntityKey<OptiaNewEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.expiredByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsExpiredByPerson(key: EntityKey<OptiaNewEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.expiredByPerson(key).reference()
      .unset({etag});
  }
  public lastEditedByPerson(key: EntityKey<OptiaNewEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('lastEditedByPerson'); 
  }
  public fetchLastEditedByPerson(key: EntityKey<OptiaNewEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.lastEditedByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsLastEditedByPerson(key: EntityKey<OptiaNewEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.lastEditedByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsLastEditedByPerson(key: EntityKey<OptiaNewEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.lastEditedByPerson(key).reference()
      .unset({etag});
  }
  public optiaNewsDocuments(key: EntityKey<OptiaNewEdm>): ODataNavigationPropertyResource<OptiaNewsDocumentEdm> { 
    return this.entity(key).navigationProperty<OptiaNewsDocumentEdm>('optiaNewsDocuments'); 
  }
  public fetchOptiaNewsDocuments(key: EntityKey<OptiaNewEdm>, options?: ODataQueryArgumentsOptions<OptiaNewsDocumentEdm>) {
    return this.fetchNavigationProperty<OptiaNewsDocumentEdm>(
      this.optiaNewsDocuments(key), 
      'entities', options) as Observable<ODataEntities<OptiaNewsDocumentEdm>>;
  }
  public addOptiaNewsDocumentEdmToOptiaNewsDocuments(key: EntityKey<OptiaNewEdm>, target: ODataEntityResource<ODataEntities<OptiaNewsDocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.optiaNewsDocuments(key).reference()
      .add(target);
  }
  public removeOptiaNewsDocumentEdmFromOptiaNewsDocuments(key: EntityKey<OptiaNewEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<OptiaNewsDocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.optiaNewsDocuments(key).reference()
      .remove(target);
  }
  public optiaNewsPersons(key: EntityKey<OptiaNewEdm>): ODataNavigationPropertyResource<OptiaNewsPersonEdm> { 
    return this.entity(key).navigationProperty<OptiaNewsPersonEdm>('optiaNewsPersons'); 
  }
  public fetchOptiaNewsPersons(key: EntityKey<OptiaNewEdm>, options?: ODataQueryArgumentsOptions<OptiaNewsPersonEdm>) {
    return this.fetchNavigationProperty<OptiaNewsPersonEdm>(
      this.optiaNewsPersons(key), 
      'entities', options) as Observable<ODataEntities<OptiaNewsPersonEdm>>;
  }
  public addOptiaNewsPersonEdmToOptiaNewsPersons(key: EntityKey<OptiaNewEdm>, target: ODataEntityResource<ODataEntities<OptiaNewsPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.optiaNewsPersons(key).reference()
      .add(target);
  }
  public removeOptiaNewsPersonEdmFromOptiaNewsPersons(key: EntityKey<OptiaNewEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<OptiaNewsPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.optiaNewsPersons(key).reference()
      .remove(target);
  }
  //#endregion
}
