import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CompaniesHouseDetailEdm } from './DataDomain/EFEntities/companieshousedetailedm.entity';
import { CompaniesHouseDetailsAddressEdm } from './DataDomain/EFEntities/companieshousedetailsaddressedm.entity';
import { CompaniesHouseDetailsPersonEdm } from './DataDomain/EFEntities/companieshousedetailspersonedm.entity';
import { AgencyEdm } from './DataDomain/EFEntities/Agency/agencyedm.entity';
import { CustomerEdm } from './DataDomain/EFEntities/Customer/customeredm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class CompaniesHouseDetailsService extends ODataEntitySetService<CompaniesHouseDetailEdm> {
  constructor(client: ODataClient) {
    super(client, 'CompaniesHouseDetails', 'OptiaApi.DataDomain.EFEntities.CompaniesHouseDetailEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public agencies(key: EntityKey<CompaniesHouseDetailEdm>): ODataNavigationPropertyResource<AgencyEdm> { 
    return this.entity(key).navigationProperty<AgencyEdm>('agencies'); 
  }
  public fetchAgencies(key: EntityKey<CompaniesHouseDetailEdm>, options?: ODataQueryArgumentsOptions<AgencyEdm>) {
    return this.fetchNavigationProperty<AgencyEdm>(
      this.agencies(key), 
      'entities', options) as Observable<ODataEntities<AgencyEdm>>;
  }
  public addAgencyEdmToAgencies(key: EntityKey<CompaniesHouseDetailEdm>, target: ODataEntityResource<ODataEntities<AgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencies(key).reference()
      .add(target);
  }
  public removeAgencyEdmFromAgencies(key: EntityKey<CompaniesHouseDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencies(key).reference()
      .remove(target);
  }
  public companiesHouseDetailsAddresses(key: EntityKey<CompaniesHouseDetailEdm>): ODataNavigationPropertyResource<CompaniesHouseDetailsAddressEdm> { 
    return this.entity(key).navigationProperty<CompaniesHouseDetailsAddressEdm>('companiesHouseDetailsAddresses'); 
  }
  public fetchCompaniesHouseDetailsAddresses(key: EntityKey<CompaniesHouseDetailEdm>, options?: ODataQueryArgumentsOptions<CompaniesHouseDetailsAddressEdm>) {
    return this.fetchNavigationProperty<CompaniesHouseDetailsAddressEdm>(
      this.companiesHouseDetailsAddresses(key), 
      'entities', options) as Observable<ODataEntities<CompaniesHouseDetailsAddressEdm>>;
  }
  public addCompaniesHouseDetailsAddressEdmToCompaniesHouseDetailsAddresses(key: EntityKey<CompaniesHouseDetailEdm>, target: ODataEntityResource<ODataEntities<CompaniesHouseDetailsAddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.companiesHouseDetailsAddresses(key).reference()
      .add(target);
  }
  public removeCompaniesHouseDetailsAddressEdmFromCompaniesHouseDetailsAddresses(key: EntityKey<CompaniesHouseDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CompaniesHouseDetailsAddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.companiesHouseDetailsAddresses(key).reference()
      .remove(target);
  }
  public companiesHouseDetailsPersons(key: EntityKey<CompaniesHouseDetailEdm>): ODataNavigationPropertyResource<CompaniesHouseDetailsPersonEdm> { 
    return this.entity(key).navigationProperty<CompaniesHouseDetailsPersonEdm>('companiesHouseDetailsPersons'); 
  }
  public fetchCompaniesHouseDetailsPersons(key: EntityKey<CompaniesHouseDetailEdm>, options?: ODataQueryArgumentsOptions<CompaniesHouseDetailsPersonEdm>) {
    return this.fetchNavigationProperty<CompaniesHouseDetailsPersonEdm>(
      this.companiesHouseDetailsPersons(key), 
      'entities', options) as Observable<ODataEntities<CompaniesHouseDetailsPersonEdm>>;
  }
  public addCompaniesHouseDetailsPersonEdmToCompaniesHouseDetailsPersons(key: EntityKey<CompaniesHouseDetailEdm>, target: ODataEntityResource<ODataEntities<CompaniesHouseDetailsPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.companiesHouseDetailsPersons(key).reference()
      .add(target);
  }
  public removeCompaniesHouseDetailsPersonEdmFromCompaniesHouseDetailsPersons(key: EntityKey<CompaniesHouseDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CompaniesHouseDetailsPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.companiesHouseDetailsPersons(key).reference()
      .remove(target);
  }
  public createdByPerson(key: EntityKey<CompaniesHouseDetailEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<CompaniesHouseDetailEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<CompaniesHouseDetailEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<CompaniesHouseDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public customers(key: EntityKey<CompaniesHouseDetailEdm>): ODataNavigationPropertyResource<CustomerEdm> { 
    return this.entity(key).navigationProperty<CustomerEdm>('customers'); 
  }
  public fetchCustomers(key: EntityKey<CompaniesHouseDetailEdm>, options?: ODataQueryArgumentsOptions<CustomerEdm>) {
    return this.fetchNavigationProperty<CustomerEdm>(
      this.customers(key), 
      'entities', options) as Observable<ODataEntities<CustomerEdm>>;
  }
  public addCustomerEdmToCustomers(key: EntityKey<CompaniesHouseDetailEdm>, target: ODataEntityResource<ODataEntities<CustomerEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customers(key).reference()
      .add(target);
  }
  public removeCustomerEdmFromCustomers(key: EntityKey<CompaniesHouseDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CustomerEdm>>, etag?: string} = {}): Observable<any> {
    return this.customers(key).reference()
      .remove(target);
  }
  //#endregion
}
