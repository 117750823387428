//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerAddressesService } from './customeraddresses.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CustomerAddressesServiceEntitySetConfig = {
  name: 'CustomerAddresses',
  entityType: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAddressEdm',
  service: CustomerAddressesService
} as EntitySetConfig;
//#endregion