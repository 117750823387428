//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonUserGroupEdm } from './personusergroupedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PersonUserGroupEdmEntityConfig = {
  name: 'PersonUserGroupEdm',
  keys: [{name: 'personGuid'},{name: 'userGroupGuid'}],
  fields: {
    personGuid: {type: 'Edm.Guid'},
    userGroupGuid: {type: 'Edm.Guid'},
    person: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]},
    userGroup: {type: 'OptiaApi.DataDomain.EFEntities.Admin.UserGroupEdm', navigation: true, referentials: [{property: 'userGroupGuid', referencedProperty: 'userGroupGuid'}]}
  }
} as StructuredTypeConfig<PersonUserGroupEdm>;
//#endregion