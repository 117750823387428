import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DocumentEdm } from './DataDomain/EFEntities/documentedm.entity';
import { WorkerTypeEdm } from './DataDomain/EFEntities/Worker/workertypeedm.entity';
import { WorkerTypeDocumentEdm } from './DataDomain/EFEntities/Worker/workertypedocumentedm.entity';
//#endregion

@Injectable()
export class WorkerTypeDocumentsService extends ODataEntitySetService<WorkerTypeDocumentEdm> {
  constructor(client: ODataClient) {
    super(client, 'WorkerTypeDocuments', 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeDocumentEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public document(key: EntityKey<WorkerTypeDocumentEdm>): ODataNavigationPropertyResource<DocumentEdm> { 
    return this.entity(key).navigationProperty<DocumentEdm>('document'); 
  }
  public fetchDocument(key: EntityKey<WorkerTypeDocumentEdm>, options?: ODataQueryArgumentsOptions<DocumentEdm>) {
    return this.fetchNavigationProperty<DocumentEdm>(
      this.document(key), 
      'entity', options) as Observable<ODataEntity<DocumentEdm>>;
  }
  public setDocumentEdmAsDocument(key: EntityKey<WorkerTypeDocumentEdm>, target: ODataEntityResource<ODataEntity<DocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.document(key).reference()
      .set(target, {etag});
  }
  public unsetDocumentEdmAsDocument(key: EntityKey<WorkerTypeDocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<DocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.document(key).reference()
      .unset({etag});
  }
  public workerType(key: EntityKey<WorkerTypeDocumentEdm>): ODataNavigationPropertyResource<WorkerTypeEdm> { 
    return this.entity(key).navigationProperty<WorkerTypeEdm>('workerType'); 
  }
  public fetchWorkerType(key: EntityKey<WorkerTypeDocumentEdm>, options?: ODataQueryArgumentsOptions<WorkerTypeEdm>) {
    return this.fetchNavigationProperty<WorkerTypeEdm>(
      this.workerType(key), 
      'entity', options) as Observable<ODataEntity<WorkerTypeEdm>>;
  }
  public setWorkerTypeEdmAsWorkerType(key: EntityKey<WorkerTypeDocumentEdm>, target: ODataEntityResource<ODataEntity<WorkerTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerType(key).reference()
      .set(target, {etag});
  }
  public unsetWorkerTypeEdmAsWorkerType(key: EntityKey<WorkerTypeDocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WorkerTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerType(key).reference()
      .unset({etag});
  }
  //#endregion
}
