//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CreditAssignmentEdm } from './creditassignmentedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CreditAssignmentEdmEntityConfig = {
  name: 'CreditAssignmentEdm',
  keys: [{name: 'creditAssignmentGuid'}],
  fields: {
    creditAssignmentGuid: {type: 'Edm.Guid', nullable: false},
    customerAgencyGuid: {type: 'Edm.Guid'},
    creditLimitGuid: {type: 'Edm.Guid'},
    description: {type: 'Edm.String'},
    amount: {type: 'Edm.Decimal', scale: 'variable'},
    fromDate: {type: 'Edm.DateTimeOffset'},
    toDate: {type: 'Edm.DateTimeOffset'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    creditLimit: {type: 'OptiaApi.DataDomain.EFEntities.Credit.CreditLimitEdm', navigation: true, referentials: [{property: 'creditLimitGuid', referencedProperty: 'creditLimitGuid'}]},
    customerAgency: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyEdm', navigation: true, referentials: [{property: 'customerAgencyGuid', referencedProperty: 'customerAgencyGuid'}]}
  }
} as StructuredTypeConfig<CreditAssignmentEdm>;
//#endregion