//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerAddressEdm } from './customeraddressedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CustomerAddressEdmEntityConfig = {
  name: 'CustomerAddressEdm',
  keys: [{name: 'addressGuid'},{name: 'customerGuid'},{name: 'fromDate'}],
  fields: {
    customerGuid: {type: 'Edm.Guid'},
    addressGuid: {type: 'Edm.Guid'},
    addressType: {type: 'Edm.Int16'},
    manuallyEntered: {type: 'Edm.Boolean'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    address: {type: 'OptiaApi.DataDomain.EFEntities.AddressEdm', navigation: true, referentials: [{property: 'addressGuid', referencedProperty: 'addressGuid'}]},
    customer: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerEdm', navigation: true, referentials: [{property: 'customerGuid', referencedProperty: 'customerGuid'}]}
  }
} as StructuredTypeConfig<CustomerAddressEdm>;
//#endregion