import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TransferEdm } from './DataDomain/EFEntities/Reporting/transferedm.entity';
import { TransferAgencyEdm } from './DataDomain/EFEntities/Reporting/transferagencyedm.entity';
import { TransferMiReportTypeEdm } from './DataDomain/EFEntities/Reporting/transfermireporttypeedm.entity';
import { TransferLogEdm } from './DataDomain/EFEntities/Reporting/transferlogedm.entity';
import { TransferRevisionEdm } from './DataDomain/EFEntities/Reporting/transferrevisionedm.entity';
//#endregion

@Injectable()
export class TransfersService extends ODataEntitySetService<TransferEdm> {
  constructor(client: ODataClient) {
    super(client, 'Transfers', 'OptiaApi.DataDomain.EFEntities.Reporting.TransferEdm');
  }
  //#region ODataApiGen Actions
  public sendUploadNotification(key: EntityKey<TransferEdm>): ODataActionResource<null, boolean> { 
    return this.entity(key).action<null, boolean>('OptiaApi.SendUploadNotification');
  }
  public callSendUploadNotification(key: EntityKey<TransferEdm>, options?: ODataOptions & {alias?: boolean}) {
    return this.callAction<null, boolean>(
      null, 
      this.sendUploadNotification(key), 
      'property', options) as Observable<ODataProperty<boolean>>;
  }
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public transferAgencies(key: EntityKey<TransferEdm>): ODataNavigationPropertyResource<TransferAgencyEdm> { 
    return this.entity(key).navigationProperty<TransferAgencyEdm>('transferAgencies'); 
  }
  public fetchTransferAgencies(key: EntityKey<TransferEdm>, options?: ODataQueryArgumentsOptions<TransferAgencyEdm>) {
    return this.fetchNavigationProperty<TransferAgencyEdm>(
      this.transferAgencies(key), 
      'entities', options) as Observable<ODataEntities<TransferAgencyEdm>>;
  }
  public addTransferAgencyEdmToTransferAgencies(key: EntityKey<TransferEdm>, target: ODataEntityResource<ODataEntities<TransferAgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.transferAgencies(key).reference()
      .add(target);
  }
  public removeTransferAgencyEdmFromTransferAgencies(key: EntityKey<TransferEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<TransferAgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.transferAgencies(key).reference()
      .remove(target);
  }
  public transferLogs(key: EntityKey<TransferEdm>): ODataNavigationPropertyResource<TransferLogEdm> { 
    return this.entity(key).navigationProperty<TransferLogEdm>('transferLogs'); 
  }
  public fetchTransferLogs(key: EntityKey<TransferEdm>, options?: ODataQueryArgumentsOptions<TransferLogEdm>) {
    return this.fetchNavigationProperty<TransferLogEdm>(
      this.transferLogs(key), 
      'entities', options) as Observable<ODataEntities<TransferLogEdm>>;
  }
  public addTransferLogEdmToTransferLogs(key: EntityKey<TransferEdm>, target: ODataEntityResource<ODataEntities<TransferLogEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.transferLogs(key).reference()
      .add(target);
  }
  public removeTransferLogEdmFromTransferLogs(key: EntityKey<TransferEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<TransferLogEdm>>, etag?: string} = {}): Observable<any> {
    return this.transferLogs(key).reference()
      .remove(target);
  }
  public transferMiReportTypes(key: EntityKey<TransferEdm>): ODataNavigationPropertyResource<TransferMiReportTypeEdm> { 
    return this.entity(key).navigationProperty<TransferMiReportTypeEdm>('transferMiReportTypes'); 
  }
  public fetchTransferMiReportTypes(key: EntityKey<TransferEdm>, options?: ODataQueryArgumentsOptions<TransferMiReportTypeEdm>) {
    return this.fetchNavigationProperty<TransferMiReportTypeEdm>(
      this.transferMiReportTypes(key), 
      'entities', options) as Observable<ODataEntities<TransferMiReportTypeEdm>>;
  }
  public addTransferMiReportTypeEdmToTransferMiReportTypes(key: EntityKey<TransferEdm>, target: ODataEntityResource<ODataEntities<TransferMiReportTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.transferMiReportTypes(key).reference()
      .add(target);
  }
  public removeTransferMiReportTypeEdmFromTransferMiReportTypes(key: EntityKey<TransferEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<TransferMiReportTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.transferMiReportTypes(key).reference()
      .remove(target);
  }
  public transferRevisions(key: EntityKey<TransferEdm>): ODataNavigationPropertyResource<TransferRevisionEdm> { 
    return this.entity(key).navigationProperty<TransferRevisionEdm>('transferRevisions'); 
  }
  public fetchTransferRevisions(key: EntityKey<TransferEdm>, options?: ODataQueryArgumentsOptions<TransferRevisionEdm>) {
    return this.fetchNavigationProperty<TransferRevisionEdm>(
      this.transferRevisions(key), 
      'entities', options) as Observable<ODataEntities<TransferRevisionEdm>>;
  }
  public addTransferRevisionEdmToTransferRevisions(key: EntityKey<TransferEdm>, target: ODataEntityResource<ODataEntities<TransferRevisionEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.transferRevisions(key).reference()
      .add(target);
  }
  public removeTransferRevisionEdmFromTransferRevisions(key: EntityKey<TransferEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<TransferRevisionEdm>>, etag?: string} = {}): Observable<any> {
    return this.transferRevisions(key).reference()
      .remove(target);
  }
  //#endregion
}
