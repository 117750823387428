import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfProcessEdm } from './DataDomain/EFEntities/Workflow/wfprocessedm.entity';
import { WfProcessWfScheduleEdm } from './DataDomain/EFEntities/Workflow/wfprocesswfscheduleedm.entity';
import { WfScheduleEdm } from './DataDomain/EFEntities/Workflow/wfscheduleedm.entity';
//#endregion

@Injectable()
export class WfProcessWfSchedulesService extends ODataEntitySetService<WfProcessWfScheduleEdm> {
  constructor(client: ODataClient) {
    super(client, 'WfProcessWfSchedules', 'OptiaApi.DataDomain.EFEntities.Workflow.WfProcessWfScheduleEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public wfProcess(key: EntityKey<WfProcessWfScheduleEdm>): ODataNavigationPropertyResource<WfProcessEdm> { 
    return this.entity(key).navigationProperty<WfProcessEdm>('wfProcess'); 
  }
  public fetchWfProcess(key: EntityKey<WfProcessWfScheduleEdm>, options?: ODataQueryArgumentsOptions<WfProcessEdm>) {
    return this.fetchNavigationProperty<WfProcessEdm>(
      this.wfProcess(key), 
      'entity', options) as Observable<ODataEntity<WfProcessEdm>>;
  }
  public setWfProcessEdmAsWfProcess(key: EntityKey<WfProcessWfScheduleEdm>, target: ODataEntityResource<ODataEntity<WfProcessEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfProcess(key).reference()
      .set(target, {etag});
  }
  public unsetWfProcessEdmAsWfProcess(key: EntityKey<WfProcessWfScheduleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WfProcessEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfProcess(key).reference()
      .unset({etag});
  }
  public wfSchedule(key: EntityKey<WfProcessWfScheduleEdm>): ODataNavigationPropertyResource<WfScheduleEdm> { 
    return this.entity(key).navigationProperty<WfScheduleEdm>('wfSchedule'); 
  }
  public fetchWfSchedule(key: EntityKey<WfProcessWfScheduleEdm>, options?: ODataQueryArgumentsOptions<WfScheduleEdm>) {
    return this.fetchNavigationProperty<WfScheduleEdm>(
      this.wfSchedule(key), 
      'entity', options) as Observable<ODataEntity<WfScheduleEdm>>;
  }
  public setWfScheduleEdmAsWfSchedule(key: EntityKey<WfProcessWfScheduleEdm>, target: ODataEntityResource<ODataEntity<WfScheduleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfSchedule(key).reference()
      .set(target, {etag});
  }
  public unsetWfScheduleEdmAsWfSchedule(key: EntityKey<WfProcessWfScheduleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WfScheduleEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfSchedule(key).reference()
      .unset({etag});
  }
  //#endregion
}
