import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CompaniesHouseDetailEdm } from './DataDomain/EFEntities/companieshousedetailedm.entity';
import { CompaniesHouseDetailsPersonEdm } from './DataDomain/EFEntities/companieshousedetailspersonedm.entity';
//#endregion

@Injectable()
export class CompaniesHouseDetailsPersonsService extends ODataEntitySetService<CompaniesHouseDetailsPersonEdm> {
  constructor(client: ODataClient) {
    super(client, 'CompaniesHouseDetailsPersons', 'OptiaApi.DataDomain.EFEntities.CompaniesHouseDetailsPersonEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public companiesHouseDetail(key: EntityKey<CompaniesHouseDetailsPersonEdm>): ODataNavigationPropertyResource<CompaniesHouseDetailEdm> { 
    return this.entity(key).navigationProperty<CompaniesHouseDetailEdm>('companiesHouseDetail'); 
  }
  public fetchCompaniesHouseDetail(key: EntityKey<CompaniesHouseDetailsPersonEdm>, options?: ODataQueryArgumentsOptions<CompaniesHouseDetailEdm>) {
    return this.fetchNavigationProperty<CompaniesHouseDetailEdm>(
      this.companiesHouseDetail(key), 
      'entity', options) as Observable<ODataEntity<CompaniesHouseDetailEdm>>;
  }
  public setCompaniesHouseDetailEdmAsCompaniesHouseDetail(key: EntityKey<CompaniesHouseDetailsPersonEdm>, target: ODataEntityResource<ODataEntity<CompaniesHouseDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.companiesHouseDetail(key).reference()
      .set(target, {etag});
  }
  public unsetCompaniesHouseDetailEdmAsCompaniesHouseDetail(key: EntityKey<CompaniesHouseDetailsPersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<CompaniesHouseDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.companiesHouseDetail(key).reference()
      .unset({etag});
  }
  //#endregion
}
