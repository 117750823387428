import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfOutcomeEdm } from './DataDomain/EFEntities/Workflow/wfoutcomeedm.entity';
import { WfPreRequisiteEdm } from './DataDomain/EFEntities/Workflow/wfprerequisiteedm.entity';
import { WfRuleEdm } from './DataDomain/EFEntities/Workflow/wfruleedm.entity';
//#endregion

@Injectable()
export class WfPreRequisitesService extends ODataEntitySetService<WfPreRequisiteEdm> {
  constructor(client: ODataClient) {
    super(client, 'WfPreRequisites', 'OptiaApi.DataDomain.EFEntities.Workflow.WfPreRequisiteEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public wfOutcome(key: EntityKey<WfPreRequisiteEdm>): ODataNavigationPropertyResource<WfOutcomeEdm> { 
    return this.entity(key).navigationProperty<WfOutcomeEdm>('wfOutcome'); 
  }
  public fetchWfOutcome(key: EntityKey<WfPreRequisiteEdm>, options?: ODataQueryArgumentsOptions<WfOutcomeEdm>) {
    return this.fetchNavigationProperty<WfOutcomeEdm>(
      this.wfOutcome(key), 
      'entity', options) as Observable<ODataEntity<WfOutcomeEdm>>;
  }
  public setWfOutcomeEdmAsWfOutcome(key: EntityKey<WfPreRequisiteEdm>, target: ODataEntityResource<ODataEntity<WfOutcomeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfOutcome(key).reference()
      .set(target, {etag});
  }
  public unsetWfOutcomeEdmAsWfOutcome(key: EntityKey<WfPreRequisiteEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WfOutcomeEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfOutcome(key).reference()
      .unset({etag});
  }
  public wfRule(key: EntityKey<WfPreRequisiteEdm>): ODataNavigationPropertyResource<WfRuleEdm> { 
    return this.entity(key).navigationProperty<WfRuleEdm>('wfRule'); 
  }
  public fetchWfRule(key: EntityKey<WfPreRequisiteEdm>, options?: ODataQueryArgumentsOptions<WfRuleEdm>) {
    return this.fetchNavigationProperty<WfRuleEdm>(
      this.wfRule(key), 
      'entity', options) as Observable<ODataEntity<WfRuleEdm>>;
  }
  public setWfRuleEdmAsWfRule(key: EntityKey<WfPreRequisiteEdm>, target: ODataEntityResource<ODataEntity<WfRuleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfRule(key).reference()
      .set(target, {etag});
  }
  public unsetWfRuleEdmAsWfRule(key: EntityKey<WfPreRequisiteEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WfRuleEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfRule(key).reference()
      .unset({etag});
  }
  //#endregion
}
