import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AddressEdm } from './DataDomain/EFEntities/addressedm.entity';
import { CustomerEdm } from './DataDomain/EFEntities/Customer/customeredm.entity';
import { CustomerAddressEdm } from './DataDomain/EFEntities/Customer/customeraddressedm.entity';
//#endregion

@Injectable()
export class CustomerAddressesService extends ODataEntitySetService<CustomerAddressEdm> {
  constructor(client: ODataClient) {
    super(client, 'CustomerAddresses', 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAddressEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public address(key: EntityKey<CustomerAddressEdm>): ODataNavigationPropertyResource<AddressEdm> { 
    return this.entity(key).navigationProperty<AddressEdm>('address'); 
  }
  public fetchAddress(key: EntityKey<CustomerAddressEdm>, options?: ODataQueryArgumentsOptions<AddressEdm>) {
    return this.fetchNavigationProperty<AddressEdm>(
      this.address(key), 
      'entity', options) as Observable<ODataEntity<AddressEdm>>;
  }
  public setAddressEdmAsAddress(key: EntityKey<CustomerAddressEdm>, target: ODataEntityResource<ODataEntity<AddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.address(key).reference()
      .set(target, {etag});
  }
  public unsetAddressEdmAsAddress(key: EntityKey<CustomerAddressEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.address(key).reference()
      .unset({etag});
  }
  public customer(key: EntityKey<CustomerAddressEdm>): ODataNavigationPropertyResource<CustomerEdm> { 
    return this.entity(key).navigationProperty<CustomerEdm>('customer'); 
  }
  public fetchCustomer(key: EntityKey<CustomerAddressEdm>, options?: ODataQueryArgumentsOptions<CustomerEdm>) {
    return this.fetchNavigationProperty<CustomerEdm>(
      this.customer(key), 
      'entity', options) as Observable<ODataEntity<CustomerEdm>>;
  }
  public setCustomerEdmAsCustomer(key: EntityKey<CustomerAddressEdm>, target: ODataEntityResource<ODataEntity<CustomerEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customer(key).reference()
      .set(target, {etag});
  }
  public unsetCustomerEdmAsCustomer(key: EntityKey<CustomerAddressEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<CustomerEdm>>, etag?: string} = {}): Observable<any> {
    return this.customer(key).reference()
      .unset({etag});
  }
  //#endregion
}
