//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerTypesService } from './workertypes.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WorkerTypesServiceEntitySetConfig = {
  name: 'WorkerTypes',
  entityType: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeEdm',
  service: WorkerTypesService
} as EntitySetConfig;
//#endregion