//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskContactTypeEdm } from './wftaskcontacttypeedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfTaskContactTypeEdmEntityConfig = {
  name: 'WfTaskContactTypeEdm',
  keys: [{name: 'contactType'},{name: 'wfTaskGuid'}],
  fields: {
    wfTaskGuid: {type: 'Edm.Guid'},
    contactType: {type: 'Edm.Int16', nullable: false},
    roleType: {type: 'Edm.Int32'},
    wfTask: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskEdm', navigation: true, referentials: [{property: 'wfTaskGuid', referencedProperty: 'wfTaskGuid'}]}
  }
} as StructuredTypeConfig<WfTaskContactTypeEdm>;
//#endregion