//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MiReportTypePersonsService } from './mireporttypepersons.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const MiReportTypePersonsServiceEntitySetConfig = {
  name: 'MiReportTypePersons',
  entityType: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportTypePersonEdm',
  service: MiReportTypePersonsService
} as EntitySetConfig;
//#endregion