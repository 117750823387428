//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyDocumentEdm } from './agencydocumentedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AgencyDocumentEdmEntityConfig = {
  name: 'AgencyDocumentEdm',
  keys: [{name: 'agencyGuid'},{name: 'documentGuid'},{name: 'fromDate'}],
  fields: {
    documentGuid: {type: 'Edm.Guid'},
    agencyGuid: {type: 'Edm.Guid'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    agency: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm', navigation: true, referentials: [{property: 'agencyGuid', referencedProperty: 'agencyGuid'}]},
    document: {type: 'OptiaApi.DataDomain.EFEntities.DocumentEdm', navigation: true, referentials: [{property: 'documentGuid', referencedProperty: 'documentGuid'}]}
  }
} as StructuredTypeConfig<AgencyDocumentEdm>;
//#endregion