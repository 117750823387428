//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerAgenciesService } from './customeragencies.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CustomerAgenciesServiceEntitySetConfig = {
  name: 'CustomerAgencies',
  entityType: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyEdm',
  service: CustomerAgenciesService
} as EntitySetConfig;
//#endregion