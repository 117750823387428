import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
import { RefDataItemEdm } from './DataDomain/EFEntities/RefData/refdataitemedm.entity';
import { RefDataTypeEdm } from './DataDomain/EFEntities/RefData/refdatatypeedm.entity';
//#endregion

@Injectable()
export class RefDataTypesService extends ODataEntitySetService<RefDataTypeEdm> {
  constructor(client: ODataClient) {
    super(client, 'RefDataTypes', 'OptiaApi.DataDomain.EFEntities.RefData.RefDataTypeEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public createdByPerson(key: EntityKey<RefDataTypeEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<RefDataTypeEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<RefDataTypeEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<RefDataTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public parentRefDataType(key: EntityKey<RefDataTypeEdm>): ODataNavigationPropertyResource<RefDataTypeEdm> { 
    return this.entity(key).navigationProperty<RefDataTypeEdm>('parentRefDataType'); 
  }
  public fetchParentRefDataType(key: EntityKey<RefDataTypeEdm>, options?: ODataQueryArgumentsOptions<RefDataTypeEdm>) {
    return this.fetchNavigationProperty<RefDataTypeEdm>(
      this.parentRefDataType(key), 
      'entity', options) as Observable<ODataEntity<RefDataTypeEdm>>;
  }
  public setRefDataTypeEdmAsParentRefDataType(key: EntityKey<RefDataTypeEdm>, target: ODataEntityResource<ODataEntity<RefDataTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.parentRefDataType(key).reference()
      .set(target, {etag});
  }
  public unsetRefDataTypeEdmAsParentRefDataType(key: EntityKey<RefDataTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<RefDataTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.parentRefDataType(key).reference()
      .unset({etag});
  }
  public parentRefDataTypes(key: EntityKey<RefDataTypeEdm>): ODataNavigationPropertyResource<RefDataTypeEdm> { 
    return this.entity(key).navigationProperty<RefDataTypeEdm>('parentRefDataTypes'); 
  }
  public fetchParentRefDataTypes(key: EntityKey<RefDataTypeEdm>, options?: ODataQueryArgumentsOptions<RefDataTypeEdm>) {
    return this.fetchNavigationProperty<RefDataTypeEdm>(
      this.parentRefDataTypes(key), 
      'entities', options) as Observable<ODataEntities<RefDataTypeEdm>>;
  }
  public addRefDataTypeEdmToParentRefDataTypes(key: EntityKey<RefDataTypeEdm>, target: ODataEntityResource<ODataEntities<RefDataTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.parentRefDataTypes(key).reference()
      .add(target);
  }
  public removeRefDataTypeEdmFromParentRefDataTypes(key: EntityKey<RefDataTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<RefDataTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.parentRefDataTypes(key).reference()
      .remove(target);
  }
  public refDataItems(key: EntityKey<RefDataTypeEdm>): ODataNavigationPropertyResource<RefDataItemEdm> { 
    return this.entity(key).navigationProperty<RefDataItemEdm>('refDataItems'); 
  }
  public fetchRefDataItems(key: EntityKey<RefDataTypeEdm>, options?: ODataQueryArgumentsOptions<RefDataItemEdm>) {
    return this.fetchNavigationProperty<RefDataItemEdm>(
      this.refDataItems(key), 
      'entities', options) as Observable<ODataEntities<RefDataItemEdm>>;
  }
  public addRefDataItemEdmToRefDataItems(key: EntityKey<RefDataTypeEdm>, target: ODataEntityResource<ODataEntities<RefDataItemEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.refDataItems(key).reference()
      .add(target);
  }
  public removeRefDataItemEdmFromRefDataItems(key: EntityKey<RefDataTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<RefDataItemEdm>>, etag?: string} = {}): Observable<any> {
    return this.refDataItems(key).reference()
      .remove(target);
  }
  //#endregion
}
