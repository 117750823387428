//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerDetailEdm } from './workerdetailedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WorkerDetailEdmComplexConfig = {
  name: 'WorkerDetailEdm',
  fields: {
    workerGuid: {type: 'Edm.Guid', nullable: false},
    workerName: {type: 'Edm.String', nullable: false},
    agencyGuid: {type: 'Edm.Guid', nullable: false},
    agencyName: {type: 'Edm.String'},
    emailAddress: {type: 'Edm.String', nullable: false},
    telephoneNumber: {type: 'Edm.String'},
    status: {type: 'Edm.Int16', nullable: false}
  }
} as StructuredTypeConfig<WorkerDetailEdm>;
//#endregion