//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CharitiesService } from './charities.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CharitiesServiceEntitySetConfig = {
  name: 'Charities',
  entityType: 'OptiaApi.DataDomain.EFEntities.CharityEdm',
  service: CharitiesService
} as EntitySetConfig;
//#endregion