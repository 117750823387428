//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerPersonsService } from './workerpersons.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WorkerPersonsServiceEntitySetConfig = {
  name: 'WorkerPersons',
  entityType: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerPersonEdm',
  service: WorkerPersonsService
} as EntitySetConfig;
//#endregion