//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgenciesService } from './agencies.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AgenciesServiceEntitySetConfig = {
  name: 'Agencies',
  entityType: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm',
  annotations: [
  {
    "term": "Org.OData.Core.V1.OptimisticConcurrency",
    "properties": [
      "rowVersion"
    ]
  }
],
  service: AgenciesService
} as EntitySetConfig;
//#endregion