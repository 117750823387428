//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SupplierBankDetailEdm } from './supplierbankdetailedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const SupplierBankDetailEdmEntityConfig = {
  name: 'SupplierBankDetailEdm',
  keys: [{name: 'bankDetailsGuid'},{name: 'fromDate'},{name: 'supplierGuid'}],
  fields: {
    supplierGuid: {type: 'Edm.Guid'},
    bankDetailsGuid: {type: 'Edm.Guid'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    bankDetail: {type: 'OptiaApi.DataDomain.EFEntities.BankDetailEdm', navigation: true, referentials: [{property: 'bankDetailsGuid', referencedProperty: 'bankDetailsGuid'}]},
    supplier: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierEdm', navigation: true, referentials: [{property: 'supplierGuid', referencedProperty: 'supplierGuid'}]}
  }
} as StructuredTypeConfig<SupplierBankDetailEdm>;
//#endregion