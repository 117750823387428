//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfProcessesService } from './wfprocesses.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WfProcessesServiceEntitySetConfig = {
  name: 'WfProcesses',
  entityType: 'OptiaApi.DataDomain.EFEntities.Workflow.WfProcessEdm',
  service: WfProcessesService
} as EntitySetConfig;
//#endregion