//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonPhoneNumberEdm } from './personphonenumberedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PersonPhoneNumberEdmEntityConfig = {
  name: 'PersonPhoneNumberEdm',
  keys: [{name: 'personPhoneNumberGuid'}],
  fields: {
    personGuid: {type: 'Edm.Guid'},
    phoneNumberGuid: {type: 'Edm.Guid'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    primary: {type: 'Edm.Boolean'},
    personPhoneNumberGuid: {type: 'Edm.Guid', nullable: false},
    person: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]},
    phoneNumber: {type: 'OptiaApi.DataDomain.EFEntities.PhoneNumberEdm', navigation: true, referentials: [{property: 'phoneNumberGuid', referencedProperty: 'phoneNumberGuid'}]}
  }
} as StructuredTypeConfig<PersonPhoneNumberEdm>;
//#endregion