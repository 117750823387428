import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CreditCheckEdm } from './DataDomain/EFEntities/Credit/creditcheckedm.entity';
import { CreditLimitEdm } from './DataDomain/EFEntities/Credit/creditlimitedm.entity';
import { CustomerEdm } from './DataDomain/EFEntities/Customer/customeredm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class CreditChecksService extends ODataEntitySetService<CreditCheckEdm> {
  constructor(client: ODataClient) {
    super(client, 'CreditChecks', 'OptiaApi.DataDomain.EFEntities.Credit.CreditCheckEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public createdByPerson(key: EntityKey<CreditCheckEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<CreditCheckEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<CreditCheckEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<CreditCheckEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public creditLimits(key: EntityKey<CreditCheckEdm>): ODataNavigationPropertyResource<CreditLimitEdm> { 
    return this.entity(key).navigationProperty<CreditLimitEdm>('creditLimits'); 
  }
  public fetchCreditLimits(key: EntityKey<CreditCheckEdm>, options?: ODataQueryArgumentsOptions<CreditLimitEdm>) {
    return this.fetchNavigationProperty<CreditLimitEdm>(
      this.creditLimits(key), 
      'entities', options) as Observable<ODataEntities<CreditLimitEdm>>;
  }
  public addCreditLimitEdmToCreditLimits(key: EntityKey<CreditCheckEdm>, target: ODataEntityResource<ODataEntities<CreditLimitEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.creditLimits(key).reference()
      .add(target);
  }
  public removeCreditLimitEdmFromCreditLimits(key: EntityKey<CreditCheckEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CreditLimitEdm>>, etag?: string} = {}): Observable<any> {
    return this.creditLimits(key).reference()
      .remove(target);
  }
  public customer(key: EntityKey<CreditCheckEdm>): ODataNavigationPropertyResource<CustomerEdm> { 
    return this.entity(key).navigationProperty<CustomerEdm>('customer'); 
  }
  public fetchCustomer(key: EntityKey<CreditCheckEdm>, options?: ODataQueryArgumentsOptions<CustomerEdm>) {
    return this.fetchNavigationProperty<CustomerEdm>(
      this.customer(key), 
      'entity', options) as Observable<ODataEntity<CustomerEdm>>;
  }
  public setCustomerEdmAsCustomer(key: EntityKey<CreditCheckEdm>, target: ODataEntityResource<ODataEntity<CustomerEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customer(key).reference()
      .set(target, {etag});
  }
  public unsetCustomerEdmAsCustomer(key: EntityKey<CreditCheckEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<CustomerEdm>>, etag?: string} = {}): Observable<any> {
    return this.customer(key).reference()
      .unset({etag});
  }
  //#endregion
}
