//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SupplierAddressesService } from './supplieraddresses.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const SupplierAddressesServiceEntitySetConfig = {
  name: 'SupplierAddresses',
  entityType: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierAddressEdm',
  service: SupplierAddressesService
} as EntitySetConfig;
//#endregion