import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { BankDetailEdm } from './DataDomain/EFEntities/bankdetailedm.entity';
import { SupplierEdm } from './DataDomain/EFEntities/Supplier/supplieredm.entity';
import { SupplierBankDetailEdm } from './DataDomain/EFEntities/Supplier/supplierbankdetailedm.entity';
//#endregion

@Injectable()
export class SupplierBankDetailsService extends ODataEntitySetService<SupplierBankDetailEdm> {
  constructor(client: ODataClient) {
    super(client, 'SupplierBankDetails', 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierBankDetailEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public bankDetail(key: EntityKey<SupplierBankDetailEdm>): ODataNavigationPropertyResource<BankDetailEdm> { 
    return this.entity(key).navigationProperty<BankDetailEdm>('bankDetail'); 
  }
  public fetchBankDetail(key: EntityKey<SupplierBankDetailEdm>, options?: ODataQueryArgumentsOptions<BankDetailEdm>) {
    return this.fetchNavigationProperty<BankDetailEdm>(
      this.bankDetail(key), 
      'entity', options) as Observable<ODataEntity<BankDetailEdm>>;
  }
  public setBankDetailEdmAsBankDetail(key: EntityKey<SupplierBankDetailEdm>, target: ODataEntityResource<ODataEntity<BankDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.bankDetail(key).reference()
      .set(target, {etag});
  }
  public unsetBankDetailEdmAsBankDetail(key: EntityKey<SupplierBankDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<BankDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.bankDetail(key).reference()
      .unset({etag});
  }
  public supplier(key: EntityKey<SupplierBankDetailEdm>): ODataNavigationPropertyResource<SupplierEdm> { 
    return this.entity(key).navigationProperty<SupplierEdm>('supplier'); 
  }
  public fetchSupplier(key: EntityKey<SupplierBankDetailEdm>, options?: ODataQueryArgumentsOptions<SupplierEdm>) {
    return this.fetchNavigationProperty<SupplierEdm>(
      this.supplier(key), 
      'entity', options) as Observable<ODataEntity<SupplierEdm>>;
  }
  public setSupplierEdmAsSupplier(key: EntityKey<SupplierBankDetailEdm>, target: ODataEntityResource<ODataEntity<SupplierEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.supplier(key).reference()
      .set(target, {etag});
  }
  public unsetSupplierEdmAsSupplier(key: EntityKey<SupplierBankDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<SupplierEdm>>, etag?: string} = {}): Observable<any> {
    return this.supplier(key).reference()
      .unset({etag});
  }
  //#endregion
}
