//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CreditCheckEdm } from './creditcheckedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CreditCheckEdmEntityConfig = {
  name: 'CreditCheckEdm',
  keys: [{name: 'creditCheckGuid'}],
  fields: {
    creditCheckGuid: {type: 'Edm.Guid', nullable: false},
    customerGuid: {type: 'Edm.Guid'},
    checkResult: {type: 'Edm.Int16', nullable: false},
    checkResultAmount: {type: 'Edm.Decimal', scale: 'variable'},
    checkResultDate: {type: 'Edm.DateTimeOffset'},
    insurerResult: {type: 'Edm.Int16', nullable: false},
    insurerResultAmount: {type: 'Edm.Decimal', scale: 'variable'},
    insurerResultDate: {type: 'Edm.DateTimeOffset'},
    reviewDate: {type: 'Edm.DateTimeOffset'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    customer: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerEdm', navigation: true, referentials: [{property: 'customerGuid', referencedProperty: 'customerGuid'}]},
    creditLimits: {type: 'OptiaApi.DataDomain.EFEntities.Credit.CreditLimitEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<CreditCheckEdm>;
//#endregion