//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CompaniesHouseDetailsPersonsService } from './companieshousedetailspersons.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CompaniesHouseDetailsPersonsServiceEntitySetConfig = {
  name: 'CompaniesHouseDetailsPersons',
  entityType: 'OptiaApi.DataDomain.EFEntities.CompaniesHouseDetailsPersonEdm',
  service: CompaniesHouseDetailsPersonsService
} as EntitySetConfig;
//#endregion