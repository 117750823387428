//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TransferAgencyEdm } from './transferagencyedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const TransferAgencyEdmEntityConfig = {
  name: 'TransferAgencyEdm',
  keys: [{name: 'agencyGuid'},{name: 'transferGuid'}],
  fields: {
    transferGuid: {type: 'Edm.Guid'},
    agencyGuid: {type: 'Edm.Guid'},
    agency: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm', navigation: true, referentials: [{property: 'agencyGuid', referencedProperty: 'agencyGuid'}]},
    transfer: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferEdm', navigation: true, referentials: [{property: 'transferGuid', referencedProperty: 'transferGuid'}]}
  }
} as StructuredTypeConfig<TransferAgencyEdm>;
//#endregion