//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyAddressesService } from './agencyaddresses.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AgencyAddressesServiceEntitySetConfig = {
  name: 'AgencyAddresses',
  entityType: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyAddressEdm',
  service: AgencyAddressesService
} as EntitySetConfig;
//#endregion