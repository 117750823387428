//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { RefDataItemsService } from './refdataitems.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const RefDataItemsServiceEntitySetConfig = {
  name: 'RefDataItems',
  entityType: 'OptiaApi.DataDomain.EFEntities.RefData.RefDataItemEdm',
  service: RefDataItemsService
} as EntitySetConfig;
//#endregion