export function compareStrings(str1: any, str2: any) : boolean {

    if(!str1 || !str2
        || (str1 === '' && str2 !== '') 
        || (str1 !== '' && str2 === '')
        ) {
            return false;
    }
    
    if ((typeof str1 === 'string' || str1 instanceof String) 
        && (typeof str2 === 'string' || str2 instanceof String)
        && str1.length > 0 && str2.length > 0) {
        return str1.toLowerCase() === str2.toLowerCase()
    } else {
        return false;
    }
}