//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyReportingCategoryEdm } from './agencyreportingcategoryedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AgencyReportingCategoryEdmEntityConfig = {
  name: 'AgencyReportingCategoryEdm',
  keys: [{name: 'agencyReportingCategoryGuid'}],
  fields: {
    agencyReportingCategoryGuid: {type: 'Edm.Guid', nullable: false},
    reportingCategoryGuid: {type: 'Edm.Guid'},
    agencyGuid: {type: 'Edm.Guid'},
    fromDate: {type: 'Edm.DateTimeOffset'},
    toDate: {type: 'Edm.DateTimeOffset'},
    agency: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm', navigation: true, referentials: [{property: 'agencyGuid', referencedProperty: 'agencyGuid'}]},
    reportingCategory: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.ReportingCategoryEdm', navigation: true, referentials: [{property: 'reportingCategoryGuid', referencedProperty: 'reportingCategoryGuid'}]},
    reportingLabels: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.ReportingLabelEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<AgencyReportingCategoryEdm>;
//#endregion