//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MiReportTypeValidationRuleEdm } from './mireporttypevalidationruleedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const MiReportTypeValidationRuleEdmEntityConfig = {
  name: 'MiReportTypeValidationRuleEdm',
  keys: [{name: 'miReportTypeValidationRulesGuid'}],
  fields: {
    miReportTypeValidationRulesGuid: {type: 'Edm.Guid', nullable: false},
    miReportTypeGuid: {type: 'Edm.Guid'},
    fileType: {type: 'Edm.Int16'},
    valueToMatch: {type: 'Edm.String'},
    cellLocation: {type: 'Edm.String'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    miReportType: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportTypeEdm', navigation: true, referentials: [{property: 'miReportTypeGuid', referencedProperty: 'miReportTypeGuid'}]}
  }
} as StructuredTypeConfig<MiReportTypeValidationRuleEdm>;
//#endregion