//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OptiaNewsDetailEdm } from './optianewsdetailedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const OptiaNewsDetailEdmComplexConfig = {
  name: 'OptiaNewsDetailEdm',
  fields: {
    createdByPerson: {type: 'Edm.String', nullable: false},
    optiaNewsGuid: {type: 'Edm.Guid', nullable: false},
    title: {type: 'Edm.String', nullable: false},
    details: {type: 'Edm.String', maxLength: -1},
    launchDate: {type: 'Edm.DateTimeOffset'},
    lastEditedDate: {type: 'Edm.DateTimeOffset'},
    lastEditedBy: {type: 'Edm.Guid'},
    expiryDate: {type: 'Edm.DateTimeOffset'},
    isUrgent: {type: 'Edm.Boolean', nullable: false},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid', nullable: false},
    deleted: {type: 'Edm.DateTimeOffset'},
    expiredBy: {type: 'Edm.Guid'},
    personGuid: {type: 'Edm.Guid'},
    status: {type: 'Edm.Int16', nullable: false}
  }
} as StructuredTypeConfig<OptiaNewsDetailEdm>;
//#endregion