import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContractEdm } from './DataDomain/EFEntities/contractedm.entity';
import { ContractPersonEdm } from './DataDomain/EFEntities/contractpersonedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class ContractPersonsService extends ODataEntitySetService<ContractPersonEdm> {
  constructor(client: ODataClient) {
    super(client, 'ContractPersons', 'OptiaApi.DataDomain.EFEntities.ContractPersonEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public contract(key: EntityKey<ContractPersonEdm>): ODataNavigationPropertyResource<ContractEdm> { 
    return this.entity(key).navigationProperty<ContractEdm>('contract'); 
  }
  public fetchContract(key: EntityKey<ContractPersonEdm>, options?: ODataQueryArgumentsOptions<ContractEdm>) {
    return this.fetchNavigationProperty<ContractEdm>(
      this.contract(key), 
      'entity', options) as Observable<ODataEntity<ContractEdm>>;
  }
  public setContractEdmAsContract(key: EntityKey<ContractPersonEdm>, target: ODataEntityResource<ODataEntity<ContractEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.contract(key).reference()
      .set(target, {etag});
  }
  public unsetContractEdmAsContract(key: EntityKey<ContractPersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<ContractEdm>>, etag?: string} = {}): Observable<any> {
    return this.contract(key).reference()
      .unset({etag});
  }
  public person(key: EntityKey<ContractPersonEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<ContractPersonEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<ContractPersonEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<ContractPersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  //#endregion
}
