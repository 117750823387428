//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerBankDetailsService } from './workerbankdetails.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WorkerBankDetailsServiceEntitySetConfig = {
  name: 'WorkerBankDetails',
  entityType: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerBankDetailEdm',
  service: WorkerBankDetailsService
} as EntitySetConfig;
//#endregion