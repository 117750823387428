//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SupplierBankDetailsService } from './supplierbankdetails.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const SupplierBankDetailsServiceEntitySetConfig = {
  name: 'SupplierBankDetails',
  entityType: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierBankDetailEdm',
  service: SupplierBankDetailsService
} as EntitySetConfig;
//#endregion