//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerAgencyDocumentsService } from './customeragencydocuments.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CustomerAgencyDocumentsServiceEntitySetConfig = {
  name: 'CustomerAgencyDocuments',
  entityType: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyDocumentEdm',
  service: CustomerAgencyDocumentsService
} as EntitySetConfig;
//#endregion