import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContractEdm } from './DataDomain/EFEntities/contractedm.entity';
import { ContractReportingLabelEdm } from './DataDomain/EFEntities/Reporting/contractreportinglabeledm.entity';
import { ReportingLabelEdm } from './DataDomain/EFEntities/Reporting/reportinglabeledm.entity';
//#endregion

@Injectable()
export class ContractReportingLabelsService extends ODataEntitySetService<ContractReportingLabelEdm> {
  constructor(client: ODataClient) {
    super(client, 'ContractReportingLabels', 'OptiaApi.DataDomain.EFEntities.Reporting.ContractReportingLabelEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public contract(key: EntityKey<ContractReportingLabelEdm>): ODataNavigationPropertyResource<ContractEdm> { 
    return this.entity(key).navigationProperty<ContractEdm>('contract'); 
  }
  public fetchContract(key: EntityKey<ContractReportingLabelEdm>, options?: ODataQueryArgumentsOptions<ContractEdm>) {
    return this.fetchNavigationProperty<ContractEdm>(
      this.contract(key), 
      'entity', options) as Observable<ODataEntity<ContractEdm>>;
  }
  public setContractEdmAsContract(key: EntityKey<ContractReportingLabelEdm>, target: ODataEntityResource<ODataEntity<ContractEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.contract(key).reference()
      .set(target, {etag});
  }
  public unsetContractEdmAsContract(key: EntityKey<ContractReportingLabelEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<ContractEdm>>, etag?: string} = {}): Observable<any> {
    return this.contract(key).reference()
      .unset({etag});
  }
  public reportingLabel(key: EntityKey<ContractReportingLabelEdm>): ODataNavigationPropertyResource<ReportingLabelEdm> { 
    return this.entity(key).navigationProperty<ReportingLabelEdm>('reportingLabel'); 
  }
  public fetchReportingLabel(key: EntityKey<ContractReportingLabelEdm>, options?: ODataQueryArgumentsOptions<ReportingLabelEdm>) {
    return this.fetchNavigationProperty<ReportingLabelEdm>(
      this.reportingLabel(key), 
      'entity', options) as Observable<ODataEntity<ReportingLabelEdm>>;
  }
  public setReportingLabelEdmAsReportingLabel(key: EntityKey<ContractReportingLabelEdm>, target: ODataEntityResource<ODataEntity<ReportingLabelEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.reportingLabel(key).reference()
      .set(target, {etag});
  }
  public unsetReportingLabelEdmAsReportingLabel(key: EntityKey<ContractReportingLabelEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<ReportingLabelEdm>>, etag?: string} = {}): Observable<any> {
    return this.reportingLabel(key).reference()
      .unset({etag});
  }
  //#endregion
}
