//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TradingStyleEdm } from './tradingstyleedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const TradingStyleEdmEntityConfig = {
  name: 'TradingStyleEdm',
  keys: [{name: 'tradingStylesGuid'}],
  fields: {
    tradingStylesGuid: {type: 'Edm.Guid', nullable: false},
    agencyGuid: {type: 'Edm.Guid'},
    tradingStyleName: {type: 'Edm.String', nullable: false},
    shortCode: {type: 'Edm.String'},
    agency: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm', navigation: true, referentials: [{property: 'agencyGuid', referencedProperty: 'agencyGuid'}]},
    agencyReportingCombinations: {type: 'OptiaApi.DataDomain.EFEntities.AgencyReportingCombinationEdm', collection: true, navigation: true},
    contracts: {type: 'OptiaApi.DataDomain.EFEntities.ContractEdm', collection: true, navigation: true},
    workerTypes: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<TradingStyleEdm>;
//#endregion