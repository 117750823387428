//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MiReportGroupPersonEdm } from './mireportgrouppersonedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const MiReportGroupPersonEdmEntityConfig = {
  name: 'MiReportGroupPersonEdm',
  keys: [{name: 'fromDate'},{name: 'miReportGroupGuid'},{name: 'personGuid'}],
  fields: {
    miReportGroupGuid: {type: 'Edm.Guid'},
    personGuid: {type: 'Edm.Guid'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    miReportGroup: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportGroupEdm', navigation: true, referentials: [{property: 'miReportGroupGuid', referencedProperty: 'miReportGroupGuid'}]},
    person: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<MiReportGroupPersonEdm>;
//#endregion