//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonDetailEdmComplexConfig } from './persondetailedm.complex.config';
import { PersonEdmEntityConfig } from './personedm.entity.config';
import { PersonAddressEdmEntityConfig } from './personaddressedm.entity.config';
import { PersonDepartmentEdmEntityConfig } from './persondepartmentedm.entity.config';
import { PersonPhoneNumberEdmEntityConfig } from './personphonenumberedm.entity.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const PersonSchema = {
  namespace: 'OptiaApi.DataDomain.EFEntities.Person',
  enums: [],
  entities: [PersonDetailEdmComplexConfig,
    PersonEdmEntityConfig,
    PersonAddressEdmEntityConfig,
    PersonDepartmentEdmEntityConfig,
    PersonPhoneNumberEdmEntityConfig],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion