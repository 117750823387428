//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DocumentDto } from './documentdto.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const DocumentDtoComplexConfig = {
  name: 'DocumentDto',
  fields: {
    documentGuid: {type: 'Edm.Guid', nullable: false},
    uploadedOn: {type: 'Edm.DateTimeOffset'},
    uploadedBy: {type: 'Edm.Guid', nullable: false},
    filename: {type: 'Edm.String', nullable: false},
    mimeType: {type: 'Edm.String', nullable: false},
    data: {type: 'Edm.Binary'},
    documentType: {type: 'Edm.Int16', nullable: false},
    deleted: {type: 'Edm.DateTimeOffset'},
    approved: {type: 'Edm.Boolean'}
  }
} as StructuredTypeConfig<DocumentDto>;
//#endregion