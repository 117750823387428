import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfScheduleEdm } from './DataDomain/EFEntities/Workflow/wfscheduleedm.entity';
import { WfTaskEdm } from './DataDomain/EFEntities/Workflow/wftaskedm.entity';
import { WfTaskWfScheduleEdm } from './DataDomain/EFEntities/Workflow/wftaskwfscheduleedm.entity';
//#endregion

@Injectable()
export class WfTaskWfSchedulesService extends ODataEntitySetService<WfTaskWfScheduleEdm> {
  constructor(client: ODataClient) {
    super(client, 'WfTaskWfSchedules', 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskWfScheduleEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public wfSchedule(key: EntityKey<WfTaskWfScheduleEdm>): ODataNavigationPropertyResource<WfScheduleEdm> { 
    return this.entity(key).navigationProperty<WfScheduleEdm>('wfSchedule'); 
  }
  public fetchWfSchedule(key: EntityKey<WfTaskWfScheduleEdm>, options?: ODataQueryArgumentsOptions<WfScheduleEdm>) {
    return this.fetchNavigationProperty<WfScheduleEdm>(
      this.wfSchedule(key), 
      'entity', options) as Observable<ODataEntity<WfScheduleEdm>>;
  }
  public setWfScheduleEdmAsWfSchedule(key: EntityKey<WfTaskWfScheduleEdm>, target: ODataEntityResource<ODataEntity<WfScheduleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfSchedule(key).reference()
      .set(target, {etag});
  }
  public unsetWfScheduleEdmAsWfSchedule(key: EntityKey<WfTaskWfScheduleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WfScheduleEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfSchedule(key).reference()
      .unset({etag});
  }
  public wfTask(key: EntityKey<WfTaskWfScheduleEdm>): ODataNavigationPropertyResource<WfTaskEdm> { 
    return this.entity(key).navigationProperty<WfTaskEdm>('wfTask'); 
  }
  public fetchWfTask(key: EntityKey<WfTaskWfScheduleEdm>, options?: ODataQueryArgumentsOptions<WfTaskEdm>) {
    return this.fetchNavigationProperty<WfTaskEdm>(
      this.wfTask(key), 
      'entity', options) as Observable<ODataEntity<WfTaskEdm>>;
  }
  public setWfTaskEdmAsWfTask(key: EntityKey<WfTaskWfScheduleEdm>, target: ODataEntityResource<ODataEntity<WfTaskEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfTask(key).reference()
      .set(target, {etag});
  }
  public unsetWfTaskEdmAsWfTask(key: EntityKey<WfTaskWfScheduleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WfTaskEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfTask(key).reference()
      .unset({etag});
  }
  //#endregion
}
