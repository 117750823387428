//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PermissionDTOService } from './permissiondto.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PermissionDTOServiceEntitySetConfig = {
  name: 'PermissionDTO',
  entityType: 'OptiaApi.ServiceLayer.DTOs.PermissionDTO',
  service: PermissionDTOService
} as EntitySetConfig;
//#endregion