//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CompaniesHouseDetailEdm } from './companieshousedetailedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CompaniesHouseDetailEdmEntityConfig = {
  name: 'CompaniesHouseDetailEdm',
  keys: [{name: 'companiesHouseDetailsGuid'}],
  fields: {
    companiesHouseDetailsGuid: {type: 'Edm.Guid', nullable: false},
    exactLegalName: {type: 'Edm.String', nullable: false},
    registeredCompanyNumber: {type: 'Edm.String', nullable: false},
    companyType: {type: 'Edm.String'},
    incorporatedDate: {type: 'Edm.DateTimeOffset'},
    companyStatus: {type: 'Edm.String'},
    statusDetails: {type: 'Edm.String'},
    lastAccountsMadeUpTo: {type: 'Edm.DateTimeOffset'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    agencies: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm', collection: true, navigation: true},
    companiesHouseDetailsAddresses: {type: 'OptiaApi.DataDomain.EFEntities.CompaniesHouseDetailsAddressEdm', collection: true, navigation: true},
    companiesHouseDetailsPersons: {type: 'OptiaApi.DataDomain.EFEntities.CompaniesHouseDetailsPersonEdm', collection: true, navigation: true},
    customers: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<CompaniesHouseDetailEdm>;
//#endregion