//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PurchaseOrdersService } from './purchaseorders.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PurchaseOrdersServiceEntitySetConfig = {
  name: 'PurchaseOrders',
  entityType: 'OptiaApi.DataDomain.EFEntities.PurchaseOrderEdm',
  service: PurchaseOrdersService
} as EntitySetConfig;
//#endregion