import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
import { SupplierEdm } from './DataDomain/EFEntities/Supplier/supplieredm.entity';
import { SupplierAddressEdm } from './DataDomain/EFEntities/Supplier/supplieraddressedm.entity';
import { SupplierAgencyEdm } from './DataDomain/EFEntities/Supplier/supplieragencyedm.entity';
import { SupplierBankDetailEdm } from './DataDomain/EFEntities/Supplier/supplierbankdetailedm.entity';
import { SupplierDocumentEdm } from './DataDomain/EFEntities/Supplier/supplierdocumentedm.entity';
import { SupplierPersonEdm } from './DataDomain/EFEntities/Supplier/supplierpersonedm.entity';
import { SupplierWorkerTypeEdm } from './DataDomain/EFEntities/Supplier/supplierworkertypeedm.entity';
//#endregion

@Injectable()
export class SuppliersService extends ODataEntitySetService<SupplierEdm> {
  constructor(client: ODataClient) {
    super(client, 'Suppliers', 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public createdByPerson(key: EntityKey<SupplierEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<SupplierEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<SupplierEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<SupplierEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public supplierAddresses(key: EntityKey<SupplierEdm>): ODataNavigationPropertyResource<SupplierAddressEdm> { 
    return this.entity(key).navigationProperty<SupplierAddressEdm>('supplierAddresses'); 
  }
  public fetchSupplierAddresses(key: EntityKey<SupplierEdm>, options?: ODataQueryArgumentsOptions<SupplierAddressEdm>) {
    return this.fetchNavigationProperty<SupplierAddressEdm>(
      this.supplierAddresses(key), 
      'entities', options) as Observable<ODataEntities<SupplierAddressEdm>>;
  }
  public addSupplierAddressEdmToSupplierAddresses(key: EntityKey<SupplierEdm>, target: ODataEntityResource<ODataEntities<SupplierAddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.supplierAddresses(key).reference()
      .add(target);
  }
  public removeSupplierAddressEdmFromSupplierAddresses(key: EntityKey<SupplierEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<SupplierAddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.supplierAddresses(key).reference()
      .remove(target);
  }
  public supplierAgencies(key: EntityKey<SupplierEdm>): ODataNavigationPropertyResource<SupplierAgencyEdm> { 
    return this.entity(key).navigationProperty<SupplierAgencyEdm>('supplierAgencies'); 
  }
  public fetchSupplierAgencies(key: EntityKey<SupplierEdm>, options?: ODataQueryArgumentsOptions<SupplierAgencyEdm>) {
    return this.fetchNavigationProperty<SupplierAgencyEdm>(
      this.supplierAgencies(key), 
      'entities', options) as Observable<ODataEntities<SupplierAgencyEdm>>;
  }
  public addSupplierAgencyEdmToSupplierAgencies(key: EntityKey<SupplierEdm>, target: ODataEntityResource<ODataEntities<SupplierAgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.supplierAgencies(key).reference()
      .add(target);
  }
  public removeSupplierAgencyEdmFromSupplierAgencies(key: EntityKey<SupplierEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<SupplierAgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.supplierAgencies(key).reference()
      .remove(target);
  }
  public supplierBankDetails(key: EntityKey<SupplierEdm>): ODataNavigationPropertyResource<SupplierBankDetailEdm> { 
    return this.entity(key).navigationProperty<SupplierBankDetailEdm>('supplierBankDetails'); 
  }
  public fetchSupplierBankDetails(key: EntityKey<SupplierEdm>, options?: ODataQueryArgumentsOptions<SupplierBankDetailEdm>) {
    return this.fetchNavigationProperty<SupplierBankDetailEdm>(
      this.supplierBankDetails(key), 
      'entities', options) as Observable<ODataEntities<SupplierBankDetailEdm>>;
  }
  public addSupplierBankDetailEdmToSupplierBankDetails(key: EntityKey<SupplierEdm>, target: ODataEntityResource<ODataEntities<SupplierBankDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.supplierBankDetails(key).reference()
      .add(target);
  }
  public removeSupplierBankDetailEdmFromSupplierBankDetails(key: EntityKey<SupplierEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<SupplierBankDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.supplierBankDetails(key).reference()
      .remove(target);
  }
  public supplierDocuments(key: EntityKey<SupplierEdm>): ODataNavigationPropertyResource<SupplierDocumentEdm> { 
    return this.entity(key).navigationProperty<SupplierDocumentEdm>('supplierDocuments'); 
  }
  public fetchSupplierDocuments(key: EntityKey<SupplierEdm>, options?: ODataQueryArgumentsOptions<SupplierDocumentEdm>) {
    return this.fetchNavigationProperty<SupplierDocumentEdm>(
      this.supplierDocuments(key), 
      'entities', options) as Observable<ODataEntities<SupplierDocumentEdm>>;
  }
  public addSupplierDocumentEdmToSupplierDocuments(key: EntityKey<SupplierEdm>, target: ODataEntityResource<ODataEntities<SupplierDocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.supplierDocuments(key).reference()
      .add(target);
  }
  public removeSupplierDocumentEdmFromSupplierDocuments(key: EntityKey<SupplierEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<SupplierDocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.supplierDocuments(key).reference()
      .remove(target);
  }
  public supplierPersons(key: EntityKey<SupplierEdm>): ODataNavigationPropertyResource<SupplierPersonEdm> { 
    return this.entity(key).navigationProperty<SupplierPersonEdm>('supplierPersons'); 
  }
  public fetchSupplierPersons(key: EntityKey<SupplierEdm>, options?: ODataQueryArgumentsOptions<SupplierPersonEdm>) {
    return this.fetchNavigationProperty<SupplierPersonEdm>(
      this.supplierPersons(key), 
      'entities', options) as Observable<ODataEntities<SupplierPersonEdm>>;
  }
  public addSupplierPersonEdmToSupplierPersons(key: EntityKey<SupplierEdm>, target: ODataEntityResource<ODataEntities<SupplierPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.supplierPersons(key).reference()
      .add(target);
  }
  public removeSupplierPersonEdmFromSupplierPersons(key: EntityKey<SupplierEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<SupplierPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.supplierPersons(key).reference()
      .remove(target);
  }
  public supplierWorkerTypes(key: EntityKey<SupplierEdm>): ODataNavigationPropertyResource<SupplierWorkerTypeEdm> { 
    return this.entity(key).navigationProperty<SupplierWorkerTypeEdm>('supplierWorkerTypes'); 
  }
  public fetchSupplierWorkerTypes(key: EntityKey<SupplierEdm>, options?: ODataQueryArgumentsOptions<SupplierWorkerTypeEdm>) {
    return this.fetchNavigationProperty<SupplierWorkerTypeEdm>(
      this.supplierWorkerTypes(key), 
      'entities', options) as Observable<ODataEntities<SupplierWorkerTypeEdm>>;
  }
  public addSupplierWorkerTypeEdmToSupplierWorkerTypes(key: EntityKey<SupplierEdm>, target: ODataEntityResource<ODataEntities<SupplierWorkerTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.supplierWorkerTypes(key).reference()
      .add(target);
  }
  public removeSupplierWorkerTypeEdmFromSupplierWorkerTypes(key: EntityKey<SupplierEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<SupplierWorkerTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.supplierWorkerTypes(key).reference()
      .remove(target);
  }
  //#endregion
}
