//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TransferMiReportTypesService } from './transfermireporttypes.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const TransferMiReportTypesServiceEntitySetConfig = {
  name: 'TransferMiReportTypes',
  entityType: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferMiReportTypeEdm',
  service: TransferMiReportTypesService
} as EntitySetConfig;
//#endregion