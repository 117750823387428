import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AccountEdm } from './DataDomain/EFEntities/accountedm.entity';
import { AccountPersonEdm } from './DataDomain/EFEntities/accountpersonedm.entity';
import { AddressEdm } from './DataDomain/EFEntities/addressedm.entity';
import { AssignmentEdm } from './DataDomain/EFEntities/assignmentedm.entity';
import { BankDetailEdm } from './DataDomain/EFEntities/bankdetailedm.entity';
import { CharityEdm } from './DataDomain/EFEntities/charityedm.entity';
import { CompaniesHouseDetailEdm } from './DataDomain/EFEntities/companieshousedetailedm.entity';
import { ContractEdm } from './DataDomain/EFEntities/contractedm.entity';
import { ContractPersonEdm } from './DataDomain/EFEntities/contractpersonedm.entity';
import { DocumentEdm } from './DataDomain/EFEntities/documentedm.entity';
import { PhoneNumberEdm } from './DataDomain/EFEntities/phonenumberedm.entity';
import { PurchaseOrderEdm } from './DataDomain/EFEntities/purchaseorderedm.entity';
import { AgencyEdm } from './DataDomain/EFEntities/Agency/agencyedm.entity';
import { AgencyPersonEdm } from './DataDomain/EFEntities/Agency/agencypersonedm.entity';
import { AgencyPersonWfRoleEdm } from './DataDomain/EFEntities/Agency/agencypersonwfroleedm.entity';
import { AuditEdm } from './DataDomain/EFEntities/Admin/auditedm.entity';
import { ChangeSetEdm } from './DataDomain/EFEntities/Admin/changesetedm.entity';
import { PermissionEdm } from './DataDomain/EFEntities/Admin/permissionedm.entity';
import { PersonUserGroupEdm } from './DataDomain/EFEntities/Admin/personusergroupedm.entity';
import { RegExEdm } from './DataDomain/EFEntities/Admin/regexedm.entity';
import { UserGroupEdm } from './DataDomain/EFEntities/Admin/usergroupedm.entity';
import { MiReportGroupEdm } from './DataDomain/EFEntities/Reporting/mireportgroupedm.entity';
import { MiReportGroupPersonEdm } from './DataDomain/EFEntities/Reporting/mireportgrouppersonedm.entity';
import { MiReportTypeEdm } from './DataDomain/EFEntities/Reporting/mireporttypeedm.entity';
import { MiReportTypePersonEdm } from './DataDomain/EFEntities/Reporting/mireporttypepersonedm.entity';
import { MiReportTypeValidationRuleEdm } from './DataDomain/EFEntities/Reporting/mireporttypevalidationruleedm.entity';
import { TransferLogEdm } from './DataDomain/EFEntities/Reporting/transferlogedm.entity';
import { CreditAssignmentEdm } from './DataDomain/EFEntities/Credit/creditassignmentedm.entity';
import { CreditCheckEdm } from './DataDomain/EFEntities/Credit/creditcheckedm.entity';
import { CreditLimitEdm } from './DataDomain/EFEntities/Credit/creditlimitedm.entity';
import { CustomerEdm } from './DataDomain/EFEntities/Customer/customeredm.entity';
import { CustomerAgencyPersonEdm } from './DataDomain/EFEntities/Customer/customeragencypersonedm.entity';
import { CustomerPersonEdm } from './DataDomain/EFEntities/Customer/customerpersonedm.entity';
import { NotificationPersonEdm } from './DataDomain/EFEntities/Notify/notificationpersonedm.entity';
import { OptiaNewEdm } from './DataDomain/EFEntities/Notify/optianewedm.entity';
import { OptiaNewsPersonEdm } from './DataDomain/EFEntities/Notify/optianewspersonedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
import { PersonAddressEdm } from './DataDomain/EFEntities/Person/personaddressedm.entity';
import { PersonDepartmentEdm } from './DataDomain/EFEntities/Person/persondepartmentedm.entity';
import { PersonPhoneNumberEdm } from './DataDomain/EFEntities/Person/personphonenumberedm.entity';
import { RefDataItemEdm } from './DataDomain/EFEntities/RefData/refdataitemedm.entity';
import { RefDataTypeEdm } from './DataDomain/EFEntities/RefData/refdatatypeedm.entity';
import { SupplierEdm } from './DataDomain/EFEntities/Supplier/supplieredm.entity';
import { SupplierPersonEdm } from './DataDomain/EFEntities/Supplier/supplierpersonedm.entity';
import { WorkerEdm } from './DataDomain/EFEntities/Worker/workeredm.entity';
import { WorkerPersonEdm } from './DataDomain/EFEntities/Worker/workerpersonedm.entity';
import { WorkerTypeEdm } from './DataDomain/EFEntities/Worker/workertypeedm.entity';
import { WorkerTypePersonEdm } from './DataDomain/EFEntities/Worker/workertypepersonedm.entity';
import { SetPasswordDTO } from './ServiceLayer/DTOs/setpassworddto.entity';
//#endregion

@Injectable()
export class PeopleService extends ODataEntitySetService<PersonEdm> {
  constructor(client: ODataClient) {
    super(client, 'People', 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm');
  }
  //#region ODataApiGen Actions
  public setPassword(key: EntityKey<PersonEdm>): ODataActionResource<{password: SetPasswordDTO}, boolean> { 
    return this.entity(key).action<{password: SetPasswordDTO}, boolean>('OptiaApi.SetPassword');
  }
  public callSetPassword(key: EntityKey<PersonEdm>, password: SetPasswordDTO, options?: ODataOptions & {alias?: boolean}) {
    return this.callAction<{password: SetPasswordDTO}, boolean>(
      {password}, 
      this.setPassword(key), 
      'property', options) as Observable<ODataProperty<boolean>>;
  }
  public updateCustomClaim(key: EntityKey<PersonEdm>): ODataActionResource<{azureUserGuid: string, customClaim: string, value: string}, boolean> { 
    return this.entity(key).action<{azureUserGuid: string, customClaim: string, value: string}, boolean>('OptiaApi.UpdateCustomClaim');
  }
  public callUpdateCustomClaim(key: EntityKey<PersonEdm>, azureUserGuid: string, customClaim: string, value: string, options?: ODataOptions & {alias?: boolean}) {
    return this.callAction<{azureUserGuid: string, customClaim: string, value: string}, boolean>(
      {azureUserGuid, customClaim, value}, 
      this.updateCustomClaim(key), 
      'property', options) as Observable<ODataProperty<boolean>>;
  }
  public getCustomClaim(key: EntityKey<PersonEdm>): ODataActionResource<{azureUserGuid: string, customClaim: string}, string> { 
    return this.entity(key).action<{azureUserGuid: string, customClaim: string}, string>('OptiaApi.GetCustomClaim');
  }
  public callGetCustomClaim(key: EntityKey<PersonEdm>, azureUserGuid: string, customClaim: string, options?: ODataOptions & {alias?: boolean}) {
    return this.callAction<{azureUserGuid: string, customClaim: string}, string>(
      {azureUserGuid, customClaim}, 
      this.getCustomClaim(key), 
      'property', options) as Observable<ODataProperty<string>>;
  }
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public accountPersons(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<AccountPersonEdm> { 
    return this.entity(key).navigationProperty<AccountPersonEdm>('accountPersons'); 
  }
  public fetchAccountPersons(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<AccountPersonEdm>) {
    return this.fetchNavigationProperty<AccountPersonEdm>(
      this.accountPersons(key), 
      'entities', options) as Observable<ODataEntities<AccountPersonEdm>>;
  }
  public addAccountPersonEdmToAccountPersons(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<AccountPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.accountPersons(key).reference()
      .add(target);
  }
  public removeAccountPersonEdmFromAccountPersons(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AccountPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.accountPersons(key).reference()
      .remove(target);
  }
  public actionedByChangeSets(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<ChangeSetEdm> { 
    return this.entity(key).navigationProperty<ChangeSetEdm>('actionedByChangeSets'); 
  }
  public fetchActionedByChangeSets(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<ChangeSetEdm>) {
    return this.fetchNavigationProperty<ChangeSetEdm>(
      this.actionedByChangeSets(key), 
      'entities', options) as Observable<ODataEntities<ChangeSetEdm>>;
  }
  public addChangeSetEdmToActionedByChangeSets(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<ChangeSetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.actionedByChangeSets(key).reference()
      .add(target);
  }
  public removeChangeSetEdmFromActionedByChangeSets(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ChangeSetEdm>>, etag?: string} = {}): Observable<any> {
    return this.actionedByChangeSets(key).reference()
      .remove(target);
  }
  public agencyPersons(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<AgencyPersonEdm> { 
    return this.entity(key).navigationProperty<AgencyPersonEdm>('agencyPersons'); 
  }
  public fetchAgencyPersons(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<AgencyPersonEdm>) {
    return this.fetchNavigationProperty<AgencyPersonEdm>(
      this.agencyPersons(key), 
      'entities', options) as Observable<ODataEntities<AgencyPersonEdm>>;
  }
  public addAgencyPersonEdmToAgencyPersons(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<AgencyPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyPersons(key).reference()
      .add(target);
  }
  public removeAgencyPersonEdmFromAgencyPersons(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyPersons(key).reference()
      .remove(target);
  }
  public agencyPersonWfRoles(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<AgencyPersonWfRoleEdm> { 
    return this.entity(key).navigationProperty<AgencyPersonWfRoleEdm>('agencyPersonWfRoles'); 
  }
  public fetchAgencyPersonWfRoles(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<AgencyPersonWfRoleEdm>) {
    return this.fetchNavigationProperty<AgencyPersonWfRoleEdm>(
      this.agencyPersonWfRoles(key), 
      'entities', options) as Observable<ODataEntities<AgencyPersonWfRoleEdm>>;
  }
  public addAgencyPersonWfRoleEdmToAgencyPersonWfRoles(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<AgencyPersonWfRoleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyPersonWfRoles(key).reference()
      .add(target);
  }
  public removeAgencyPersonWfRoleEdmFromAgencyPersonWfRoles(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyPersonWfRoleEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyPersonWfRoles(key).reference()
      .remove(target);
  }
  public approvedByTransferLogs(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<TransferLogEdm> { 
    return this.entity(key).navigationProperty<TransferLogEdm>('approvedByTransferLogs'); 
  }
  public fetchApprovedByTransferLogs(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<TransferLogEdm>) {
    return this.fetchNavigationProperty<TransferLogEdm>(
      this.approvedByTransferLogs(key), 
      'entities', options) as Observable<ODataEntities<TransferLogEdm>>;
  }
  public addTransferLogEdmToApprovedByTransferLogs(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<TransferLogEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.approvedByTransferLogs(key).reference()
      .add(target);
  }
  public removeTransferLogEdmFromApprovedByTransferLogs(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<TransferLogEdm>>, etag?: string} = {}): Observable<any> {
    return this.approvedByTransferLogs(key).reference()
      .remove(target);
  }
  public audits(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<AuditEdm> { 
    return this.entity(key).navigationProperty<AuditEdm>('audits'); 
  }
  public fetchAudits(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<AuditEdm>) {
    return this.fetchNavigationProperty<AuditEdm>(
      this.audits(key), 
      'entities', options) as Observable<ODataEntities<AuditEdm>>;
  }
  public addAuditEdmToAudits(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<AuditEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.audits(key).reference()
      .add(target);
  }
  public removeAuditEdmFromAudits(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AuditEdm>>, etag?: string} = {}): Observable<any> {
    return this.audits(key).reference()
      .remove(target);
  }
  public changeSets(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<ChangeSetEdm> { 
    return this.entity(key).navigationProperty<ChangeSetEdm>('changeSets'); 
  }
  public fetchChangeSets(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<ChangeSetEdm>) {
    return this.fetchNavigationProperty<ChangeSetEdm>(
      this.changeSets(key), 
      'entities', options) as Observable<ODataEntities<ChangeSetEdm>>;
  }
  public addChangeSetEdmToChangeSets(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<ChangeSetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.changeSets(key).reference()
      .add(target);
  }
  public removeChangeSetEdmFromChangeSets(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ChangeSetEdm>>, etag?: string} = {}): Observable<any> {
    return this.changeSets(key).reference()
      .remove(target);
  }
  public contractPersons(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<ContractPersonEdm> { 
    return this.entity(key).navigationProperty<ContractPersonEdm>('contractPersons'); 
  }
  public fetchContractPersons(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<ContractPersonEdm>) {
    return this.fetchNavigationProperty<ContractPersonEdm>(
      this.contractPersons(key), 
      'entities', options) as Observable<ODataEntities<ContractPersonEdm>>;
  }
  public addContractPersonEdmToContractPersons(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<ContractPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.contractPersons(key).reference()
      .add(target);
  }
  public removeContractPersonEdmFromContractPersons(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ContractPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.contractPersons(key).reference()
      .remove(target);
  }
  public createdByAccounts(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<AccountEdm> { 
    return this.entity(key).navigationProperty<AccountEdm>('createdByAccounts'); 
  }
  public fetchCreatedByAccounts(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<AccountEdm>) {
    return this.fetchNavigationProperty<AccountEdm>(
      this.createdByAccounts(key), 
      'entities', options) as Observable<ODataEntities<AccountEdm>>;
  }
  public addAccountEdmToCreatedByAccounts(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<AccountEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByAccounts(key).reference()
      .add(target);
  }
  public removeAccountEdmFromCreatedByAccounts(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AccountEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByAccounts(key).reference()
      .remove(target);
  }
  public createdByAddresses(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<AddressEdm> { 
    return this.entity(key).navigationProperty<AddressEdm>('createdByAddresses'); 
  }
  public fetchCreatedByAddresses(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<AddressEdm>) {
    return this.fetchNavigationProperty<AddressEdm>(
      this.createdByAddresses(key), 
      'entities', options) as Observable<ODataEntities<AddressEdm>>;
  }
  public addAddressEdmToCreatedByAddresses(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<AddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByAddresses(key).reference()
      .add(target);
  }
  public removeAddressEdmFromCreatedByAddresses(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByAddresses(key).reference()
      .remove(target);
  }
  public createdByAgencies(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<AgencyEdm> { 
    return this.entity(key).navigationProperty<AgencyEdm>('createdByAgencies'); 
  }
  public fetchCreatedByAgencies(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<AgencyEdm>) {
    return this.fetchNavigationProperty<AgencyEdm>(
      this.createdByAgencies(key), 
      'entities', options) as Observable<ODataEntities<AgencyEdm>>;
  }
  public addAgencyEdmToCreatedByAgencies(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<AgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByAgencies(key).reference()
      .add(target);
  }
  public removeAgencyEdmFromCreatedByAgencies(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByAgencies(key).reference()
      .remove(target);
  }
  public createdByAssignments(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<AssignmentEdm> { 
    return this.entity(key).navigationProperty<AssignmentEdm>('createdByAssignments'); 
  }
  public fetchCreatedByAssignments(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<AssignmentEdm>) {
    return this.fetchNavigationProperty<AssignmentEdm>(
      this.createdByAssignments(key), 
      'entities', options) as Observable<ODataEntities<AssignmentEdm>>;
  }
  public addAssignmentEdmToCreatedByAssignments(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<AssignmentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByAssignments(key).reference()
      .add(target);
  }
  public removeAssignmentEdmFromCreatedByAssignments(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AssignmentEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByAssignments(key).reference()
      .remove(target);
  }
  public createdByBankDetails(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<BankDetailEdm> { 
    return this.entity(key).navigationProperty<BankDetailEdm>('createdByBankDetails'); 
  }
  public fetchCreatedByBankDetails(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<BankDetailEdm>) {
    return this.fetchNavigationProperty<BankDetailEdm>(
      this.createdByBankDetails(key), 
      'entities', options) as Observable<ODataEntities<BankDetailEdm>>;
  }
  public addBankDetailEdmToCreatedByBankDetails(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<BankDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByBankDetails(key).reference()
      .add(target);
  }
  public removeBankDetailEdmFromCreatedByBankDetails(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<BankDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByBankDetails(key).reference()
      .remove(target);
  }
  public createdByCharities(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<CharityEdm> { 
    return this.entity(key).navigationProperty<CharityEdm>('createdByCharities'); 
  }
  public fetchCreatedByCharities(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<CharityEdm>) {
    return this.fetchNavigationProperty<CharityEdm>(
      this.createdByCharities(key), 
      'entities', options) as Observable<ODataEntities<CharityEdm>>;
  }
  public addCharityEdmToCreatedByCharities(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<CharityEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByCharities(key).reference()
      .add(target);
  }
  public removeCharityEdmFromCreatedByCharities(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CharityEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByCharities(key).reference()
      .remove(target);
  }
  public createdByCompaniesHouseDetails(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<CompaniesHouseDetailEdm> { 
    return this.entity(key).navigationProperty<CompaniesHouseDetailEdm>('createdByCompaniesHouseDetails'); 
  }
  public fetchCreatedByCompaniesHouseDetails(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<CompaniesHouseDetailEdm>) {
    return this.fetchNavigationProperty<CompaniesHouseDetailEdm>(
      this.createdByCompaniesHouseDetails(key), 
      'entities', options) as Observable<ODataEntities<CompaniesHouseDetailEdm>>;
  }
  public addCompaniesHouseDetailEdmToCreatedByCompaniesHouseDetails(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<CompaniesHouseDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByCompaniesHouseDetails(key).reference()
      .add(target);
  }
  public removeCompaniesHouseDetailEdmFromCreatedByCompaniesHouseDetails(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CompaniesHouseDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByCompaniesHouseDetails(key).reference()
      .remove(target);
  }
  public createdByContracts(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<ContractEdm> { 
    return this.entity(key).navigationProperty<ContractEdm>('createdByContracts'); 
  }
  public fetchCreatedByContracts(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<ContractEdm>) {
    return this.fetchNavigationProperty<ContractEdm>(
      this.createdByContracts(key), 
      'entities', options) as Observable<ODataEntities<ContractEdm>>;
  }
  public addContractEdmToCreatedByContracts(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<ContractEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByContracts(key).reference()
      .add(target);
  }
  public removeContractEdmFromCreatedByContracts(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ContractEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByContracts(key).reference()
      .remove(target);
  }
  public createdByCreditAssignments(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<CreditAssignmentEdm> { 
    return this.entity(key).navigationProperty<CreditAssignmentEdm>('createdByCreditAssignments'); 
  }
  public fetchCreatedByCreditAssignments(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<CreditAssignmentEdm>) {
    return this.fetchNavigationProperty<CreditAssignmentEdm>(
      this.createdByCreditAssignments(key), 
      'entities', options) as Observable<ODataEntities<CreditAssignmentEdm>>;
  }
  public addCreditAssignmentEdmToCreatedByCreditAssignments(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<CreditAssignmentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByCreditAssignments(key).reference()
      .add(target);
  }
  public removeCreditAssignmentEdmFromCreatedByCreditAssignments(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CreditAssignmentEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByCreditAssignments(key).reference()
      .remove(target);
  }
  public createdByCreditChecks(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<CreditCheckEdm> { 
    return this.entity(key).navigationProperty<CreditCheckEdm>('createdByCreditChecks'); 
  }
  public fetchCreatedByCreditChecks(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<CreditCheckEdm>) {
    return this.fetchNavigationProperty<CreditCheckEdm>(
      this.createdByCreditChecks(key), 
      'entities', options) as Observable<ODataEntities<CreditCheckEdm>>;
  }
  public addCreditCheckEdmToCreatedByCreditChecks(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<CreditCheckEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByCreditChecks(key).reference()
      .add(target);
  }
  public removeCreditCheckEdmFromCreatedByCreditChecks(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CreditCheckEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByCreditChecks(key).reference()
      .remove(target);
  }
  public createdByCreditLimits(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<CreditLimitEdm> { 
    return this.entity(key).navigationProperty<CreditLimitEdm>('createdByCreditLimits'); 
  }
  public fetchCreatedByCreditLimits(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<CreditLimitEdm>) {
    return this.fetchNavigationProperty<CreditLimitEdm>(
      this.createdByCreditLimits(key), 
      'entities', options) as Observable<ODataEntities<CreditLimitEdm>>;
  }
  public addCreditLimitEdmToCreatedByCreditLimits(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<CreditLimitEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByCreditLimits(key).reference()
      .add(target);
  }
  public removeCreditLimitEdmFromCreatedByCreditLimits(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CreditLimitEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByCreditLimits(key).reference()
      .remove(target);
  }
  public createdByCustomers(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<CustomerEdm> { 
    return this.entity(key).navigationProperty<CustomerEdm>('createdByCustomers'); 
  }
  public fetchCreatedByCustomers(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<CustomerEdm>) {
    return this.fetchNavigationProperty<CustomerEdm>(
      this.createdByCustomers(key), 
      'entities', options) as Observable<ODataEntities<CustomerEdm>>;
  }
  public addCustomerEdmToCreatedByCustomers(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<CustomerEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByCustomers(key).reference()
      .add(target);
  }
  public removeCustomerEdmFromCreatedByCustomers(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CustomerEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByCustomers(key).reference()
      .remove(target);
  }
  public createdByMiReportGroups(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<MiReportGroupEdm> { 
    return this.entity(key).navigationProperty<MiReportGroupEdm>('createdByMiReportGroups'); 
  }
  public fetchCreatedByMiReportGroups(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<MiReportGroupEdm>) {
    return this.fetchNavigationProperty<MiReportGroupEdm>(
      this.createdByMiReportGroups(key), 
      'entities', options) as Observable<ODataEntities<MiReportGroupEdm>>;
  }
  public addMiReportGroupEdmToCreatedByMiReportGroups(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<MiReportGroupEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByMiReportGroups(key).reference()
      .add(target);
  }
  public removeMiReportGroupEdmFromCreatedByMiReportGroups(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<MiReportGroupEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByMiReportGroups(key).reference()
      .remove(target);
  }
  public createdByMiReportTypes(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<MiReportTypeEdm> { 
    return this.entity(key).navigationProperty<MiReportTypeEdm>('createdByMiReportTypes'); 
  }
  public fetchCreatedByMiReportTypes(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<MiReportTypeEdm>) {
    return this.fetchNavigationProperty<MiReportTypeEdm>(
      this.createdByMiReportTypes(key), 
      'entities', options) as Observable<ODataEntities<MiReportTypeEdm>>;
  }
  public addMiReportTypeEdmToCreatedByMiReportTypes(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<MiReportTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByMiReportTypes(key).reference()
      .add(target);
  }
  public removeMiReportTypeEdmFromCreatedByMiReportTypes(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<MiReportTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByMiReportTypes(key).reference()
      .remove(target);
  }
  public createdByMiReportTypeValidationRules(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<MiReportTypeValidationRuleEdm> { 
    return this.entity(key).navigationProperty<MiReportTypeValidationRuleEdm>('createdByMiReportTypeValidationRules'); 
  }
  public fetchCreatedByMiReportTypeValidationRules(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<MiReportTypeValidationRuleEdm>) {
    return this.fetchNavigationProperty<MiReportTypeValidationRuleEdm>(
      this.createdByMiReportTypeValidationRules(key), 
      'entities', options) as Observable<ODataEntities<MiReportTypeValidationRuleEdm>>;
  }
  public addMiReportTypeValidationRuleEdmToCreatedByMiReportTypeValidationRules(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<MiReportTypeValidationRuleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByMiReportTypeValidationRules(key).reference()
      .add(target);
  }
  public removeMiReportTypeValidationRuleEdmFromCreatedByMiReportTypeValidationRules(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<MiReportTypeValidationRuleEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByMiReportTypeValidationRules(key).reference()
      .remove(target);
  }
  public createdByOptiaNews(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<OptiaNewEdm> { 
    return this.entity(key).navigationProperty<OptiaNewEdm>('createdByOptiaNews'); 
  }
  public fetchCreatedByOptiaNews(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<OptiaNewEdm>) {
    return this.fetchNavigationProperty<OptiaNewEdm>(
      this.createdByOptiaNews(key), 
      'entities', options) as Observable<ODataEntities<OptiaNewEdm>>;
  }
  public addOptiaNewEdmToCreatedByOptiaNews(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<OptiaNewEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByOptiaNews(key).reference()
      .add(target);
  }
  public removeOptiaNewEdmFromCreatedByOptiaNews(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<OptiaNewEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByOptiaNews(key).reference()
      .remove(target);
  }
  public createdByPeople(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPeople'); 
  }
  public fetchCreatedByPeople(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPeople(key), 
      'entities', options) as Observable<ODataEntities<PersonEdm>>;
  }
  public addPersonEdmToCreatedByPeople(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPeople(key).reference()
      .add(target);
  }
  public removePersonEdmFromCreatedByPeople(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPeople(key).reference()
      .remove(target);
  }
  public createdByPermissions(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PermissionEdm> { 
    return this.entity(key).navigationProperty<PermissionEdm>('createdByPermissions'); 
  }
  public fetchCreatedByPermissions(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PermissionEdm>) {
    return this.fetchNavigationProperty<PermissionEdm>(
      this.createdByPermissions(key), 
      'entities', options) as Observable<ODataEntities<PermissionEdm>>;
  }
  public addPermissionEdmToCreatedByPermissions(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<PermissionEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPermissions(key).reference()
      .add(target);
  }
  public removePermissionEdmFromCreatedByPermissions(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PermissionEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPermissions(key).reference()
      .remove(target);
  }
  public createdByPerson(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public createdByPhoneNumbers(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PhoneNumberEdm> { 
    return this.entity(key).navigationProperty<PhoneNumberEdm>('createdByPhoneNumbers'); 
  }
  public fetchCreatedByPhoneNumbers(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PhoneNumberEdm>) {
    return this.fetchNavigationProperty<PhoneNumberEdm>(
      this.createdByPhoneNumbers(key), 
      'entities', options) as Observable<ODataEntities<PhoneNumberEdm>>;
  }
  public addPhoneNumberEdmToCreatedByPhoneNumbers(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<PhoneNumberEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPhoneNumbers(key).reference()
      .add(target);
  }
  public removePhoneNumberEdmFromCreatedByPhoneNumbers(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PhoneNumberEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPhoneNumbers(key).reference()
      .remove(target);
  }
  public createdByPurchaseOrders(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PurchaseOrderEdm> { 
    return this.entity(key).navigationProperty<PurchaseOrderEdm>('createdByPurchaseOrders'); 
  }
  public fetchCreatedByPurchaseOrders(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PurchaseOrderEdm>) {
    return this.fetchNavigationProperty<PurchaseOrderEdm>(
      this.createdByPurchaseOrders(key), 
      'entities', options) as Observable<ODataEntities<PurchaseOrderEdm>>;
  }
  public addPurchaseOrderEdmToCreatedByPurchaseOrders(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<PurchaseOrderEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPurchaseOrders(key).reference()
      .add(target);
  }
  public removePurchaseOrderEdmFromCreatedByPurchaseOrders(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PurchaseOrderEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPurchaseOrders(key).reference()
      .remove(target);
  }
  public createdByRefDataItems(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<RefDataItemEdm> { 
    return this.entity(key).navigationProperty<RefDataItemEdm>('createdByRefDataItems'); 
  }
  public fetchCreatedByRefDataItems(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<RefDataItemEdm>) {
    return this.fetchNavigationProperty<RefDataItemEdm>(
      this.createdByRefDataItems(key), 
      'entities', options) as Observable<ODataEntities<RefDataItemEdm>>;
  }
  public addRefDataItemEdmToCreatedByRefDataItems(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<RefDataItemEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByRefDataItems(key).reference()
      .add(target);
  }
  public removeRefDataItemEdmFromCreatedByRefDataItems(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<RefDataItemEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByRefDataItems(key).reference()
      .remove(target);
  }
  public createdByRefDataTypes(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<RefDataTypeEdm> { 
    return this.entity(key).navigationProperty<RefDataTypeEdm>('createdByRefDataTypes'); 
  }
  public fetchCreatedByRefDataTypes(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<RefDataTypeEdm>) {
    return this.fetchNavigationProperty<RefDataTypeEdm>(
      this.createdByRefDataTypes(key), 
      'entities', options) as Observable<ODataEntities<RefDataTypeEdm>>;
  }
  public addRefDataTypeEdmToCreatedByRefDataTypes(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<RefDataTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByRefDataTypes(key).reference()
      .add(target);
  }
  public removeRefDataTypeEdmFromCreatedByRefDataTypes(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<RefDataTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByRefDataTypes(key).reference()
      .remove(target);
  }
  public createdByRegExes(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<RegExEdm> { 
    return this.entity(key).navigationProperty<RegExEdm>('createdByRegExes'); 
  }
  public fetchCreatedByRegExes(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<RegExEdm>) {
    return this.fetchNavigationProperty<RegExEdm>(
      this.createdByRegExes(key), 
      'entities', options) as Observable<ODataEntities<RegExEdm>>;
  }
  public addRegExEdmToCreatedByRegExes(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<RegExEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByRegExes(key).reference()
      .add(target);
  }
  public removeRegExEdmFromCreatedByRegExes(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<RegExEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByRegExes(key).reference()
      .remove(target);
  }
  public createdBySuppliers(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<SupplierEdm> { 
    return this.entity(key).navigationProperty<SupplierEdm>('createdBySuppliers'); 
  }
  public fetchCreatedBySuppliers(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<SupplierEdm>) {
    return this.fetchNavigationProperty<SupplierEdm>(
      this.createdBySuppliers(key), 
      'entities', options) as Observable<ODataEntities<SupplierEdm>>;
  }
  public addSupplierEdmToCreatedBySuppliers(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<SupplierEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdBySuppliers(key).reference()
      .add(target);
  }
  public removeSupplierEdmFromCreatedBySuppliers(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<SupplierEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdBySuppliers(key).reference()
      .remove(target);
  }
  public createdByUserGroups(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<UserGroupEdm> { 
    return this.entity(key).navigationProperty<UserGroupEdm>('createdByUserGroups'); 
  }
  public fetchCreatedByUserGroups(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<UserGroupEdm>) {
    return this.fetchNavigationProperty<UserGroupEdm>(
      this.createdByUserGroups(key), 
      'entities', options) as Observable<ODataEntities<UserGroupEdm>>;
  }
  public addUserGroupEdmToCreatedByUserGroups(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<UserGroupEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByUserGroups(key).reference()
      .add(target);
  }
  public removeUserGroupEdmFromCreatedByUserGroups(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<UserGroupEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByUserGroups(key).reference()
      .remove(target);
  }
  public createdByWorkers(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<WorkerEdm> { 
    return this.entity(key).navigationProperty<WorkerEdm>('createdByWorkers'); 
  }
  public fetchCreatedByWorkers(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<WorkerEdm>) {
    return this.fetchNavigationProperty<WorkerEdm>(
      this.createdByWorkers(key), 
      'entities', options) as Observable<ODataEntities<WorkerEdm>>;
  }
  public addWorkerEdmToCreatedByWorkers(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<WorkerEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByWorkers(key).reference()
      .add(target);
  }
  public removeWorkerEdmFromCreatedByWorkers(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByWorkers(key).reference()
      .remove(target);
  }
  public createdByWorkerTypes(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<WorkerTypeEdm> { 
    return this.entity(key).navigationProperty<WorkerTypeEdm>('createdByWorkerTypes'); 
  }
  public fetchCreatedByWorkerTypes(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<WorkerTypeEdm>) {
    return this.fetchNavigationProperty<WorkerTypeEdm>(
      this.createdByWorkerTypes(key), 
      'entities', options) as Observable<ODataEntities<WorkerTypeEdm>>;
  }
  public addWorkerTypeEdmToCreatedByWorkerTypes(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<WorkerTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByWorkerTypes(key).reference()
      .add(target);
  }
  public removeWorkerTypeEdmFromCreatedByWorkerTypes(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByWorkerTypes(key).reference()
      .remove(target);
  }
  public customerAgencyPersons(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<CustomerAgencyPersonEdm> { 
    return this.entity(key).navigationProperty<CustomerAgencyPersonEdm>('customerAgencyPersons'); 
  }
  public fetchCustomerAgencyPersons(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<CustomerAgencyPersonEdm>) {
    return this.fetchNavigationProperty<CustomerAgencyPersonEdm>(
      this.customerAgencyPersons(key), 
      'entities', options) as Observable<ODataEntities<CustomerAgencyPersonEdm>>;
  }
  public addCustomerAgencyPersonEdmToCustomerAgencyPersons(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<CustomerAgencyPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customerAgencyPersons(key).reference()
      .add(target);
  }
  public removeCustomerAgencyPersonEdmFromCustomerAgencyPersons(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CustomerAgencyPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.customerAgencyPersons(key).reference()
      .remove(target);
  }
  public customerPersons(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<CustomerPersonEdm> { 
    return this.entity(key).navigationProperty<CustomerPersonEdm>('customerPersons'); 
  }
  public fetchCustomerPersons(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<CustomerPersonEdm>) {
    return this.fetchNavigationProperty<CustomerPersonEdm>(
      this.customerPersons(key), 
      'entities', options) as Observable<ODataEntities<CustomerPersonEdm>>;
  }
  public addCustomerPersonEdmToCustomerPersons(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<CustomerPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customerPersons(key).reference()
      .add(target);
  }
  public removeCustomerPersonEdmFromCustomerPersons(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CustomerPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.customerPersons(key).reference()
      .remove(target);
  }
  public expiredByOptiaNews(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<OptiaNewEdm> { 
    return this.entity(key).navigationProperty<OptiaNewEdm>('expiredByOptiaNews'); 
  }
  public fetchExpiredByOptiaNews(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<OptiaNewEdm>) {
    return this.fetchNavigationProperty<OptiaNewEdm>(
      this.expiredByOptiaNews(key), 
      'entities', options) as Observable<ODataEntities<OptiaNewEdm>>;
  }
  public addOptiaNewEdmToExpiredByOptiaNews(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<OptiaNewEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.expiredByOptiaNews(key).reference()
      .add(target);
  }
  public removeOptiaNewEdmFromExpiredByOptiaNews(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<OptiaNewEdm>>, etag?: string} = {}): Observable<any> {
    return this.expiredByOptiaNews(key).reference()
      .remove(target);
  }
  public lastEditedByOptiaNews(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<OptiaNewEdm> { 
    return this.entity(key).navigationProperty<OptiaNewEdm>('lastEditedByOptiaNews'); 
  }
  public fetchLastEditedByOptiaNews(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<OptiaNewEdm>) {
    return this.fetchNavigationProperty<OptiaNewEdm>(
      this.lastEditedByOptiaNews(key), 
      'entities', options) as Observable<ODataEntities<OptiaNewEdm>>;
  }
  public addOptiaNewEdmToLastEditedByOptiaNews(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<OptiaNewEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.lastEditedByOptiaNews(key).reference()
      .add(target);
  }
  public removeOptiaNewEdmFromLastEditedByOptiaNews(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<OptiaNewEdm>>, etag?: string} = {}): Observable<any> {
    return this.lastEditedByOptiaNews(key).reference()
      .remove(target);
  }
  public miReportGroupPersons(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<MiReportGroupPersonEdm> { 
    return this.entity(key).navigationProperty<MiReportGroupPersonEdm>('miReportGroupPersons'); 
  }
  public fetchMiReportGroupPersons(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<MiReportGroupPersonEdm>) {
    return this.fetchNavigationProperty<MiReportGroupPersonEdm>(
      this.miReportGroupPersons(key), 
      'entities', options) as Observable<ODataEntities<MiReportGroupPersonEdm>>;
  }
  public addMiReportGroupPersonEdmToMiReportGroupPersons(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<MiReportGroupPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.miReportGroupPersons(key).reference()
      .add(target);
  }
  public removeMiReportGroupPersonEdmFromMiReportGroupPersons(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<MiReportGroupPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.miReportGroupPersons(key).reference()
      .remove(target);
  }
  public miReportTypePersons(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<MiReportTypePersonEdm> { 
    return this.entity(key).navigationProperty<MiReportTypePersonEdm>('miReportTypePersons'); 
  }
  public fetchMiReportTypePersons(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<MiReportTypePersonEdm>) {
    return this.fetchNavigationProperty<MiReportTypePersonEdm>(
      this.miReportTypePersons(key), 
      'entities', options) as Observable<ODataEntities<MiReportTypePersonEdm>>;
  }
  public addMiReportTypePersonEdmToMiReportTypePersons(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<MiReportTypePersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.miReportTypePersons(key).reference()
      .add(target);
  }
  public removeMiReportTypePersonEdmFromMiReportTypePersons(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<MiReportTypePersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.miReportTypePersons(key).reference()
      .remove(target);
  }
  public notificationPersons(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<NotificationPersonEdm> { 
    return this.entity(key).navigationProperty<NotificationPersonEdm>('notificationPersons'); 
  }
  public fetchNotificationPersons(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<NotificationPersonEdm>) {
    return this.fetchNavigationProperty<NotificationPersonEdm>(
      this.notificationPersons(key), 
      'entities', options) as Observable<ODataEntities<NotificationPersonEdm>>;
  }
  public addNotificationPersonEdmToNotificationPersons(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<NotificationPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.notificationPersons(key).reference()
      .add(target);
  }
  public removeNotificationPersonEdmFromNotificationPersons(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<NotificationPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.notificationPersons(key).reference()
      .remove(target);
  }
  public optiaNewsPersons(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<OptiaNewsPersonEdm> { 
    return this.entity(key).navigationProperty<OptiaNewsPersonEdm>('optiaNewsPersons'); 
  }
  public fetchOptiaNewsPersons(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<OptiaNewsPersonEdm>) {
    return this.fetchNavigationProperty<OptiaNewsPersonEdm>(
      this.optiaNewsPersons(key), 
      'entities', options) as Observable<ODataEntities<OptiaNewsPersonEdm>>;
  }
  public addOptiaNewsPersonEdmToOptiaNewsPersons(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<OptiaNewsPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.optiaNewsPersons(key).reference()
      .add(target);
  }
  public removeOptiaNewsPersonEdmFromOptiaNewsPersons(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<OptiaNewsPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.optiaNewsPersons(key).reference()
      .remove(target);
  }
  public personAddresses(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PersonAddressEdm> { 
    return this.entity(key).navigationProperty<PersonAddressEdm>('personAddresses'); 
  }
  public fetchPersonAddresses(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PersonAddressEdm>) {
    return this.fetchNavigationProperty<PersonAddressEdm>(
      this.personAddresses(key), 
      'entities', options) as Observable<ODataEntities<PersonAddressEdm>>;
  }
  public addPersonAddressEdmToPersonAddresses(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<PersonAddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personAddresses(key).reference()
      .add(target);
  }
  public removePersonAddressEdmFromPersonAddresses(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonAddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.personAddresses(key).reference()
      .remove(target);
  }
  public personDepartments(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PersonDepartmentEdm> { 
    return this.entity(key).navigationProperty<PersonDepartmentEdm>('personDepartments'); 
  }
  public fetchPersonDepartments(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PersonDepartmentEdm>) {
    return this.fetchNavigationProperty<PersonDepartmentEdm>(
      this.personDepartments(key), 
      'entities', options) as Observable<ODataEntities<PersonDepartmentEdm>>;
  }
  public addPersonDepartmentEdmToPersonDepartments(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<PersonDepartmentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personDepartments(key).reference()
      .add(target);
  }
  public removePersonDepartmentEdmFromPersonDepartments(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonDepartmentEdm>>, etag?: string} = {}): Observable<any> {
    return this.personDepartments(key).reference()
      .remove(target);
  }
  public personPhoneNumbers(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PersonPhoneNumberEdm> { 
    return this.entity(key).navigationProperty<PersonPhoneNumberEdm>('personPhoneNumbers'); 
  }
  public fetchPersonPhoneNumbers(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PersonPhoneNumberEdm>) {
    return this.fetchNavigationProperty<PersonPhoneNumberEdm>(
      this.personPhoneNumbers(key), 
      'entities', options) as Observable<ODataEntities<PersonPhoneNumberEdm>>;
  }
  public addPersonPhoneNumberEdmToPersonPhoneNumbers(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<PersonPhoneNumberEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personPhoneNumbers(key).reference()
      .add(target);
  }
  public removePersonPhoneNumberEdmFromPersonPhoneNumbers(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonPhoneNumberEdm>>, etag?: string} = {}): Observable<any> {
    return this.personPhoneNumbers(key).reference()
      .remove(target);
  }
  public personUserGroups(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PersonUserGroupEdm> { 
    return this.entity(key).navigationProperty<PersonUserGroupEdm>('personUserGroups'); 
  }
  public fetchPersonUserGroups(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PersonUserGroupEdm>) {
    return this.fetchNavigationProperty<PersonUserGroupEdm>(
      this.personUserGroups(key), 
      'entities', options) as Observable<ODataEntities<PersonUserGroupEdm>>;
  }
  public addPersonUserGroupEdmToPersonUserGroups(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<PersonUserGroupEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personUserGroups(key).reference()
      .add(target);
  }
  public removePersonUserGroupEdmFromPersonUserGroups(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonUserGroupEdm>>, etag?: string} = {}): Observable<any> {
    return this.personUserGroups(key).reference()
      .remove(target);
  }
  public supplierPersons(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<SupplierPersonEdm> { 
    return this.entity(key).navigationProperty<SupplierPersonEdm>('supplierPersons'); 
  }
  public fetchSupplierPersons(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<SupplierPersonEdm>) {
    return this.fetchNavigationProperty<SupplierPersonEdm>(
      this.supplierPersons(key), 
      'entities', options) as Observable<ODataEntities<SupplierPersonEdm>>;
  }
  public addSupplierPersonEdmToSupplierPersons(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<SupplierPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.supplierPersons(key).reference()
      .add(target);
  }
  public removeSupplierPersonEdmFromSupplierPersons(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<SupplierPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.supplierPersons(key).reference()
      .remove(target);
  }
  public uploadedByDocuments(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<DocumentEdm> { 
    return this.entity(key).navigationProperty<DocumentEdm>('uploadedByDocuments'); 
  }
  public fetchUploadedByDocuments(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<DocumentEdm>) {
    return this.fetchNavigationProperty<DocumentEdm>(
      this.uploadedByDocuments(key), 
      'entities', options) as Observable<ODataEntities<DocumentEdm>>;
  }
  public addDocumentEdmToUploadedByDocuments(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<DocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.uploadedByDocuments(key).reference()
      .add(target);
  }
  public removeDocumentEdmFromUploadedByDocuments(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<DocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.uploadedByDocuments(key).reference()
      .remove(target);
  }
  public workerPersons(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<WorkerPersonEdm> { 
    return this.entity(key).navigationProperty<WorkerPersonEdm>('workerPersons'); 
  }
  public fetchWorkerPersons(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<WorkerPersonEdm>) {
    return this.fetchNavigationProperty<WorkerPersonEdm>(
      this.workerPersons(key), 
      'entities', options) as Observable<ODataEntities<WorkerPersonEdm>>;
  }
  public addWorkerPersonEdmToWorkerPersons(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<WorkerPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerPersons(key).reference()
      .add(target);
  }
  public removeWorkerPersonEdmFromWorkerPersons(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerPersons(key).reference()
      .remove(target);
  }
  public workerTypePersons(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<WorkerTypePersonEdm> { 
    return this.entity(key).navigationProperty<WorkerTypePersonEdm>('workerTypePersons'); 
  }
  public fetchWorkerTypePersons(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<WorkerTypePersonEdm>) {
    return this.fetchNavigationProperty<WorkerTypePersonEdm>(
      this.workerTypePersons(key), 
      'entities', options) as Observable<ODataEntities<WorkerTypePersonEdm>>;
  }
  public addWorkerTypePersonEdmToWorkerTypePersons(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<WorkerTypePersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerTypePersons(key).reference()
      .add(target);
  }
  public removeWorkerTypePersonEdmFromWorkerTypePersons(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerTypePersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerTypePersons(key).reference()
      .remove(target);
  }
  //#endregion
}
