import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MiReportTypeEdm } from './DataDomain/EFEntities/Reporting/mireporttypeedm.entity';
import { MiReportTypeValidationRuleEdm } from './DataDomain/EFEntities/Reporting/mireporttypevalidationruleedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class MiReportTypeValidationRulesService extends ODataEntitySetService<MiReportTypeValidationRuleEdm> {
  constructor(client: ODataClient) {
    super(client, 'MiReportTypeValidationRules', 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportTypeValidationRuleEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public createdByPerson(key: EntityKey<MiReportTypeValidationRuleEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<MiReportTypeValidationRuleEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<MiReportTypeValidationRuleEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<MiReportTypeValidationRuleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public miReportType(key: EntityKey<MiReportTypeValidationRuleEdm>): ODataNavigationPropertyResource<MiReportTypeEdm> { 
    return this.entity(key).navigationProperty<MiReportTypeEdm>('miReportType'); 
  }
  public fetchMiReportType(key: EntityKey<MiReportTypeValidationRuleEdm>, options?: ODataQueryArgumentsOptions<MiReportTypeEdm>) {
    return this.fetchNavigationProperty<MiReportTypeEdm>(
      this.miReportType(key), 
      'entity', options) as Observable<ODataEntity<MiReportTypeEdm>>;
  }
  public setMiReportTypeEdmAsMiReportType(key: EntityKey<MiReportTypeValidationRuleEdm>, target: ODataEntityResource<ODataEntity<MiReportTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.miReportType(key).reference()
      .set(target, {etag});
  }
  public unsetMiReportTypeEdmAsMiReportType(key: EntityKey<MiReportTypeValidationRuleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<MiReportTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.miReportType(key).reference()
      .unset({etag});
  }
  //#endregion
}
