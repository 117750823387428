//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyReportingCombinationsService } from './agencyreportingcombinations.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AgencyReportingCombinationsServiceEntitySetConfig = {
  name: 'AgencyReportingCombinations',
  entityType: 'OptiaApi.DataDomain.EFEntities.AgencyReportingCombinationEdm',
  service: AgencyReportingCombinationsService
} as EntitySetConfig;
//#endregion