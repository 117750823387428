import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MiReportGroupEdm } from './DataDomain/EFEntities/Reporting/mireportgroupedm.entity';
import { MiReportTypeEdm } from './DataDomain/EFEntities/Reporting/mireporttypeedm.entity';
import { MiReportTypePersonEdm } from './DataDomain/EFEntities/Reporting/mireporttypepersonedm.entity';
import { MiReportTypeValidationRuleEdm } from './DataDomain/EFEntities/Reporting/mireporttypevalidationruleedm.entity';
import { TransferMiReportTypeEdm } from './DataDomain/EFEntities/Reporting/transfermireporttypeedm.entity';
import { TransferLogEdm } from './DataDomain/EFEntities/Reporting/transferlogedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class MiReportTypesService extends ODataEntitySetService<MiReportTypeEdm> {
  constructor(client: ODataClient) {
    super(client, 'MiReportTypes', 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportTypeEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public createdByPerson(key: EntityKey<MiReportTypeEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<MiReportTypeEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<MiReportTypeEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<MiReportTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public miReportGroup(key: EntityKey<MiReportTypeEdm>): ODataNavigationPropertyResource<MiReportGroupEdm> { 
    return this.entity(key).navigationProperty<MiReportGroupEdm>('miReportGroup'); 
  }
  public fetchMiReportGroup(key: EntityKey<MiReportTypeEdm>, options?: ODataQueryArgumentsOptions<MiReportGroupEdm>) {
    return this.fetchNavigationProperty<MiReportGroupEdm>(
      this.miReportGroup(key), 
      'entity', options) as Observable<ODataEntity<MiReportGroupEdm>>;
  }
  public setMiReportGroupEdmAsMiReportGroup(key: EntityKey<MiReportTypeEdm>, target: ODataEntityResource<ODataEntity<MiReportGroupEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.miReportGroup(key).reference()
      .set(target, {etag});
  }
  public unsetMiReportGroupEdmAsMiReportGroup(key: EntityKey<MiReportTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<MiReportGroupEdm>>, etag?: string} = {}): Observable<any> {
    return this.miReportGroup(key).reference()
      .unset({etag});
  }
  public miReportTypePersons(key: EntityKey<MiReportTypeEdm>): ODataNavigationPropertyResource<MiReportTypePersonEdm> { 
    return this.entity(key).navigationProperty<MiReportTypePersonEdm>('miReportTypePersons'); 
  }
  public fetchMiReportTypePersons(key: EntityKey<MiReportTypeEdm>, options?: ODataQueryArgumentsOptions<MiReportTypePersonEdm>) {
    return this.fetchNavigationProperty<MiReportTypePersonEdm>(
      this.miReportTypePersons(key), 
      'entities', options) as Observable<ODataEntities<MiReportTypePersonEdm>>;
  }
  public addMiReportTypePersonEdmToMiReportTypePersons(key: EntityKey<MiReportTypeEdm>, target: ODataEntityResource<ODataEntities<MiReportTypePersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.miReportTypePersons(key).reference()
      .add(target);
  }
  public removeMiReportTypePersonEdmFromMiReportTypePersons(key: EntityKey<MiReportTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<MiReportTypePersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.miReportTypePersons(key).reference()
      .remove(target);
  }
  public miReportTypeValidationRules(key: EntityKey<MiReportTypeEdm>): ODataNavigationPropertyResource<MiReportTypeValidationRuleEdm> { 
    return this.entity(key).navigationProperty<MiReportTypeValidationRuleEdm>('miReportTypeValidationRules'); 
  }
  public fetchMiReportTypeValidationRules(key: EntityKey<MiReportTypeEdm>, options?: ODataQueryArgumentsOptions<MiReportTypeValidationRuleEdm>) {
    return this.fetchNavigationProperty<MiReportTypeValidationRuleEdm>(
      this.miReportTypeValidationRules(key), 
      'entities', options) as Observable<ODataEntities<MiReportTypeValidationRuleEdm>>;
  }
  public addMiReportTypeValidationRuleEdmToMiReportTypeValidationRules(key: EntityKey<MiReportTypeEdm>, target: ODataEntityResource<ODataEntities<MiReportTypeValidationRuleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.miReportTypeValidationRules(key).reference()
      .add(target);
  }
  public removeMiReportTypeValidationRuleEdmFromMiReportTypeValidationRules(key: EntityKey<MiReportTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<MiReportTypeValidationRuleEdm>>, etag?: string} = {}): Observable<any> {
    return this.miReportTypeValidationRules(key).reference()
      .remove(target);
  }
  public transferLogs(key: EntityKey<MiReportTypeEdm>): ODataNavigationPropertyResource<TransferLogEdm> { 
    return this.entity(key).navigationProperty<TransferLogEdm>('transferLogs'); 
  }
  public fetchTransferLogs(key: EntityKey<MiReportTypeEdm>, options?: ODataQueryArgumentsOptions<TransferLogEdm>) {
    return this.fetchNavigationProperty<TransferLogEdm>(
      this.transferLogs(key), 
      'entities', options) as Observable<ODataEntities<TransferLogEdm>>;
  }
  public addTransferLogEdmToTransferLogs(key: EntityKey<MiReportTypeEdm>, target: ODataEntityResource<ODataEntities<TransferLogEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.transferLogs(key).reference()
      .add(target);
  }
  public removeTransferLogEdmFromTransferLogs(key: EntityKey<MiReportTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<TransferLogEdm>>, etag?: string} = {}): Observable<any> {
    return this.transferLogs(key).reference()
      .remove(target);
  }
  public transferMiReportTypes(key: EntityKey<MiReportTypeEdm>): ODataNavigationPropertyResource<TransferMiReportTypeEdm> { 
    return this.entity(key).navigationProperty<TransferMiReportTypeEdm>('transferMiReportTypes'); 
  }
  public fetchTransferMiReportTypes(key: EntityKey<MiReportTypeEdm>, options?: ODataQueryArgumentsOptions<TransferMiReportTypeEdm>) {
    return this.fetchNavigationProperty<TransferMiReportTypeEdm>(
      this.transferMiReportTypes(key), 
      'entities', options) as Observable<ODataEntities<TransferMiReportTypeEdm>>;
  }
  public addTransferMiReportTypeEdmToTransferMiReportTypes(key: EntityKey<MiReportTypeEdm>, target: ODataEntityResource<ODataEntities<TransferMiReportTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.transferMiReportTypes(key).reference()
      .add(target);
  }
  public removeTransferMiReportTypeEdmFromTransferMiReportTypes(key: EntityKey<MiReportTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<TransferMiReportTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.transferMiReportTypes(key).reference()
      .remove(target);
  }
  //#endregion
}
