import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuditEdm } from '@app/odata/OptiaApi/DataDomain/EFEntities/Admin/auditedm.entity';
import { ODataServiceFactory } from 'angular-odata';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(private http: HttpClient, private factory: ODataServiceFactory) { }

  createAudit(audit: AuditEdm) {
    let auditService = this.factory.entitySet<AuditEdm>(
      "Audits",
      "OptiaApi.DataDomain.EFEntities.AuditEdm"
    );
    return auditService.create(audit);
  }

}
