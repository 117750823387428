//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OptiaNewsDocumentDto } from './optianewsdocumentdto.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const OptiaNewsDocumentDtoComplexConfig = {
  name: 'OptiaNewsDocumentDto',
  fields: {
    optiaNewsGuid: {type: 'Edm.Guid', nullable: false},
    documentGuid: {type: 'Edm.Guid', nullable: false},
    document: {type: 'OptiaApi.DocumentDto'}
  }
} as StructuredTypeConfig<OptiaNewsDocumentDto>;
//#endregion