//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OptiaNewsDetailEdmComplexConfig } from './optianewsdetailedm.complex.config';
import { NotificationEdmEntityConfig } from './notificationedm.entity.config';
import { NotificationPersonEdmEntityConfig } from './notificationpersonedm.entity.config';
import { NotificationTemplateEdmEntityConfig } from './notificationtemplateedm.entity.config';
import { OptiaNewEdmEntityConfig } from './optianewedm.entity.config';
import { OptiaNewsDocumentEdmEntityConfig } from './optianewsdocumentedm.entity.config';
import { OptiaNewsPersonEdmEntityConfig } from './optianewspersonedm.entity.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const NotifySchema = {
  namespace: 'OptiaApi.DataDomain.EFEntities.Notify',
  enums: [],
  entities: [OptiaNewsDetailEdmComplexConfig,
    NotificationEdmEntityConfig,
    NotificationPersonEdmEntityConfig,
    NotificationTemplateEdmEntityConfig,
    OptiaNewEdmEntityConfig,
    OptiaNewsDocumentEdmEntityConfig,
    OptiaNewsPersonEdmEntityConfig],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion