import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PhoneNumberEdm } from './DataDomain/EFEntities/phonenumberedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
import { PersonPhoneNumberEdm } from './DataDomain/EFEntities/Person/personphonenumberedm.entity';
import { WorkerPhoneNumberEdm } from './DataDomain/EFEntities/Worker/workerphonenumberedm.entity';
//#endregion

@Injectable()
export class PhoneNumbersService extends ODataEntitySetService<PhoneNumberEdm> {
  constructor(client: ODataClient) {
    super(client, 'PhoneNumbers', 'OptiaApi.DataDomain.EFEntities.PhoneNumberEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public createdByPerson(key: EntityKey<PhoneNumberEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<PhoneNumberEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<PhoneNumberEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<PhoneNumberEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public personPhoneNumbers(key: EntityKey<PhoneNumberEdm>): ODataNavigationPropertyResource<PersonPhoneNumberEdm> { 
    return this.entity(key).navigationProperty<PersonPhoneNumberEdm>('personPhoneNumbers'); 
  }
  public fetchPersonPhoneNumbers(key: EntityKey<PhoneNumberEdm>, options?: ODataQueryArgumentsOptions<PersonPhoneNumberEdm>) {
    return this.fetchNavigationProperty<PersonPhoneNumberEdm>(
      this.personPhoneNumbers(key), 
      'entities', options) as Observable<ODataEntities<PersonPhoneNumberEdm>>;
  }
  public addPersonPhoneNumberEdmToPersonPhoneNumbers(key: EntityKey<PhoneNumberEdm>, target: ODataEntityResource<ODataEntities<PersonPhoneNumberEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personPhoneNumbers(key).reference()
      .add(target);
  }
  public removePersonPhoneNumberEdmFromPersonPhoneNumbers(key: EntityKey<PhoneNumberEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonPhoneNumberEdm>>, etag?: string} = {}): Observable<any> {
    return this.personPhoneNumbers(key).reference()
      .remove(target);
  }
  public workerPhoneNumbers(key: EntityKey<PhoneNumberEdm>): ODataNavigationPropertyResource<WorkerPhoneNumberEdm> { 
    return this.entity(key).navigationProperty<WorkerPhoneNumberEdm>('workerPhoneNumbers'); 
  }
  public fetchWorkerPhoneNumbers(key: EntityKey<PhoneNumberEdm>, options?: ODataQueryArgumentsOptions<WorkerPhoneNumberEdm>) {
    return this.fetchNavigationProperty<WorkerPhoneNumberEdm>(
      this.workerPhoneNumbers(key), 
      'entities', options) as Observable<ODataEntities<WorkerPhoneNumberEdm>>;
  }
  public addWorkerPhoneNumberEdmToWorkerPhoneNumbers(key: EntityKey<PhoneNumberEdm>, target: ODataEntityResource<ODataEntities<WorkerPhoneNumberEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerPhoneNumbers(key).reference()
      .add(target);
  }
  public removeWorkerPhoneNumberEdmFromWorkerPhoneNumbers(key: EntityKey<PhoneNumberEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerPhoneNumberEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerPhoneNumbers(key).reference()
      .remove(target);
  }
  //#endregion
}
