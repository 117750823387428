//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DocumentEdm } from './documentedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const DocumentEdmEntityConfig = {
  name: 'DocumentEdm',
  keys: [{name: 'documentGuid'}],
  fields: {
    documentGuid: {type: 'Edm.Guid', nullable: false},
    uploadedOn: {type: 'Edm.DateTimeOffset'},
    uploadedBy: {type: 'Edm.Guid'},
    filename: {type: 'Edm.String', nullable: false},
    mimeType: {type: 'Edm.String', nullable: false},
    data: {type: 'Edm.Binary', nullable: false},
    documentType: {type: 'Edm.Int16', nullable: false},
    deleted: {type: 'Edm.DateTimeOffset'},
    approved: {type: 'Edm.Boolean'},
    uploadedByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'uploadedBy', referencedProperty: 'personGuid'}]},
    agencyDocuments: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyDocumentEdm', collection: true, navigation: true},
    customerAgencyDocuments: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyDocumentEdm', collection: true, navigation: true},
    customerDocuments: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerDocumentEdm', collection: true, navigation: true},
    optiaNewsDocuments: {type: 'OptiaApi.DataDomain.EFEntities.Notify.OptiaNewsDocumentEdm', collection: true, navigation: true},
    supplierDocuments: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierDocumentEdm', collection: true, navigation: true},
    workerDocuments: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerDocumentEdm', collection: true, navigation: true},
    workerTypeDocuments: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeDocumentEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<DocumentEdm>;
//#endregion