import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerDetailEdm } from './DataDomain/EFEntities/Customer/customerdetailedm.entity';
//#endregion

@Injectable()
export class CustomerDetailEdmService extends ODataEntitySetService<CustomerDetailEdm> {
  constructor(client: ODataClient) {
    super(client, 'CustomerDetailEdm', 'OptiaApi.DataDomain.EFEntities.Customer.CustomerDetailEdm');
  }
  //#region ODataApiGen Actions
  public searchCustomerDetails(): ODataActionResource<{filter: string, statusFilter: number, statusAgencyFilter: number, typeFilter: number, verificationOutcome: string, skip: number, take: number, sortColumn: string, sortOrder: string}, CustomerDetailEdm> { 
    return this.entities().action<{filter: string, statusFilter: number, statusAgencyFilter: number, typeFilter: number, verificationOutcome: string, skip: number, take: number, sortColumn: string, sortOrder: string}, CustomerDetailEdm>('OptiaApi.SearchCustomerDetails');
  }
  public callSearchCustomerDetails(filter: string, statusFilter: number, statusAgencyFilter: number, typeFilter: number, verificationOutcome: string, skip: number, take: number, sortColumn: string, sortOrder: string, options?: ODataActionOptions<CustomerDetailEdm>) {
    return this.callAction<{filter: string, statusFilter: number, statusAgencyFilter: number, typeFilter: number, verificationOutcome: string, skip: number, take: number, sortColumn: string, sortOrder: string}, CustomerDetailEdm>(
      {filter, statusFilter, statusAgencyFilter, typeFilter, verificationOutcome, skip, take, sortColumn, sortOrder}, 
      this.searchCustomerDetails(), 
      'entities', options) as Observable<ODataEntities<CustomerDetailEdm>>;
  }
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  //#endregion
}
