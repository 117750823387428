//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerTypeReportingLabelsService } from './workertypereportinglabels.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WorkerTypeReportingLabelsServiceEntitySetConfig = {
  name: 'WorkerTypeReportingLabels',
  entityType: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeReportingLabelEdm',
  service: WorkerTypeReportingLabelsService
} as EntitySetConfig;
//#endregion