//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PermissionsService } from './permissions.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PermissionsServiceEntitySetConfig = {
  name: 'Permissions',
  entityType: 'OptiaApi.DataDomain.EFEntities.Admin.PermissionEdm',
  service: PermissionsService
} as EntitySetConfig;
//#endregion