//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ReportingLabelsService } from './reportinglabels.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ReportingLabelsServiceEntitySetConfig = {
  name: 'ReportingLabels',
  entityType: 'OptiaApi.DataDomain.EFEntities.Reporting.ReportingLabelEdm',
  service: ReportingLabelsService
} as EntitySetConfig;
//#endregion