//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TransferRevisionEdm } from './transferrevisionedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const TransferRevisionEdmEntityConfig = {
  name: 'TransferRevisionEdm',
  keys: [{name: 'transferGuid'}],
  fields: {
    transferGuid: {type: 'Edm.Guid'},
    zDrive: {type: 'Edm.String', maxLength: -1},
    portal: {type: 'Edm.String', maxLength: -1},
    tab: {type: 'Edm.String', maxLength: -1},
    transfer: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferEdm', navigation: true, referentials: [{property: 'transferGuid', referencedProperty: 'transferGuid'}]}
  }
} as StructuredTypeConfig<TransferRevisionEdm>;
//#endregion