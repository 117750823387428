//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTasksService } from './wftasks.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WfTasksServiceEntitySetConfig = {
  name: 'WfTasks',
  entityType: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskEdm',
  service: WfTasksService
} as EntitySetConfig;
//#endregion