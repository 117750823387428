//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerPersonsService } from './customerpersons.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CustomerPersonsServiceEntitySetConfig = {
  name: 'CustomerPersons',
  entityType: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerPersonEdm',
  service: CustomerPersonsService
} as EntitySetConfig;
//#endregion