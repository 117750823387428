//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { RefDataItemEdm } from './refdataitemedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const RefDataItemEdmEntityConfig = {
  name: 'RefDataItemEdm',
  keys: [{name: 'refDataItemGuid'}],
  fields: {
    refDataItemGuid: {type: 'Edm.Guid', nullable: false},
    refDataTypeGuid: {type: 'Edm.Guid'},
    tag: {type: 'Edm.String'},
    value: {type: 'Edm.String'},
    description: {type: 'Edm.String'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    readOnly: {type: 'Edm.Boolean'},
    refDataType: {type: 'OptiaApi.DataDomain.EFEntities.RefData.RefDataTypeEdm', navigation: true, referentials: [{property: 'refDataTypeGuid', referencedProperty: 'refDataTypeGuid'}]},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<RefDataItemEdm>;
//#endregion