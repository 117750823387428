//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfActionsService } from './wfactions.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WfActionsServiceEntitySetConfig = {
  name: 'WfActions',
  entityType: 'OptiaApi.DataDomain.EFEntities.Workflow.WfActionEdm',
  service: WfActionsService
} as EntitySetConfig;
//#endregion