import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DocumentEdm } from './DataDomain/EFEntities/documentedm.entity';
import { CustomerAgencyEdm } from './DataDomain/EFEntities/Customer/customeragencyedm.entity';
import { CustomerAgencyDocumentEdm } from './DataDomain/EFEntities/Customer/customeragencydocumentedm.entity';
//#endregion

@Injectable()
export class CustomerAgencyDocumentsService extends ODataEntitySetService<CustomerAgencyDocumentEdm> {
  constructor(client: ODataClient) {
    super(client, 'CustomerAgencyDocuments', 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyDocumentEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public customerAgency(key: EntityKey<CustomerAgencyDocumentEdm>): ODataNavigationPropertyResource<CustomerAgencyEdm> { 
    return this.entity(key).navigationProperty<CustomerAgencyEdm>('customerAgency'); 
  }
  public fetchCustomerAgency(key: EntityKey<CustomerAgencyDocumentEdm>, options?: ODataQueryArgumentsOptions<CustomerAgencyEdm>) {
    return this.fetchNavigationProperty<CustomerAgencyEdm>(
      this.customerAgency(key), 
      'entity', options) as Observable<ODataEntity<CustomerAgencyEdm>>;
  }
  public setCustomerAgencyEdmAsCustomerAgency(key: EntityKey<CustomerAgencyDocumentEdm>, target: ODataEntityResource<ODataEntity<CustomerAgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customerAgency(key).reference()
      .set(target, {etag});
  }
  public unsetCustomerAgencyEdmAsCustomerAgency(key: EntityKey<CustomerAgencyDocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<CustomerAgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.customerAgency(key).reference()
      .unset({etag});
  }
  public document(key: EntityKey<CustomerAgencyDocumentEdm>): ODataNavigationPropertyResource<DocumentEdm> { 
    return this.entity(key).navigationProperty<DocumentEdm>('document'); 
  }
  public fetchDocument(key: EntityKey<CustomerAgencyDocumentEdm>, options?: ODataQueryArgumentsOptions<DocumentEdm>) {
    return this.fetchNavigationProperty<DocumentEdm>(
      this.document(key), 
      'entity', options) as Observable<ODataEntity<DocumentEdm>>;
  }
  public setDocumentEdmAsDocument(key: EntityKey<CustomerAgencyDocumentEdm>, target: ODataEntityResource<ODataEntity<DocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.document(key).reference()
      .set(target, {etag});
  }
  public unsetDocumentEdmAsDocument(key: EntityKey<CustomerAgencyDocumentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<DocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.document(key).reference()
      .unset({etag});
  }
  //#endregion
}
