//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SetPasswordDTO } from './setpassworddto.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const SetPasswordDTOEntityConfig = {
  name: 'SetPasswordDTO',
  keys: [{name: 'identityGuid'}],
  fields: {
    identityGuid: {type: 'Edm.Guid', nullable: false},
    password: {type: 'Edm.String', nullable: false}
  }
} as StructuredTypeConfig<SetPasswordDTO>;
//#endregion