//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonDepartmentsService } from './persondepartments.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PersonDepartmentsServiceEntitySetConfig = {
  name: 'PersonDepartments',
  entityType: 'OptiaApi.DataDomain.EFEntities.Person.PersonDepartmentEdm',
  service: PersonDepartmentsService
} as EntitySetConfig;
//#endregion