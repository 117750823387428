//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerDocumentEdm } from './workerdocumentedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WorkerDocumentEdmEntityConfig = {
  name: 'WorkerDocumentEdm',
  keys: [{name: 'workerDocumentGuid'}],
  fields: {
    workerGuid: {type: 'Edm.Guid'},
    documentGuid: {type: 'Edm.Guid'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    workerDocumentGuid: {type: 'Edm.Guid', nullable: false},
    document: {type: 'OptiaApi.DataDomain.EFEntities.DocumentEdm', navigation: true, referentials: [{property: 'documentGuid', referencedProperty: 'documentGuid'}]},
    worker: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerEdm', navigation: true, referentials: [{property: 'workerGuid', referencedProperty: 'workerGuid'}]}
  }
} as StructuredTypeConfig<WorkerDocumentEdm>;
//#endregion