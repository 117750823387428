import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MiReportGroupEdm } from './DataDomain/EFEntities/Reporting/mireportgroupedm.entity';
import { MiReportGroupPersonEdm } from './DataDomain/EFEntities/Reporting/mireportgrouppersonedm.entity';
import { MiReportTypeEdm } from './DataDomain/EFEntities/Reporting/mireporttypeedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class MiReportGroupsService extends ODataEntitySetService<MiReportGroupEdm> {
  constructor(client: ODataClient) {
    super(client, 'MiReportGroups', 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportGroupEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public createdByPerson(key: EntityKey<MiReportGroupEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<MiReportGroupEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<MiReportGroupEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<MiReportGroupEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public miReportGroupPersons(key: EntityKey<MiReportGroupEdm>): ODataNavigationPropertyResource<MiReportGroupPersonEdm> { 
    return this.entity(key).navigationProperty<MiReportGroupPersonEdm>('miReportGroupPersons'); 
  }
  public fetchMiReportGroupPersons(key: EntityKey<MiReportGroupEdm>, options?: ODataQueryArgumentsOptions<MiReportGroupPersonEdm>) {
    return this.fetchNavigationProperty<MiReportGroupPersonEdm>(
      this.miReportGroupPersons(key), 
      'entities', options) as Observable<ODataEntities<MiReportGroupPersonEdm>>;
  }
  public addMiReportGroupPersonEdmToMiReportGroupPersons(key: EntityKey<MiReportGroupEdm>, target: ODataEntityResource<ODataEntities<MiReportGroupPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.miReportGroupPersons(key).reference()
      .add(target);
  }
  public removeMiReportGroupPersonEdmFromMiReportGroupPersons(key: EntityKey<MiReportGroupEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<MiReportGroupPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.miReportGroupPersons(key).reference()
      .remove(target);
  }
  public miReportTypes(key: EntityKey<MiReportGroupEdm>): ODataNavigationPropertyResource<MiReportTypeEdm> { 
    return this.entity(key).navigationProperty<MiReportTypeEdm>('miReportTypes'); 
  }
  public fetchMiReportTypes(key: EntityKey<MiReportGroupEdm>, options?: ODataQueryArgumentsOptions<MiReportTypeEdm>) {
    return this.fetchNavigationProperty<MiReportTypeEdm>(
      this.miReportTypes(key), 
      'entities', options) as Observable<ODataEntities<MiReportTypeEdm>>;
  }
  public addMiReportTypeEdmToMiReportTypes(key: EntityKey<MiReportGroupEdm>, target: ODataEntityResource<ODataEntities<MiReportTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.miReportTypes(key).reference()
      .add(target);
  }
  public removeMiReportTypeEdmFromMiReportTypes(key: EntityKey<MiReportGroupEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<MiReportTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.miReportTypes(key).reference()
      .remove(target);
  }
  //#endregion
}
