import { Injectable, Inject } from '@angular/core';

import { MsalService } from '@azure/msal-angular';

import { IInactivityLogoutConfig, INACTIVITY_CONFIG } from '../../inactivity-logout-config'

@Injectable({
    providedIn: 'root',
})
export class InactivityLogoutService {
    activityCheckInterval: number = 0;
    activityEventHandler: () => void;
    storageEventHandler: () => void;
    
    constructor(
        @Inject(INACTIVITY_CONFIG) private inactivityConfig: IInactivityLogoutConfig,
        private authService: MsalService) {

            this.activityEventHandler = this.resetLatestActivity.bind(this);
            this.storageEventHandler = this.storageEvent.bind(this);
            this.initListener();
            this.initInterval();
            localStorage.setItem(this.inactivityConfig.localStorageKey, Date.now().toString());
            this.checkInactivityTimeoutExpired();
    }

    public getLastAction() {
        return parseInt(localStorage.getItem(this.inactivityConfig.localStorageKey) ?? "0");
    }

    public setLastAction(lastAction: number) {
        localStorage.setItem(this.inactivityConfig.localStorageKey, lastAction.toString());
    }

    initListener() {
        window.addEventListener('click', this.activityEventHandler);
        window.addEventListener('mousemove', this.activityEventHandler);
        window.addEventListener('scroll', this.activityEventHandler);
        window.addEventListener('keydown', this.activityEventHandler);
        
        window.addEventListener('storage', this.storageEventHandler);
    }

    resetLatestActivity() {
        this.setLastAction(Date.now());
    }

    initInterval() {
        this.activityCheckInterval = window.setInterval(() => {
            this.checkInactivityTimeoutExpired();
        }, this.inactivityConfig.checkInterval);
    }

    checkInactivityTimeoutExpired() {
        const now = Date.now();
        const timeleft = this.getLastAction() + this.inactivityConfig.minutesAutoLogout * 60 * 1000;
        const diff = timeleft - now;
        const isTimeout = diff < 0;

        if (isTimeout) {
            localStorage.clear();
            this.authService.logout();
            //this.router.navigate(['./login']);
        }
    }

    storageEvent() {
        //this.val = localStorage.getItem(STORE_KEY);
    }

    cleanUp() {
        window.clearInterval(this.activityCheckInterval);
        window.removeEventListener("click", this.activityEventHandler);
        window.removeEventListener("mousemove", this.activityEventHandler);
        window.removeEventListener("scroll", this.activityEventHandler);
        window.removeEventListener("keydown", this.activityEventHandler);

        window.removeEventListener('storage', () => this.storageEventHandler);
    }
}