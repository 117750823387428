import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContractEdm } from './DataDomain/EFEntities/contractedm.entity';
import { PurchaseOrderEdm } from './DataDomain/EFEntities/purchaseorderedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class PurchaseOrdersService extends ODataEntitySetService<PurchaseOrderEdm> {
  constructor(client: ODataClient) {
    super(client, 'PurchaseOrders', 'OptiaApi.DataDomain.EFEntities.PurchaseOrderEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public contract(key: EntityKey<PurchaseOrderEdm>): ODataNavigationPropertyResource<ContractEdm> { 
    return this.entity(key).navigationProperty<ContractEdm>('contract'); 
  }
  public fetchContract(key: EntityKey<PurchaseOrderEdm>, options?: ODataQueryArgumentsOptions<ContractEdm>) {
    return this.fetchNavigationProperty<ContractEdm>(
      this.contract(key), 
      'entity', options) as Observable<ODataEntity<ContractEdm>>;
  }
  public setContractEdmAsContract(key: EntityKey<PurchaseOrderEdm>, target: ODataEntityResource<ODataEntity<ContractEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.contract(key).reference()
      .set(target, {etag});
  }
  public unsetContractEdmAsContract(key: EntityKey<PurchaseOrderEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<ContractEdm>>, etag?: string} = {}): Observable<any> {
    return this.contract(key).reference()
      .unset({etag});
  }
  public createdByPerson(key: EntityKey<PurchaseOrderEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<PurchaseOrderEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<PurchaseOrderEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<PurchaseOrderEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  //#endregion
}
