//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { LoggedInUserDTOService } from './loggedinuserdto.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const LoggedInUserDTOServiceEntitySetConfig = {
  name: 'LoggedInUserDTO',
  entityType: 'OptiaApi.ServiceLayer.DTOs.LoggedInUserDTO',
  service: LoggedInUserDTOService
} as EntitySetConfig;
//#endregion