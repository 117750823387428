//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContractPersonEdm } from './contractpersonedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ContractPersonEdmEntityConfig = {
  name: 'ContractPersonEdm',
  keys: [{name: 'contractPersonGuid'}],
  fields: {
    contractGuid: {type: 'Edm.Guid'},
    personGuid: {type: 'Edm.Guid'},
    personType: {type: 'Edm.Int16'},
    jobDescription: {type: 'Edm.String'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    contractPersonGuid: {type: 'Edm.Guid', nullable: false},
    contract: {type: 'OptiaApi.DataDomain.EFEntities.ContractEdm', navigation: true, referentials: [{property: 'contractGuid', referencedProperty: 'contractGuid'}]},
    person: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<ContractPersonEdm>;
//#endregion