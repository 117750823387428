//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerTypeDocumentEdm } from './workertypedocumentedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WorkerTypeDocumentEdmEntityConfig = {
  name: 'WorkerTypeDocumentEdm',
  keys: [{name: 'workerTypeDocumentGuid'}],
  fields: {
    workerTypeGuid: {type: 'Edm.Guid'},
    documentGuid: {type: 'Edm.Guid'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    workerTypeDocumentGuid: {type: 'Edm.Guid', nullable: false},
    document: {type: 'OptiaApi.DataDomain.EFEntities.DocumentEdm', navigation: true, referentials: [{property: 'documentGuid', referencedProperty: 'documentGuid'}]},
    workerType: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeEdm', navigation: true, referentials: [{property: 'workerTypeGuid', referencedProperty: 'workerTypeGuid'}]}
  }
} as StructuredTypeConfig<WorkerTypeDocumentEdm>;
//#endregion