//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OptiaNewsPersonEdm } from './optianewspersonedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const OptiaNewsPersonEdmEntityConfig = {
  name: 'OptiaNewsPersonEdm',
  keys: [{name: 'optiaNewsGuid'},{name: 'personGuid'}],
  fields: {
    optiaNewsGuid: {type: 'Edm.Guid'},
    personGuid: {type: 'Edm.Guid'},
    status: {type: 'Edm.Int16', nullable: false},
    dateRead: {type: 'Edm.DateTimeOffset'},
    optiaNew: {type: 'OptiaApi.DataDomain.EFEntities.Notify.OptiaNewEdm', navigation: true, referentials: [{property: 'optiaNewsGuid', referencedProperty: 'optiaNewsGuid'}]},
    person: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<OptiaNewsPersonEdm>;
//#endregion