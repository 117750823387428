import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskContactTypeEdm } from './DataDomain/EFEntities/wftaskcontacttypeedm.entity';
import { WfActionEdm } from './DataDomain/EFEntities/Workflow/wfactionedm.entity';
import { WfOutcomeEdm } from './DataDomain/EFEntities/Workflow/wfoutcomeedm.entity';
import { WfRuleEdm } from './DataDomain/EFEntities/Workflow/wfruleedm.entity';
import { WfTaskEdm } from './DataDomain/EFEntities/Workflow/wftaskedm.entity';
import { WfTaskGroupEdm } from './DataDomain/EFEntities/Workflow/wftaskgroupedm.entity';
import { WfTaskRoleEdm } from './DataDomain/EFEntities/Workflow/wftaskroleedm.entity';
import { WfTaskTabEdm } from './DataDomain/EFEntities/Workflow/wftasktabedm.entity';
import { WfTaskTemplateEdm } from './DataDomain/EFEntities/Workflow/wftasktemplateedm.entity';
import { WfTaskWfScheduleEdm } from './DataDomain/EFEntities/Workflow/wftaskwfscheduleedm.entity';
//#endregion

@Injectable()
export class WfTasksService extends ODataEntitySetService<WfTaskEdm> {
  constructor(client: ODataClient) {
    super(client, 'WfTasks', 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public wfActions(key: EntityKey<WfTaskEdm>): ODataNavigationPropertyResource<WfActionEdm> { 
    return this.entity(key).navigationProperty<WfActionEdm>('wfActions'); 
  }
  public fetchWfActions(key: EntityKey<WfTaskEdm>, options?: ODataQueryArgumentsOptions<WfActionEdm>) {
    return this.fetchNavigationProperty<WfActionEdm>(
      this.wfActions(key), 
      'entities', options) as Observable<ODataEntities<WfActionEdm>>;
  }
  public addWfActionEdmToWfActions(key: EntityKey<WfTaskEdm>, target: ODataEntityResource<ODataEntities<WfActionEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfActions(key).reference()
      .add(target);
  }
  public removeWfActionEdmFromWfActions(key: EntityKey<WfTaskEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfActionEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfActions(key).reference()
      .remove(target);
  }
  public wfOutcomes(key: EntityKey<WfTaskEdm>): ODataNavigationPropertyResource<WfOutcomeEdm> { 
    return this.entity(key).navigationProperty<WfOutcomeEdm>('wfOutcomes'); 
  }
  public fetchWfOutcomes(key: EntityKey<WfTaskEdm>, options?: ODataQueryArgumentsOptions<WfOutcomeEdm>) {
    return this.fetchNavigationProperty<WfOutcomeEdm>(
      this.wfOutcomes(key), 
      'entities', options) as Observable<ODataEntities<WfOutcomeEdm>>;
  }
  public addWfOutcomeEdmToWfOutcomes(key: EntityKey<WfTaskEdm>, target: ODataEntityResource<ODataEntities<WfOutcomeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfOutcomes(key).reference()
      .add(target);
  }
  public removeWfOutcomeEdmFromWfOutcomes(key: EntityKey<WfTaskEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfOutcomeEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfOutcomes(key).reference()
      .remove(target);
  }
  public wfRules(key: EntityKey<WfTaskEdm>): ODataNavigationPropertyResource<WfRuleEdm> { 
    return this.entity(key).navigationProperty<WfRuleEdm>('wfRules'); 
  }
  public fetchWfRules(key: EntityKey<WfTaskEdm>, options?: ODataQueryArgumentsOptions<WfRuleEdm>) {
    return this.fetchNavigationProperty<WfRuleEdm>(
      this.wfRules(key), 
      'entities', options) as Observable<ODataEntities<WfRuleEdm>>;
  }
  public addWfRuleEdmToWfRules(key: EntityKey<WfTaskEdm>, target: ODataEntityResource<ODataEntities<WfRuleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfRules(key).reference()
      .add(target);
  }
  public removeWfRuleEdmFromWfRules(key: EntityKey<WfTaskEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfRuleEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfRules(key).reference()
      .remove(target);
  }
  public wfTaskContactTypes(key: EntityKey<WfTaskEdm>): ODataNavigationPropertyResource<WfTaskContactTypeEdm> { 
    return this.entity(key).navigationProperty<WfTaskContactTypeEdm>('wfTaskContactTypes'); 
  }
  public fetchWfTaskContactTypes(key: EntityKey<WfTaskEdm>, options?: ODataQueryArgumentsOptions<WfTaskContactTypeEdm>) {
    return this.fetchNavigationProperty<WfTaskContactTypeEdm>(
      this.wfTaskContactTypes(key), 
      'entities', options) as Observable<ODataEntities<WfTaskContactTypeEdm>>;
  }
  public addWfTaskContactTypeEdmToWfTaskContactTypes(key: EntityKey<WfTaskEdm>, target: ODataEntityResource<ODataEntities<WfTaskContactTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfTaskContactTypes(key).reference()
      .add(target);
  }
  public removeWfTaskContactTypeEdmFromWfTaskContactTypes(key: EntityKey<WfTaskEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfTaskContactTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfTaskContactTypes(key).reference()
      .remove(target);
  }
  public wfTaskGroup(key: EntityKey<WfTaskEdm>): ODataNavigationPropertyResource<WfTaskGroupEdm> { 
    return this.entity(key).navigationProperty<WfTaskGroupEdm>('wfTaskGroup'); 
  }
  public fetchWfTaskGroup(key: EntityKey<WfTaskEdm>, options?: ODataQueryArgumentsOptions<WfTaskGroupEdm>) {
    return this.fetchNavigationProperty<WfTaskGroupEdm>(
      this.wfTaskGroup(key), 
      'entity', options) as Observable<ODataEntity<WfTaskGroupEdm>>;
  }
  public setWfTaskGroupEdmAsWfTaskGroup(key: EntityKey<WfTaskEdm>, target: ODataEntityResource<ODataEntity<WfTaskGroupEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfTaskGroup(key).reference()
      .set(target, {etag});
  }
  public unsetWfTaskGroupEdmAsWfTaskGroup(key: EntityKey<WfTaskEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WfTaskGroupEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfTaskGroup(key).reference()
      .unset({etag});
  }
  public wfTaskRoles(key: EntityKey<WfTaskEdm>): ODataNavigationPropertyResource<WfTaskRoleEdm> { 
    return this.entity(key).navigationProperty<WfTaskRoleEdm>('wfTaskRoles'); 
  }
  public fetchWfTaskRoles(key: EntityKey<WfTaskEdm>, options?: ODataQueryArgumentsOptions<WfTaskRoleEdm>) {
    return this.fetchNavigationProperty<WfTaskRoleEdm>(
      this.wfTaskRoles(key), 
      'entities', options) as Observable<ODataEntities<WfTaskRoleEdm>>;
  }
  public addWfTaskRoleEdmToWfTaskRoles(key: EntityKey<WfTaskEdm>, target: ODataEntityResource<ODataEntities<WfTaskRoleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfTaskRoles(key).reference()
      .add(target);
  }
  public removeWfTaskRoleEdmFromWfTaskRoles(key: EntityKey<WfTaskEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfTaskRoleEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfTaskRoles(key).reference()
      .remove(target);
  }
  public wfTaskTabs(key: EntityKey<WfTaskEdm>): ODataNavigationPropertyResource<WfTaskTabEdm> { 
    return this.entity(key).navigationProperty<WfTaskTabEdm>('wfTaskTabs'); 
  }
  public fetchWfTaskTabs(key: EntityKey<WfTaskEdm>, options?: ODataQueryArgumentsOptions<WfTaskTabEdm>) {
    return this.fetchNavigationProperty<WfTaskTabEdm>(
      this.wfTaskTabs(key), 
      'entities', options) as Observable<ODataEntities<WfTaskTabEdm>>;
  }
  public addWfTaskTabEdmToWfTaskTabs(key: EntityKey<WfTaskEdm>, target: ODataEntityResource<ODataEntities<WfTaskTabEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfTaskTabs(key).reference()
      .add(target);
  }
  public removeWfTaskTabEdmFromWfTaskTabs(key: EntityKey<WfTaskEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfTaskTabEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfTaskTabs(key).reference()
      .remove(target);
  }
  public wfTaskTemplates(key: EntityKey<WfTaskEdm>): ODataNavigationPropertyResource<WfTaskTemplateEdm> { 
    return this.entity(key).navigationProperty<WfTaskTemplateEdm>('wfTaskTemplates'); 
  }
  public fetchWfTaskTemplates(key: EntityKey<WfTaskEdm>, options?: ODataQueryArgumentsOptions<WfTaskTemplateEdm>) {
    return this.fetchNavigationProperty<WfTaskTemplateEdm>(
      this.wfTaskTemplates(key), 
      'entities', options) as Observable<ODataEntities<WfTaskTemplateEdm>>;
  }
  public addWfTaskTemplateEdmToWfTaskTemplates(key: EntityKey<WfTaskEdm>, target: ODataEntityResource<ODataEntities<WfTaskTemplateEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfTaskTemplates(key).reference()
      .add(target);
  }
  public removeWfTaskTemplateEdmFromWfTaskTemplates(key: EntityKey<WfTaskEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfTaskTemplateEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfTaskTemplates(key).reference()
      .remove(target);
  }
  public wfTaskWfSchedules(key: EntityKey<WfTaskEdm>): ODataNavigationPropertyResource<WfTaskWfScheduleEdm> { 
    return this.entity(key).navigationProperty<WfTaskWfScheduleEdm>('wfTaskWfSchedules'); 
  }
  public fetchWfTaskWfSchedules(key: EntityKey<WfTaskEdm>, options?: ODataQueryArgumentsOptions<WfTaskWfScheduleEdm>) {
    return this.fetchNavigationProperty<WfTaskWfScheduleEdm>(
      this.wfTaskWfSchedules(key), 
      'entities', options) as Observable<ODataEntities<WfTaskWfScheduleEdm>>;
  }
  public addWfTaskWfScheduleEdmToWfTaskWfSchedules(key: EntityKey<WfTaskEdm>, target: ODataEntityResource<ODataEntities<WfTaskWfScheduleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfTaskWfSchedules(key).reference()
      .add(target);
  }
  public removeWfTaskWfScheduleEdmFromWfTaskWfSchedules(key: EntityKey<WfTaskEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfTaskWfScheduleEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfTaskWfSchedules(key).reference()
      .remove(target);
  }
  //#endregion
}
