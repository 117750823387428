//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OptiaNewsDocumentsService } from './optianewsdocuments.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const OptiaNewsDocumentsServiceEntitySetConfig = {
  name: 'OptiaNewsDocuments',
  entityType: 'OptiaApi.DataDomain.EFEntities.Notify.OptiaNewsDocumentEdm',
  service: OptiaNewsDocumentsService
} as EntitySetConfig;
//#endregion