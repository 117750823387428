//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ReportingCategoriesService } from './reportingcategories.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ReportingCategoriesServiceEntitySetConfig = {
  name: 'ReportingCategories',
  entityType: 'OptiaApi.DataDomain.EFEntities.Reporting.ReportingCategoryEdm',
  service: ReportingCategoriesService
} as EntitySetConfig;
//#endregion