//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ChangeSetGroupEdm } from './changesetgroupedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ChangeSetGroupEdmEntityConfig = {
  name: 'ChangeSetGroupEdm',
  keys: [{name: 'changeSetGroupGuid'}],
  fields: {
    changeSetGroupGuid: {type: 'Edm.Guid', nullable: false},
    requestDate: {type: 'Edm.DateTimeOffset'},
    entity: {type: 'Edm.Int16'},
    entityKey: {type: 'Edm.Guid'},
    status: {type: 'Edm.Int16'},
    agencyGuid: {type: 'Edm.Guid'},
    entityLink: {type: 'Edm.String'},
    description: {type: 'Edm.String'},
    agency: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm', navigation: true},
    changeSets: {type: 'OptiaApi.DataDomain.EFEntities.Admin.ChangeSetEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<ChangeSetGroupEdm>;
//#endregion