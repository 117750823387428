//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerDetailsFilterDtoService } from './customerdetailsfilterdto.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CustomerDetailsFilterDtoServiceEntitySetConfig = {
  name: 'CustomerDetailsFilterDto',
  entityType: 'OptiaApi.ServiceLayer.DTOs.CustomerDetailsFilterDto',
  service: CustomerDetailsFilterDtoService
} as EntitySetConfig;
//#endregion