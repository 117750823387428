//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonDetailEdm } from './persondetailedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PersonDetailEdmComplexConfig = {
  name: 'PersonDetailEdm',
  fields: {
    personGuid: {type: 'Edm.Guid', nullable: false},
    identityGuid: {type: 'Edm.Guid'},
    firstName: {type: 'Edm.String'},
    surname: {type: 'Edm.String'},
    fullName: {type: 'Edm.String'},
    emailAddress: {type: 'Edm.String'},
    active: {type: 'Edm.Boolean'},
    userType: {type: 'Edm.Int16', nullable: false},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid', nullable: false},
    deleted: {type: 'Edm.DateTimeOffset'},
    agencyGuid: {type: 'Edm.Guid'},
    agencyDescription: {type: 'Edm.String'},
    departmentGuid: {type: 'Edm.Guid'},
    departmentDescription: {type: 'Edm.String'},
    userGroupGuid: {type: 'Edm.Int32'}
  }
} as StructuredTypeConfig<PersonDetailEdm>;
//#endregion