import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonUserGroupEdm } from './DataDomain/EFEntities/Admin/personusergroupedm.entity';
import { UserGroupEdm } from './DataDomain/EFEntities/Admin/usergroupedm.entity';
import { UserGroupPermissionEdm } from './DataDomain/EFEntities/Admin/usergrouppermissionedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class UserGroupsService extends ODataEntitySetService<UserGroupEdm> {
  constructor(client: ODataClient) {
    super(client, 'UserGroups', 'OptiaApi.DataDomain.EFEntities.Admin.UserGroupEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public createdByPerson(key: EntityKey<UserGroupEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<UserGroupEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<UserGroupEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<UserGroupEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public personUserGroups(key: EntityKey<UserGroupEdm>): ODataNavigationPropertyResource<PersonUserGroupEdm> { 
    return this.entity(key).navigationProperty<PersonUserGroupEdm>('personUserGroups'); 
  }
  public fetchPersonUserGroups(key: EntityKey<UserGroupEdm>, options?: ODataQueryArgumentsOptions<PersonUserGroupEdm>) {
    return this.fetchNavigationProperty<PersonUserGroupEdm>(
      this.personUserGroups(key), 
      'entities', options) as Observable<ODataEntities<PersonUserGroupEdm>>;
  }
  public addPersonUserGroupEdmToPersonUserGroups(key: EntityKey<UserGroupEdm>, target: ODataEntityResource<ODataEntities<PersonUserGroupEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personUserGroups(key).reference()
      .add(target);
  }
  public removePersonUserGroupEdmFromPersonUserGroups(key: EntityKey<UserGroupEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonUserGroupEdm>>, etag?: string} = {}): Observable<any> {
    return this.personUserGroups(key).reference()
      .remove(target);
  }
  public userGroupPermissions(key: EntityKey<UserGroupEdm>): ODataNavigationPropertyResource<UserGroupPermissionEdm> { 
    return this.entity(key).navigationProperty<UserGroupPermissionEdm>('userGroupPermissions'); 
  }
  public fetchUserGroupPermissions(key: EntityKey<UserGroupEdm>, options?: ODataQueryArgumentsOptions<UserGroupPermissionEdm>) {
    return this.fetchNavigationProperty<UserGroupPermissionEdm>(
      this.userGroupPermissions(key), 
      'entities', options) as Observable<ODataEntities<UserGroupPermissionEdm>>;
  }
  public addUserGroupPermissionEdmToUserGroupPermissions(key: EntityKey<UserGroupEdm>, target: ODataEntityResource<ODataEntities<UserGroupPermissionEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.userGroupPermissions(key).reference()
      .add(target);
  }
  public removeUserGroupPermissionEdmFromUserGroupPermissions(key: EntityKey<UserGroupEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<UserGroupPermissionEdm>>, etag?: string} = {}): Observable<any> {
    return this.userGroupPermissions(key).reference()
      .remove(target);
  }
  //#endregion
}
