//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { NotificationEdm } from './notificationedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const NotificationEdmEntityConfig = {
  name: 'NotificationEdm',
  keys: [{name: 'notificationGuid'}],
  fields: {
    notificationGuid: {type: 'Edm.Guid', nullable: false},
    dateSent: {type: 'Edm.DateTimeOffset'},
    expiryDate: {type: 'Edm.DateTimeOffset'},
    content: {type: 'Edm.String', nullable: false, maxLength: -1},
    subject: {type: 'Edm.String', nullable: false},
    type: {type: 'Edm.Int16', nullable: false},
    notificationPersons: {type: 'OptiaApi.DataDomain.EFEntities.Notify.NotificationPersonEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<NotificationEdm>;
//#endregion