import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfActionEdm } from './DataDomain/EFEntities/Workflow/wfactionedm.entity';
import { WfOutcomeEdm } from './DataDomain/EFEntities/Workflow/wfoutcomeedm.entity';
import { WfPreRequisiteEdm } from './DataDomain/EFEntities/Workflow/wfprerequisiteedm.entity';
import { WfTaskEdm } from './DataDomain/EFEntities/Workflow/wftaskedm.entity';
//#endregion

@Injectable()
export class WfOutcomesService extends ODataEntitySetService<WfOutcomeEdm> {
  constructor(client: ODataClient) {
    super(client, 'WfOutcomes', 'OptiaApi.DataDomain.EFEntities.Workflow.WfOutcomeEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public wfActions(key: EntityKey<WfOutcomeEdm>): ODataNavigationPropertyResource<WfActionEdm> { 
    return this.entity(key).navigationProperty<WfActionEdm>('wfActions'); 
  }
  public fetchWfActions(key: EntityKey<WfOutcomeEdm>, options?: ODataQueryArgumentsOptions<WfActionEdm>) {
    return this.fetchNavigationProperty<WfActionEdm>(
      this.wfActions(key), 
      'entities', options) as Observable<ODataEntities<WfActionEdm>>;
  }
  public addWfActionEdmToWfActions(key: EntityKey<WfOutcomeEdm>, target: ODataEntityResource<ODataEntities<WfActionEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfActions(key).reference()
      .add(target);
  }
  public removeWfActionEdmFromWfActions(key: EntityKey<WfOutcomeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfActionEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfActions(key).reference()
      .remove(target);
  }
  public wfPreRequisites(key: EntityKey<WfOutcomeEdm>): ODataNavigationPropertyResource<WfPreRequisiteEdm> { 
    return this.entity(key).navigationProperty<WfPreRequisiteEdm>('wfPreRequisites'); 
  }
  public fetchWfPreRequisites(key: EntityKey<WfOutcomeEdm>, options?: ODataQueryArgumentsOptions<WfPreRequisiteEdm>) {
    return this.fetchNavigationProperty<WfPreRequisiteEdm>(
      this.wfPreRequisites(key), 
      'entities', options) as Observable<ODataEntities<WfPreRequisiteEdm>>;
  }
  public addWfPreRequisiteEdmToWfPreRequisites(key: EntityKey<WfOutcomeEdm>, target: ODataEntityResource<ODataEntities<WfPreRequisiteEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfPreRequisites(key).reference()
      .add(target);
  }
  public removeWfPreRequisiteEdmFromWfPreRequisites(key: EntityKey<WfOutcomeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfPreRequisiteEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfPreRequisites(key).reference()
      .remove(target);
  }
  public wfTask(key: EntityKey<WfOutcomeEdm>): ODataNavigationPropertyResource<WfTaskEdm> { 
    return this.entity(key).navigationProperty<WfTaskEdm>('wfTask'); 
  }
  public fetchWfTask(key: EntityKey<WfOutcomeEdm>, options?: ODataQueryArgumentsOptions<WfTaskEdm>) {
    return this.fetchNavigationProperty<WfTaskEdm>(
      this.wfTask(key), 
      'entity', options) as Observable<ODataEntity<WfTaskEdm>>;
  }
  public setWfTaskEdmAsWfTask(key: EntityKey<WfOutcomeEdm>, target: ODataEntityResource<ODataEntity<WfTaskEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfTask(key).reference()
      .set(target, {etag});
  }
  public unsetWfTaskEdmAsWfTask(key: EntityKey<WfOutcomeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WfTaskEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfTask(key).reference()
      .unset({etag});
  }
  //#endregion
}
