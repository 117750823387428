import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssignmentEdm } from './DataDomain/EFEntities/assignmentedm.entity';
import { ContractEdm } from './DataDomain/EFEntities/contractedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
import { WorkerEdm } from './DataDomain/EFEntities/Worker/workeredm.entity';
//#endregion

@Injectable()
export class AssignmentsService extends ODataEntitySetService<AssignmentEdm> {
  constructor(client: ODataClient) {
    super(client, 'Assignments', 'OptiaApi.DataDomain.EFEntities.AssignmentEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public contract(key: EntityKey<AssignmentEdm>): ODataNavigationPropertyResource<ContractEdm> { 
    return this.entity(key).navigationProperty<ContractEdm>('contract'); 
  }
  public fetchContract(key: EntityKey<AssignmentEdm>, options?: ODataQueryArgumentsOptions<ContractEdm>) {
    return this.fetchNavigationProperty<ContractEdm>(
      this.contract(key), 
      'entity', options) as Observable<ODataEntity<ContractEdm>>;
  }
  public setContractEdmAsContract(key: EntityKey<AssignmentEdm>, target: ODataEntityResource<ODataEntity<ContractEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.contract(key).reference()
      .set(target, {etag});
  }
  public unsetContractEdmAsContract(key: EntityKey<AssignmentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<ContractEdm>>, etag?: string} = {}): Observable<any> {
    return this.contract(key).reference()
      .unset({etag});
  }
  public createdByPerson(key: EntityKey<AssignmentEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<AssignmentEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<AssignmentEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<AssignmentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public worker(key: EntityKey<AssignmentEdm>): ODataNavigationPropertyResource<WorkerEdm> { 
    return this.entity(key).navigationProperty<WorkerEdm>('worker'); 
  }
  public fetchWorker(key: EntityKey<AssignmentEdm>, options?: ODataQueryArgumentsOptions<WorkerEdm>) {
    return this.fetchNavigationProperty<WorkerEdm>(
      this.worker(key), 
      'entity', options) as Observable<ODataEntity<WorkerEdm>>;
  }
  public setWorkerEdmAsWorker(key: EntityKey<AssignmentEdm>, target: ODataEntityResource<ODataEntity<WorkerEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.worker(key).reference()
      .set(target, {etag});
  }
  public unsetWorkerEdmAsWorker(key: EntityKey<AssignmentEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WorkerEdm>>, etag?: string} = {}): Observable<any> {
    return this.worker(key).reference()
      .unset({etag});
  }
  //#endregion
}
