//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AccountPersonEdm } from './accountpersonedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AccountPersonEdmEntityConfig = {
  name: 'AccountPersonEdm',
  keys: [{name: 'accountGuid'},{name: 'fromDate'},{name: 'personGuid'}],
  fields: {
    personGuid: {type: 'Edm.Guid'},
    accountGuid: {type: 'Edm.Guid'},
    jobDescription: {type: 'Edm.String'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    account: {type: 'OptiaApi.DataDomain.EFEntities.AccountEdm', navigation: true, referentials: [{property: 'accountGuid', referencedProperty: 'accountGuid'}]},
    person: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<AccountPersonEdm>;
//#endregion