//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerDetailEdmService } from './customerdetailedm.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CustomerDetailEdmServiceEntitySetConfig = {
  name: 'CustomerDetailEdm',
  entityType: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerDetailEdm',
  service: CustomerDetailEdmService
} as EntitySetConfig;
//#endregion