//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { BankDetailEdm } from './bankdetailedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const BankDetailEdmEntityConfig = {
  name: 'BankDetailEdm',
  keys: [{name: 'bankDetailsGuid'}],
  fields: {
    bankDetailsGuid: {type: 'Edm.Guid', nullable: false},
    sortCode: {type: 'Edm.String'},
    accountNumber: {type: 'Edm.String'},
    payeeName: {type: 'Edm.String'},
    bankName: {type: 'Edm.String'},
    branch: {type: 'Edm.String'},
    refBuildingSocietyRollNumber: {type: 'Edm.String'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    agencyBankDetails: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyBankDetailEdm', collection: true, navigation: true},
    supplierBankDetails: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierBankDetailEdm', collection: true, navigation: true},
    workerBankDetails: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerBankDetailEdm', collection: true, navigation: true},
    workerTypeBankDetails: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeBankDetailEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<BankDetailEdm>;
//#endregion