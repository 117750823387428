//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { BankDetailsService } from './bankdetails.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const BankDetailsServiceEntitySetConfig = {
  name: 'BankDetails',
  entityType: 'OptiaApi.DataDomain.EFEntities.BankDetailEdm',
  service: BankDetailsService
} as EntitySetConfig;
//#endregion