//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyWorkersService } from './agencyworkers.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AgencyWorkersServiceEntitySetConfig = {
  name: 'AgencyWorkers',
  entityType: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyWorkerEdm',
  service: AgencyWorkersService
} as EntitySetConfig;
//#endregion