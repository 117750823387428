import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CharityEdm } from './DataDomain/EFEntities/charityedm.entity';
import { CompaniesHouseDetailEdm } from './DataDomain/EFEntities/companieshousedetailedm.entity';
import { CreditCheckEdm } from './DataDomain/EFEntities/Credit/creditcheckedm.entity';
import { CustomerEdm } from './DataDomain/EFEntities/Customer/customeredm.entity';
import { CustomerAddressEdm } from './DataDomain/EFEntities/Customer/customeraddressedm.entity';
import { CustomerAgencyEdm } from './DataDomain/EFEntities/Customer/customeragencyedm.entity';
import { CustomerDocumentEdm } from './DataDomain/EFEntities/Customer/customerdocumentedm.entity';
import { CustomerPersonEdm } from './DataDomain/EFEntities/Customer/customerpersonedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class CustomersService extends ODataEntitySetService<CustomerEdm> {
  constructor(client: ODataClient) {
    super(client, 'Customers', 'OptiaApi.DataDomain.EFEntities.Customer.CustomerEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public charity(key: EntityKey<CustomerEdm>): ODataNavigationPropertyResource<CharityEdm> { 
    return this.entity(key).navigationProperty<CharityEdm>('charity'); 
  }
  public fetchCharity(key: EntityKey<CustomerEdm>, options?: ODataQueryArgumentsOptions<CharityEdm>) {
    return this.fetchNavigationProperty<CharityEdm>(
      this.charity(key), 
      'entity', options) as Observable<ODataEntity<CharityEdm>>;
  }
  public setCharityEdmAsCharity(key: EntityKey<CustomerEdm>, target: ODataEntityResource<ODataEntity<CharityEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.charity(key).reference()
      .set(target, {etag});
  }
  public unsetCharityEdmAsCharity(key: EntityKey<CustomerEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<CharityEdm>>, etag?: string} = {}): Observable<any> {
    return this.charity(key).reference()
      .unset({etag});
  }
  public companiesHouseDetail(key: EntityKey<CustomerEdm>): ODataNavigationPropertyResource<CompaniesHouseDetailEdm> { 
    return this.entity(key).navigationProperty<CompaniesHouseDetailEdm>('companiesHouseDetail'); 
  }
  public fetchCompaniesHouseDetail(key: EntityKey<CustomerEdm>, options?: ODataQueryArgumentsOptions<CompaniesHouseDetailEdm>) {
    return this.fetchNavigationProperty<CompaniesHouseDetailEdm>(
      this.companiesHouseDetail(key), 
      'entity', options) as Observable<ODataEntity<CompaniesHouseDetailEdm>>;
  }
  public setCompaniesHouseDetailEdmAsCompaniesHouseDetail(key: EntityKey<CustomerEdm>, target: ODataEntityResource<ODataEntity<CompaniesHouseDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.companiesHouseDetail(key).reference()
      .set(target, {etag});
  }
  public unsetCompaniesHouseDetailEdmAsCompaniesHouseDetail(key: EntityKey<CustomerEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<CompaniesHouseDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.companiesHouseDetail(key).reference()
      .unset({etag});
  }
  public createdByPerson(key: EntityKey<CustomerEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<CustomerEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<CustomerEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<CustomerEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public creditChecks(key: EntityKey<CustomerEdm>): ODataNavigationPropertyResource<CreditCheckEdm> { 
    return this.entity(key).navigationProperty<CreditCheckEdm>('creditChecks'); 
  }
  public fetchCreditChecks(key: EntityKey<CustomerEdm>, options?: ODataQueryArgumentsOptions<CreditCheckEdm>) {
    return this.fetchNavigationProperty<CreditCheckEdm>(
      this.creditChecks(key), 
      'entities', options) as Observable<ODataEntities<CreditCheckEdm>>;
  }
  public addCreditCheckEdmToCreditChecks(key: EntityKey<CustomerEdm>, target: ODataEntityResource<ODataEntities<CreditCheckEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.creditChecks(key).reference()
      .add(target);
  }
  public removeCreditCheckEdmFromCreditChecks(key: EntityKey<CustomerEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CreditCheckEdm>>, etag?: string} = {}): Observable<any> {
    return this.creditChecks(key).reference()
      .remove(target);
  }
  public customerAddresses(key: EntityKey<CustomerEdm>): ODataNavigationPropertyResource<CustomerAddressEdm> { 
    return this.entity(key).navigationProperty<CustomerAddressEdm>('customerAddresses'); 
  }
  public fetchCustomerAddresses(key: EntityKey<CustomerEdm>, options?: ODataQueryArgumentsOptions<CustomerAddressEdm>) {
    return this.fetchNavigationProperty<CustomerAddressEdm>(
      this.customerAddresses(key), 
      'entities', options) as Observable<ODataEntities<CustomerAddressEdm>>;
  }
  public addCustomerAddressEdmToCustomerAddresses(key: EntityKey<CustomerEdm>, target: ODataEntityResource<ODataEntities<CustomerAddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customerAddresses(key).reference()
      .add(target);
  }
  public removeCustomerAddressEdmFromCustomerAddresses(key: EntityKey<CustomerEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CustomerAddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.customerAddresses(key).reference()
      .remove(target);
  }
  public customerAgencies(key: EntityKey<CustomerEdm>): ODataNavigationPropertyResource<CustomerAgencyEdm> { 
    return this.entity(key).navigationProperty<CustomerAgencyEdm>('customerAgencies'); 
  }
  public fetchCustomerAgencies(key: EntityKey<CustomerEdm>, options?: ODataQueryArgumentsOptions<CustomerAgencyEdm>) {
    return this.fetchNavigationProperty<CustomerAgencyEdm>(
      this.customerAgencies(key), 
      'entities', options) as Observable<ODataEntities<CustomerAgencyEdm>>;
  }
  public addCustomerAgencyEdmToCustomerAgencies(key: EntityKey<CustomerEdm>, target: ODataEntityResource<ODataEntities<CustomerAgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customerAgencies(key).reference()
      .add(target);
  }
  public removeCustomerAgencyEdmFromCustomerAgencies(key: EntityKey<CustomerEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CustomerAgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.customerAgencies(key).reference()
      .remove(target);
  }
  public customerDocuments(key: EntityKey<CustomerEdm>): ODataNavigationPropertyResource<CustomerDocumentEdm> { 
    return this.entity(key).navigationProperty<CustomerDocumentEdm>('customerDocuments'); 
  }
  public fetchCustomerDocuments(key: EntityKey<CustomerEdm>, options?: ODataQueryArgumentsOptions<CustomerDocumentEdm>) {
    return this.fetchNavigationProperty<CustomerDocumentEdm>(
      this.customerDocuments(key), 
      'entities', options) as Observable<ODataEntities<CustomerDocumentEdm>>;
  }
  public addCustomerDocumentEdmToCustomerDocuments(key: EntityKey<CustomerEdm>, target: ODataEntityResource<ODataEntities<CustomerDocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customerDocuments(key).reference()
      .add(target);
  }
  public removeCustomerDocumentEdmFromCustomerDocuments(key: EntityKey<CustomerEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CustomerDocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.customerDocuments(key).reference()
      .remove(target);
  }
  public customerPersons(key: EntityKey<CustomerEdm>): ODataNavigationPropertyResource<CustomerPersonEdm> { 
    return this.entity(key).navigationProperty<CustomerPersonEdm>('customerPersons'); 
  }
  public fetchCustomerPersons(key: EntityKey<CustomerEdm>, options?: ODataQueryArgumentsOptions<CustomerPersonEdm>) {
    return this.fetchNavigationProperty<CustomerPersonEdm>(
      this.customerPersons(key), 
      'entities', options) as Observable<ODataEntities<CustomerPersonEdm>>;
  }
  public addCustomerPersonEdmToCustomerPersons(key: EntityKey<CustomerEdm>, target: ODataEntityResource<ODataEntities<CustomerPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customerPersons(key).reference()
      .add(target);
  }
  public removeCustomerPersonEdmFromCustomerPersons(key: EntityKey<CustomerEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CustomerPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.customerPersons(key).reference()
      .remove(target);
  }
  //#endregion
}
