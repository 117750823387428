//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { RegExEdm } from './regexedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const RegExEdmEntityConfig = {
  name: 'RegExEdm',
  keys: [{name: 'regExGuid'}],
  fields: {
    regExGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String'},
    tag: {type: 'Edm.String', nullable: false},
    pattern: {type: 'Edm.String', nullable: false},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<RegExEdm>;
//#endregion