//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerAgencyPersonEdm } from './customeragencypersonedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CustomerAgencyPersonEdmEntityConfig = {
  name: 'CustomerAgencyPersonEdm',
  keys: [{name: 'customerAgencyGuid'},{name: 'fromDate'},{name: 'personGuid'}],
  fields: {
    customerAgencyGuid: {type: 'Edm.Guid'},
    personGuid: {type: 'Edm.Guid'},
    jobDescription: {type: 'Edm.String'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    customerAgency: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyEdm', navigation: true, referentials: [{property: 'customerAgencyGuid', referencedProperty: 'customerAgencyGuid'}]},
    person: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<CustomerAgencyPersonEdm>;
//#endregion