//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SupplierWorkerTypeEdm } from './supplierworkertypeedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const SupplierWorkerTypeEdmEntityConfig = {
  name: 'SupplierWorkerTypeEdm',
  keys: [{name: 'supplierWorkerTypeGuid'}],
  fields: {
    supplierGuid: {type: 'Edm.Guid'},
    workerTypeGuid: {type: 'Edm.Guid'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    supplierWorkerTypeGuid: {type: 'Edm.Guid', nullable: false},
    supplier: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierEdm', navigation: true, referentials: [{property: 'supplierGuid', referencedProperty: 'supplierGuid'}]},
    workerType: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeEdm', navigation: true, referentials: [{property: 'workerTypeGuid', referencedProperty: 'workerTypeGuid'}]}
  }
} as StructuredTypeConfig<SupplierWorkerTypeEdm>;
//#endregion