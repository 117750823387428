//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SupplierAgenciesService } from './supplieragencies.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const SupplierAgenciesServiceEntitySetConfig = {
  name: 'SupplierAgencies',
  entityType: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierAgencyEdm',
  service: SupplierAgenciesService
} as EntitySetConfig;
//#endregion