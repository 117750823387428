//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerTypeDetailEdm } from './workertypedetailedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WorkerTypeDetailEdmComplexConfig = {
  name: 'WorkerTypeDetailEdm',
  fields: {
    workerGuid: {type: 'Edm.Guid', nullable: false},
    workerName: {type: 'Edm.String', nullable: false},
    agencyGuid: {type: 'Edm.Guid', nullable: false},
    agencyName: {type: 'Edm.String'},
    emailAddress: {type: 'Edm.String', nullable: false},
    telephoneNumber: {type: 'Edm.String'},
    workerStatus: {type: 'Edm.Int16', nullable: false},
    workerTypeGuid: {type: 'Edm.Guid', nullable: false},
    payrollType: {type: 'Edm.Int16', nullable: false},
    payeDeclaration: {type: 'Edm.Int16'},
    supplierName: {type: 'Edm.String'},
    ir35Status: {type: 'Edm.Int16', nullable: false},
    utrNumber: {type: 'Edm.String'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid', nullable: false},
    deleted: {type: 'Edm.DateTimeOffset'},
    workerTypeStatus: {type: 'Edm.Int16', nullable: false},
    reference: {type: 'Edm.String'},
    transferredToPayrollDate: {type: 'Edm.DateTimeOffset'},
    firstAssignment: {type: 'Edm.String', maxLength: -1},
    comments: {type: 'Edm.String', maxLength: -1},
    isSelfBill: {type: 'Edm.Boolean'},
    syncStatus: {type: 'Edm.Int16'},
    companyName: {type: 'Edm.String'},
    companyNumber: {type: 'Edm.String'},
    vatNumber: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<WorkerTypeDetailEdm>;
//#endregion