//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OptiaNewsDocumentEdm } from './optianewsdocumentedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const OptiaNewsDocumentEdmEntityConfig = {
  name: 'OptiaNewsDocumentEdm',
  keys: [{name: 'documentGuid'},{name: 'optiaNewsGuid'}],
  fields: {
    optiaNewsGuid: {type: 'Edm.Guid'},
    documentGuid: {type: 'Edm.Guid'},
    document: {type: 'OptiaApi.DataDomain.EFEntities.DocumentEdm', navigation: true, referentials: [{property: 'documentGuid', referencedProperty: 'documentGuid'}]},
    optiaNew: {type: 'OptiaApi.DataDomain.EFEntities.Notify.OptiaNewEdm', navigation: true, referentials: [{property: 'optiaNewsGuid', referencedProperty: 'optiaNewsGuid'}]}
  }
} as StructuredTypeConfig<OptiaNewsDocumentEdm>;
//#endregion