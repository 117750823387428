//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContractReportingLabelsService } from './contractreportinglabels.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ContractReportingLabelsServiceEntitySetConfig = {
  name: 'ContractReportingLabels',
  entityType: 'OptiaApi.DataDomain.EFEntities.Reporting.ContractReportingLabelEdm',
  service: ContractReportingLabelsService
} as EntitySetConfig;
//#endregion