//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContractReportingLabelEdm } from './contractreportinglabeledm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ContractReportingLabelEdmEntityConfig = {
  name: 'ContractReportingLabelEdm',
  keys: [{name: 'contractReportingLableGuid'}],
  fields: {
    contractGuid: {type: 'Edm.Guid'},
    reportingLabelGuid: {type: 'Edm.Guid'},
    contractReportingLableGuid: {type: 'Edm.Guid', nullable: false},
    contract: {type: 'OptiaApi.DataDomain.EFEntities.ContractEdm', navigation: true, referentials: [{property: 'contractGuid', referencedProperty: 'contractGuid'}]},
    reportingLabel: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.ReportingLabelEdm', navigation: true, referentials: [{property: 'reportingLabelGuid', referencedProperty: 'reportingLabelGuid'}]}
  }
} as StructuredTypeConfig<ContractReportingLabelEdm>;
//#endregion