//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CompaniesHouseDetailsAddressEdm } from './companieshousedetailsaddressedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CompaniesHouseDetailsAddressEdmEntityConfig = {
  name: 'CompaniesHouseDetailsAddressEdm',
  keys: [{name: 'addressGuid'},{name: 'companiesHouseDetailsGuid'},{name: 'fromDate'}],
  fields: {
    companiesHouseDetailsGuid: {type: 'Edm.Guid'},
    addressGuid: {type: 'Edm.Guid'},
    addressType: {type: 'Edm.Int16'},
    manuallyEntered: {type: 'Edm.Boolean'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    address: {type: 'OptiaApi.DataDomain.EFEntities.AddressEdm', navigation: true, referentials: [{property: 'addressGuid', referencedProperty: 'addressGuid'}]},
    companiesHouseDetail: {type: 'OptiaApi.DataDomain.EFEntities.CompaniesHouseDetailEdm', navigation: true, referentials: [{property: 'companiesHouseDetailsGuid', referencedProperty: 'companiesHouseDetailsGuid'}]}
  }
} as StructuredTypeConfig<CompaniesHouseDetailsAddressEdm>;
//#endregion