//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OptiaNewsDocumentDtoComplexConfig } from './optianewsdocumentdto.complex.config';
import { DocumentDtoComplexConfig } from './documentdto.complex.config';
import { ContainerContainer } from './container.container';
//#endregion

//#region ODataApiGen SchemaConfig
export const OptiaApiSchema = {
  namespace: 'OptiaApi',
  enums: [],
  entities: [OptiaNewsDocumentDtoComplexConfig,
    DocumentDtoComplexConfig],
  callables: [{
    name: 'AgencyDetails',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyDetailEdm', collection: false }
  }, {
    name: 'AgencyPermissionDetails',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.Admin.AgencyPermissionDetailEdm', collection: false }
  }, {
    name: 'BackOfficePermissionDetails',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.Admin.BackOfficePermissionDetailEdm', collection: false }
  }, {
    name: 'CustomerAgencyDetails',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyDetailEdm', collection: false }
  }, {
    name: 'DocumentDetails',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.DocumentDetailEdm', collection: false }
  }, {
    name: 'InputValidationTasks',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.Reporting.InputValidationTaskEdm', collection: false }
  }, {
    name: 'MiReportCombinations',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportCombinationEdm', collection: false }
  }, {
    name: 'OptiaNewsDetails',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.Notify.OptiaNewsDetailEdm', collection: false }
  }, {
    name: 'OutstandingActions',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.Workflow.OutstandingActionEdm', collection: false }
  }, {
    name: 'PersonDetails',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.Person.PersonDetailEdm', collection: false }
  }, {
    name: 'ReportDetails',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.Reporting.ReportDetailEdm', collection: false }
  }, {
    name: 'ReportGroupsWithTypes',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.Reporting.ReportGroupsWithTypeEdm', collection: false }
  }, {
    name: 'TransferDetails',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferDetailEdm', collection: false }
  }, {
    name: 'UserGroupDetails',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.Admin.UserGroupDetailEdm', collection: false }
  }, {
    name: 'WorkerDetails',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.WorkerDetailEdm', collection: false }
  }, {
    name: 'WorkerTypeDetails',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeDetailEdm', collection: false }
  }, {
    name: 'ReportDetailsWeekly',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.Reporting.ReportDetailEdm', collection: false }
  }, {
    name: 'ReportDetailsMonthly',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.Reporting.ReportDetailEdm', collection: false }
  }, {
    name: 'ReportDetailsValidation',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.DataDomain.EFEntities.Reporting.ReportDetailEdm', collection: false }
  }, {
    name: 'MyOptiaNewsDetails',
    bound: false,
    composable: false,
    return: { type: 'OptiaApi.ServiceLayer.DTOs.OptiaNewsDto', collection: false }
  }, {
    name: 'SetPassword',
    bound: true,
    composable: false,
    parameters: { bindingParameter: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm'}, password: {type: 'OptiaApi.ServiceLayer.DTOs.SetPasswordDTO'} },
    return: { type: 'Edm.Boolean', collection: false }
  }, {
    name: 'UpdateCustomClaim',
    bound: true,
    composable: false,
    parameters: { bindingParameter: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm'}, azureUserGuid: {type: 'Edm.String'}, customClaim: {type: 'Edm.String'}, value: {type: 'Edm.String'} },
    return: { type: 'Edm.Boolean', collection: false }
  }, {
    name: 'GetCustomClaim',
    bound: true,
    composable: false,
    parameters: { bindingParameter: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm'}, azureUserGuid: {type: 'Edm.String'}, customClaim: {type: 'Edm.String'} },
    return: { type: 'Edm.String', collection: false }
  }, {
    name: 'MarkAsDeleted',
    bound: true,
    composable: false,
    parameters: { bindingParameter: {type: 'OptiaApi.DataDomain.EFEntities.DocumentEdm'} },
    return: { type: 'Edm.Boolean', collection: false }
  }, {
    name: 'VerifyCustomerAgency',
    bound: true,
    composable: false,
    parameters: { bindingParameter: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyEdm'} },
    return: { type: 'Edm.Boolean', collection: false }
  }, {
    name: 'myActions',
    bound: true,
    composable: false,
    parameters: { bindingParameter: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfActionEdm', collection: true}, personGuid: {type: 'Edm.String'} },
    return: { type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfActionOutstandingEdm', collection: true }
  }, {
    name: 'Complete',
    bound: true,
    composable: false,
    parameters: { bindingParameter: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfActionEdm'}, personGuid: {type: 'Edm.String'}, wfOutcomeGuid: {type: 'Edm.String'} },
    return: { type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfActionOutstandingEdm', collection: false }
  }, {
    name: 'SendUploadNotification',
    bound: true,
    composable: false,
    parameters: { bindingParameter: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferEdm'} },
    return: { type: 'Edm.Boolean', collection: false }
  }, {
    name: 'SearchCustomerDetails',
    bound: true,
    composable: false,
    parameters: { bindingParameter: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerDetailEdm', collection: true}, filter: {type: 'Edm.String'}, statusFilter: {type: 'Edm.Int32'}, statusAgencyFilter: {type: 'Edm.Int32'}, typeFilter: {type: 'Edm.Int32'}, verificationOutcome: {type: 'Edm.String'}, skip: {type: 'Edm.Int32', nullable: false}, take: {type: 'Edm.Int32', nullable: false}, sortColumn: {type: 'Edm.String'}, sortOrder: {type: 'Edm.String'} },
    return: { type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerDetailEdm', collection: true }
  }],
  containers: [ContainerContainer]
} as SchemaConfig;
//#endregion