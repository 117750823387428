//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfScheduleEdm } from './wfscheduleedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfScheduleEdmEntityConfig = {
  name: 'WfScheduleEdm',
  keys: [{name: 'wfScheduleGuid'}],
  fields: {
    wfScheduleGuid: {type: 'Edm.Guid', nullable: false},
    month: {type: 'Edm.Int32'},
    monthDay: {type: 'Edm.Int32'},
    weekdays: {type: 'Edm.Int32'},
    minutes: {type: 'Edm.Int32', nullable: false},
    wfProcessWfSchedules: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfProcessWfScheduleEdm', collection: true, navigation: true},
    wfTaskWfSchedules: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskWfScheduleEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<WfScheduleEdm>;
//#endregion