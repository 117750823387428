//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MiReportCombinationEdm } from './mireportcombinationedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const MiReportCombinationEdmComplexConfig = {
  name: 'MiReportCombinationEdm',
  fields: {
    agencyGuid: {type: 'Edm.Guid', nullable: false},
    branchSector: {type: 'Edm.String'},
    industrial: {type: 'Edm.Int32'},
    commercial: {type: 'Edm.Int32'},
    drivers: {type: 'Edm.Int32'},
    education: {type: 'Edm.Int32'},
    healthcare: {type: 'Edm.Int32'}
  }
} as StructuredTypeConfig<MiReportCombinationEdm>;
//#endregion