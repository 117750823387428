//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CreditLimitsService } from './creditlimits.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CreditLimitsServiceEntitySetConfig = {
  name: 'CreditLimits',
  entityType: 'OptiaApi.DataDomain.EFEntities.Credit.CreditLimitEdm',
  service: CreditLimitsService
} as EntitySetConfig;
//#endregion