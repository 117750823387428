//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyDocumentsService } from './agencydocuments.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AgencyDocumentsServiceEntitySetConfig = {
  name: 'AgencyDocuments',
  entityType: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyDocumentEdm',
  service: AgencyDocumentsService
} as EntitySetConfig;
//#endregion