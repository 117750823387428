import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyReportingCombinationEdm } from './DataDomain/EFEntities/agencyreportingcombinationedm.entity';
import { ContractEdm } from './DataDomain/EFEntities/contractedm.entity';
import { AgencyEdm } from './DataDomain/EFEntities/Agency/agencyedm.entity';
import { TradingStyleEdm } from './DataDomain/EFEntities/Agency/tradingstyleedm.entity';
import { WorkerTypeEdm } from './DataDomain/EFEntities/Worker/workertypeedm.entity';
//#endregion

@Injectable()
export class TradingStylesService extends ODataEntitySetService<TradingStyleEdm> {
  constructor(client: ODataClient) {
    super(client, 'TradingStyles', 'OptiaApi.DataDomain.EFEntities.Agency.TradingStyleEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public agency(key: EntityKey<TradingStyleEdm>): ODataNavigationPropertyResource<AgencyEdm> { 
    return this.entity(key).navigationProperty<AgencyEdm>('agency'); 
  }
  public fetchAgency(key: EntityKey<TradingStyleEdm>, options?: ODataQueryArgumentsOptions<AgencyEdm>) {
    return this.fetchNavigationProperty<AgencyEdm>(
      this.agency(key), 
      'entity', options) as Observable<ODataEntity<AgencyEdm>>;
  }
  public setAgencyEdmAsAgency(key: EntityKey<TradingStyleEdm>, target: ODataEntityResource<ODataEntity<AgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .set(target, {etag});
  }
  public unsetAgencyEdmAsAgency(key: EntityKey<TradingStyleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .unset({etag});
  }
  public agencyReportingCombinations(key: EntityKey<TradingStyleEdm>): ODataNavigationPropertyResource<AgencyReportingCombinationEdm> { 
    return this.entity(key).navigationProperty<AgencyReportingCombinationEdm>('agencyReportingCombinations'); 
  }
  public fetchAgencyReportingCombinations(key: EntityKey<TradingStyleEdm>, options?: ODataQueryArgumentsOptions<AgencyReportingCombinationEdm>) {
    return this.fetchNavigationProperty<AgencyReportingCombinationEdm>(
      this.agencyReportingCombinations(key), 
      'entities', options) as Observable<ODataEntities<AgencyReportingCombinationEdm>>;
  }
  public addAgencyReportingCombinationEdmToAgencyReportingCombinations(key: EntityKey<TradingStyleEdm>, target: ODataEntityResource<ODataEntities<AgencyReportingCombinationEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyReportingCombinations(key).reference()
      .add(target);
  }
  public removeAgencyReportingCombinationEdmFromAgencyReportingCombinations(key: EntityKey<TradingStyleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyReportingCombinationEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyReportingCombinations(key).reference()
      .remove(target);
  }
  public contracts(key: EntityKey<TradingStyleEdm>): ODataNavigationPropertyResource<ContractEdm> { 
    return this.entity(key).navigationProperty<ContractEdm>('contracts'); 
  }
  public fetchContracts(key: EntityKey<TradingStyleEdm>, options?: ODataQueryArgumentsOptions<ContractEdm>) {
    return this.fetchNavigationProperty<ContractEdm>(
      this.contracts(key), 
      'entities', options) as Observable<ODataEntities<ContractEdm>>;
  }
  public addContractEdmToContracts(key: EntityKey<TradingStyleEdm>, target: ODataEntityResource<ODataEntities<ContractEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.contracts(key).reference()
      .add(target);
  }
  public removeContractEdmFromContracts(key: EntityKey<TradingStyleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ContractEdm>>, etag?: string} = {}): Observable<any> {
    return this.contracts(key).reference()
      .remove(target);
  }
  public workerTypes(key: EntityKey<TradingStyleEdm>): ODataNavigationPropertyResource<WorkerTypeEdm> { 
    return this.entity(key).navigationProperty<WorkerTypeEdm>('workerTypes'); 
  }
  public fetchWorkerTypes(key: EntityKey<TradingStyleEdm>, options?: ODataQueryArgumentsOptions<WorkerTypeEdm>) {
    return this.fetchNavigationProperty<WorkerTypeEdm>(
      this.workerTypes(key), 
      'entities', options) as Observable<ODataEntities<WorkerTypeEdm>>;
  }
  public addWorkerTypeEdmToWorkerTypes(key: EntityKey<TradingStyleEdm>, target: ODataEntityResource<ODataEntities<WorkerTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerTypes(key).reference()
      .add(target);
  }
  public removeWorkerTypeEdmFromWorkerTypes(key: EntityKey<TradingStyleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerTypes(key).reference()
      .remove(target);
  }
  //#endregion
}
