//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ReportGroupsWithTypeEdm } from './reportgroupswithtypeedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ReportGroupsWithTypeEdmComplexConfig = {
  name: 'ReportGroupsWithTypeEdm',
  fields: {
    miReportGroupGuid: {type: 'Edm.Guid', nullable: false},
    groupName: {type: 'Edm.String'},
    groupStatus: {type: 'Edm.Int16', nullable: false},
    miReportTypeGuid: {type: 'Edm.Guid', nullable: false},
    typeName: {type: 'Edm.String'},
    typeStatus: {type: 'Edm.Int16', nullable: false}
  }
} as StructuredTypeConfig<ReportGroupsWithTypeEdm>;
//#endregion