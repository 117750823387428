//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyMtdCalendarsService } from './agencymtdcalendars.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AgencyMtdCalendarsServiceEntitySetConfig = {
  name: 'AgencyMtdCalendars',
  entityType: 'OptiaApi.DataDomain.EFEntities.AgencyMtdCalendarEdm',
  service: AgencyMtdCalendarsService
} as EntitySetConfig;
//#endregion