import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ChangeSetEdm } from './DataDomain/EFEntities/Admin/changesetedm.entity';
import { ChangeSetLineEdm } from './DataDomain/EFEntities/Admin/changesetlineedm.entity';
//#endregion

@Injectable()
export class ChangeSetLinesService extends ODataEntitySetService<ChangeSetLineEdm> {
  constructor(client: ODataClient) {
    super(client, 'ChangeSetLines', 'OptiaApi.DataDomain.EFEntities.Admin.ChangeSetLineEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public changeSet(key: EntityKey<ChangeSetLineEdm>): ODataNavigationPropertyResource<ChangeSetEdm> { 
    return this.entity(key).navigationProperty<ChangeSetEdm>('changeSet'); 
  }
  public fetchChangeSet(key: EntityKey<ChangeSetLineEdm>, options?: ODataQueryArgumentsOptions<ChangeSetEdm>) {
    return this.fetchNavigationProperty<ChangeSetEdm>(
      this.changeSet(key), 
      'entity', options) as Observable<ODataEntity<ChangeSetEdm>>;
  }
  public setChangeSetEdmAsChangeSet(key: EntityKey<ChangeSetLineEdm>, target: ODataEntityResource<ODataEntity<ChangeSetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.changeSet(key).reference()
      .set(target, {etag});
  }
  public unsetChangeSetEdmAsChangeSet(key: EntityKey<ChangeSetLineEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<ChangeSetEdm>>, etag?: string} = {}): Observable<any> {
    return this.changeSet(key).reference()
      .unset({etag});
  }
  //#endregion
}
