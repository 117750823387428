//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { RefDataItemEdmEntityConfig } from './refdataitemedm.entity.config';
import { RefDataTypeEdmEntityConfig } from './refdatatypeedm.entity.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const RefDataSchema = {
  namespace: 'OptiaApi.DataDomain.EFEntities.RefData',
  enums: [],
  entities: [RefDataItemEdmEntityConfig,
    RefDataTypeEdmEntityConfig],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion