//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PhoneNumbersService } from './phonenumbers.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PhoneNumbersServiceEntitySetConfig = {
  name: 'PhoneNumbers',
  entityType: 'OptiaApi.DataDomain.EFEntities.PhoneNumberEdm',
  service: PhoneNumbersService
} as EntitySetConfig;
//#endregion