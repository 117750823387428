import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskEdm } from './DataDomain/EFEntities/Workflow/wftaskedm.entity';
import { WfTaskTemplateEdm } from './DataDomain/EFEntities/Workflow/wftasktemplateedm.entity';
import { WfTemplateEdm } from './DataDomain/EFEntities/Workflow/wftemplateedm.entity';
//#endregion

@Injectable()
export class WfTaskTemplatesService extends ODataEntitySetService<WfTaskTemplateEdm> {
  constructor(client: ODataClient) {
    super(client, 'WfTaskTemplates', 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskTemplateEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public wfTask(key: EntityKey<WfTaskTemplateEdm>): ODataNavigationPropertyResource<WfTaskEdm> { 
    return this.entity(key).navigationProperty<WfTaskEdm>('wfTask'); 
  }
  public fetchWfTask(key: EntityKey<WfTaskTemplateEdm>, options?: ODataQueryArgumentsOptions<WfTaskEdm>) {
    return this.fetchNavigationProperty<WfTaskEdm>(
      this.wfTask(key), 
      'entity', options) as Observable<ODataEntity<WfTaskEdm>>;
  }
  public setWfTaskEdmAsWfTask(key: EntityKey<WfTaskTemplateEdm>, target: ODataEntityResource<ODataEntity<WfTaskEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfTask(key).reference()
      .set(target, {etag});
  }
  public unsetWfTaskEdmAsWfTask(key: EntityKey<WfTaskTemplateEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WfTaskEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfTask(key).reference()
      .unset({etag});
  }
  public wfTemplate(key: EntityKey<WfTaskTemplateEdm>): ODataNavigationPropertyResource<WfTemplateEdm> { 
    return this.entity(key).navigationProperty<WfTemplateEdm>('wfTemplate'); 
  }
  public fetchWfTemplate(key: EntityKey<WfTaskTemplateEdm>, options?: ODataQueryArgumentsOptions<WfTemplateEdm>) {
    return this.fetchNavigationProperty<WfTemplateEdm>(
      this.wfTemplate(key), 
      'entity', options) as Observable<ODataEntity<WfTemplateEdm>>;
  }
  public setWfTemplateEdmAsWfTemplate(key: EntityKey<WfTaskTemplateEdm>, target: ODataEntityResource<ODataEntity<WfTemplateEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfTemplate(key).reference()
      .set(target, {etag});
  }
  public unsetWfTemplateEdmAsWfTemplate(key: EntityKey<WfTaskTemplateEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WfTemplateEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfTemplate(key).reference()
      .unset({etag});
  }
  //#endregion
}
