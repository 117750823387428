//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfProcessEdm } from './wfprocessedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfProcessEdmEntityConfig = {
  name: 'WfProcessEdm',
  keys: [{name: 'wfProcessGuid'}],
  fields: {
    wfProcessGuid: {type: 'Edm.Guid', nullable: false},
    ownerGuid: {type: 'Edm.Guid'},
    description: {type: 'Edm.String'},
    tag: {type: 'Edm.String'},
    processType: {type: 'Edm.Int32'},
    flags: {type: 'Edm.Int32'},
    diagramData: {type: 'Edm.String', maxLength: -1},
    wfInstances: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfInstanceEdm', collection: true, navigation: true},
    wfProcessWfSchedules: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfProcessWfScheduleEdm', collection: true, navigation: true},
    wfRules: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfRuleEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<WfProcessEdm>;
//#endregion