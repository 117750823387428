//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerDocumentEdm } from './customerdocumentedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CustomerDocumentEdmEntityConfig = {
  name: 'CustomerDocumentEdm',
  keys: [{name: 'customerGuid'},{name: 'documentGuid'},{name: 'fromDate'}],
  fields: {
    documentGuid: {type: 'Edm.Guid'},
    customerGuid: {type: 'Edm.Guid'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    customer: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerEdm', navigation: true, referentials: [{property: 'customerGuid', referencedProperty: 'customerGuid'}]},
    document: {type: 'OptiaApi.DataDomain.EFEntities.DocumentEdm', navigation: true, referentials: [{property: 'documentGuid', referencedProperty: 'documentGuid'}]}
  }
} as StructuredTypeConfig<CustomerDocumentEdm>;
//#endregion