//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PhoneNumberEdm } from './phonenumberedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PhoneNumberEdmEntityConfig = {
  name: 'PhoneNumberEdm',
  keys: [{name: 'phoneNumberGuid'}],
  fields: {
    phoneNumberGuid: {type: 'Edm.Guid', nullable: false},
    type: {type: 'Edm.Int16', nullable: false},
    countryCode: {type: 'Edm.String', nullable: false},
    number: {type: 'Edm.String', nullable: false},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    personPhoneNumbers: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonPhoneNumberEdm', collection: true, navigation: true},
    workerPhoneNumbers: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerPhoneNumberEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<PhoneNumberEdm>;
//#endregion