//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SupplierPersonsService } from './supplierpersons.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const SupplierPersonsServiceEntitySetConfig = {
  name: 'SupplierPersons',
  entityType: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierPersonEdm',
  service: SupplierPersonsService
} as EntitySetConfig;
//#endregion