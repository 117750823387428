//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyPersonWfRolesService } from './agencypersonwfroles.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AgencyPersonWfRolesServiceEntitySetConfig = {
  name: 'AgencyPersonWfRoles',
  entityType: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyPersonWfRoleEdm',
  service: AgencyPersonWfRolesService
} as EntitySetConfig;
//#endregion