//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerTypeReportingLabelEdm } from './workertypereportinglabeledm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WorkerTypeReportingLabelEdmEntityConfig = {
  name: 'WorkerTypeReportingLabelEdm',
  keys: [{name: 'workerTypeReportingLabelGuid'}],
  fields: {
    workerTypeGuid: {type: 'Edm.Guid'},
    reportingLabelGuid: {type: 'Edm.Guid'},
    workerTypeReportingLabelGuid: {type: 'Edm.Guid', nullable: false},
    reportingLabel: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.ReportingLabelEdm', navigation: true, referentials: [{property: 'reportingLabelGuid', referencedProperty: 'reportingLabelGuid'}]},
    workerType: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeEdm', navigation: true, referentials: [{property: 'workerTypeGuid', referencedProperty: 'workerTypeGuid'}]}
  }
} as StructuredTypeConfig<WorkerTypeReportingLabelEdm>;
//#endregion