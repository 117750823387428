//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerPersonEdm } from './workerpersonedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WorkerPersonEdmEntityConfig = {
  name: 'WorkerPersonEdm',
  keys: [{name: 'fromDate'},{name: 'personGuid'},{name: 'workerGuid'}],
  fields: {
    personGuid: {type: 'Edm.Guid'},
    workerGuid: {type: 'Edm.Guid'},
    personType: {type: 'Edm.Int16', nullable: false},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    workerPersonGuid: {type: 'Edm.Guid'},
    person: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]},
    worker: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerEdm', navigation: true, referentials: [{property: 'workerGuid', referencedProperty: 'workerGuid'}]}
  }
} as StructuredTypeConfig<WorkerPersonEdm>;
//#endregion