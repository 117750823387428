//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonUserGroupsService } from './personusergroups.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PersonUserGroupsServiceEntitySetConfig = {
  name: 'PersonUserGroups',
  entityType: 'OptiaApi.DataDomain.EFEntities.Admin.PersonUserGroupEdm',
  service: PersonUserGroupsService
} as EntitySetConfig;
//#endregion