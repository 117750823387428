//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfOutcomesService } from './wfoutcomes.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WfOutcomesServiceEntitySetConfig = {
  name: 'WfOutcomes',
  entityType: 'OptiaApi.DataDomain.EFEntities.Workflow.WfOutcomeEdm',
  service: WfOutcomesService
} as EntitySetConfig;
//#endregion