//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AgencyPersonEdm } from './agencypersonedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AgencyPersonEdmEntityConfig = {
  name: 'AgencyPersonEdm',
  keys: [{name: 'agencyGuid'},{name: 'contactType'},{name: 'fromDate'},{name: 'personGuid'}],
  fields: {
    agencyGuid: {type: 'Edm.Guid'},
    personGuid: {type: 'Edm.Guid'},
    jobDescription: {type: 'Edm.String'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    contactType: {type: 'Edm.Int16', nullable: false},
    agency: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm', navigation: true, referentials: [{property: 'agencyGuid', referencedProperty: 'agencyGuid'}]},
    person: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<AgencyPersonEdm>;
//#endregion