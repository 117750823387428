//#region ODataApiGen ODataImports
import {
  ApiConfig,
  EDM_PARSERS
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EFEntitiesSchema } from './OptiaApi/DataDomain/EFEntities/efentities.schema';
import { AgencySchema } from './OptiaApi/DataDomain/EFEntities/Agency/agency.schema';
import { AdminSchema } from './OptiaApi/DataDomain/EFEntities/Admin/admin.schema';
import { ReportingSchema } from './OptiaApi/DataDomain/EFEntities/Reporting/reporting.schema';
import { CreditSchema } from './OptiaApi/DataDomain/EFEntities/Credit/credit.schema';
import { CustomerSchema } from './OptiaApi/DataDomain/EFEntities/Customer/customer.schema';
import { NotifySchema } from './OptiaApi/DataDomain/EFEntities/Notify/notify.schema';
import { PersonSchema } from './OptiaApi/DataDomain/EFEntities/Person/person.schema';
import { RefDataSchema } from './OptiaApi/DataDomain/EFEntities/RefData/refdata.schema';
import { SupplierSchema } from './OptiaApi/DataDomain/EFEntities/Supplier/supplier.schema';
import { WorkflowSchema } from './OptiaApi/DataDomain/EFEntities/Workflow/workflow.schema';
import { WorkerSchema } from './OptiaApi/DataDomain/EFEntities/Worker/worker.schema';
import { DTOsSchema } from './OptiaApi/ServiceLayer/DTOs/dtos.schema';
import { OptiaApiSchema } from './OptiaApi/optiaapi.schema';
import { environment } from 'src/environments/environment';
//#endregion

//#region ODataApiGen ApiConfig
export const Optia2023OdataConfig = {
  serviceRootUrl: environment.API,
  name: 'Optia2023Odata',
  version: '4.0',
  creation: new Date('2024-07-22T05:10:05.8987876+00:00'),
  schemas: [
    EFEntitiesSchema,
    AgencySchema,
    AdminSchema,
    ReportingSchema,
    CreditSchema,
    CustomerSchema,
    NotifySchema,
    PersonSchema,
    RefDataSchema,
    SupplierSchema,
    WorkflowSchema,
    WorkerSchema,
    DTOsSchema,
    OptiaApiSchema
  ],
  parsers: EDM_PARSERS
} as ApiConfig;
//#endregion