//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfPreRequisitesService } from './wfprerequisites.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WfPreRequisitesServiceEntitySetConfig = {
  name: 'WfPreRequisites',
  entityType: 'OptiaApi.DataDomain.EFEntities.Workflow.WfPreRequisiteEdm',
  service: WfPreRequisitesService
} as EntitySetConfig;
//#endregion