//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SupplierWorkerTypesService } from './supplierworkertypes.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const SupplierWorkerTypesServiceEntitySetConfig = {
  name: 'SupplierWorkerTypes',
  entityType: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierWorkerTypeEdm',
  service: SupplierWorkerTypesService
} as EntitySetConfig;
//#endregion