import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerAgencyEdm } from './DataDomain/EFEntities/Customer/customeragencyedm.entity';
import { CustomerAgencyPersonEdm } from './DataDomain/EFEntities/Customer/customeragencypersonedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class CustomerAgencyPersonsService extends ODataEntitySetService<CustomerAgencyPersonEdm> {
  constructor(client: ODataClient) {
    super(client, 'CustomerAgencyPersons', 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyPersonEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public customerAgency(key: EntityKey<CustomerAgencyPersonEdm>): ODataNavigationPropertyResource<CustomerAgencyEdm> { 
    return this.entity(key).navigationProperty<CustomerAgencyEdm>('customerAgency'); 
  }
  public fetchCustomerAgency(key: EntityKey<CustomerAgencyPersonEdm>, options?: ODataQueryArgumentsOptions<CustomerAgencyEdm>) {
    return this.fetchNavigationProperty<CustomerAgencyEdm>(
      this.customerAgency(key), 
      'entity', options) as Observable<ODataEntity<CustomerAgencyEdm>>;
  }
  public setCustomerAgencyEdmAsCustomerAgency(key: EntityKey<CustomerAgencyPersonEdm>, target: ODataEntityResource<ODataEntity<CustomerAgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customerAgency(key).reference()
      .set(target, {etag});
  }
  public unsetCustomerAgencyEdmAsCustomerAgency(key: EntityKey<CustomerAgencyPersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<CustomerAgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.customerAgency(key).reference()
      .unset({etag});
  }
  public person(key: EntityKey<CustomerAgencyPersonEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<CustomerAgencyPersonEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<CustomerAgencyPersonEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<CustomerAgencyPersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  //#endregion
}
