import { NgModule } from '@angular/core';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { MsalGuard } from './aadb2cauth/msal.guard';
import { BrowserUtils } from '@azure/msal-browser';
import { GuardedComponent } from './guarded/guarded.component';
import { RouterModule, Routes } from '@angular/router';
import { DashboardPageComponent } from './system/pages/dashboard-page/dashboard-page.component';
import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './components/logout/logout.component';


const routes: Routes = [
  {
    path: 'guarded',
    component: GuardedComponent,
    data: {
      breadcrumb: 'Guarded'
    },
    canActivate: [
      MsalGuard
    ]
  },
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {
      breadcrumb: 'Home'
    },
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: {
      breadcrumb: 'Logout'
    }
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardPageComponent,
    data: {
      breadcrumb: 'Dashboard'
    },
    canActivate: [
      MsalGuard
    ],
    loadChildren: () =>
      import('./system/system.module').then(m => m.SystemModule)
  },
  {
    path: 'agencies',
    canActivate: [
      MsalGuard
    ],
    data: {
      breadcrumb: 'Agencies'
    },
    loadChildren: () =>
      import('./agency/agency.module').then(m => m.AgencyModule)
  },

  {
    path: 'customers',
    data: {
      breadcrumb: 'Customers'
    },
    canActivate: [
      MsalGuard
    ],
    loadChildren: () =>
      import('./customer/customer.module').then(m => m.CustomerModule)
  },
  {
    path: 'workers',
    data: {
      breadcrumb: 'Workers'
    },
    canActivate: [
      MsalGuard
    ],
    loadChildren: () =>
      import('./worker/worker.module').then(m => m.WorkerModule)
  },
  {
    path: 'suppliers',
    data: {
      breadcrumb: 'Suppliers'
    },
    canActivate: [
      MsalGuard
    ],
    loadChildren: () =>
      import('./suppliers/suppliers.module').then(m => m.SuppliersModule)
  },
  {
    path: 'management-information',
    canActivate: [
      MsalGuard
    ],
    data: {
      breadcrumb: 'Management-Information'
    },
    loadChildren: () =>
      import('./management-information/management-information.module').then(m => m.ManagementInformationModule)
  },
  {
    path: 'manage',
    canActivate: [
      MsalGuard
    ],
    data: {
      breadcrumb: 'User Management'
    },
    loadChildren: () =>
      import('./manage/manage.module').then(m => m.ManageModule)
  },
  {
    path: 'system',
    canActivate: [
      MsalGuard
    ],
    data: {
      breadcrumb: 'System'
    },
    loadChildren: () =>
      import('./system/system.module').then(m => m.SystemModule)
  },
  {
    path: 'signup',
    data: {
      breadcrumb: 'Signup'
    },
    loadChildren: () =>
      import('./sign-up/signup.module').then(m => m.SignupModule)
  },
  {
    path: 'optia-news',
    canActivate: [
      MsalGuard
    ],
    data: {
      breadcrumb: 'Optia News'
    },
    loadChildren: () =>
      import('./optia-news/optia-news.module').then(m => m.OptiaNewsModule)
  },
  {
    path: 'documents',
    canActivate: [
      MsalGuard
    ],
    data: {
      breadcrumb: 'Documents'
    },
    loadChildren: () =>
      import('./document/document.module').then(m => m.DocumentModule)
  },
  // { path: 'page-not-found', component: PageNotFoundComponent },
  // { path: '**', redirectTo: 'page-not-found' },

]


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
