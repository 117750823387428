//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OutstandingActionEdm } from './outstandingactionedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const OutstandingActionEdmComplexConfig = {
  name: 'OutstandingActionEdm',
  fields: {
    taskDescription: {type: 'Edm.String'},
    taskType: {type: 'Edm.Int32', nullable: false},
    wfActionGuid: {type: 'Edm.Guid', nullable: false},
    wfInstanceGuid: {type: 'Edm.Guid', nullable: false},
    wfTaskGuid: {type: 'Edm.Guid', nullable: false},
    wfOutcomeGuid: {type: 'Edm.Guid'},
    wfRuleGuid: {type: 'Edm.Guid'},
    ownerGuid: {type: 'Edm.Guid'},
    requested: {type: 'Edm.DateTimeOffset', nullable: false},
    completed: {type: 'Edm.DateTimeOffset'},
    tag: {type: 'Edm.String'},
    reminderDue: {type: 'Edm.DateTimeOffset'},
    completionDue: {type: 'Edm.DateTimeOffset'},
    comments: {type: 'Edm.String', maxLength: -1},
    branchIndex: {type: 'Edm.Int32'},
    changeSetGuid: {type: 'Edm.Guid'},
    changeSetEntity: {type: 'Edm.Int16'},
    agencyGuid: {type: 'Edm.Guid', nullable: false},
    agencyKnownAsName: {type: 'Edm.String'},
    agencyShortCode: {type: 'Edm.String'},
    agencyExactLegalName: {type: 'Edm.String'},
    customerGuid: {type: 'Edm.Guid'},
    customerAgencyGuid: {type: 'Edm.Guid'},
    customerTradingName: {type: 'Edm.String'},
    accountGuid: {type: 'Edm.Guid'},
    accountReference: {type: 'Edm.String'},
    contractGuid: {type: 'Edm.Guid'},
    contractName: {type: 'Edm.String'},
    contractFromDate: {type: 'Edm.DateTimeOffset'},
    contractToDate: {type: 'Edm.DateTimeOffset'},
    workerFirstName: {type: 'Edm.String'},
    workerSurname: {type: 'Edm.String'},
    workerGuid: {type: 'Edm.Guid'},
    assignedPersonGuid: {type: 'Edm.Guid', nullable: false},
    positiveOutcomeGuid: {type: 'Edm.Guid', nullable: false},
    negativeOutcomeGuid: {type: 'Edm.Guid', nullable: false}
  }
} as StructuredTypeConfig<OutstandingActionEdm>;
//#endregion