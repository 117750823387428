//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonDepartmentEdm } from './persondepartmentedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PersonDepartmentEdmEntityConfig = {
  name: 'PersonDepartmentEdm',
  keys: [{name: 'departmentGuid'},{name: 'fromDate'},{name: 'personGuid'}],
  fields: {
    personGuid: {type: 'Edm.Guid'},
    departmentGuid: {type: 'Edm.Guid'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    department: {type: 'OptiaApi.DataDomain.EFEntities.DepartmentEdm', navigation: true, referentials: [{property: 'departmentGuid', referencedProperty: 'departmentGuid'}]},
    person: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<PersonDepartmentEdm>;
//#endregion