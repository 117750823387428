import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { LoggedInUserDTO } from './ServiceLayer/DTOs/loggedinuserdto.entity';
import { PermissionDTO } from './ServiceLayer/DTOs/permissiondto.entity';
//#endregion

@Injectable()
export class LoggedInUserDTOService extends ODataEntitySetService<LoggedInUserDTO> {
  constructor(client: ODataClient) {
    super(client, 'LoggedInUserDTO', 'OptiaApi.ServiceLayer.DTOs.LoggedInUserDTO');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public userPermissions(key: EntityKey<LoggedInUserDTO>): ODataNavigationPropertyResource<PermissionDTO> { 
    return this.entity(key).navigationProperty<PermissionDTO>('userPermissions'); 
  }
  public fetchUserPermissions(key: EntityKey<LoggedInUserDTO>, options?: ODataQueryArgumentsOptions<PermissionDTO>) {
    return this.fetchNavigationProperty<PermissionDTO>(
      this.userPermissions(key), 
      'entities', options) as Observable<ODataEntities<PermissionDTO>>;
  }
  public addPermissionDTOToUserPermissions(key: EntityKey<LoggedInUserDTO>, target: ODataEntityResource<ODataEntities<PermissionDTO>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.userPermissions(key).reference()
      .add(target);
  }
  public removePermissionDTOFromUserPermissions(key: EntityKey<LoggedInUserDTO>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PermissionDTO>>, etag?: string} = {}): Observable<any> {
    return this.userPermissions(key).reference()
      .remove(target);
  }
  //#endregion
}
