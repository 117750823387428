//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerPhoneNumbersService } from './workerphonenumbers.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WorkerPhoneNumbersServiceEntitySetConfig = {
  name: 'WorkerPhoneNumbers',
  entityType: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerPhoneNumberEdm',
  service: WorkerPhoneNumbersService
} as EntitySetConfig;
//#endregion